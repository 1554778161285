<orbit-modal-base [hasCloseBtn]="true">
  <ion-card-content style="max-height: calc(100% - var(--ion-safe-area-bottom) - 44px); overflow: auto;"
    class="ion-no-padding p-bottom-20">
    <ion-grid fixed class="ion-no-padding">
      <ion-row class="ion-justify-content-center ion-no-padding ion-align-items-center">
        <ion-col size="12" class="ion-no-padding">
          <h4 class="ion-text-center font-size-48-i line-100 font-weight-850-i p-bottom-16 ">
            ➕
          </h4>
          <h4 class="ion-text-center p-bottom-16 font-size-18-i font-weight-850-i">
            {{"general.add_photo_options_title" | translate}}
          </h4>
          <p class="ion-text-center p-bottom-16">{{"general.add_photo_options_desc" | translate}}</p>
        </ion-col>
        <ion-col size="12">
          <div class="d-flex row gap-12">
            @for(item of list; track item.id){
            <div class="flex-1">
              <ion-card button color="light" (click)="itemClicked(item)" [id]="'add-photo-option-' + item.key + '-btn'"
                class="m-all-0 p-vertical-12">
                <ion-grid fixed>
                  <ion-row class="d-flex ion-justify-content-center ion-align-items-center">
                    <ion-col size="auto">
                      <ion-icon class="font-size-32" [src]="item.icon | orbitIcons"></ion-icon>
                    </ion-col>
                    <ion-col size="auto">
                      <span class="font-size-13-i font-weight-550-i ">{{item.value}}</span>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card>
            </div>
            }
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</orbit-modal-base>