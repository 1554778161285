import { Injectable } from "@angular/core";
import { generate, observable, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ConstantsApiService } from "../api/constants-api.service";
import { ICropResponse } from "../models/interfaces/crop-response";
import { ICropVarietyResponse } from "../models/interfaces/crop-variety-response";
import { IDistrictModel, IGenericConstantModel, IVillageModel } from "../models/interfaces/generic-constant-model";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { AppConstantsService } from "./app-constants.service";

@Injectable({
    providedIn: "root"
})
export class DoktarConstantsService {

    cropList: Array<ICropResponse>
    irrigationCrops: Array<ICropResponse>
    // cropVarietyList: Array<ICropVarietyResponse>
    cityList: Array<any>
    districtList: Array<any>
    villageList: Array<any>


    constructor(private constantsApi: ConstantsApiService, private appConstants: AppConstantsService) {

    }

    public RequestCropList(refresh: boolean = false): Observable<Array<ICropResponse>> {
        return new Observable<Array<ICropResponse>>(observer => {
            if (!refresh && Array.isArray(this.cropList) && this.cropList.length > 0) {
                observer.next(this.cropList)
                observer.complete()
            } else {
                this.constantsApi.GetCrops().subscribe(cropResponse => {
                    if (cropResponse.IsSuccess && Array.isArray(cropResponse.Data)) {
                        this.cropList = cropResponse.Data
                    } else {
                        this.cropList = []
                    }
                    observer.next(this.cropList)
                    observer.complete()
                }, err => {
                    observer.error(err)
                    observer.complete()
                })
            }
        })
    }

    public RequestIrrigationAvailableCropList(refresh: boolean = false) {
        return new Observable<Array<ICropResponse>>(observer => {
            if (!refresh && Array.isArray(this.irrigationCrops) && this.irrigationCrops.length > 0) {
                observer.next(this.irrigationCrops)
                observer.complete()
            } else {
                this.constantsApi.GetIrrigationCrops().subscribe({
                    next: irrigationCropResponse => {
                        if (irrigationCropResponse.IsSuccess && Array.isArray(irrigationCropResponse.Data)) {
                            this.irrigationCrops = irrigationCropResponse.Data
                        } else {
                            this.irrigationCrops = []
                        }
                        observer.next(this.irrigationCrops)
                        observer.complete()
                    },
                    error: err => {
                        observer.error(err)
                        observer.complete()
                    }
                })
            }
        })
    }

    public RequestCropVariety(cropId: number) {
        return new Observable<Array<ICropVarietyResponse>>(observer => {
            if (cropId) {
                this.constantsApi.GetCropVarieties(cropId).subscribe(varietyResponse => {
                    if (varietyResponse.IsSuccess)
                        observer.next(varietyResponse.Data)
                    else {
                        observer.error(varietyResponse.Message)
                    }
                    observer.complete()
                }, err => {
                    observer.error(err.Message)
                    observer.complete()
                })
            } else {
                observer.error("invalid_crop_id")
                observer.complete()
            }
        })
    }

    public RequestCityList(isInTurkey: boolean) {
        return new Observable<Array<IGenericConstantModel>>(observer => {
            this.constantsApi.GetCityList(isInTurkey).subscribe(response => {
                if (response.IsSuccess && response.Data)
                    observer.next(response.Data)
                else {
                    let genError = {
                        Data: null,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                }
                observer.complete()
            }, error => {
                let genError = {
                    Data: error,
                    IsSuccess: false,
                    Message: "error_on_request",
                    StatusCode: 0
                } as IGenericResponse<any>
                observer.error(genError)
                observer.complete()
            })
        })
    }

    public RequestDistrictList(cityId: number) {
        return new Observable<Array<IDistrictModel>>(observer => {
            this.constantsApi.GetDistrictList(cityId).subscribe(response => {
                if (response.IsSuccess && response.Data)
                    observer.next(response.Data)
                else {
                    let genError = {
                        Data: null,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                }
                observer.complete()
            }, error => {
                let genError = {
                    Data: error,
                    IsSuccess: false,
                    Message: "error_on_request",
                    StatusCode: 0
                } as IGenericResponse<any>
                observer.error(genError)
                observer.complete()
            })
        })
    }

    public RequestVillageList(districtId: number) {
        return new Observable<Array<IVillageModel>>(observer => {
            this.constantsApi.GetVillageList(districtId).subscribe(response => {
                if (response.IsSuccess && response.Data)
                    observer.next(response.Data)
                else {
                    let genError = {
                        Data: null,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                }
                observer.complete()
            }, error => {
                let genError = {
                    Data: error,
                    IsSuccess: false,
                    Message: "error_on_request",
                    StatusCode: 0
                } as IGenericResponse<any>
                observer.error(genError)
                observer.complete()
            })
        })
    }

    public RequestParcelCityList() {
        return new Observable<Array<IGenericConstantModel>>(observer => {
            this.constantsApi.GetParcelCityList().subscribe(response => {
                if (response.IsSuccess && response.Data)
                    observer.next(response.Data)
                else {
                    let genError = {
                        Data: null,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                }
                observer.complete()
            }, error => {
                let genError = {
                    Data: error,
                    IsSuccess: false,
                    Message: "error_on_request",
                    StatusCode: 0
                } as IGenericResponse<any>
                observer.error(genError)
                observer.complete()
            })
        })
    }
    public RequestParcelDistrictList(cityId: number) {
        return new Observable<Array<IGenericConstantModel>>(observer => {
            this.constantsApi.GetParcelDistrictList(cityId).subscribe(response => {
                if (response.IsSuccess && response.Data)
                    observer.next(response.Data)
                else {
                    let genError = {
                        Data: null,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                }
                observer.complete()
            }, error => {
                let genError = {
                    Data: error,
                    IsSuccess: false,
                    Message: "error_on_request",
                    StatusCode: 0
                } as IGenericResponse<any>
                observer.error(genError)
                observer.complete()
            })
        })
    }
    public RequestParcelPlaceList(districtId: number) {
        return new Observable<Array<IGenericConstantModel>>(observer => {
            this.constantsApi.GetParcelPlaceList(districtId).subscribe(response => {
                if (response.IsSuccess && response.Data)
                    observer.next(response.Data)
                else {
                    let genError = {
                        Data: null,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                }
                observer.complete()
            }, error => {
                let genError = {
                    Data: error,
                    IsSuccess: false,
                    Message: "error_on_request",
                    StatusCode: 0
                } as IGenericResponse<any>
                observer.error(genError)
                observer.complete()
            })
        })
    }

    private _problemList: Array<IGenericConstantModel>
    public RequestProblemList() {
        return new Observable<Array<IGenericConstantModel>>(observer => {
            if (this._problemList) {
                observer.next(this._problemList)
                observer.complete()
            } else
                this.constantsApi.GetGroupList(918).subscribe(response => {

                    if (response.IsSuccess && response.Data) {
                        this._problemList = response.Data.map(t => {
                            t.Color = this.appConstants.orbitConstants.problem_colors[t.ConstantId]
                            t.Order = this.appConstants.orbitConstants.problems_order[t.ConstantId]
                            return t;
                        })
                        observer.next(response.Data)
                    }
                    else {
                        let genError = {
                            Data: null,
                            IsSuccess: false,
                            Message: "error_on_request",
                            StatusCode: 0
                        } as IGenericResponse<any>
                        observer.error(genError)
                    }
                    observer.complete()
                }, error => {
                    let genError = {
                        Data: error,
                        IsSuccess: false,
                        Message: "error_on_request",
                        StatusCode: 0
                    } as IGenericResponse<any>
                    observer.error(genError)
                    observer.complete()
                })
        })
    }

    private _tagList: Array<IGenericConstantModel>
    public RequestTagList(): Observable<Array<IGenericConstantModel>> {
        return new Observable(observer => {
            if (this._tagList && this._tagList.length > 0) {
                observer.next(this._tagList)
                observer.complete()
            } else {
                this.constantsApi.GetCustomTags().subscribe(response => {
                    if (response.IsSuccess) {
                        this._tagList = response.Data
                        observer.next(this._tagList)
                        observer.complete()
                    } else {
                        observer.error(response)
                        observer.complete()
                    }
                }, err => {
                    observer.error(err)
                    observer.complete()
                })
            }
        })
    }

    public RequestTagListByProblem(problemId: number): Observable<IGenericResponse<Array<IGenericConstantModel>>> {
        return this.constantsApi.GetTagsByProblem(problemId)
    }

    private _waterTransferlist: Array<IGenericConstantModel>
    public RequestWaterTransferTypeList(): Observable<Array<IGenericConstantModel>> {
        return new Observable(observer => {
            if (this._waterTransferlist && this._waterTransferlist.length > 0) {
                observer.next(this._waterTransferlist)
                observer.complete()
            } else {
                this.constantsApi.GetGroupList(1451).subscribe(response => {
                    if (response.IsSuccess) {
                        this._waterTransferlist = response.Data
                        observer.next(this._waterTransferlist)
                        observer.complete()
                    } else {
                        observer.error(response)
                        observer.complete()
                    }
                }, err => {
                    observer.error(err)
                    observer.complete()
                })
            }
        })
    }

    private _irrigationTypeList: Array<IGenericConstantModel>
    public RequestIrrigationTypeList(): Observable<Array<IGenericConstantModel>> {
        return new Observable(observer => {
            if (this._irrigationTypeList && this._irrigationTypeList.length > 0) {
                observer.next(this._irrigationTypeList)
                observer.complete()
            } else {
                this.constantsApi.GetGroupList(2).subscribe({
                    next: response => {
                        if (response.IsSuccess) {
                            this._irrigationTypeList = response.Data
                            observer.next(this._irrigationTypeList)
                            observer.complete()
                        } else {
                            observer.error(response)
                            observer.complete()
                        }
                    }, error: err => {
                        observer.error(err)
                        observer.complete()
                    }
                })
            }
        })
    }

    private _soilTypeList: Array<IGenericConstantModel>
    public RequestSoilTypeList(): Observable<Array<IGenericConstantModel>> {
        return new Observable(observer => {
            if (this._soilTypeList && this._soilTypeList.length > 0) {
                observer.next(this._soilTypeList)
                observer.complete()
            } else {
                this.constantsApi.GetGroupList(3).subscribe(response => {
                    if (response.IsSuccess) {
                        this._soilTypeList = response.Data
                        observer.next(this._soilTypeList)
                        observer.complete()
                    } else {
                        observer.error(response)
                        observer.complete()
                    }
                }, err => {
                    observer.error(err)
                    observer.complete()
                })
            }
        })
    }

    public RequestConstantByGroupId(groupId: number) {
        return this.constantsApi.GetGroupList(groupId)
    }
}