import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: '[orbitBackButton]'
})
export class BackButtonDirective {
  @HostListener('click', ['$event'])
  onClick(event) {
    this.goBack(event)
  }

  @Input() defaultHref: string = null
  constructor(private navigationService: NavigationService) { }

  goBack(ev) {
    this.navigationService.goBack(this.defaultHref);
  }
}
