import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../services/date-time.service';

@Pipe({
  name: 'momentFromNow'
})
export class MomentFromNowPipe implements PipeTransform {

  constructor(private momentService: DateTimeService) { }
  transform(date: string) {
    if (date == null) {
      return 'N/A';
    }
    return this.momentService.fromNow(date);
  }


}
