import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpRequestTypes } from "../models/enums/http-request-types";
import { ICropResponse } from "../models/interfaces/crop-response";
import { ICropVarietyResponse } from "../models/interfaces/crop-variety-response";
import { IDistrictModel, IGenericConstantModel, IVillageModel } from "../models/interfaces/generic-constant-model";
import { ClientBaseApiService } from "./client-base-api.service";

@Injectable({
    providedIn: "root"
})
export class ConstantsApiService {
    /**
     *
     */
    constructor(private clientApi: ClientBaseApiService) {

    }

    public GetCrops() {
        let url = environment.baseApiUrl + "get/gp/crop/list";
        return this.clientApi.baseApiCall<Array<ICropResponse>>({ url, payload: null, requestType: HttpRequestTypes.POST })
    }
    
    public GetIrrigationCrops() {
        let url = environment.gatewayApiUrl + "v2/api/irrigation/available/crops";
        return this.clientApi.baseApiCall<Array<ICropResponse>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetCropVarieties(cropId: number) {
        let url = `${environment.baseApiUrl}get/gp/crop/variety/list?cropId=${cropId}`
        return this.clientApi.baseApiCall<Array<ICropVarietyResponse>>({ url, payload: null, requestType: HttpRequestTypes.POST })
    }

    public GetCityList(isInTurkey: boolean) {
        let url = `${environment.baseApiUrl}city/list?isCityInTurkey=${isInTurkey}`
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }
    public GetDistrictList(cityId: number) {
        let url = `${environment.baseApiUrl}district/list?cityId=${cityId}`
        return this.clientApi.baseApiCall<Array<IDistrictModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }
    public GetVillageList(districtId: number) {
        let url = `${environment.baseApiUrl}village/list?districtId=${districtId}`
        return this.clientApi.baseApiCall<Array<IVillageModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetParcelCityList() {
        let url = `${environment.baseApiUrl}get/parcel/city/list`
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }
    public GetParcelDistrictList(cityId: number) {
        let url = `${environment.baseApiUrl}get/parcel/${cityId}/district/list`
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }
    public GetParcelPlaceList(districtId: number) {
        let url = `${environment.baseApiUrl}get/parcel/${districtId}/place/list`
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetGroupList(groupId) {
        let url = `${environment.baseApiUrl}constants/list/?groupId=${groupId}`;
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetCustomTags() {
        let url = `${environment.baseApiUrl}get/custom/tags`
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetTagsByProblem(problemId){
        // https://doktarnewapi.azurewebsites.net/v2/api/tag/custom/list?campaignId=113&constantsId=919
        let url = `${environment.gatewayApiUrl}v2/api/tag/custom/list?campaignId=113&constantsId=${problemId}`
        return this.clientApi.baseApiCall<Array<IGenericConstantModel>>({url, payload: null, requestType: HttpRequestTypes.GET})
    }

}