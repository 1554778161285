import { Injectable } from "@angular/core";
import { ModalController, ModalOptions } from "@ionic/angular";
import { ComponentRef } from "@ionic/core";
import { Subject } from "rxjs";
import { CalendarPickerModalComponent } from "../helper/components/calendar-picker-modal/calendar-picker-modal.component";
import { GenericListModalComponent } from "../helper/components/generic-list-modal/generic-list-modal.component";
import { ListModalComponent } from "../helper/components/list-modal/list-modal.component";
import { OnboardingTutorialComponent } from "../helper/components/onboarding-tutorial/onboarding-tutorial.component";
import { PageOnboardingComponent } from "../helper/components/page-onboarding/page-onboarding.component";
import { IListModalComponentModel } from "../models/interfaces/modal-components-models";
import { IModalItem } from "../models/interfaces/modal-item";
import { PullupItemListModalComponent } from "../helper/components/pullup-item-list-modal/pullup-item-list-modal.component";
import { modalHorizontalEnterAnimation, modalHorizontalLeaveAnimation } from "../animations/modal-animations";

@Injectable({
    providedIn: "root"
})
export class ModalService {
    private _modalSubject: Subject<{ modalId, data }> = new Subject<{ modalId, data }>()
    modals: Array<IModalItem> = []
    static defaultOptions: ModalOptions<ComponentRef> = { animated: false, initialBreakpoint: 0.98, cssClass: "modal-fullscreen modal-radius", showBackdrop: true } as ModalOptions<ComponentRef>
    static fullPageOptions: ModalOptions<ComponentRef> = { animated: false, initialBreakpoint: 1, cssClass: "modal-fullscreen", backdropBreakpoint: 1, showBackdrop: true } as ModalOptions<ComponentRef>
    static networkAlertOptions: ModalOptions<ComponentRef> = { animated: false, initialBreakpoint: 1, cssClass: "modal-fullscreen modal-networkalert", backdropDismiss: false, keyboardClose: false, canDismiss: true, backdropBreakpoint: 1, showBackdrop: true } as ModalOptions<ComponentRef>
    static defaultOptionsTransparent: ModalOptions<ComponentRef> = { animated: false, initialBreakpoint: 1, cssClass: "modal-fullscreen modal-transparent", backdropBreakpoint: 1, showBackdrop: true } as ModalOptions<ComponentRef>
    /**
     *
     */
    constructor(private modalController: ModalController) {

    }

    public ModalChangeListener() {
        return this._modalSubject.asObservable();
    }

    public SubjectNext(modalId, data) {
        this._modalSubject.next({ modalId, data })
    }

    public async presentModal(props: IModalItem) {
        if (!props.id)
            props.id = Date.now();
        if (!props.component)
            return
        if (props.componentProps) {
            props.componentProps.id = props.id;
        } else {
            props.componentProps = {
                id: props.id
            }
        }
        let modalProps = props.options || {} as ModalOptions<ComponentRef>
        if (props.options.cssClass === '')
            props.options.cssClass = "modal-fullscreen"
        else {
            props.options.cssClass += " modal-fullscreen"
        }
        modalProps.component = props.component;
        modalProps.componentProps = props.componentProps;

        if (props.hasPageAnimations) {
            modalProps.enterAnimation = modalHorizontalEnterAnimation
            modalProps.leaveAnimation = modalHorizontalLeaveAnimation
        } else {
            modalProps.enterAnimation = null
            modalProps.leaveAnimation = null
        }

        if (modalProps.initialBreakpoint)
            modalProps.componentProps.initialBreakpoint = modalProps.initialBreakpoint
        // {
        //     component: props.component,
        //     componentProps: props.componentProps,

        // }
        const modal = await this.modalController.create(modalProps);

        await modal.present();
        props.modalObj = modal;
        this.modals.push(props);
        return props
    }

    public getModalItem(modalId: number) {
        return this.modals.find(t => t.id === modalId);
    }

    public async dismissByModalId(modalId: number, data, role?) {
        let modalItem = this.getModalItem(modalId);
        if (modalItem) {
            let tempModal = modalItem.modalObj;
            return await tempModal.dismiss(data, role);
        } else {
            return false;
        }
    }

    public async modalOnDidDismiss(modalId: number) {
        let modalItem = this.getModalItem(modalId);
        if (modalItem) {
            let tempModal = modalItem.modalObj;
            return await tempModal.onDidDismiss()
        } else
            return null;
    }


    /**
     * title ve confirmBtnText component içerisinde translate edilmektedir.
     * translate keyleri halinde gönderilebilir.
     * 
     * @param componentProps  
     * @param dismissable 
     * @returns 
     */
    public presentListModal(componentProps: IListModalComponentModel & { isFullscreen: boolean }, dismissable = false) {
        return this.presentModal({
            component: ListModalComponent, componentProps, options: componentProps.isFullscreen ? ModalService.fullPageOptions :
                { cssClass: "modal-transparent", backdropDismiss: dismissable } as ModalOptions<ComponentRef>
        })
    }
    /**
 * title ve confirmBtnText component içerisinde translate edilmektedir.
 * translate keyleri halinde gönderilebilir.
 * 
 * @param componentProps  
 * @param dismissable 
 * @returns 
 */
    public presentPullupItemListModal(componentProps: IListModalComponentModel & { isFullscreen: boolean }, dismissable = false) {
        return this.presentModal({
            component: PullupItemListModalComponent, componentProps, options: componentProps.isFullscreen ? ModalService.defaultOptions :
                { cssClass: "modal-transparent", backdropDismiss: dismissable } as ModalOptions<ComponentRef>
        })
    }

    public presentGenericListModal(componentProps: any, dismissable = false) {
        return this.presentModal({
            component: GenericListModalComponent,
            componentProps,
            options: { cssClass: "modal-transparent", backdropDismiss: dismissable }
        })
    }

    public openCalendarModal({ title, startDate, preSelected, min, max = null, isBottomSheet, options, selectionMode = null }) {
        let subject = new Subject<any>();
        this.presentModal({
            component: CalendarPickerModalComponent,
            componentProps: {
                title,
                startDate,
                preSelected,
                min,
                max,
                isBottomSheet,
                selectionMode
            },
            options: { cssClass: "modal-transparent modal-alert", backdropDismiss: true }
        }).then(response => {
            response.modalObj.onDidDismiss().then(dismiss => {
                if (dismiss.role == "selected") {
                    subject.next(dismiss.data)
                } else
                    subject.error(dismiss.role)
                subject.complete();
            }).catch(error => {
                subject.error("error_on_calendar_modal")
                subject.complete();
            })
        }).catch(err => {
            subject.error("error_on_modal")
            subject.complete();
        })
        return subject.asObservable()
    }

    /**
     * App seviyesinde onboarding tutorial
     * @param props 
     * @returns 
     */
    public presentOnboardTutorialModal(props: any = {}) {
        return this.presentModal({ component: OnboardingTutorialComponent, componentProps: {}, options: { cssClass: "modal modal-onboarding" } })
    }

    /**
     * Page, component seviyesinde onboarding tutorial
     * PageOnboardingModule eklenmesi gerekli
     * @param props 
     * @returns 
     */
    public presentPageTutorialModal(props: any = []) {
        return this.presentModal({ component: PageOnboardingComponent, componentProps: { list: props }, options: { cssClass: "modal modal-onboarding" } })
    }
}