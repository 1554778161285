<orbit-modal-base [hasCloseBtn]="true" [hasHandle]="true" [isBottomSheet]="true">
  <!-- <ion-toolbar bg="none" headerarea>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss(null)" possibleOutcome="close modal" id="dismiss-add-media-sheet-btn" color="dark">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar> -->
  <ion-card-content style="max-height: calc(100% - var(--ion-safe-area-bottom) - 44px); overflow: auto;" class="ion-no-padding p-bottom-20">
    <ion-grid fixed class="ion-no-padding">
      <ion-row class="ion-justify-content-center ion-no-padding ion-align-items-center">
        <ion-col size="12">
          <h3 class="font-size-18-i font-weight-900-i ion-text-center" [innerHTML]="'media-options.select-media-type' | translate"></h3>
          <p class="ion-text-center">{{"media-options.select-media-type-desc" | translate}}</p>
        </ion-col>
        @for(item of list; track item.key){
        <ion-col size="4">
          <ion-card button color="light" class="height-80 m-horizontal-6" (click)="itemClicked(item)" [id]="'media-options-' + item.key +'-btn'">
            <div class="d-flex column justify-center align-center gap-4">
              <ion-icon [src]="item.icon | orbitIcons" class="font-size-24"></ion-icon>
              <span class="ion-text-center font-size-13 line-100">{{item.value}}</span>
            </div>
          </ion-card>
        </ion-col>
        }
        <!-- <ion-col size="12" class="ion-no-padding">
          <ion-list bg="none">
            <ion-item shape="round" class="m-vertical-5" lines="none" *ngFor="let item of list; let index = index"
              (click)="itemClicked(item)" bg="none" [id]="'media-options-' + item.key +'-btn'">
              <ion-icon slot="start" [name]="item.icon"></ion-icon>
              <ion-label>{{item.value}}</ion-label>
              <ion-icon color="dark" slot="end" name="chevron-forward-outline"></ion-icon>

            </ion-item>
          </ion-list>
        </ion-col> -->
      </ion-row>
    </ion-grid>
  </ion-card-content>
</orbit-modal-base>