import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SCREEN_NAME_BY_COMPONENT_NAME } from 'src/app/core/constants/url-screenname.constant';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { AccountService } from 'src/app/core/services/account.service';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'orbit-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss', '../../auth.page.scss'],
})
export class NewPasswordComponent extends BaseModal implements OnInit {
  // passwordForm: UntypedFormGroup = null
  passwordVisibility: boolean = false
  passwordFormControl = new FormControl()
  @Input() otpKey: any;
  @Input() phoneNumber: string;
  isSend: boolean = false;
  passwordMatch: boolean = true;
  constructor(private loadingService: LoadingService,
    private analyticService:AnalyticService,
    private accountService: AccountService, private langService:LanguageService,
    private router: Router, private modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    let that = this
    this.passwordFormControl.addValidators([Validators.minLength(6), Validators.maxLength(14), Validators.required])
  }

  save() {
      let payload = {
        "PhoneNumber": this.phoneNumber,
        "Otp": this.otpKey,
        "NewPassword": this.passwordFormControl.value
      }
      this.passwordFormControl.valid
      this.accountService.SetNewPassword(payload).then(e => {
        if (e.type === Message_Type.SUCCESS) {
          this.isSend = true;
        } else if (e.type === Message_Type.WARNING) {
          this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.data[0]), type: Message_Type.WARNING, duration: 3000 });
        } else {
          this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.data[0]), type: Message_Type.DANGER, duration: 3000 });
        }
      });
  }


  redirect() {
    this.dismiss(null);
    this.router.navigate(["/tabs/map"], { replaceUrl: true });
  }  
  openModal() {
    this.langService.openOptionsMenu()
  }
  back(){
    this.analyticService.currentScreenName = this.analyticService.previousScreenName
    this.dismiss(null)
  }
}
