import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonInput, IonicModule } from '@ionic/angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { MaskitoModule } from '@maskito/angular';
import { MaskitoElementPredicateAsync } from '@maskito/core';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, MaskitoModule],
  selector: 'orbit-arpa-input',
  templateUrl: './arpa-input.component.html',
  styleUrls: ['./arpa-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ArpaInputComponent),
    multi: true
  }]
})
export class ArpaInputComponent implements OnInit,OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild("inputElement") inputElement: IonInput

  @Input() type: TextFieldTypes
  @Input() placeholder: string
  @Input() label: string
  @Input() labelPlacement: 'fixed' | 'stacked' | 'floating'
  @Input() maskito
  // @Input() maskitoElement //:  MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();
  disabled = false;
  borderStyle: string = ""

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes => ", changes)
  }
  ngOnDestroy(): void {
    console.log("input - ngOnDestroy")
  }

  writeValue(obj: any): void {
    console.log("writeValue",obj)
    // throw new Error('Method not implemented.');
    this.val = obj
  }
  registerOnChange(fn: any): void {
    console.log("registerOnChange",fn)
    // throw new Error('Method not implemented.');
      this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    console.log("registerOnTouched",fn)
    // throw new Error('Method not implemented.');
      this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
    console.log("set disabled => ", isDisabled)
    this.disabled = isDisabled
  }

  getInputElement = () => {
    return this.inputElement.getInputElement()
  }

  ngOnInit() { 
    console.log("input - oninit")
  }
  onChange: any = () => { }
  onTouch: any = () => { }
  val = "" // this is the updated value that the class accesses
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val !== undefined && this.val !== val) {
      this.val = val
      this.onChange(val)
      this.onTouch(val)
    }
  }

  onFocused() {
    this.borderStyle = 'focused'
    this.onTouch(this.value)
  }

  onInputChanged(ev) {
    this.value = ev.detail.value
    this.onChange(this.val)
  }

  onChangeListener(ev) {
  }
}

type TextFieldTypes =
  | 'date'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'time'
  | 'week'
  | 'month'
  | 'datetime-local';
