import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { ModalService } from 'src/app/core/services/modal.service';
import { AccountService } from "src/app/core/services/account.service";
import { LoadingService } from 'src/app/core/services/loading.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { IButtonOpts, ListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { LanguageService } from 'src/app/core/services/lang.service';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { StorageService } from 'src/app/core/services/storage.service';
import { IntlPhoneInputService } from 'src/app/core/services/intl-phone.service';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { environment } from 'src/environments/environment';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { SCREEN_NAME_BY_COMPONENT_NAME } from 'src/app/core/constants/url-screenname.constant';


@Component({
  selector: 'orbit-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss', './../../auth.page.scss'],
})
export class ForgetPasswordComponent extends BaseModal implements OnInit {
  @ViewChild("smsValidation") smsValidation: any
  countryCodes: string = "us"
  componentType = ComponentType.FULL_INPUT_SEPERATED
  forgotForm: UntypedFormGroup;
  stages = {

  }
  phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();

  selectedCountry
  showCodeSendedMessage: boolean = false;
  isSendCode: boolean = false;
  constructor(private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private alertService: ClientAlertService,
    private analyticService:AnalyticService,
    private storageService: StorageService,
    private intlPhoneService: IntlPhoneInputService,
    private langService:LanguageService,
    private translateService: TranslateService) {
    super()
    super._modalService = modalService

    let rawList = translateService.getBrowserCultureLang().split("-")

    if (rawList.length > 1) {
      let code = rawList[1].toLowerCase();
      this.countryCodes = code + "," + this.countryCodes
    } else {
      this.countryCodes = rawList[0] + "," + this.countryCodes
    }
    if (LanguageService.CountryCodeFromIP && LanguageService.CountryCodeFromIP !== '')
      this.countryCodes = LanguageService.CountryCodeFromIP + "," + this.countryCodes
    this.generateMaskAndPlaceholder()

  }
  placeholder
  generateMaskAndPlaceholder() {
    this.intlPhoneService.GetCountryCodes().subscribe({
      next: (ev: Array<any>) => {

        if (!this.selectedCountry)
          this.selectedCountry = ev.find(t => t.iso2 == this.countryCodes.split(",")[0])

        let results = this.intlPhoneService.GeneratePlaceholderAndMask(this.selectedCountry)
        let splittedMask = results.mask.split("").map(t => {
          return Number.parseInt(t) >= 0 ? /\d/ : t
        })
        this.phoneMask = { mask: splittedMask }
        this.dialCode = this.selectedCountry.dialCode
        this.placeholder = results.placeholder
      }
    })
  }

  ngOnInit() {
    // this.analyticService.RecordeScreenView("Forget Password-1")
    this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.ForgetPasswordComponent.screenName
    this.setupForm()
  }

  setupForm() {
    this.forgotForm = this.formBuilder.group({
      phone: ["", [OrbitFormValidators.phoneNumberValidator(), Validators.required]],
    });
  }


  currentCountryInfo
  dialCode
  lastPhonenumber
  rawPhoneNumber
  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        this.storageService.set(StorageKeys.PHONE_CODE, { dialCode: this.dialCode }).then(res => { })
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.rawPhoneNumber = ev.rawValue
        this.lastPhonenumber = phone
        if (this.currentCountryInfo) {
          this.forgotForm.patchValue({ phone: phone }, { emitEvent: true })
        } else {

        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          this.forgotForm.patchValue({ phone: null }, { emitEvent: true })
          // this.loginForm.controls["phone"].markAsDirty()
        }
        break;
      default:
        break;
    }
  }

  requestCode() {
    // this.isSendCode = true;
    let phone = this.forgotForm.get("phone").value.split("").map(t => !Number.isNaN(Number.parseInt(t)) ? t : null).filter(t => t).join("")
    this.lastPhonenumber = phone;
    let payload = {
      "PhoneNumber": `${this.dialCode}${phone}`,
      "Otp": null,
      "NewPassword": null
    }
    this.loadingService.showLoading();
    this.showCodeSendedMessage = false
    this.accountService.RequestSmsCode(payload).then(e => {
      // this.loadingService.hideLoading();
      if (e.type === Message_Type.SUCCESS) {
        this.smsValidation?.startTimer()
        this.isSendCode = true;
      } else if (e.type === Message_Type.WARNING) {
        this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.data[0]), type: Message_Type.WARNING, duration: 3000 });
      } else {
        this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.data[0]), type: Message_Type.WARNING, duration: 3000 });
      }
    }).catch(err => {
      // this.loadingService.hideLoading();
      console.log(err)
      if (err.data[0].Message === 'password-has-not-expired') {
        this.isSendCode = true;
        this.showCodeSendedMessage = true
      } else if(err.data[0].Message === "record-not-found"){
        this.toastService.PresentBasicToast({ message: this.translateService.instant("auth.forget_pass_record_not_found"), type: Message_Type.DANGER, duration: 3000 }).then();
      }else
        this.toastService.PresentBasicToast({ message: this.translateService.instant("errors.error_on_sms_request"), type: Message_Type.DANGER, duration: 3000 }).then();
    }).finally(() => {
      this.loadingService.hideLoading();
    });
  }

  confirm(otp) {
    let phone = this.forgotForm.get("phone").value.split("").map(t => !Number.isNaN(Number.parseInt(t)) ? t : null).filter(t => t).join("")
    let payload = {
      "PhoneNumber": `${this.dialCode}${phone}`,
      "Otp": otp,
      "NewPassword": null
    }
    this.loadingService.showLoading();
    this.showCodeSendedMessage = false
    this.accountService.ConfirmSmsCode(payload).then(res => {
      this.loadingService.hideLoading();

      let opts = ModalService.defaultOptionsTransparent;
      opts.cssClass = "modal-fullscreen modal-auth"
      this.modalService.presentModal({
        component: NewPasswordComponent,
        componentProps: res,
        options: opts
      }).then(modalFP => {
        this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.ForgetPasswordComponentNewPassword.screenName
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          this.dismiss(null);
        });
      });
      this.isSendCode = true;
    }).catch(err => {
      this.loadingService.hideLoading();
      let buttons: IButtonOpts[] = [
        {
          callback: () => { },
          id: "forget-password-error-alert-btn",
          color: "primary",
          fill: "clear",
          text: this.translateService.instant("auth.ok")
        }
      ]
      this.alertService.PresentAlert({
        buttons,
        description: this.translateService.instant("auth." + err.data),
        title: this.translateService.instant("auth.error_on_sms_confirm_title")
      }, false).then()
    });
  }
  openFlagsModal() {
    this.loadingService.showLoading()
    this.intlPhoneService.GetCountryCodes().subscribe(codesRes => {
      this.modalService.presentListModal({
        hasConfirmBtn: false,
        isFullscreen: true,
        isMultiple: false,
        showSearchbar: true,
        searchPlaceholder: this.translateService.instant("general.search_countries"),
        itemList: codesRes.map(t => new ListModalModel({ id: t.iso2, data: t, value: `${t.name} (+${t.dialCode})`, icon: environment.countryFlags + t.iso2.toUpperCase() + '.svg', iconSource: "image", iconStyle: "width:28px;height:20px", iconSlot: "start" })),
        title: this.translateService.instant("general.countries"),
        confirmBtnText: null,
      }).then(resPresenter => {
        this.loadingService.hideLoading()
        resPresenter.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.role == "itemSelected") {
            this.selectedCountry = dismiss.data.selectedItem.data
            this.countryCodes = this.selectedCountry.iso2
            this.generateMaskAndPlaceholder()
          }
        })
      })
    })
  }
  openModal() {
    this.langService.openOptionsMenu()
  }
  override dismiss(data: any, role?: string): Promise<void> {
    this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.LoginComponent.screenName
    return super.dismiss(data,role)  
  }
}
