<ion-content (click)="dismissModal()" possibleOutcome="close modal" id="list-modal-ion-content" [ngClass]="contentClass" class="bg-default" forceOverscroll="false"
  style="overflow: hidden;"
  [style]="{'--padding-top': (bottomPadding > 0 ?  'calc(' + bottomPadding + '% + 66px)' : '66px')}">
  <div style="display: flex; height:100%; flex-direction: column; justify-content: flex-end">
    <ion-card (click)="$event.stopPropagation()" possibleOutcome="not_allowed" color="light" id="list-modal-container-card"
      class="ion-no-margin p-top-0 p-bottom-30 p-horizontal-16 custom-ion-card modal-card-area"
      [ngClass]="isFullscreen ? 'full-height': 'max-height-98'">
      <ion-toolbar bg="none" *ngIf="detailedToolbar">
        <ion-buttons slot="start">
          <ion-button (click)="dismiss(null)" id="list-modal-back-btn">
            <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title *ngIf="title && title !== ''" class="p-horizontal-60">
          <span class="break-spaces">
            {{title | translate}}
          </span>
        </ion-title>
        <ion-buttons slot="end" *ngIf="hasConfirmBtn">
          <ion-button (click)="clear()" id="list-modal-clear-selections">
            {{"clear" | translate}}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar bg="none" *ngIf="!detailedToolbar">
        <ion-title *ngIf="title && title !== ''" class="p-horizontal-0-i custom">
          <span class="break-spaces">
            {{title | translate}}
          </span>
        </ion-title>
      </ion-toolbar>
      <ion-searchbar *ngIf="showSearchbar" class="custom" [placeholder]="searchPlaceholder" inputmode="search" id="list-modal-searchbar"
        type="text" (ionInput)="onSearchChange($event)" [debounce]="250" clearIcon></ion-searchbar>
      <!-- hasConfirmBtn ? showSearchbar ? 'with-button with-searchbar' : 'with-button' : showSearchbar ? 'with-searchbar' : '' -->
      <ion-card-content class="ion-no-padding card-content"
        [ngClass]="{'with-button': hasConfirmBtn, 'with-searchbar': showSearchbar}">
        <ion-grid fixed [ngStyle]="{'max-height': 'calc(100% - ' + areaHeight + 'px)'}" class="ion-no-padding">
          <ion-row class="ion-justify-content-center ion-no-padding ion-align-items-center">
            <ion-col size="12" class="ion-no-padding">
              <ion-list bg="none">
                <ion-item bg="none" lines="full" *ngFor="let item of filteredList; let index = index"
                  (click)="itemClicked(item);" [id]="'list-modal-items-' + index">
                  <ng-container *ngIf="item.icon">
                    <img *ngIf="item.iconSource === 'image'" [style]="item.iconStyle" [slot]="item.iconSlot"
                      [src]="item.icon" alt="" srcset="">
                    <ion-icon *ngIf="item.iconSource === 'ionIcon'" [style]="item.iconStyle" [slot]="item.iconSlot"
                      [name]="item.icon">
                    </ion-icon>
                    <ion-icon *ngIf="item.iconSource === 'asset'" [style]="item.iconStyle" [slot]="item.iconSlot"
                      [src]="item.icon">
                    </ion-icon>
                  </ng-container>
                  <ion-label>{{item.value | translate}}</ion-label>

                  <ion-icon *ngIf="isMultiple; else singleSelect" [color]="item.isChecked ? 'primary' : 'dark'"
                    slot="end" [name]="item.isChecked ? 'checkmark-circle' : 'ellipse-outline'">
                  </ion-icon>
                  <ng-template #singleSelect>
                    <ion-icon color="primary" slot="end"
                      [src]="( isMultiple ? (item.isChecked ? 'select-radio' : 'select-radio-empty') : selectedList && selectedList[0]?.id === item.id ? 'select-radio' : 'select-radio-empty') | orbitIcons">
                    </ion-icon>
                  </ng-template>
                  <!-- <ion-radio mode="md"  color="primary" slot="end"></ion-radio> -->
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
      <ion-button *ngIf="hasConfirmBtn" (click)="applyBtn()" id="list-modal-confirm-btn" expand="block" fill="solid" shape="round">
        {{confirmBtnText | translate}}
      </ion-button>
    </ion-card>
  </div>
</ion-content>