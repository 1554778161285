<ion-content  >
  <ion-header class="onboarding-header" style="background-color: #FFFFFF00;">
    <ion-toolbar bg="none">
      <img slot="start" class="width-40 m-left-8" src="./../../../../../assets/icon/orbit-app-icon-light.svg" alt="">
      <ion-buttons slot="end">
        <ion-button color="white" (click)="openModal()" possibleOutcome="open modal" id="onboarding-lang-options">
          <span class="font-size-14 font-weight-700">
            {{"lang.default" | translate}} <ion-icon class="m-left-5" style="margin-bottom:-3px" name="chevron-down-outline"></ion-icon>
          </span>
          <!-- <ion-icon slot="icon-only" style="border-radius: 100%;"
            [src]="'./../../../../../assets/lang-flags/' + langService.getcurrentLanguage().code +'.svg' "></ion-icon> -->
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <swiper #swiperItem [slidesPerView]="1" [direction]="'horizontal'" [effect]="'creative'" [grabCursor]="true" [centeredSlides]="true"
  [slidesPerView]="'auto'" [autoplay]="true" [creativeEffect]="swiperEffectStyle" [pagination]="false" class="onboarding-tutorial-swiper" (slideChange)="slideChanged($event)">
  <ng-template swiperSlide *ngFor="let item of list;let i=index;">
    <div class="onboarding-bg" [style.backgroundImage]="list ? 'url(' +item.imageUrl +')' : ''">
    
      <div class="page-container" #swipeArea id="swipeArea">

        <div [innerHTML]="item.title | htmlTranslate"></div>
        <!-- <p class="font-size-28 font-weight-900 m-top-0 m-bottom-12">
          {{item.title}}
        </p> -->
        <div [innerHTML]="item.description | htmlTranslate"></div>
        <!-- <p class="font-size-14 font-weight-400 m-top-0 m-bottom-80">{{item.description}}</p> -->

    
      </div>
    </div>
  </ng-template>
</swiper>
<div class="onboarding-footer">
  <div class="slider-index-bullets" *ngIf="list">
    <div *ngFor="let item of list" [ngClass]="item.id == (current + 1) ? 'active-bullet bullet' : 'bullet'" ></div>
  </div>

  <ion-button color="primary" expand="block" fill="solid" shape="round" (click)="openAddField()" id="onboarding-go-add-field-btn">
    {{'onboarding.start_with_free_trial' | translate}}
  </ion-button>

  <div [innerHTML]="'onboarding.have_an_account_signin_register_lbl' | htmlTranslate"></div>  
  
</div>
</ion-content>