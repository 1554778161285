import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormatDatePipe } from '../pipes/format-date.pipe';
import { MomentFromNowPipe } from '../pipes/moment-from-now.pipe';
import { IsTodayPipe } from '../pipes/is-today.pipe';



@NgModule({
  declarations: [FormatDatePipe, MomentFromNowPipe, IsTodayPipe],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports:[FormatDatePipe, MomentFromNowPipe, IsTodayPipe]
})
export class DatePipeModule { }
