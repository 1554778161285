import { Injectable } from "@angular/core";
import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics";
import { Capacitor } from "@capacitor/core";

@Injectable({
    providedIn: "root"
})
export class FirebaseCrashService {

    /**
     *
     */
    constructor() {

    }

    async CheckAutoTrackIsEnabled() {
        if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android")
            return await FirebaseCrashlytics.isEnabled()
        else
            return new Promise((resolve, reject) => { resolve(null) })
    }

    async EnableAutoTrack() {
        if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android")
            return await FirebaseCrashlytics.setEnabled({ enabled: true });
        else
            return new Promise((resolve, reject) => { resolve(null) })
    }

    async DisableAutoTrack() {
        if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android")
            return await FirebaseCrashlytics.setEnabled({ enabled: false });
        else
            return new Promise((resolve, reject) => { resolve(null) })
    }

    async SendCrashLog(message) {
        if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android")
            return FirebaseCrashlytics.addLogMessage({ message });
        else
            return new Promise((resolve, reject) => { resolve(null) })
    }

    async RecordException(message: string, code: number, domain: string, payload: string) {
        if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android")
           {
            let _message = JSON.stringify({ message, payload})
             return FirebaseCrashlytics.recordException({ message: _message, code, domain })
            }
        else
            return new Promise((resolve, reject) => { resolve(null) })
    }

}