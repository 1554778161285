import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as mapboxgl from 'mapbox-gl';
import { environment } from "src/environments/environment";
import * as turf from '@turf/turf';
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { ExtendDrawBar } from "../helper/class/extended-toolbar";
import { IAddPolygonParams, IGeoJSONObj, IMapBasicParams, IMapBuild, IMarkerModel } from "../models/interfaces/map-fn-props";
import { ICoords } from "../models/interfaces/coords";
import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic';
import { LocationService } from "./location.service";
import { UtilsService } from "./utils.service";


const styles = [
    // ACTIVE (being drawn)
    // line stroke
    {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#FFB83D",
            "line-dasharray": [1],
            "line-width": 4
        }
    },
    // polygon fill
    {
        "id": "gl-draw-polygon-fill",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "paint": {
            "fill-color": "#FFB83D",
            "fill-outline-color": "#FFB83D",
            "fill-opacity": 0.24
        }
    },
    // polygon mid points
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']],
        'paint': {
            'circle-radius': 6,
            'circle-color': '#FFFFFF'
        }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        "id": "gl-draw-polygon-stroke-active",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#FFB83D",
            "line-dasharray": [1],
            "line-width": 4
        }
    },
    // vertex point halos
    {
        "id": "gl-draw-polygon-and-line-vertex-halo-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 11,
            "circle-color": "#FFFFFF"
        }
    },
    // vertex points
    {
        "id": "gl-draw-polygon-and-line-vertex-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 10,
            "circle-color": "#ffb83d"
        }
    },

    // INACTIVE (static, already drawn)
    // line stroke
    {
        "id": "gl-draw-line-static",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#000",
            "line-width": 3
        }
    },
    // polygon fill
    {
        "id": "gl-draw-polygon-fill-static",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        "paint": {
            "fill-color": "#000",
            "fill-outline-color": "#000",
            "fill-opacity": 0.1
        }
    },
    // polygon outline
    {
        "id": "gl-draw-polygon-stroke-static",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#000",
            "line-width": 3
        }
    }
]

/**
 *              -------ÖNEMLİ------
 * 
 *      harita için eklenen listenerları off etmek için, on aşamasında kullanılan 
 *  callback fonksiyonu kullanılmalı. Aksi taktirde listener kapatılmıyor ve hata da almıyorsunuz.
 *  Arka arkaya tekrar açılıyor.
 * 
 * 
 * 
 */
@Injectable({
    providedIn: "root"
})
export class MapService {

    // style = 'mapbox://styles/mapbox/satellite-v9'; //'mapbox://styles/mstfcinar/clauw5ytp00cv15p6rt35c4i7'
    /**
     *
     */
    constructor() {
        // mapboxgl.accessToken = environment.mapbox.accessToken;
    }

    buildMap(prop: IMapBuild): Observable<mapboxgl.Map> {
        let returnMap: mapboxgl.Map;
        return new Observable(observer => {
            let options: any = {
                container: prop.mapId,
                accessToken: environment.mapbox.accessToken,
                projection: 'globe'
            }
            if (prop.style)
                options.style = prop.style
            if (prop.zoom)
                options.zoom = prop.zoom
            if (prop.center)
                options.center = [prop.center.lng, prop.center.lat]
            returnMap = new mapboxgl.Map(options);
            returnMap.on('load', () => {
                returnMap.setFog({})
                // returnMap.resize()
                // returnMap.addControl(new mapboxgl.FullscreenControl());
                // returnMap.addControl(new mapboxgl.NavigationControl());
                prop.onLoadCallback(returnMap);
                returnMap.on("zoom", (ev) => {
                    this.resizeMap(returnMap)
                })
            });
            observer.next(returnMap);
        });
    }

    destroyMap(map: mapboxgl.Map) {
        if (map)
            map.remove()
    }

    public resizeMap(map) {
        if (map)
            map = map.resize()
        return map
    }

    public mapAddNavigationCtrl(map) {
        map.addControl(new mapboxgl.NavigationControl())
        return map
    }
    public mapAddFullscreenCtrl(map) {
        map.addControl(new mapboxgl.FullscreenControl())
        return map
    }

    public addDrawControls(map, scope, callback) {
        // Darwing
        const modes = MapboxDraw.modes;

        let draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                trash: true
            },
        });
        var drawBar = new ExtendDrawBar({
            scope: scope,
            draw: draw,
            buttons: [{
                on: 'click',
                action: () => {
                    draw.changeMode("draw_polygon");
                },
                classes: [],
                content: '<span><i class="fas fa-square"></i></span>'
            }]
        });
        map.addControl(drawBar);
        callback(map)
        // Darawing END
    }

    //click yerine touchStart eventı tetiklendiği durumlarda
    // mapbox-gl-draw.js:1 Unable to preventDefault inside passive event listener invocation.
    // şeklinde bir hata dönüyor. draw event listenerları çalışıyor sorunsuz
    public drawPolygon(props: IMapBasicParams) {
        // let modes = MapboxDraw.modes;
        // modes = MapboxDrawGeodesic.enable(modes);
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            touchEnabled: true,
            boxSelect: false,
            userProperties: true,
            controls: {
                // trash: true
            },
            styles,
            defaultMode: 'draw_polygon'
        })

        props.map.addControl(draw);

        props.map.on('draw.create', props.callback)
        props.map.on('draw.delete', props.callback)
        props.map.on('draw.update', props.callback)
        props.map.on('draw.render', props.callback)
        // props.map.on('draw.combine',props.callback)
        // props.map.on('draw.uncombine',props.callback)
        props.map.on('draw.modechange', props.callback)
        props.map.on('draw.actionable', props.callback)
        props.map.on('draw.selectionchange', props.callback)

        return draw
    }

    public customDrawPolygon(props: IMapBasicParams, vertextListener: Observable<any>): Observable<any> {
        props.map = props.map as mapboxgl.Map
        return new Observable(observer => {
            let drawingMarkersArray: Array<mapboxgl.Marker> = []
            let lineStringsArray: Array<{ source: string, layer: string }> = []
            let polygonCoordinates: Array<Array<number>> = []



            let deleteFn = () => {
                if (lineStringsArray.length > 0) {
                    let lastLineString = lineStringsArray.pop();
                    props.map.removeLayer(lastLineString.layer);
                    props.map.removeSource(lastLineString.source);
                }
                if (drawingMarkersArray.length > 0) {
                    let lastMarker = drawingMarkersArray.pop();
                    lastMarker.remove();
                }
                if (polygonCoordinates.length >= 3) {
                    this._removeDrawingPolygonShape(props.map)
                    polygonCoordinates.pop()
                    this._createDrawingPolygonShape(props.map, polygonCoordinates)
                } else if (polygonCoordinates.length === 2) {
                    this._removeDrawingPolygonShape(props.map)
                    polygonCoordinates.pop()
                } else if (polygonCoordinates.length > 0) {
                    polygonCoordinates.pop()
                }
                let geojsobObj: IGeoJSONObj = {
                    geometry: {
                        coordinates: [[...polygonCoordinates, polygonCoordinates[0]]],
                        type: "Polygon"
                    },
                    properties: {},
                    type: "Feature"
                }
                observer.next({ state: "last_vertex_deleted", data: geojsobObj })
            }


            let clickListenerFn = ev => {
                var doc = document.createElement('div');
                doc.className = "farm-drawing-marker";
                doc.id = 'drawingMarker_' + drawingMarkersArray.length + "_" + Date.now();

                if (drawingMarkersArray.length === 0) {

                    doc.className = "farm-drawing-marker first-marker";
                    doc.addEventListener('click', function (e) {
                        // Prevent the `map.on('click')` from being triggered
                        e.stopPropagation();
                        finishDrawingBeginUpdate()
                    });
                } else {

                    // ilk kordinat kontrolü
                    if (drawingMarkersArray.length >= 3 && polygonCoordinates[0][0] == ev.lngLat.lng && polygonCoordinates[0][0] == ev.lngLat.lat) {
                        finishDrawingBeginUpdate()
                    } else {
                        let lastMarker = drawingMarkersArray[drawingMarkersArray.length - 1];
                        let lineIdSuffix = lineStringsArray.length + "_" + Date.now()
                        props.map.addSource(`route_${lineIdSuffix}`, {
                            type: "geojson",
                            data: {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                    coordinates: [
                                        [ev.lngLat.lng, ev.lngLat.lat],
                                        [lastMarker.getLngLat().lng, lastMarker.getLngLat().lat]
                                    ]
                                }
                            }
                        })
                        props.map.addLayer({
                            id: `linestring_${lineIdSuffix}`,
                            type: "line",
                            source: `route_${lineIdSuffix}`,
                            layout: {
                                "line-cap": "round",
                                "line-join": "round"
                            },
                            paint: {
                                "line-color": "#FFF",
                                "line-width": 3,
                                "line-dasharray": [3, 2, 3, 2]
                            }
                        })
                        lineStringsArray.push({ source: `route_${lineIdSuffix}`, layer: `linestring_${lineIdSuffix}` })
                    }
                }
                let markerObj = new mapboxgl.Marker(doc, { anchor: "center" })
                markerObj.setLngLat([ev.lngLat.lng, ev.lngLat.lat])
                markerObj.addTo(props.map)
                drawingMarkersArray.push(markerObj)

                polygonCoordinates.push([ev.lngLat.lng, ev.lngLat.lat])
                let geojsobObj: IGeoJSONObj = {
                    geometry: {
                        coordinates: [[...polygonCoordinates, polygonCoordinates[0]]],
                        type: "Polygon"
                    },
                    properties: {},
                    type: "Feature"
                }
                if (drawingMarkersArray.length >= 3) {
                    this._removeDrawingPolygonShape(props.map)
                    this._createDrawingPolygonShape(props.map, polygonCoordinates)
                    if (this.checkIfCoordsCross([[...polygonCoordinates, polygonCoordinates[0]]]))
                        observer.next({ state: "linesCrossed", data: geojsobObj })
                    else
                        observer.next({ state: "render", data: geojsobObj })
                } else
                    observer.next({ state: "start", data: geojsobObj })
            }

            props.map = props.map.on('click', clickListenerFn)

            var finishDrawingBeginUpdate = () => {
                if (drawingMarkersArray && drawingMarkersArray.length >= 3) {
                    this._removeDrawingPolygonShape(props.map);
                    drawingMarkersArray.forEach(el => {
                        el.remove();
                    })
                    lineStringsArray.forEach(el => {
                        if (props.map.getLayer(el.layer))
                            props.map.removeLayer(el.layer);
                        if (props.map.getSource(el.source))
                            props.map.removeSource(el.source);
                    });
                    drawingMarkersArray = []
                    lineStringsArray = []
                    let drawingObj = new MapboxDraw({
                        displayControlsDefault: false,
                        touchEnabled: true,
                        boxSelect: false,
                        userProperties: true,
                        controls: {
                            // trash: true
                        },
                        styles,
                        defaultMode: 'draw_polygon',
                    })
                    props.map.addControl(drawingObj)
                    props.map.on('draw.delete', props.callback)
                    props.map.on('draw.update', props.callback)
                    props.map.on('draw.render', props.callback)
                    let geojsonCoords: any = [...polygonCoordinates, polygonCoordinates[0]]
                    let featureId = "drawing_item_" + Math.ceil(Math.random() * 1000)
                    let geojsonObj: GeoJSON.Feature = {
                        geometry: {
                            type: "Polygon",
                            coordinates: [geojsonCoords]
                        },
                        id: featureId,
                        properties: {},
                        type: "Feature"
                    }
                    drawingObj.add(geojsonObj)
                    drawingObj.changeMode("direct_select", { featureId })
                    // props.callback(drawingObj)
                    observer.next({ state: "finished", data: geojsonObj, draw: drawingObj })
                    polygonCoordinates = []
                    deleteVertexListener.unsubscribe()
                    deleteVertexListener = undefined
                    props.map = props.map.off("click", clickListenerFn)
                    observer.complete();
                }
            }

            var deleteVertexListener = vertextListener.subscribe(res => {
                switch (res.state) {
                    case "delete":
                        deleteFn()
                        break;
                    case "complete":
                        finishDrawingBeginUpdate()
                        break;
                    default:
                        break;
                }
            })
        })
    }

    private _createDrawingPolygonShape(map: mapboxgl.Map, coordinates) {
        let geojsonCoords: any = [...coordinates, coordinates[0]]
        map.addSource("polgonDrawingItemSource", {
            type: "geojson",
            data: {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "Polygon",
                    coordinates: [geojsonCoords]
                }
            }
        })

        map.addLayer({
            id: "polgonDrawingItemLayer",
            type: "fill",
            source: "polgonDrawingItemSource",
            layout: {},
            paint: {
                'fill-color': environment.mapLayerStyle.fill, //'#0080ff', // blue color fill
                'fill-opacity': environment.mapLayerStyle.opacity//0.5
            }
        })
    }

    private _removeDrawingPolygonShape(map) {
        if (map.getLayer("polgonDrawingItemLayer"))
            map.removeLayer("polgonDrawingItemLayer")
        if (map.getSource("polgonDrawingItemSource"))
            map.removeSource("polgonDrawingItemSource")
    }

    public checkIfPolygonLinesCross(draw: MapboxDraw) {
        let geojson = draw.getAll()
        let turfPolygon = turf.polygon(geojson.features[0].geometry["coordinates"])
        let turfKink = turf.kinks(turfPolygon)
        return turfKink.features.length > 0
    }

    public checkIfCoordsCross(prop: number[][][]) {
        let turfPolygon = turf.polygon(prop)
        let turfKink = turf.kinks(turfPolygon)
        return turfKink.features.length > 0
    }

    public clickToGetCoords(props: IMapBasicParams) {
        props.map.on("click", props.callback)
        return props.map
    }


    public addEvent<T extends IMapBasicParams & { eventName: string }>(props: T) {
        let _map: mapboxgl.Map;
        props.map.on(props.eventName, props.callback)
    }

    public removeEvent<T extends IMapBasicParams & { eventName: keyof mapboxgl.MapLayerEventType, layerId?: string }>(props: T) {
        let map: mapboxgl.Map = props.map
        if (props.layerId)
            map = map.off(props.eventName, props.layerId, props.callback)
        else
            map = map.off(props.eventName, props.callback)
        return map
    }

    /**
     *@description tüm geojsonları tek layer olarak haritaya yükler.
     */
    public addPolygonsAllAtOnce<T extends IMapBasicParams & IAddPolygonParams & { geojsons: Array<IGeoJSONObj>, zoomLimit?: number, beforeId?: string }>(props: T) {
        let sourceData: any = {
            type: "geojson",
            data: {
                'type': 'FeatureCollection',
                'features': props.geojsons
            }
        }
        let sourceId = "all_farms"
        if (props.map.getSource(sourceId)) {
            if (props.map.getLayer(`${sourceId}_polygons`))
                props.map.removeLayer(`${sourceId}_polygons`)
            if (props.map.getLayer(`${sourceId}_points`))
                props.map.removeLayer(`${sourceId}_points`)
            if (props.map.getLayer(`${sourceId}_outline`))
                props.map.removeLayer(`${sourceId}_outline`)
            props.map.removeSource(sourceId)
        }
        props.map.addSource(sourceId, sourceData)
        // let map: mapboxgl.Map
        if (!props.map.getLayer(props.beforeId))
            props.beforeId = null;
        props.map.addLayer({
            id: `${sourceId}_polygons`,
            type: "fill",
            source: sourceId,
            layout: {},
            minzoom: props.zoomLimit == undefined || props.zoomLimit == null ? environment.homeMapZoomLimit : props.zoomLimit,
            paint: {
                'fill-color': ['get', 'fill'], // environment.mapLayerStyle.fillColor, //'#0080ff', // blue color fill
                'fill-opacity': ['get', 'opacity'] // environment.mapLayerStyle.opacity//0.5
            },
            filter: ['==', '$type', 'Polygon']
        }, props.beforeId)
        props.map.addLayer({
            'id': `${sourceId}_points`,
            'type': 'circle',
            'source': sourceId,
            minzoom: environment.homeMapZoomLimit,
            'paint': {
                'circle-radius': 8,
                'circle-stroke-width': 2,
                'circle-color': 'red',
                'circle-stroke-color': 'white'
            },
            'filter': ['==', '$type', 'Point']
        });
        props.map.addLayer({
            'id': `${sourceId}_outline`,
            'type': 'line',
            'source': sourceId,
            'layout': {
            },
            minzoom: environment.homeMapZoomLimit,
            'paint': {
                'line-color': ['get', 'stroke'], // environment.mapLayerStyle.lineColor, // '#000',
                'line-width': ['get', 'stroke-width'] // environment.mapLayerStyle.lineWidth // 3
            },
            filter: ['==', '$type', 'Polygon']
        }, props.beforeId)


        if (props.callback && props.addClickEvent) {
            props.map.on("click", sourceId + "_points", props.callback)

            props.map.on("click", sourceId + "_polygons", props.callback)
        }
        return props.map;
    }

    public addPolygonMarkersAllAtOnce<T extends IMapBasicParams & IAddPolygonParams & { geojsons: Array<IGeoJSONObj> }>(props: T) {
        let sourceCluster: any = {
            type: "geojson",
            data: {
                'type': 'FeatureCollection',
                'features': props.geojsons
            },
            cluster: true,
        }
        let sourceData: any = {
            type: "geojson",
            data: {
                'type': 'FeatureCollection',
                'features': props.geojsons
            },
            cluster: false
        }
        let map: mapboxgl.Map
        let sourceId = "all_points"

        if (props.map.getSource(`${sourceId}_cluster`)) {
            if (props.map.getLayer(`${sourceId}_cluster`))
                props.map.removeLayer(`${sourceId}_cluster`)
            props.map.removeSource(`${sourceId}_cluster`)
        }
        if (props.map.getSource(sourceId)) {
            if (props.map.getLayer(`${sourceId}_markers`))
                props.map.removeLayer(`${sourceId}_markers`)
            if (props.map.getLayer(`${sourceId}_farmnames`))
                props.map.removeLayer(`${sourceId}_farmnames`)
            props.map.removeSource(sourceId)
        }

        props.map.addSource(`${sourceId}_cluster`, sourceCluster)
        props.map.addSource(sourceId, sourceData)

        let actions = () => {

            props.map.addLayer({
                'id': `${sourceId}_cluster`,
                'type': "symbol",
                source: `${sourceId}_cluster`,
                layout: {
                    'icon-image': 'farm_marker'
                },

                maxzoom: environment.homeMapZoomLimit - 4,
                // maxzoom: environment.homeMapZoomLimit,
                // paint: {
                //     'circle-radius': 8,
                //     'circle-stroke-width': 2,
                //     'circle-color': 'red',
                //     'circle-stroke-color': 'white'
                // },
                filter: ['has', 'point_count'],
                // filter: ['==', '$type', 'Point']
            })

            props.map.addLayer({
                'id': `${sourceId}_markers`,
                'type': "symbol",
                source: sourceId,
                layout: {
                    'icon-image': 'farm_marker',
                    "icon-allow-overlap": true,
                    // 'text-field': ['get', 'title'],
                    // "text-padding": 10,
                    // "text-anchor": "top"
                },
                // ,
                // paint: {
                //     "text-color": "#333333",
                //     "text-halo-color": "#FF0000"
                // },
                minzoom: environment.homeMapZoomLimit - 5,
                // maxzoom: environment.homeMapZoomLimit,
                // paint: {
                //     'circle-radius': 8,
                //     'circle-stroke-width': 2,
                //     'circle-color': 'red',
                //     'circle-stroke-color': 'white'
                // },
                filter: ['==', '$type', 'Point']
            })

            props.map.addLayer({
                'id': `${sourceId}_farmnames`,
                'type': "symbol",
                source: sourceId,
                layout: {
                    'text-field': ['get', 'title'],
                    "text-padding": 100,
                    "text-anchor": "top",
                    'text-size': 16,
                    'text-line-height': 1.5,
                    // 'text-letter-spacing': 0.05,
                    'text-offset': [0, 2]
                },
                paint: {
                    "text-color": "#FFFFFF",
                    // "text-halo-color": "#FFFFFF",
                    // 'text-color': '#202',
                    // 'text-halo-color': '#fff',
                    // 'text-halo-width': 5
                },
                minzoom: environment.homeMapZoomLimit - 5,
                // maxzoom: environment.homeMapZoomLimit,
                // paint: {
                //     'circle-radius': 8,
                //     'circle-stroke-width': 2,
                //     'circle-color': 'red',
                //     'circle-stroke-color': 'white'
                // },
                filter: ['==', '$type', 'Point']
            })

            props.map.on("click", `${sourceId}_cluster`, props.callback)
            props.map.on("click", `${sourceId}_markers`, props.callback)
        }
        if (!props.map.hasImage("farm_marker")) {
            props.map.loadImage("assets/images/map_marker.png",
                // "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
                (error, image) => {
                    if (error) {

                    }
                    else {
                        props.map.addImage("farm_marker", image)
                    }
                    actions()
                })
        } else {
            actions()
        }
    }

    public addTextToCoords(map, geojson, baseId: string) {
        let sourceData: any = {
            type: "geojson",
            data: {
                'type': 'FeatureCollection',
                'features': [geojson]
            },
            cluster: false
        }
        let id = `text_to_coords_${baseId}`
        map.addSource(`${id}_source`, sourceData);
        let _map: mapboxgl.Map = map

        let action = () => {
            map.addLayer({
                id: id,
                type: 'symbol',
                source: `${id}_source`,
                layout: {
                    'text-field': ['get', 'title'],
                    "text-padding": 0,
                    "text-anchor": "top",
                    'text-size': 12,
                    'text-line-height': 1.5,
                    // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                    'text-radial-offset': 0,
                    'text-justify': 'auto',
                    // 'text-letter-spacing': 0.05,
                    'text-offset': [0, 0],
                    "icon-text-fit": "both",
                    "icon-image": "avg_ndvi_bg",
                    "icon-text-fit-padding": [6, 10, 6, 10]
                },
                paint: {

                    "text-color": "#FFFFFF",
                    "text-halo-color": "#FFFFFF",
                    // 'text-color': '#202',
                    // 'text-halo-color': '#fff',
                    'text-halo-width': 0
                },
                filter: ['==', '$type', 'Point']
            })
        }

        if (!map.hasImage("avg_ndvi_bg")) {
            map.loadImage("assets/images/avg_ndvi_bg.png", (error, image) => {
                if (error) {

                }
                else {
                    map.addImage("avg_ndvi_bg", image)
                }
                action()
            })
        } else
            action()

    }

    public toggleLayer(map: mapboxgl.Map, layerId, status?: 'visible' | 'none') {
        // let _map: mapboxgl.Map = map;
        if (map && Object.keys(map).length > 0)
            if (map.getLayer(layerId))
                if (status)
                    map.setLayoutProperty(layerId, 'visibility', status);
                else {
                    var visibility = map.getLayoutProperty(layerId, 'visibility')
                    if (visibility === 'visible') {
                        map.setLayoutProperty(layerId, 'visibility', 'none');
                    } else {
                        map.setLayoutProperty(layerId, 'visibility', 'visible');
                    }
                }
        return map
    }

    public addPolygonToMap<T extends IMapBasicParams & IAddPolygonParams & { longPressCallback }>(props: T) {
        // if (!props.polygonId)
        // props.polygonId = Date.now()
        // var id = `${props.polygonId}`
        let sourceGeoJSON: any = {
            type: "geojson",
            data: props.geojson
        }
        if (props.map.getSource(props.polygonId)) {
            if (props.map.getLayer(props.polygonId))
                props.map.removeLayer(props.polygonId)
            if (props.map.getLayer(`${props.polygonId}_outline`))
                props.map.removeLayer(`${props.polygonId}_outline`)
            props.map.removeSource(props.polygonId)
        }
        props.map.addSource(props.polygonId, sourceGeoJSON)
        // Add a black outline around the polygon.
        let prevLayerId = null
        if (props.map.getLayer("farm_image"))
            props.map.addLayer({
                'id': `${props.polygonId}_outline`,
                'type': 'line',
                'source': props.polygonId,
                'layout': {},
                'paint': {
                    'line-color': ['get', 'stroke'], // environment.mapLayerStyle.lineColor, // '#000',
                    'line-width': ['get', 'stroke-width'] // environment.mapLayerStyle.lineWidth // 3
                }
            }, "farm_image")
        else
            props.map.addLayer({
                'id': `${props.polygonId}_outline`,
                'type': 'line',
                'source': props.polygonId,
                'layout': {},
                'paint': {
                    'line-color': ['get', 'stroke'], // environment.mapLayerStyle.lineColor, // '#000',
                    'line-width': ['get', 'stroke-width'] // environment.mapLayerStyle.lineWidth // 3
                }
            })
        props.map.addLayer({
            id: props.polygonId,
            type: "fill",
            source: props.polygonId,
            layout: {},
            paint: {
                'fill-color': ['get', 'fill'], // environment.mapLayerStyle.fillColor, //'#0080ff', // blue color fill
                'fill-opacity': ['get', 'opacity'] // environment.mapLayerStyle.opacity//0.5
            }
        }, `${props.polygonId}_outline`)

        if (props.callback && props.addClickEvent) {
            props.map.on("click", props.polygonId,
                props.callback
                // this.sendEventResponse
                // (ev) => {
                //     callback(ev)
                // }
            )
        }

        if (props.longPressCallback) {
            let map: mapboxgl.Map = props.map;
            let timerInterval;
            let passedTime = 0;
            props.map.on("touchstart", props.polygonId, props.longPressCallback
            )
            props.map.on("touchend", props.polygonId, props.longPressCallback
            )
            props.map.on("move", props.longPressCallback
            )
            props.map.on("zoom", props.longPressCallback
            )
        }
        return props.map;
    }

    public addClickEventToLayer<T extends IMapBasicParams & { layerId: string }>(props: T) {
        props.map.on("click", props.layerId,
            props.callback
            // this.sendEventResponse
            // (ev) => {
            //     callback(ev)
            // }
        )
        return props.map
    }

    public addDrawablePolygon(props: IMapBasicParams & IAddPolygonParams) {
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            touchEnabled: true,
            boxSelect: false,
            userProperties: true,
            controls: {
                // trash: true
            },
            styles,
            defaultMode: 'simple_select'// 'draw_polygon'
        })
        props.map.addControl(draw);
        props.map.on('draw.create', props.callback)
        props.map.on('draw.delete', props.callback)
        props.map.on('draw.update', props.callback)
        props.map.on('draw.render', props.callback)
        let featureId = draw.add(props.geojson.geometry as any)
        draw.changeMode("direct_select", { featureId: featureId[0] })
        return { draw, map: props.map }
    }
    /**
     * 
     * Marker tanımlanırsa yeni marker oluşturmak yerine,
     * gönderilmiş olan markerın konumu güncellenir.
     * 
     * return edilen markerda currentMarker olarak kullanılabilir
     * 
     * @param props 
     * @returns mapboxgl.Marker
     */
    public setMyLocationPin<T extends IMapBasicParams & IMarkerModel & { icon?: any, id?: string }>(props: T) {
        if (!props.id)
            props.id = "my-location-pulse"
        if (!props.icon) {
            let doc = document.createElement("div")
            doc.className = "blob blue";
            doc.id = props.id;
            props.icon = doc;
        }
        let markerObj = props.currentMarker || new mapboxgl.Marker(props.icon, { anchor: "bottom" })
        markerObj.setLngLat([props.coords.longitude, props.coords.latitude])
        markerObj.addTo(props.map)
        return markerObj;
    }
    /**
     * 
     * Tanımlanan markerın konumu aktif olarak güncellenir.
     * 
     * @param props 
     * @returns void
     */
    public async followMyLocation(props: { map: mapboxgl.Map, icon: any, id: string }) {

        if (!props.id)
            props.id = "my-location-pulse"
        if (!props.icon) {

            let doc: any = document.getElementById(props.id)//document.createElement("div")
            if (!doc)
                doc = document.createElement("div")
            doc.className = "blob yellow";
            doc.id = props.id;
            props.icon = doc;
        }
        let markerObj = new mapboxgl.Marker(props.icon, { anchor: "center" })
        let currentCoords = {} as any
        try {
            currentCoords = (await LocationService.GetMyCurrentLocation()).Data
        } catch (error) {
            // console.error(error)
            if (LocationService.currentCoords?.coords)
                currentCoords = { longitude: LocationService.currentCoords.coords.longitude, latitude: LocationService.currentCoords.coords.latitude }
            else
                try {
                    currentCoords = await LocationService.GetLocationFromIp()
                } catch (error) {
                    currentCoords = LocationService.currentCoords?.coords ? { longitude: LocationService.currentCoords.coords.longitude, latitude: LocationService.currentCoords.coords.latitude } : { longitude: 39.9035557, latitude: 32.6226818 }
                }

        }
        markerObj.setLngLat([currentCoords.longitude, currentCoords.latitude])
        markerObj.setDraggable(false)
        markerObj.addTo(props.map)
        LocationService.WatchMyLocation().subscribe({
            next: response => {
                if (response?.coords && response.coords.longitude > 0 && response.coords.latitude > 0)
                    markerObj.setLngLat([response.coords.longitude, response.coords.latitude])
            },
            error: err => {
            }
        })

    }

    public addMarkerPin(props: IMapBasicParams & IMarkerModel & { markerIcon?}) {
        let markerObj: mapboxgl.Marker
        if (props.markerIcon)
            markerObj = new mapboxgl.Marker(props.markerIcon, { anchor: "bottom" });
        else
            markerObj = new mapboxgl.Marker({ anchor: "bottom" });
        if (props.currentMarker) {
            markerObj = props.currentMarker;
            markerObj.setLngLat([props.coords.longitude, props.coords.latitude])
        } else {
            markerObj.setLngLat([props.coords.longitude, props.coords.latitude])
            markerObj.setDraggable(!props.preventDragging)
            markerObj.addTo(props.map);
            if (props.callback)
                markerObj.on("dragend", props.callback)
        }

        return markerObj
    }

    public addMarkersArray(props: { map: mapboxgl.Map, tempFarmAttId: number, flagsGeojson: Array<IGeoJSONObj>, colors, callback }) {
        let markerList: Array<{ id: string, marker: mapboxgl.Marker }> = []
        props.flagsGeojson.forEach(el => {
            var doc = document.createElement('div');
            doc.className = el.properties["markerClassName"];
            doc.id = 'marker-' + el.properties["id"];
            // doc.style.height = '20px';
            // doc.style.width = '20px';
            // doc.style.backgroundColor = 'black';

            doc.addEventListener('click', function (e) {
                // Prevent the `map.on('click')` from being triggered
                e.stopPropagation();
                props.callback(el.properties["id"])
            });
            let markerObj: mapboxgl.Marker
            // if (el.properties.icon)
            //     markerObj = new mapboxgl.Marker(el.properties.icon)
            // else
            markerObj = new mapboxgl.Marker(doc, { anchor: "bottom" })
            markerObj.setLngLat(el.geometry.coordinates as mapboxgl.LngLatLike)
            markerObj.setDraggable(false)
            markerObj.setPitchAlignment
            markerObj.setPopup()
            markerObj.addTo(props.map)
            // markerObj.on("", props.callback)
            markerList.push({ id: el.properties["id"], marker: markerObj })
        });
        return markerList
    }

    public removeMarker(props: { map: mapboxgl.Map, marker: mapboxgl.Marker }) {
        props.marker.remove()
    }

    public addMarkersLayer(props: { map: mapboxgl.Map, tempFarmAttId: number, flagsGeojson: Array<IGeoJSONObj>, colors, callback }) {

        let sourceData: any = {
            type: "geojson",
            data: {
                'type': 'FeatureCollection',
                'features': props.flagsGeojson
            },
            cluster: false
        }


        let sourceId = `${props.tempFarmAttId}_notes`;

        if (props.map.getSource(`${sourceId}_source`)) {
            if (props.map.getLayer(`${sourceId}_layer`))
                props.map.removeLayer(`${sourceId}_layer`)
            props.map.removeSource(`${sourceId}_source`)
        }
        props.map.addSource(`${sourceId}_source`, sourceData)
        let promises = []
        props.colors.forEach((element: string) => {
            if (!props.map.hasImage(`${element}`)) {
                var encoded = element.replace('#', '%23')
                props.map.loadImage("https://api.geoapify.com/v1/icon/?type=awesome&color=" + encoded + "&scaleFactor=1&apiKey=9c39901875b54e8783f50a96c7a3f6d2",
                    (error, image) => {
                        if (error) {
                            props.map.loadImage("https://api.geoapify.com/v1/icon/?type=awesome&color=%23ffb83d&scaleFactor=1&apiKey=9c39901875b54e8783f50a96c7a3f6d2", (err, image2) => {
                                props.map.addImage(`${element}`, image2)
                            })
                        }
                        else {
                            props.map.addImage(`${element}`, image)
                        }
                    })
            }

        });
        props.map.addLayer({
            id: `${sourceId}_layer`,
            type: "symbol",
            source: `${sourceId}_source`,
            layout: {
                'icon-image': ['get', 'color'], //'flag_marker'
                "icon-allow-overlap": true,
                "symbol-z-order": "viewport-y"
            },
            paint: {
                "icon-color": ['get', 'color'],
            },
            filter: ['==', '$type', 'Point']
        })
        props.map.on("click", `${sourceId}_layer`, props.callback)
    }

    public clearMarkersLayer(props: { map: mapboxgl.Map, tempFarmAttId, callback }) {
        let sourceId = `${props.tempFarmAttId}_notes`;
        props.map.off("click", `${sourceId}_layer`, props.callback)
        if (props.map.getLayer(`${sourceId}_layer`))
            props.map.removeLayer(`${sourceId}_layer`)
        if (props.map.getSource(`${sourceId}_source`))
            props.map.removeSource(`${sourceId}_source`)
    }

    public flyToLocation<T extends IMapBasicParams & ICoords & { zoom?: number, animate?: boolean }>(props: T) {
        if (!Object.keys(props).some(t => t == "animate"))
            props.animate = true
        if (!props.zoom)
            props.zoom = 15
        let map: mapboxgl.Map = props.map
        let options: mapboxgl.FlyToOptions = {
            animate: props.animate,
            center: { lng: props.longitude, lat: props.latitude },
            zoom: props.zoom
        }
        map.flyTo(options)
        return map
    }

    /**
     * @description burada tek geojson gönderiyoruz
     * @param props 
     */
    public fitBounds(props: { map, geojson, padding?, speed?}) {
        try {
            let map: mapboxgl.Map = props.map
            if (props.geojson.geometry.type === 'Polygon') {
                let bounds = new mapboxgl.LngLatBounds(props.geojson.geometry.coordinates[0][0], props.geojson.geometry.coordinates[0][0]);
                props.geojson.geometry.coordinates[0].forEach(element => {
                    bounds.extend(element);
                });
                map.fitBounds(bounds, { padding: props.padding, animate: false, speed: props.speed || 3000 })
            } else if (props.geojson.geometry.type === 'Point') {
                console.log(props.geojson.geometry)
                map.flyTo({ animate: false, center: props.geojson.geometry.coordinates })
            }
        } catch (error) {

        }
    }

    /**
     * @description geojson listesi gönderiyoruz burada.
     * @param props 
     */
    public fitMultipleBounds(props) {
        let map: mapboxgl.Map = props.map
        let firstType = props.geojsonList[0].geometry.type
        let bounds: mapboxgl.LngLatBounds
        if (firstType == 'Point') {
            bounds = new mapboxgl.LngLatBounds(props.geojsonList[0].geometry.coordinates, props.geojsonList[0].geometry.coordinates);
        } else { //if(firstType == "Polygon")
            bounds = new mapboxgl.LngLatBounds(props.geojsonList[0].geometry.coordinates[0][0], props.geojsonList[0].geometry.coordinates[0][0]);
        }

        props.geojsonList.forEach(el1 => {
            let type = el1.geometry.type
            if (type == "Point") {
                bounds.extend(el1.geometry.coordinates);
            } else
                el1.geometry.coordinates[0].forEach(element => {
                    bounds.extend(element);
                });
        });
        map.fitBounds(bounds, { padding: props.padding, speed: 3000 })
    }

    public getBounds(geojson) {
        let bounds = new mapboxgl.LngLatBounds(geojson.geometry.coordinates[0][0], geojson.geometry.coordinates[0][0]);
        geojson.geometry.coordinates[0].forEach(element => {
            bounds.extend(element);
        });
        return bounds
    }

    public deleteAndRedraw<T extends IMapBasicParams & { draw: MapboxDraw }>(props: T) {
        props.draw.trash()
        props.draw.changeMode("draw_polygon")
        props.draw.getDefaultPosition()
        return props.draw
        // throw new Error('Method not implemented.');
    }

    /**
     * @deprecated
     * @param props 
     * @returns 
     */
    public deleteLastVertex(props: any) {
        throw new Error('Method not implemented.');
        let feature = props.draw.getAll().features[0]
        props.draw.changeMode("direct_select", { featureId: feature.id })
        props.draw.deleteAll()
        // props.map.removeControl(props.draw)

        let coords: Array<any> = feature.geometry.coordinates[0].filter(t => true)
        let popoedCoord = coords.pop();
        feature.geometry.coordinates[0] = coords.filter(t => true)
        // const newDraw = new MapboxDraw({
        //     displayControlsDefault: false,
        //     touchEnabled: true,
        //     boxSelect: false,
        //     controls: {
        //         trash: true
        //     },
        //     styles,
        //     defaultMode: 'draw_polygon'
        // })
        props.draw.changeMode("draw_polygon")
        let featureId = props.draw.add(feature.geometry)
        return props.draw
        // props.draw = props.draw.deleteAll()
        // let newFeature = props.draw.add(feature)
        // props.map.addControl(props.draw)
        // draw.set(newFeature)
    }

    /**
     * 
     * @param e 
     * @param draw 
     * @returns hektar
     */
    calculateArea(e, draw) {
        const data = draw.getAll();
        if (data.features.length > 0) {
            const area = turf.area(data) * 0.001;
            const rounded_area = Math.round(area * 1000) / 1000;

            return rounded_area
        } else {
            return 0
            // if (e.type !== 'draw.delete')
            //     alert('Click the map to draw a polygon.');
        }
    }
    /**
     * 
     * @param geojson 
     * @returns hektar
     */
    calculateAreaFromGeoJson(geojson: IGeoJSONObj) {
        const area = turf.area(geojson.geometry) * 0.001;
        const rounded_area = Math.round(area * 1000) / 1000;

        return rounded_area
    }

    //#region Rainviewer

    addRaster<T extends IMapBasicParams & { id: number }>(props: T) {
        if (props.id) {
            if (!props.map.getSource("rainviewer_source" + props.id)) {

                props.map.addSource("rainviewer_source" + props.id, {
                    type: "raster",
                    tiles: [
                        "https://tilecache.rainviewer.com/v2/radar/" + props.id + "/256/{z}/{x}/{y}/2/1_1.png"
                    ],
                    'tileSize': 256
                });

                setTimeout(() => {
                    if (!props.map.getLayer("rainviewer_layer" + props.id)) {
                        props.map.addLayer({
                            id: "rainviewer_layer" + props.id,
                            type: "raster",
                            source: "rainviewer_source" + props.id
                        });
                    }

                });
            }
        }


    }

    removeRaster<T extends IMapBasicParams & { id: number }>(props: T) {
        if (props.map.getLayer("rainviewer_layer" + props.id)) {
            props.map.removeLayer("rainviewer_layer" + props.id);
        }
        if (props.map.getSource("rainviewer_source" + props.id))
            props.map.removeSource("rainviewer_source" + props.id);

    }
    //#endregion

    /**
     * 
     * @param map 
     * @param imageUrl 
     * @param coordinates 
     * @param before
     * @returns 
     */
    public addOverlayImage(map: mapboxgl.Map, imageUrl, coordinates: Array<[number, number]>, beforeLayer?: string) {
        if (!(Array.isArray(coordinates) && coordinates.length > 0))
            return
        let bounds = new mapboxgl.LngLatBounds([coordinates[0], coordinates.reverse()[0]]);
        coordinates.forEach(element => {
            bounds.extend(element);
        });

        // let _map: mapboxgl.Map = map;
        try {
            if (map.getLayer("farm_image"))
                map.removeLayer("farm_image")
            if (map.getSource('farm_image_source')) {
                map.removeSource('farm_image_source')
            }
        } catch (error) {

        }
        map = map.addSource('farm_image_source', {
            type: 'image',
            url: imageUrl,
            coordinates: [
                bounds.getNorthWest().toArray(),
                bounds.getNorthEast().toArray(),
                bounds.getSouthEast().toArray(),
                bounds.getSouthWest().toArray()
            ]
        });
        if (beforeLayer && map.getLayer(beforeLayer)) {
            map = map.addLayer({
                id: "farm_image",
                type: 'raster',
                source: "farm_image_source",
                paint: {
                    "raster-resampling": "nearest"
                }
            }, beforeLayer)
        } else
            map = map.addLayer({
                id: "farm_image",
                type: 'raster',
                source: "farm_image_source",
                paint: {
                    "raster-resampling": "nearest"
                }
            })

        return map
    }

    /**
 * 
 * @param map 
 * @param imageUrl 
 * @param coordinates 
 * @param before
 * @returns 
 */
    public addVRAImage(map: mapboxgl.Map, imageUrl, coordinates: Array<[number, number]>, beforeLayer?: string) {
        if (!(Array.isArray(coordinates) && coordinates.length > 0))
            return
        let bounds = new mapboxgl.LngLatBounds([coordinates[0], coordinates.reverse()[0]]);
        coordinates.forEach(element => {
            bounds.extend(element);
        });

        // let _map: mapboxgl.Map = map;
        try {
            if (map.getLayer("farm_image"))
                map.removeLayer("farm_image")
            if (map.getSource('farm_image_source')) {
                map.removeSource('farm_image_source')
            }
        } catch (error) {

        }
        map = map.addSource('farm_image_source', {
            type: 'image',
            url: imageUrl,
            coordinates: [
                bounds.getNorthWest().toArray(),
                bounds.getNorthEast().toArray(),
                bounds.getSouthEast().toArray(),
                bounds.getSouthWest().toArray()
            ]
        });
        if (beforeLayer && map.getLayer(beforeLayer)) {
            map = map.addLayer({
                id: "farm_image",
                type: 'raster',
                source: "farm_image_source",
                // // paint: {
                // //     "raster-resampling": "nearest"
                // // }
            }, beforeLayer)
        } else
            map = map.addLayer({
                id: "farm_image",
                type: 'raster',
                source: "farm_image_source",
                // // paint: {
                // //     "raster-resampling": "nearest"
                // // }
            })

        return map
    }



    public removeOverlayImage(map) {
        let _map: mapboxgl.Map = map;
        if (map) {
            let layer = map.getLayer("farm_image")
            if (layer)
                map.removeLayer("farm_image")
            let source = map.getSource("farm_image_source")
            if (source)
                map.removeSource("farm_image_source")
        }
        return map
    }

    /**
     * 
     * @param map 
     * @param layerId 
     * @param removeSource 
     * @param sourceId 
     */
    public removeLayer(map, layerId, removeSource = false, sourceId?) {
        // let _map: mapboxgl.Map
        if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
            if (removeSource && map.getSource(sourceId))
                map.removeSource(sourceId)
        }
    }

    public checkIsInPolygon(props: { polygonGeojson: Array<Array<Array<number>>>, coordinates }) {
        var point = turf.point(props.coordinates);
        var polygon = turf.polygon(props.polygonGeojson, { name: Date.now() + "" })
        return turf.inside(point, polygon)
    }

    public getBoundryBox(geojson: IGeoJSONObj) {
        let coords: number[][][] = geojson.geometry.coordinates as number[][][]
        let polygon = turf.polygon(coords)
        turf.bbox(geojson)
        return turf.bbox(geojson)
    }

    public getBoundingBoxFromBboxCoords(coords: number[]) {
        const southWest = new mapboxgl.LngLat(coords[0], coords[1]);
        const northEast = new mapboxgl.LngLat(coords[2], coords[3]);

        return new mapboxgl.LngLatBounds(southWest, northEast);
    }

    public setPopupToLabel(map: mapboxgl.Map, e, description): mapboxgl.Popup {
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
        map.getCanvas().style.cursor = 'pointer';

        // Copy coordinates array.
        const bounds = this.getBounds(e)
        // const coordinates = bounds.getCenter() //e.features[0].geometry.coordinates.slice();
        const coordinates = { lng: (bounds.getNorthWest().lng + bounds.getNorthEast().lng) / 2, lat: bounds.getNorthEast().lat * 1.0000003 }
        // const description = "<span><b>Your field has been deleted.</b><br>You cannot view your field anymore.</span>" //e.features[0].properties.description;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(bounds.getCenter().lng - coordinates[0]) > 180) {
            coordinates[0] += bounds.getCenter().lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.addClassName("map-popup-container")
        // popup.setLngLat(coordinates).setHTML(description).addTo(map);
        popup.setLngLat(bounds.getCenter()).setHTML(description).addTo(map);
        //map.flyTo({ animate: false, center: bounds.getCenter() })
        return popup
    }


    public getDiagonalDistanceFromBounds(bounds: mapboxgl.LngLatBounds): number {
        var line: any = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "LineString",
                        "coordinates": [
                            bounds.getNorthWest().toArray(),
                            bounds.getNorthEast().toArray(),
                            bounds.getSouthEast().toArray(),
                            bounds.getSouthWest().toArray()
                        ]
                    }
                }
            ]
        };

        var start: any = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": bounds.getNorthWest().toArray()
            }
        };
        var stop: any = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": bounds.getSouthEast().toArray()
            }
        };

        var sliced = turf.lineSlice(start, stop, line.features[0]);
        var length = turf.lineDistance(sliced, { units: "meters" });
        return length
    }



    public addRedCircle(map: mapboxgl.Map, geojson: IGeoJSONObj, radiusMultiplier: number, beforeLayerId: string) {
        let bounds = this.getBounds(geojson)
        let center = bounds.getCenter()
        let radius = this.getDiagonalDistanceFromBounds(bounds) * radiusMultiplier
        const points = turf.featureCollection([]);
        let circleSource = map?.getSource('spot_radius_circle_src');
        let circleLayer = map?.getLayer('spot_radius_circle_layer');
        if (circleSource) {
            if (circleLayer)
                map.removeLayer("spot_radius_circle_layer")
            map.removeSource("spot_radius_circle_src")
        }
        map?.addSource('spot_radius_circle_src', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                    {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "Point",
                            "coordinates": bounds.getCenter().toArray()
                        }
                    }
                ],
            },
        });


        // if (circleLayer)
        //     map.removeLayer("spot_radius_circle_layer")
        // burada neler oluyor anlamadım
        const metersToPixelsAtMaxZoom = (meters: any, latitude: any) => meters / 0.075 / Math.cos(latitude * Math.PI / 180);
        map?.addLayer({
            id: 'spot_radius_circle_layer',
            type: 'circle',
            source: 'spot_radius_circle_src',
            interactive: false,
            paint: {
                'circle-color': 'rgba(230, 76, 0, 0.7)',
                'circle-radius': {
                    base: 2,
                    stops: [
                        [0, 0],
                        [20, metersToPixelsAtMaxZoom(radius, center.lat)]
                    ]
                },
                'circle-stroke-width': 1.5,
                'circle-stroke-color': '#E64C00'
            }
        }, beforeLayerId);


        // const lngLat = new Array(center.lng, center.lat);
        // let point = turf.point(lngLat);
        // point.properties = {
        //     id: "spot_radius_circle_point"
        // }
        // points.features.push(point);

        // (map?.getSource('spot_radius_circle_src') as any).setData(points);
    }

    preventMapInteractions(map: mapboxgl.Map) {
        if (map) {
            map["scrollZoom"].disable();
            map["boxZoom"].disable();
            map["dragRotate"].disable();
            map["dragPan"].disable();
            map["keyboard"].disable();
            map["doubleClickZoom"].disable();
            map["touchZoomRotate"].disable();
        }
    }

    enableMapInteractions(map: mapboxgl.Map) {
        if (map) {
            map["scrollZoom"].enable();
            map["boxZoom"].enable();
            map["dragRotate"].enable();
            map["dragPan"].enable();
            map["keyboard"].enable();
            map["doubleClickZoom"].enable();
            map["touchZoomRotate"].enable();
        }
    }

    public drawVRATractorLine(map: mapboxgl.Map, id: string, coords: number[][], workingWidth: number) {
        if (coords.length < 2)
            return
        let source: mapboxgl.AnySourceData = {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coords
                }
            }
        }
        if (map.getLayer(id))
            map.removeLayer(id)
        if (map.getLayer(id + "-background"))
            map.removeLayer(id + "-background")
        if (map.getSource(id))
            map.removeSource(id)
        if (map.getSource("line-offset"))
            map.removeSource("line-offset")
        map.addSource(id, source)
        map.addLayer({
            id,
            type: "line",
            source: id,
            layout: {
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-color": "#FFFFFF",
                'line-width': 2,
            }
        })

        let line = turf.lineString(coords, { stroke: '#F00', })
        line = turf.cleanCoords(line)
        console.log(line)
        console.log(line.geometry.coordinates[0])
        line.geometry.coordinates = line.geometry.coordinates.filter(t => {

            return t.some(a => a) // !Number.isNaN(t[0]) && !Number.isNaN(t[1])
        })
        console.log(line.geometry.coordinates[0])
        var offset1 = turf.lineOffset(line, workingWidth / 2, { units: "meters" })
        offset1.geometry.coordinates = offset1.geometry.coordinates.filter(t => t.some(a => a))
        console.log(offset1.geometry.coordinates[0])
        var offset2 = turf.lineOffset(line, -1 * (workingWidth / 2), { units: "meters" })
        offset2.geometry.coordinates = offset2.geometry.coordinates.filter(t => t.some(a => a))
        console.log(offset2.geometry.coordinates[0])
        var offset: mapboxgl.AnySourceData = {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': [[...offset1.geometry.coordinates, ...offset2.geometry.coordinates.reverse(), offset1.geometry.coordinates[0]]]
                }
            }
        }
        map.addSource("line-offset", offset);
        map.addLayer({
            id: id + "-background",
            type: "fill",
            source: "line-offset",
            layout: {
                visibility: "visible"
            },
            paint: {
                "fill-color": "rgba(142, 142, 142, 0.60)"
                // // 'line-width': 20,
            }
        }, id)

        return map.getSource(id)
    }

    public updateTractorLineSource(map: mapboxgl.Map, id: string, coords: number[][], workingWidth: number) {
        try {
            let lineSource = {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coords
                }
            }
            map.getSource(id)["setData"](lineSource)


        } catch (error) {
            console.error("ilk part error => ", error)
        }
        try {
            let line = turf.lineString(coords, { stroke: '#F00' })
            line = turf.cleanCoords(line)
            console.log(line.geometry.coordinates)
            line.geometry.coordinates = line.geometry.coordinates.filter(t => {

                return t.some(a => a) // !Number.isNaN(t) && !Number.isNaN(t[1])
            })
            var offset1 = turf.lineOffset(line, workingWidth / 2, { units: "meters" })
            offset1.geometry.coordinates = offset1.geometry.coordinates.filter(t => t.some(a => a))
            var offset2 = turf.lineOffset(line, -1 * (workingWidth / 2), { units: "meters" })
            offset2.geometry.coordinates = offset2.geometry.coordinates.filter(t => t.some(a => a))
            var offset: mapboxgl.AnySourceData = {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [[...offset1.geometry.coordinates, ...offset2.geometry.coordinates.reverse(), offset1.geometry.coordinates[0]]]
                    }
                }
            }
            console.log(offset)
            map.getSource("line-offset")["setData"](offset.data)

        } catch (error) {
            console.error("alan part => ", error)
        }
        return map.getSource(id)
    }

    /**
     * @description clear the array if the nth coordinate and n-1 is close each other more than treshold value and returns new array
     * @param coords number[][]
     * @param treshold number -> meter
     */
    public CleanCoordsForTheNearest(coords: number[][], treshold:number): number[][] {
        if(coords.length > 3){
            coords = coords.reverse()
            var latestPosition = coords[0], previousCoordinates = coords[1]
            var distance = this.HaversineDistance(latestPosition, previousCoordinates)
            coords = coords.reverse()
            if(distance < treshold){
                coords.length = coords.length -2
                coords.push(latestPosition)
                return coords;
            }else{
                return coords
            }
        }else
        return coords
    }

    /**
     * @name Haversine mesafesi hesaplama fonksiyonu
     * @description Dünya üzerindeki koordinatlar (enlem ve boylam) için mesafe hesaplamaları yaparken, genellikle Haversine formülü kullanılır. Bu formül, iki nokta arasındaki büyük çember mesafesini hesaplamak için kullanılır ve doğru sonuçlar verir.
     * @param coord1 
     * @param coord2 
     * @returns 
     */
    private HaversineDistance(coord1, coord2) {
        const R = 6371e3
        var degreesToRadians = (degrees) => {
            return degrees * (Math.PI / 180);
        }
        const lat1 = degreesToRadians(coord1[0]);
        const lon1 = degreesToRadians(coord1[1]);
        const lat2 = degreesToRadians(coord2[0]);
        const lon2 = degreesToRadians(coord2[1]);

        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        console.log(coord1)
        console.log(coord2)
        console.log(R * c)
        return R * c; // distance by meter
    }

    public addPopupToMarker(marker: mapboxgl.Marker, text: string, duration = 3000) {
        if (marker && text && text != '') {
            let popup = marker.getPopup()
            if (!popup) {
                popup = new mapboxgl.Popup()
                popup.setText(text)
                popup.setOffset(15)
                marker.setPopup(popup)
            }
            if (!popup.isOpen())
                marker.togglePopup()
            setTimeout(() => {
                let popup = marker.getPopup()
                if (popup.isOpen())
                    marker.togglePopup()
            }, duration);
        }
    }

}

/**
 * 
 * 
<div style="padding:6px;">
    <p style="margin-top:0;">
        Lorem ipsum dolor sit amet, <br>consectetur adipiscing elit.
    </p>
    <div style="display:flex; flex-direction: row; justify-content: flex-end; align-items: flex-end;">
        <button data-spotid="1231" id="later_btn"
            style="width: 56px;height: 31px;background: #FFFFFF;color:#000000;box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.05);border-radius: 4px;">
            Later
        </button>
        <button data-spotid="1231" id="checked_btn"
            style="width: 56px;height: 31px;background: #000000;color:#FFFFFF;box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.05);border-radius: 4px;">
            Checked
        </button>
    </div>
</div>

 * 
 * 
 */