<orbit-modal-base>
  <ion-grid fixed>
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12" style="position: relative;" *ngIf="mainTitle && mainTitle !== ''">
        <ion-text color="dark" class="ion-text-center">
          <h2 class="ion-text-center m-bottom-0 m-top-0 font-size-22-i font-weight-900-i">{{mainTitle}}</h2>
        </ion-text>
        <ion-icon style="position: absolute; right:10px;top: calc(50% - 5px);font-size: 18px;" name="close"
          (click)="dismissModal(id)" possibleOutcome="close modal" id="contact-us-dismiss-icon">
        </ion-icon>
      </ion-col>
      <ion-img imageLoad style="width: 50%;" class="m-vertical-10" *ngIf="image && image != ''" [src]="image"></ion-img>
      <ion-col size="12" *ngIf="title && title !== ''">
        <ion-text color="dark">
          <h2 class="ion-text-center m-bottom-0 m-top-0 font-size-20-i font-weight-900-i">{{title}}</h2>
        </ion-text>
      </ion-col>
      <ion-col size="12" class="ion-text-center" *ngIf="description && description !==  ''">
        <ion-text color="dark">
          <p class="ion-text-center m-bottom-20 m-top-8 font-size-14 font-weight-500">{{description}}</p>
        </ion-text>
      </ion-col>
      <ion-col size="12">
        <div class="d-flex row justify-center">
          <ion-card *ngFor="let item of buttonList" class="m-all-0 m-horizontal-8 p-all-8 flex-1"
            [color]="buttonOptions[item].color" shape="round" (click)="buttonOptions[item].callback()" [id]="buttonOptions[item].id">
            <div class="d-flex column align-center ion-text-center">
              <ion-icon slot="start" class="font-size-18-i" *ngIf="buttonOptions[item].iconSource == 'ionIcon'"
                [name]="buttonOptions[item].icon"></ion-icon>
              <ion-icon slot="start" class="font-size-18-i" *ngIf="buttonOptions[item].iconSource == 'asset'"
                [src]="buttonOptions[item].icon | orbitIcons"></ion-icon>
              <span class="font-size-12 font-weight-700">{{buttonOptions[item].text}} </span>
            </div>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</orbit-modal-base>