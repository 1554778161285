import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NavController, Platform } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    private history: Array<any> = []
    /**
     *
     */
    constructor(private activatedRoute: ActivatedRoute, private navController: NavController, private router: Router, private location: Location, private platform: Platform) {

    }
    private isTracking: boolean = false;
    trackNavigation(): void {
        if (!this.isTracking) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.history.push(event.urlAfterRedirects)
                }
            })
        }
    }

    public overrideBackButton() {
        // burasını incele. işe yarar bir şeyler çıkartabiliriz. capacitor
        this.platform.backButton.subscribeWithPriority(1, (el) => {
            // this.goBack();
        })
    }

    public goBack(defaultRoute?): void {
        let currentPageUrl = this.history.pop();
        if (defaultRoute) {
            this.navController.navigateBack(defaultRoute)
            // this.router.navigateByUrl(defaultRoute)
        } else {
            if (this.history.length > 0) {
                this.navController.navigateBack(this.history[this.history.length - 1])
                // this.location.back()
            } else {
                this.navController.navigateRoot("/tabs/map")
                // this.router.navigateByUrl("/")
            }
        }
    }

    public getPreviousPage(): string {
        let length = this.history.length;
        return length > 1 ? this.history[length - 2] : ""
    }

    public clearHistory(): void {
        this.history = []
    }

    public static GetQueryParams(_window): { [key: string]: string } {
        let searchData = _window.location.search as string
        if (searchData && searchData !== '') {
            let rawQueryParams = searchData.replace("?", "").split("&");
            let returnObj = {};
            rawQueryParams.forEach(el => {
                let _temp = el.split("=")
                if (_temp.length > 1) {
                    returnObj[_temp[0]] = _temp[1]
                }
            })
            return returnObj
        } else {
            return {}

        }
    }
}