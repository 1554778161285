<ion-header class="p-horizontal-8 orbit-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button [useCustom]="true" [defaultRoute]="'/tabs/account'"></app-back-button>
    </ion-buttons>
    <ion-title>{{"account.account_settings_lbl" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="false" fullscreen="true">
  <ion-header collapse="condense" class="orbit-header solid p-top-0 border-basic-bottom">
    <ion-toolbar>
      <ion-title>{{"account.account_settings_lbl" | translate}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-card shape="round" bg="ultra-light">
    <ion-list [inset]="true" lines="full" bg="ultra-light" class="p-all-0 m-vertical-0-i">
     @for(item of settingsConstants; track item.key){
      <ion-item button [id]="item.key" details="opaque" class="p-left-0-ion" (click)="item.action()" [possibleOutcome]="item.actionOutcomeType">
        <ion-label class="p-vertical-12">
          <h4 class="font-size-14-i font-weight-550-i">{{item.title | translate}}</h4>
          <span class="font-size-12-i font-weight-450-i" color="medium">{{item.subtitle | translate}}</span>
        </ion-label>
      </ion-item>
     }
     <ion-item details="opaque" class="height-70 p-left-0-ion" lines="none" (click)="deleteAccount()" color="ultra-light" id="delete-account-item" possibleOutcome="action">
      <ion-label>
        <span color="danger" class="font-size-14 font-weight-800">{{"account.delete_account" | translate}}</span>
      </ion-label>
    </ion-item>
    </ion-list>
  </ion-card>

</ion-content>
<ion-footer mode="ios" style="background: var(--ion-background-color);">
  <ion-grid>
    <ion-row>
      <ion-col size="12" class="p-bottom-20">
        <ion-text color="dark" class="ion-text-center">
          <h1 class="font-size-16 font-weight-550 m-top-0 m-bottom-0">{{"account.app_version_lbl" | translate}}</h1>
          <h2 class="font-size-14 m-top-4 m-bottom-0" color="medium" >{{'v' + appVersion}}</h2>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<ion-modal #appearanceModal class="modal-transparent">
  <ng-template>
    <orbit-modal-base [hasCloseBtn]="true" (onDismiss)="appearanceModal.dismiss()">
      <ion-toolbar bg="none" mode="md" class="border-basic-bottom p-bottom-12">
        <ion-title class="font-size-20 font-weight-850 ion-no-padding">{{"account.change_theme_lbl" | translate}}</ion-title>
      </ion-toolbar>
      <ion-grid fixed>
        <ion-row>
          <ion-col size="12" class="p-vertical-12">
            <ion-segment class="custom-segment" [value]="this.isDarkModeEnabled" (ionChange)="changeTheme($event.detail.value)">
              <ion-segment-button [value]="false">
                <ion-label>{{"account.light_theme_lbl" | translate}}</ion-label>
              </ion-segment-button>
              <ion-segment-button [value]="true">
                <ion-label>{{"account.dark_theme_lbl" | translate}}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>
      </ion-grid>
    </orbit-modal-base>
  </ng-template>
</ion-modal>