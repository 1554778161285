import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { FromGeoJsonDirective } from "../directives/from-geo-json.directive";
import { StaticFarmImagePipe } from "../pipes/get-static-farm-image.pipe";

@NgModule({
    imports: [IonicModule, FormsModule, CommonModule, TranslateModule],
    declarations: [FromGeoJsonDirective, StaticFarmImagePipe],
    exports: [FromGeoJsonDirective, StaticFarmImagePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FarmDrawingToolsModule { }