import { Pipe, PipeTransform } from '@angular/core';
import { IFarmModel } from 'src/app/core/models/interfaces/farm-model';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'staticFarmImage'
})

export class StaticFarmImagePipe implements PipeTransform {
    transform(value: string | any, properties?: { [key: string]: string }, resolution = "600x400", padding = "75"): any {
        if (value) {
            let geoJsonParsed: any = value
            if (typeof value === "string")
                geoJsonParsed = JSON.parse(value)
            if (!properties)
                properties = { "fill": "#FFB83D", "stroke": "#FFFFFF", "stroke-width": "8" }
            geoJsonParsed.properties = properties;
            let geojson = encodeURIComponent(JSON.stringify(geoJsonParsed))
            let image = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${geojson})/auto/${resolution}@2x?padding=${padding}&&access_token=${environment.mapbox.accessToken}&attribution=false&logo=false`
            return image
        } else
            return null;
    }
}