<ion-header>
  <ion-toolbar class="p-horizontal-16">
    <ion-buttons slot="start">
      <ng-container *ngIf="registerStates != 'namesurname'; else backButton">
        <ion-button id="register_Back" class="custom-back-button" (click)="goPreviousState()" color="ultra-light" fill="clear">
          <ion-icon [slot]="'icon-only'" class="custom-back-icon" [src]="'./../../../../../assets/icon/orbit-custom-back-icon.svg'"></ion-icon>
        </ion-button>
      </ng-container>
      <ng-template #backButton>
        <app-back-button id="register_Back" [useCustom]="true" color="dark" size="small" [routerLink]="['/auth']" routerDirection="back" fill="outline" shape="round"></app-back-button>
      </ng-template>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="openLangModal()" id="register-open-lang-options">
        <span class="font-size-14 font-weight-700">
          {{"lang.default" | translate}} <ion-icon class="m-left-5" style="margin-bottom:-3px" name="chevron-down-outline"></ion-icon>
        </span>
        <!-- <ion-icon slot="icon-only" style="border-radius: 100%;"
          [src]="'./../../../../../assets/lang-flags/' + langService.getcurrentLanguage().code +'.svg' "></ion-icon> -->
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed class="p-horizontal-15" *ngIf="registerForm">

    <form [formGroup]="registerForm" (keydown.enter)="$event.preventDefault()">

      <ng-container [ngSwitch]="registerStates">

        <ion-row *ngSwitchCase="'namesurname'">
          <ion-col size="12">
            <ion-col size="12">
              <h2 class="font-size-28 font-weight-900 letter-space-minus-013 m-top-6">{{"auth.register_name_title" | translate}}</h2>
              <p class="font-size-14">{{"auth.register_name_text" | translate}}</p>
            </ion-col>
          </ion-col>
          <ion-col size="12" class="m-top-11">
            <!-- <orbit-arpa-input type="text" [label]="'auth.namesurname' | translate" [labelPlacement]="'floating'" formControlName="namesurname"></orbit-arpa-input> -->
            <!-- <orbit-arpa-input type="tel" [label]="'auth.phone_number' | translate" [labelPlacement]="'floating'" [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate" ></orbit-arpa-input> -->
            <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="ultra-light" lines="none">
              <ion-label class="form-label" style="margin-top:-12px" position="floating">{{'auth.namesurname' | translate}}</ion-label>
              <ion-input class="input-item focus-border" id="register_Name" required formControlName="namesurname" type="text" color="medium" fill="outline"> </ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row *ngSwitchCase="'phone'">
          <ion-col size="12">
              <h2 class="font-size-28 font-weight-900 letter-space-minus-013 m-top-6">{{"auth.register_phone_title" | translate}}</h2>
              <p class="font-size-14">{{"auth.register_phone_text" | translate}}</p>
          </ion-col>
          <ion-col size="12">
            <div class="d-flex row gap-12">
              <div>
                <ion-item shape="round" class="border-basic focus-border full-height arpa-input-item" color="ultra-light" lines="none" id="register_PhoneCode" (click)="openFlagsModal()">
                  <img slot="start" class="height-20 m-right-8-i" [src]=" ('countryFlags' | environment) + (selectedCountry?.iso2 | uppercase)+'.svg'" alt="" srcset="">
                  <ion-label class="font-size-14-i font-weight-600" color="dark">+{{selectedCountry?.dialCode}}</ion-label>
                  <ion-icon class="font-size-14-i" color="dark" slot="end" name="chevron-forward-outline"></ion-icon>
                </ion-item>
              </div>
              <div class="flex-1">
                <!-- <orbit-arpa-input type="tel" [label]="'auth.phone_number' | translate" [labelPlacement]="'floating'" [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate" ></orbit-arpa-input> -->
                <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="ultra-light" lines="none">
                  <ion-label class="form-label" style="margin-top:-12px" position="floating">{{'auth.phone_number' | translate}}</ion-label>
                  <ion-input required formControlName="phone" id="register_PhoneNumber" type="tel" [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate"></ion-input>
                </ion-item>
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row *ngSwitchCase="'password'">
          <ion-col size="12">
            <ion-col size="12">
              <h2 class="font-size-28 font-weight-900 letter-space-minus-013 m-top-6">{{"auth.register_password_title" | translate}}</h2>
              <p class="font-size-14">{{"auth.register_password_text" | translate}}</p>
            </ion-col>
          </ion-col>
          <ion-col size="12" class="m-top-11">
            <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="ultra-light" lines="none">
              <ion-label class="form-label" style="margin-top:-12px" position="floating">{{'auth.password' | translate}}</ion-label>
              <ion-input required formControlName="password" id="register_Password" [type]="passwordVisibility ? 'text' : 'password'"> </ion-input>
              <ion-icon class="ion-justify-content-center ion-align-self-center" slot="end" id="register-password-toggle" (click)="passwordVisibility = !passwordVisibility" [src]="( passwordVisibility ? 'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
            </ion-item>
          </ion-col>
          <ion-text [color]="registerForm.controls[registerStates].dirty ? registerForm.controls[registerStates].invalid ? 'danger' : 'success' :  'medium'">
            <p class="m-all-0">
              {{"auth.password_validation_error" | translate}}
            </p>
          </ion-text>
        </ion-row>
        <div *ngSwitchDefault>

        </div>
      </ng-container>
    </form>
    <div class="position-bottom d-flex justify-end align-end full-width p-horizontal-20">
      <ion-text *ngIf="registerStates == 'namesurname'" color="dark">
        <p [innerHTML]="'auth.agree_the_terms_alert' | translate" class="font-size-11 letter-space-minus-01 m-bottom-18">

        </p>
      </ion-text>
      <ion-button class="width-120 font-size-16 font-weight-900" id="register_Next" [disabled]="registerForm.controls[registerStates].invalid" (click)="completeStage(registerStates)" fill="solid" shape="round">
        {{(registerStates === "password" ? "auth.register_complete_btn" : "auth.register_next_btn") | translate}}
      </ion-button>
    </div>
  </ion-grid>

  <!-- <orbit-external-login (socialLoginExternal)="loginWithSocial($event)"></orbit-external-login> -->

</ion-content>