import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LoadingService {

    loadingCount: number = 0
    /**
     *
     */
    constructor() {
    }

    showLoading() {
        this.loadingCount++;
    }

    hideLoading() {
        if (this.loadingCount > 0) { 
            this.loadingCount--;
        }
    }
}