import { DateTimeService } from "src/app/core/services/date-time.service";
import { GeoJson, IFarmModel, SatalliteInfoResponse } from "../../../models/interfaces/farm-model";
import FarmDataModel from "./farm-data.class";
import { AbsFarmControl } from "./farm.abstract";
import { DateFormats } from "src/app/core/models/enums/date-formats";
import { SatelliteImages } from "../satellite-images";
import { FarmApiService } from "src/app/core/api/farm-api.service";
import { ModalService } from "../../modal.service";
import { DoktarConstantsService } from "../../doktar-constants.service";
import { TranslateService } from "@ngx-translate/core";
import { IListModalModel } from "src/app/core/models/interfaces/modal-components-models";
import { IFarmEditModel } from "src/app/core/models/interfaces/farm-save-model";
import { map } from "rxjs";
import { ICropResponse } from "src/app/core/models/interfaces/crop-response";
import { ICropVarietyResponse } from "src/app/core/models/interfaces/crop-variety-response";
import { environment } from "src/environments/environment";
import { SatelliteParamNames } from "src/app/core/models/interfaces/app-constant-satellite-options";

export default class FarmControl extends AbsFarmControl {
    public satelliteList: SatelliteImages[] = new Array<SatelliteImages>()
    private _farmApiService: FarmApiService

    /**
     *
     */
    constructor(farmApiService: FarmApiService) {
        super()
        this._farmApiService = farmApiService
    }

    buildFarm(props: IFarmModel): FarmControl {
        this.farm = new FarmDataModel();
        this.farm.TempFarmAttId = props.TempFarmAttId;
        this.farm.GeoJson = props.GeoJson;
        this.farm.FarmName = props.FarmName;
        this.farm.SowingDate = props.SowingDate;
        this.farm.HarvestDate = props.HarvestDate;
        this.farm.CropName = props.CropName;
        this.farm.CropVarietyName = props.CropVarietyName;
        this.farm.CalculatedArea = props.CalculatedArea;
        this.farm.CountryName = props.CountryName;
        this.farm.CityName = props.CityName;
        this.farm.CityId = props.CityId;
        this.farm.DistrictName = props.DistrictName;
        this.farm.VillageName = props.VillageName;
        this.farm.VillageId = props.VillageId;
        this.farm.CropId = props.CropId;
        this.farm.CropVarietyId = props.CropVarietyId;
        this.farm.FarmId = props.FarmId;
        this.farm.GeoType = props.GeoType;
        this.farm.MeteoPointId = props.MeteoPointId;
        this.farm.PurchaseStatus = props.PurchaseStatus;
        this.farm.SatelliteInfo = props.SatelliteInfo;
        this.farm.PhotosCount = props.PhotosCount;
        this.farm.FlagCount = props.FlagCount;
        // this.farm.GeoJsonObj = props.GeoJsonObj;
        this.farm.Center = props.Center;
        // this.farm.SowingDateStr = props.SowingDateStr;
        this.farm.HarvestDateStr = props.HarvestDateStr;
        this.farm.CountryCode = props.CountryCode;
        this.farm.FlagCountByUserId = props.FlagCountByUserId;
        this.farm.IsArchived = props.IsArchived;
        this.farm.SharedFarm = props.SharedFarm;
        this.farm.SoilTypeId = props.SoilTypeId;
        this.farm.SoilTypeName = props.SoilTypeName;
        this.farm.HasIrrigationProgram = props.HasIrrigationProgram;
        this.farm.IrrigationTypeId = props.IrrigationTypeId;
        this.farm.IrrigationTypeName = props.IrrigationTypeName;
        this.farm.TransmissionId = props.TransmissionId;
        this.farm.ClassName = props.ClassName;
        this.farm.DistrictId = props.DistrictId;
        this.farm.FarmState = props.FarmState;
        this.farm.IrrigationProgramPermission = props.IrrigationProgramPermission;
        this.farm.FlagPermission = props.FlagPermission;
        this.farm.SprayingHoursPermission = props.SprayingHoursPermission;
        this.farm.PlanName = props.PlanName;
        this.farm.CreateVRAMapPermission = props.CreateVRAMapPermission;
        this.farm.ImportedFrom = props.ImportedFrom;
        this.farm.Ownership = props.Ownership;
        this.farm.SharedToAnother = props.SharedToAnother;
        this.farm.IsSpotDetectionAvailable = props.IsSpotDetectionAvailable;
        if (this.farm.SatelliteInfo) {
            if (this.farm.PlanName.toLowerCase() === 'free')
                this.farm["ClassName"] = "stop-tracking";
            else
                this.farm["ClassName"] = "purchase";
        } else {
            if (this.farm.PurchaseStatus == "NoPurchased") {
                this.farm["ClassName"] = "stop-tracking";
            } else {
                this.farm["ClassName"] = "purchase";
            }
        }
        // this.farm.SowingDateStr = DateTimeService.FormatDate(props.SowingDate, DateFormats.FULL_DATE_DOTS)
        return this
    }

    get SatelliteInfo() {
        return this.farm.SatelliteInfo
    }

    get isExpired(): boolean {
        if (this.SatelliteInfo) {
            if (this.farm.PlanName.toLowerCase() == 'pro') {
                return false
            } else {
                return this.SatelliteInfo.some(t => t.ParamName === SatelliteParamNames.PLANET) && new Date(this.SatelliteInfo.find(t => t.ParamName == SatelliteParamNames.PLANET).ExpireDate).getTime() < new Date().getTime()

                // if (this.SatelliteInfo && this.SatelliteInfo.length > 0 && this.SatelliteInfo.some(t => t.ParamValue.toLowerCase() == "p") && ["basic", "free"].some(a => a == this.farm.PlanName.toLowerCase()))
                //     return true
                // else
                //     return false
            }
        } else
            return false
    }

    clearSatelliteImages() {
        this.satelliteList = []
    }

    GetSatelliteImages(lastDate: string): Promise<boolean> {
        if (!Array.isArray(this.satelliteList))
            this.satelliteList = [] as SatelliteImages[]
        return new Promise((resolve, reject) => {
            if (this.farm.SatelliteInfo && this.farm.SatelliteInfo.length > 0) {
                this._farmApiService.GetSatelliteDates(this.farm.TempFarmAttId, lastDate).subscribe({
                    next: res => {
                        if (res.IsSuccess) {
                            if (Array.isArray(res.Data) && res.Data.length > 0) {
                                res.Data.forEach(el => {
                                    let tempItem = new SatelliteImages(this._farmApiService);
                                    tempItem.build(el);
                                    if (!this.satelliteList.some(t => t.Id == el.Id))
                                        this.satelliteList.push(tempItem)
                                })
                                resolve(true)
                            } else
                                resolve(false)
                        } else
                            reject(res.Message)
                    }, error: err => {
                        reject(err)
                    }
                })
            } else
                reject("purchase_error")
        })
        // throw new Error("Method not implemented.");
    }

    GetMoistureMapDates(lastDate: string) {
        return this._farmApiService.GetNdmiDates(this.TempFarmAttId, lastDate)
    }

    public get GeoJSON(): GeoJson {
        return JSON.parse(this.farm.GeoJson)
    }

    GetStaticFarmImage(): string {
        return ""
    }
    GetAnalysisData(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }
    GetFarmNotes(): void {
        throw new Error("Method not implemented.");
    }
    GetFarmIrrigation(): void {
        throw new Error("Method not implemented.");
    }
    GetVraInformation(): void {
        throw new Error("Method not implemented.");
    }
    GetSprayingData(): void {
        throw new Error("Method not implemented.");
    }
    GetStaticImage(): void {
        throw new Error("Method not implemented.");
    }

    initCropChangeProcess(constantService: DoktarConstantsService, modalService: ModalService, langService: TranslateService) {
        return new Promise((resolve, reject) => {
            constantService.RequestCropList().subscribe(crops => {
                modalService.presentListModal({
                    hasConfirmBtn: false,
                    isFullscreen: false,
                    isMultiple: false,
                    sortItems: true,
                    itemList: crops.map(t => {
                        return { id: t.CropId, value: langService.instant(`crops.${t.CropName}`), isChecked: t.CropId === this.farm.CropId } as IListModalModel
                    }),
                    title: langService.instant("my_fields.crop_type_title"),
                    confirmBtnText: ""
                }).then(modal => {
                    modal.modalObj.onDidDismiss().then(dismiss => {
                        if (dismiss.role == 'itemSelected') {
                            let tempCropId = dismiss.data.selectedItem.id
                            console.log("crop => ", tempCropId)
                            let crop = crops.find(t => t.CropId == tempCropId)
                            this.initCropVarietyChange(constantService, modalService, langService, tempCropId).then((varietyRes: any) => {
                                this.changeCropId(crop, varietyRes).subscribe({
                                    next: next => {
                                        resolve(true)
                                    },
                                    error: err => {
                                        reject(err)
                                    }
                                })
                            })
                            //   this.selectedCrop = dismiss.data.selectedItem
                            //   this.selectedCropVariety = null
                        } else {
                            reject("canceled")
                        }
                    })
                })
            })
        })
    }

    changeCropId(crop: ICropResponse, cropVariety: ICropVarietyResponse) {
        let props: IFarmEditModel = { FarmName: this.FarmName, HarvestDate: this.farm.HarvestDate, SowingDate: this.farm.SowingDate, Id: this.TempFarmAttId, CropVarietyId: cropVariety.CropVarietyId, CropId: crop.CropId }
        return this._farmApiService.FarmEditRequest(props).pipe(map(t => {
            if (t.IsSuccess && t.Data) {
                this.farm.CropId = crop.CropId
                this.farm.CropName = crop.CropName
                this.farm.CropVarietyId = cropVariety.CropVarietyId
                this.farm.CropVarietyName = cropVariety.CropVarietyName
            }
        }))
    }

    initCropVarietyChange(constantService: DoktarConstantsService, modalService: ModalService, langService: TranslateService, cropId?, canSendRequest: boolean = false) {
        return new Promise((resolve, reject) => {
            if (!cropId)
                cropId = this.farm.CropId
            constantService.RequestCropVariety(cropId).subscribe(cropVarietyResp => {
                modalService.presentListModal({
                    hasConfirmBtn: false,
                    isFullscreen: false,
                    isMultiple: false,
                    itemList: cropVarietyResp.map(t => {
                        return { id: t.CropVarietyId, value: langService.instant("crop_variety_translate." + t.CropVarietyName), isChecked: t.CropVarietyId === this.farm.CropVarietyId } as IListModalModel
                    }),
                    title: langService.instant("my_fields.crop_variety_subtitle"),
                    confirmBtnText: ""
                }).then(modal => {
                    modal.modalObj.onDidDismiss().then(dismiss => {
                        if (dismiss.role == 'itemSelected') {
                            let cropVarietyId = dismiss.data.selectedItem.id
                            console.log("crop variety => ", cropVarietyId)
                            let cropVariety = cropVarietyResp.find(t => t.CropVarietyId == cropVarietyId)
                            if (canSendRequest) {
                                this.changeCropVarietyId(cropVariety).subscribe({
                                    next: next => {
                                        resolve(true)
                                    },
                                    error: err => {
                                        reject(err)
                                    }
                                })
                            } else
                                resolve(cropVarietyResp.find(t => t.CropVarietyId == cropVarietyId))
                            //   this.selectedCropVariety = dismiss.data.selectedItem
                            //   if (!this.selectedCrop)
                            //     this.selectedCrop = { id: this.farm.CropId, value: this.langService.instant("crops." + this.farm.CropName) }
                        } else {
                            reject("canceled")
                        }
                    })
                })
            })
        })
    }

    changeCropVarietyId(cropVariety: ICropVarietyResponse) {
        let props: IFarmEditModel = { FarmName: this.FarmName, HarvestDate: this.farm.HarvestDate, SowingDate: this.farm.SowingDate, Id: this.TempFarmAttId, CropVarietyId: cropVariety.CropVarietyId, CropId: this.farm.CropId }
        return this._farmApiService.FarmEditRequest(props).pipe(map(t => {
            if (t.IsSuccess && t.Data) {
                this.farm.CropVarietyId = cropVariety.CropVarietyId
                this.farm.CropVarietyName = cropVariety.CropVarietyName
            }
        }))
    }

    changeSowingDate(modalService: ModalService, langService: TranslateService) {
        return new Promise((resolve, reject) => {
            let date = new Date(this.farm.SowingDate).getTime() != new Date(environment.emptySowingDate).getTime() ? this.farm.SowingDate : null
            let max = new Date(this.farm.HarvestDate).getTime() != new Date(environment.emptyHarvestDate).getTime() ? this.farm.HarvestDate : null
            modalService.openCalendarModal({ title: langService.instant("my_fields.sowing_date_title"), startDate: date, preSelected: date, min: null, max, isBottomSheet: true, options: ModalService.defaultOptions }).subscribe(response => {
                let sowingDate = response.date.toISOString();
                let props: IFarmEditModel = {
                    Id: this.TempFarmAttId,
                    FarmName: this.FarmName,
                    CropId: this.CropId,
                    CropVarietyId: this.farm.CropVarietyId,
                    HarvestDate: this.farm.HarvestDate,
                    SowingDate: new Date(sowingDate).toISOString()
                }
                this._farmApiService.FarmEditRequest(props).subscribe({
                    next: next => {
                        this.farm.SowingDate = new Date(sowingDate).toISOString()
                        resolve(true)
                    },
                    error: err => {
                        reject(err)
                    }
                })
            })
        })
    }
    changeHarvestDate(modalService: ModalService, langService: TranslateService) {
        return new Promise((resolve, reject) => {
            let date = new Date(this.farm.HarvestDate).getTime() != new Date(environment.emptyHarvestDate).getTime() ? this.farm.HarvestDate : null
            let min = new Date(this.farm.SowingDate).getTime() != new Date(environment.emptySowingDate).getTime() ? this.farm.SowingDate : null
            modalService.openCalendarModal({ title: langService.instant("my_fields.harvest_date_title"), startDate: date, preSelected: date, min, isBottomSheet: true, options: ModalService.defaultOptions }).subscribe(response => {
                let selectedHarvestDate = response.date.toISOString();
                let props: IFarmEditModel = {
                    Id: this.TempFarmAttId,
                    FarmName: this.FarmName,
                    CropId: this.CropId,
                    CropVarietyId: this.farm.CropVarietyId,
                    HarvestDate: new Date(selectedHarvestDate).toISOString(),
                    SowingDate: this.farm.SowingDate
                }
                this._farmApiService.FarmEditRequest(props).subscribe({
                    next: next => {
                        this.farm.HarvestDate = new Date(selectedHarvestDate).toISOString()
                        resolve(true)
                    },
                    error: err => {
                        reject(err)
                    }
                })
            })
        })
    }

    changeFarmName(newName: string) {
        return this._farmApiService.FarmEditRequest({ Id: this.farm.TempFarmAttId, FarmName: newName, CropId: this.CropId, CropVarietyId: this.farm.CropVarietyId, SowingDate: this.farm.SowingDate, HarvestDate: this.farm.HarvestDate }).pipe(map(t => {
            if (t.IsSuccess && t.Data)
                this.farm.FarmName = newName
            return t
        }))
    }
}