<ion-content (click)="hasBackdropdismiss ? dismissModal(id): null" [possibleOutcome]="hasBackdropdismiss ? 'close modal' : 'not_allowed'" id="float-alert-modal-ion-content" [ngClass]="contentClass" class="bg-default">
  <ion-grid fixed style="height: 100%;" class="p-horizontal-20">
    <ion-row style="height: 100%;" class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <ion-card color="light" (click)="$event.stopPropagation()" possibleOutcome="not_allowed" id="float-alert-modal-container-card" class="ion-no-margin ion-padding border-radius-full-24 custom-ion-card">
          <ion-grid fixed class="p-all-0">
            <ion-icon *ngIf="!preventDismiss" id="float-alert-close-icon" (click)="dismiss(null)" [possibleOutcome]="hasBackdropdismiss ? 'close modal' : 'not_allowed'" class="position-absolute-top-end font-size-25 z-index-50" color="dark" [src]="'closeCircle' | orbitIcons"></ion-icon>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-img imageLoad class="width-max-125" [ngClass]="imageClassList" *ngIf="image && image !== ''" [src]="image"></ion-img>
              <ion-col size="12" class="ion-text-center p-all-0" *ngIf="title && title !== ''">
                <ion-text color="dark">
                  <h2 class="font-size-20 font-weight-900 m-top-0" [innerHTML]="title"></h2>
                </ion-text>
              </ion-col>
              <ion-col size="12" class="ion-text-center p-all-0" *ngIf="description && description !==  ''">
                <ion-text color="dark">
                  <p class="font-size-15" [innerHTML]="description"></p>
                </ion-text>
              </ion-col>
              <ion-col size="12" class="p-all-0" *ngFor="let item of buttons">
                <ion-button (click)="buttonClick($event,item)" [id]="item.id" expand="block" [fill]="item.fill" shape="round"
                  [color]="item.color">
                  <span class="font-size-14 font-weight-500 font-family-poppins">{{item.text}}</span>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>