import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'orbit-deleted-farms-alert-modal',
  templateUrl: './deleted-farms-alert-modal.component.html',
  styleUrls: ['./deleted-farms-alert-modal.component.scss'],
})
export class DeletedFarmsAlertModalComponent extends BaseModal implements OnInit {

  private _farmList: Array<{ TempFarmAttId; FarmName; GeoItem; GeoJSON; }> = [];
  public get farmList(): Array<{ TempFarmAttId; FarmName; GeoItem; GeoJSON; }> {
    return this._farmList;
  }
  @Input() public set farmList(value: Array<{ TempFarmAttId; FarmName; GeoItem; GeoJSON; }>) {
    if (value) {
      value.forEach(el => {
        if (el.GeoItem)
          el.GeoJSON = JSON.parse(el.GeoItem);
      })
    }
    this._farmList = value;
  }

  showFarmList: boolean = false;


  constructor(private modalService: ModalService) {
    super();
    super._modalService = modalService;
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
