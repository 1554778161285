import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClientBaseApiService } from 'src/app/core/api/client-base-api.service';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from 'src/app/core/services/storage.service';
import SwiperCore, { EffectCreative, Pagination } from 'swiper';

SwiperCore.use([EffectCreative, Pagination]);

@Component({
  selector: 'orbit-page-onboarding',
  templateUrl: './page-onboarding.component.html',
  styleUrls: ['./page-onboarding.component.scss'],
})
export class PageOnboardingComponent extends BaseModal implements OnInit {

  @Input() list: Array<any> = []
  current: number = 0
  interval: any = null
  isStoped: boolean = false
  isUserLogged: boolean = false
  swiperEffectStyle = {
    prev: {
      shadow: true,
      translate: ['-100%', 0, 1]
    },
    next: {
      translate: [0, 0, 0]
    }
  }
  constructor(public langService: LanguageService, private clientApi: ClientBaseApiService, private domSanitizer: DomSanitizer, private storageService: StorageService, private modalService: ModalService, private router: Router) {
    super()
    super._modalService = modalService
    this.list = [] //new Array(4).fill(1).map((t, index) => { return { id: index, value: 0, image: "https://picsum.photos/id/" + Math.ceil(Math.random() * 100) + "/600/800", title: `Title - ${index}`, text: `Text - ${index}` } })
  }

  ngOnInit() {
    this.list = this.list.map(t => {
      t.value = 0;
      return t;
    })
  }

  loadData(i: number) {
    let element = this.list[i]
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (!this.isStoped) {
        element.value += 0.02
        if (element.value >= 1) {
          clearInterval(this.interval)
          if (this.list.length == i + 1) {
            // this.list.forEach(e => {
            //   e["value"] = 0
            // })
            // setTimeout(() => {
            //   this.current = 0;
            // }, 100);
          } else {
            this.current++
          }
        }
      }
    }, 150);
  }

  previous() {
    if (this.current != 0) {
      clearInterval(this.interval);
      this.list[this.current].value = 0;
      if (this.current - 1 > 0 || this.current - 1 == 0) {
        this.list[this.current - 1].value = 0;
        this.current = this.current - 1;
      }
    } else {
      clearInterval(this.interval);
      this.list[this.current].value = 0;
    }
  }

  next() {
    this.list[this.current].value = 1.02;
    if (this.list.length == this.current + 1) {
      // this.list.forEach(e => {
      //   e["value"] = 0
      // });
      // setTimeout(() => {
      //   this.current = 0;
      // }, 100);
    } else {
      this.current = this.current + 1
    }
    clearInterval(this.interval)
  }
  continueToApp() {
    this.dismiss(null, "continue_to_app")
  }
  expandItem: number = -1
  expandParagraph(index) {
    return;
    if (this.expandItem === index)
      this.expandItem = -1
    else
      this.expandItem = index
  }
}
