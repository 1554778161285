import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { WeatherDetailData, WeatherFarmItem } from '../models/interfaces/weather-response';
import { DateTimeService } from '../services/date-time.service';
import { ClientBaseApiService } from './client-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherForecastApiService {

  constructor(private clientService: ClientBaseApiService, private dateTimeService: DateTimeService) { }

  public GetWeatherByPointFarm() {
    let url = environment.baseApiUrl + "get/weather/farms";
    return this.clientService.baseApiCall<Array<WeatherFarmItem>>({ url, payload: {}, requestType: HttpRequestTypes.GET })
  }

  public GetRainViewerLayer() {
    let url = "https://tilecache.rainviewer.com/api/maps.json";
    return this.clientService.externalApiCall<any>({ url, payload: {}, requestType: HttpRequestTypes.GET })
  }

  public GetWeatherByPoint(payload: { pointId: number, requestType: string | "all", fromDate: number }) {
    let url = environment.baseApiUrl + "get/weather/by/pointId/";
    return this.clientService.baseApiCall<WeatherDetailData>({ url, payload, requestType: HttpRequestTypes.POST })
  }

  // getNight(date, startDate, endDate) {
  //   // date = this.dateTimeService.formatDate(date,"HH:mm");
  //   // startDate = this.dateTimeService.formatDate(startDate,"HH:mm");
  //   // endDate = this.dateTimeService.formatDate(endDate,"HH:mm");

  //   if (this.dateTimeService.isBefore(date, startDate) && this.dateTimeService.isAfter(date, endDate)) {
  //     return "_n"
  //   } else {
  //     return ""
  //   }
  // }
}
