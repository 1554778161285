import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orbit-inline-loading',
  templateUrl: './inline-loading.component.html',
  styleUrls: ['./inline-loading.component.scss'],
})
export class InlineLoadingComponent implements OnInit {
  @Input() state: boolean
  animationClass: string = "loading-animation-step1"
  hideShow = false;
  iconList = [
    "../../../../../assets/icon/loading/location.svg",
    "../../../../../assets/icon/loading/plantation.svg",
    "../../../../../assets/icon/loading/satellite.svg",
    "../../../../../assets/icon/loading/post-harvest.svg"
  ]
  iconIndex: number = 0;

  constructor() { }

  ngOnInit() {
    setInterval(() => {
      this.animationClass = "loading-animation-step2"
      setTimeout(() => {
        this.animationClass = "loading-animation-step1"
        setTimeout(() => {
          this.hideShow = !this.hideShow
        }, 700);
        setTimeout(() => {
          this.iconIndex = this.iconIndex + 1 == this.iconList.length ? 0 : this.iconIndex + 1
          this.hideShow = !this.hideShow
        }, 1000);
      }, 1000);
    }, 2000);
  }

}
