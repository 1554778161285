import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { DateFormats } from "../models/enums/date-formats";
import { IDate } from "../models/interfaces/irrigation-models";
import { LanguageService } from "./lang.service";

@Injectable({
    providedIn: "root"
})
export class DateTimeService {
    private _locale: string;
    public get locale(): string {
        return this.translate.instant("lang.culture_info");
    }
    // public set locale(value: string) {
    //     this._locale = value;
    // }
    static LocaleCode: string
    /**
     *
     */
    constructor(private translate: TranslateService) {
        // this.locale =  this.translate.instant("lang.culture_info") //translate.currentLang || "en"
        DateTimeService.LocaleCode = this.locale
    }

    public static getMoment = moment

    public static isDate(date: string | Date | any) {
        return new Date(date).toString() !== "Invalid Date"
    }

    public GetMoment(date?): moment.Moment {
        if (date && date !== '')
            return moment(date).locale(DateTimeService.LocaleCode);
        else
            return moment().locale(DateTimeService.LocaleCode);
    }

    public static CreateSortFn(objA: Object, objB: Object, key: string) {
        let dateA = objA[key], dateB = objB[key];
        return DateTimeService.CompareDates(dateA, dateB);
    }

    public static CreateSortFnAlt<T extends IDate>(_a: T, _b: T) {
        // let dateA = objA[key], dateB = objB[key];
        // return this.CompareDates(dateA, dateB);
        let dateA = _a.Date, dateB = _b.Date;
        return DateTimeService.CompareDates(dateA, dateB);
    }

    public static CompareDates(date1: string | Date, date2: string | Date, dateFormat?: string): number {
        try {
            let dateTime1 = moment(date1, dateFormat).toDate().getTime() //typeof date1 == 'string' ? new Date(date1).getTime() : date1.getTime()
            let dateTime2 = moment(date2, dateFormat).toDate().getTime() //typeof date2 == 'string' ? new Date(date2).getTime() : date2.getTime()

            return dateTime1 > dateTime2 ? 1 : dateTime1 == dateTime2 ? 0 : -1
        } catch (error) {
            return 0;
        }
    }

    public static IsBetween(dateStart, dateEnd, dateItem) {
        try {
            let dateTimeStart = moment(dateStart).toDate().getTime() //typeof date1 == 'string' ? new Date(date1).getTime() : date1.getTime()
            let dateTimeEnd = moment(dateEnd).toDate().getTime() //typeof date2 == 'string' ? new Date(date2).getTime() : date2.getTime()
            let dateTimeItem = moment(dateItem).toDate().getTime() //typeof date2 == 'string' ? new Date(date2).getTime() : date2.getTime()
            return dateTimeStart < dateTimeItem && dateTimeEnd > dateTimeItem
            // return dateTimeStart > dateTimeEnd ? 1 : dateTimeStart == dateTimeEnd ? 0 : -1
        } catch (error) {
            return true;
        }
    }

    public static IsBefore(date1, date2) {
        if (date1 == "")
            return moment().isBefore(moment(date2));
        else if (date2 == "")
            return moment(date1).isBefore(moment());
        return moment(date1).isBefore(moment(date2));
    }


    public static IsAfter(date1, date2) {
        if (date1 == "")
            return moment().isAfter(moment(date2));
        else if (date2 == "")
            return moment(date1).isAfter(moment());
        return moment(date1).isAfter(moment(date2));
    }

    public static CompareDatesByDay(date1: string | Date, date2: string | Date): number {
        try {
            let dateTime1 = moment(date1).toDate().setHours(0, 0, 0, 0) //typeof date1 == 'string' ? new Date(date1).getTime() : date1.getTime()
            let dateTime2 = moment(date2).toDate().setHours(0, 0, 0, 0) //typeof date2 == 'string' ? new Date(date2).getTime() : date2.getTime()
            return dateTime1 > dateTime2 ? 1 : dateTime1 == dateTime2 ? 0 : -1
        } catch (error) {
            return 0;
        }
    }

    public static FormatDate(date: moment.Moment | string | Date | number, format: DateFormats | string, preFormat?: DateFormats | string) {
        var locale = DateTimeService.LocaleCode || "en-EN" //LanguageService.currentLanguage.code
        if (!date)
            date = moment().locale(locale)
        else {
            try {
                if (typeof date == "string" || typeof date == "number") {
                    if (preFormat) {
                        date = moment(date, preFormat);
                        if (date.toString() == 'Invalid Date')
                            date = moment(date)
                    }
                    else
                        date = moment(date)
                }
            } catch (error) {

            }
            // date = new Date(date).toISOString()
            date = moment(date).locale(locale)
        }
        return date.format(format)
    }

    public formatDate(date: moment.Moment | string | Date, format: DateFormats | string) {
        // this.locale = this.translate.instant("lang.culture_info")
        if (!date)
            date = moment().locale(this.locale)
        else {
            try {
                if (typeof date == "string") {
                    date = new Date(date).toISOString()
                }
            } catch (error) {

            }
            // date = new Date(date).toISOString()
            date = moment(date).locale(this.locale)
        }
        return date.format(format)
    }

    public static isDateInRange(date: string | Date, minDate: string | Date, maxDate: string | Date): boolean {
        let resultMin = true, resultMax = true;
        date = new Date(date);
        if (minDate && minDate !== '') {
            let minDateObj = new Date(minDate);
            if (minDateObj.getTime() > date.getTime())
                resultMin = false;
        }

        if (maxDate && maxDate !== '') {
            let maxDateObj = new Date(maxDate);
            if (maxDateObj.getTime() < date.getTime())
                resultMax = false;
        }
        return resultMin && resultMax;
    }
    // public static ClearHours(_date: string | Date) {
    //     if (typeof _date === 'string')
    //         _date = new Date(_date);
    //     if (_date.toString() === 'Invalid Date')
    //         return null
    //     let date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate())
    //     return date
    // }

    /**
     * getTime() responselarının farkını alıp, dönen değeri dakika saat gün diye bölerek git.
     * değer negatifse ago, pozitif ise later dönecek.
     * 
     * @param _date1 
     * @param _date2 
     * 
     */
    public static GetDateDiffByDays(_date1: Date, _date2: Date) {
        let date1 = moment(_date1)
        let date2 = moment(_date2)
        return date1.diff(date2, "days")
    }

    fromNow(date) {
        let locale = this.translate.instant("lang.culture_info")
        if (date == "")
            return moment().locale(locale).fromNow();
        return moment(date).locale(locale).fromNow();
    }
}