import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FilterArrayPipe } from '../pipes/filter-array.pipe';
import { SearchOnArrayPipe } from '../pipes/search-on-array.pipe';

@NgModule({
    imports: [
        IonicModule,
        CommonModule
    ],
    exports: [FilterArrayPipe, SearchOnArrayPipe],
    declarations: [FilterArrayPipe, SearchOnArrayPipe],
    providers: [],
})
export class FilterArrayPipeModule { }
