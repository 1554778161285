import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { AFAndroidInAppPurchase, AFConstants, AFInit, AFIosInAppPurchase, AppsFlyer } from "appsflyer-capacitor-plugin"
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { UtilsService } from "./utils.service";
import { AppsflyerAnalyticEvents } from "../models/enums/analytic-events";



export class DoktarAppsFlyer {

    private _afConfig: AFInit;
    public get afConfig(): AFInit {
        return this._afConfig;
    }
    private set afConfig(value: AFInit) {
        this._afConfig = value;
    }

    private static _listener: Subject<any> = new Subject<any>();

    public static listener = DoktarAppsFlyer._listener.asObservable()

    /**
     *
     */
    private constructor(props: AFInit) {
        this.afConfig = props
    }

    static build(props: AFInit): DoktarAppsFlyer {
        if (Capacitor.isNativePlatform()) {

            let appsflyer = new DoktarAppsFlyer(props);
            let platform = Capacitor.getPlatform();

            if (appsflyer.afConfig.appID)
                appsflyer.afConfig.appID = ""
            appsflyer.afConfig.isDebug = !environment.production
            switch (platform) {
                case "ios":
                    appsflyer.afConfig.appID = environment.production ? environment.iosAppId : environment.iosDebugAppId
                    appsflyer.afConfig.useReceiptValidationSandbox = false
                    appsflyer.afConfig.useUninstallSandbox = false
                    break;
                case "android":
                // appsflyer.afConfig.appID = environment.androidAppId
                // break;
                case "web":
                default:
                    break;
            }

            // waitForATTUserAuthorization: 10, // for iOS 14 and higher
            // minTimeBetweenSessions: 6, // default 5 sec
            // registerOnDeepLink: true,
            // registerConversionListener: true,
            // registerOnAppOpenAttribution: false,
            // deepLinkTimeout: 4000, // default 3000 ms
            // useReceiptValidationSandbox: true, // iOS only
            // useUninstallSandbox: true // iOS only

            return appsflyer;
        } else
            return null
    }

    public static initAppFlyer(props: DoktarAppsFlyer) {
        if (Capacitor.isNativePlatform())
            return new Observable(observer => {
                AppsFlyer.setOneLinkCustomDomain({ domains: ["orbit.doktar.com", "orbit.onelink.me"] })
                // .then(r => {
                //     console.log("onelinks => ", r)
                // })
                AppsFlyer.initSDK(props.afConfig).then(response => {
                    console.log("appflyer success => " + JSON.stringify(response));
                    // AppsFlyer.setOneLinkCustomDomain({ domains: environment.onelinkDomains })

                    observer.next({ process: "init-success", data: response })
                }).catch(err => {
                    console.log("appflyer error => " + JSON.stringify(err));
                    observer.error({ process: "init-fail", data: err })
                })
            })
        else
            return UtilsService.EmptyObservableRequest(true, 3000)
    }

    public static updateServiceUninstallToken(token: string) {
        if (Capacitor.isNativePlatform())
            AppsFlyer.updateServerUninstallToken({ token }).then(res => { })
    }

    public static LogEvent(eventName: string, eventValue?: { [key: string]: any }) {
        try {
            if (Capacitor.isNativePlatform()) {
                if (!environment.production)
                    eventValue["traffic_type"] = "internal"
                AppsFlyer.logEvent({ eventName, eventValue }).then(res => {
                    console.log("appsflyer eventLogged => ", JSON.stringify(res))
                }).catch(err => {
                    console.log("appsflyer eventLogged failed => ", JSON.stringify(err))
                })
            }
        } catch (error) {

        }
    }

    private static previousLocation: { lat: number, lng: number }
    public static LogLocation(latitude: number, longitude: number) {
        try {
            if (Capacitor.isNativePlatform()) {
                let lat = latitude.toFixed(4), lng = longitude.toFixed(4)
                if (this.previousLocation.lat != +lat || this.previousLocation.lng != +lng) {
                    AppsFlyer.logLocation({ latitude, longitude })
                    this.previousLocation = { lat: +lat, lng: +lng }
                }
            }
        } catch (error) {

        }
    }

    public static SetUserEmail(email: string) {
        try {
            if (Capacitor.isNativePlatform())
                AppsFlyer.setUserEmails({ emails: [email], encode: false })
        } catch (error) {

        }
    }

    // public static SetScreenName(screenName){
    //     try {
    //         if(Capacitor.isNativePlatform()){
    //             AppsFlyer.logEvent({eventName:""})
    //         }
    //     } catch (error) {

    //     }
    // }

    public static SetUserPhone(phone: string) {
        try {
            if (Capacitor.isNativePlatform())
                AppsFlyer.setPhoneNumber({ phone })
        } catch (error) {

        }
    }

    public static LogPurchaseForAndroid(data: AFAndroidInAppPurchase) {
        try {
            if (Capacitor.getPlatform() === 'android') {
                if (!environment.production)
                    data["traffic_type"] = "internal"
                AppsFlyer.validateAndLogInAppPurchaseAndroid(data).then(res => {
                    console.log("appsflyer purchase android success => ", JSON.stringify(res))
                }).catch(err => {
                    console.log("appsflyer purchase android error => ", JSON.stringify(err))
                })
            }
        } catch (error) {

        }
    }

    public static LogPurchaseForIos(data: AFIosInAppPurchase) {
        try {
            if (Capacitor.getPlatform() === 'ios') {
                if (!environment.production)
                    data["traffic_type"] = "internal"
                AppsFlyer.validateAndLogInAppPurchaseIos(data).then(res => {
                    console.log("appsflyer purchase ios success => ", JSON.stringify(res))
                }).catch(err => {
                    this.LogEvent("ios_custom_in_app_purchase", data)
                    console.log("appsflyer purchase ios error => ", JSON.stringify(err))
                })
            }
        } catch (error) {

        }
    }

    public static AfEventLog(eventName: AppsflyerAnalyticEvents, eventValue?: {
        af_content?,
        af_currency?,
        af_registration_method?,
        af_customer_user_id?,
        af_revenue?,
        af_country?,
        af_adrev_ad_type?,
        af_adrev_network_name?
    } & { [key: string]: any }) {
        try {
            if (Capacitor.isNativePlatform()) {
                if (!environment.production)
                    eventValue["traffic_type"] = "internal"
                AppsFlyer.logEvent({ eventName, eventValue }).then(res => {
                    console.log("appsflyer eventLogged => ", JSON.stringify(res))
                }).catch(err => {
                    console.log("appsflyer eventLogged failed => ", JSON.stringify(err))
                })
            }
        } catch (error) {

        }
    }

}


@Injectable({
    providedIn: "root"
})
export class AppsFlyerService {

    private appflyerObj: DoktarAppsFlyer

    /**
     *
     */
    constructor() {

    }

    public initAppFlyer(prop: DoktarAppsFlyer) {
        return new Observable(observer => {
            observer.next()
            // this.appflyerObj = prop;
            // AppsFlyer.initSDK(this.appflyerObj.afConfig).then(response => {
            //     observer.next({ process: "init-success", data: true })
            // }).catch(err => {
            //     observer.error({ process: "init-fail", data: err })
            // })


        })
    }

}