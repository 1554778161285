import { Injectable } from '@angular/core';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { AnalyticEvents } from '../models/enums/analytic-events';
import { AnalyticService } from './analytic.service';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';

@Injectable({ providedIn: 'root' })
export class CleverTapService {
    constructor(private cleverTap: CleverTap) {

    }

    activate(deviceToken: string) {
        if (Capacitor.isNativePlatform()) {
            this.cleverTap.setDebugLevel(environment.production ? 3 : 2)
            this.cleverTap.getCleverTapID().then(res => {
                console.log("getCleverTapID => ", res)
                Device.getId().then(value => {
                    console.log("session started", { Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId: value.uuid })
                    this.recordEvent(AnalyticEvents.SESSION_START, { Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId: value.uuid })
                })
            }).catch(err => {
                console.log("getCleverTapID err => ", err)
            })
            this.cleverTap.notifyDeviceReady().then(res => {
                console.log("device ready => ", res)
            }).catch(err => {
                console.log("device ready error => ", err)
            })

            // this.cleverTap.registerPush().then()
            // if (Capacitor.getPlatform() === "android") {
            this.cleverTap.setPushToken(deviceToken).then(res => {
                console.log("setPushToken => ", res)
            }).catch(err => {
                console.log("setPushToken error => ", err)
            })
            // }

            this.cleverTap.activate().then(res => {
                // Device.getId().then(value => {
                //     console.log("session started",{Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId:value.uuid})
                //     this.recordEvent(AnalyticEvents.SESSION_START, {Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId:value.uuid})
                // })
                console.log("activated => ", JSON.stringify(res))
                this.cleverTap.registerPush().then(register => {
                    // console.log(register)
                    // if (Capacitor.getPlatform() == "android")
                    this.cleverTap.setPushToken(deviceToken).then()
                })
            }).catch(err => {
                console.log("activation error => ", JSON.stringify(err))
            })
        }
    }

    recordEvent(eventName: string, props?: any) {
        if (Capacitor.isNativePlatform()) {
            if (!props)
                props = {}
            if (!environment.production)
                props["traffic_type"] = "internal"
            this.cleverTap.recordEventWithNameAndProps(eventName, props).then(r => {
                console.log("clevertap event sended")
            });
            // }
            // else
            //     this.cleverTap.recordEventWithName(eventName).then(r => {
            //         console.log("clevertap event sended")
            //     })
        }
    }

    recordScreen(screenName: string) {
        if (Capacitor.isNativePlatform())
            this.cleverTap.recordScreenView(screenName)
    }

    setUserProfile(props: {
        // Name: string, //'Captain America',
        // Identity: string, //'100',
        // Email: string, //'captain@america.com',
        // Phone: string, //'+14155551234',
        // stuff: any
        "Name", // i.e. Aydin Yumusak
        "Email", // i.e. aydin.yumusak doktar.com
        "Gender", // i.e. Male
        "Phone", // i.e. 05391111111
        "Age", // i.e 30
        "Birthday", // i.e. 01.02.1990
        "Customer_Type", //  i.e. Gold, Silver, Bronze
        "UserID",
        "MSG_push", // i.e. true/false
        "MSG_webpush", // i.e. true/false
        "MSG_email", // i.e. true/false
        "MSG_sms", // i.e. true/false
        "MSG_whatsapp", // i.e. true/false
        "Language", // i.e. English,French
        "Location", // i.e. United States, Turkey
        "Timezone" // i.e. UTC+3
    }) {
        if (Capacitor.isNativePlatform()) {
            this.cleverTap.onUserLogin(props).then(res => {

            })
            this.cleverTap.enablePersonalization();
        }
    }

    private _previousLocation: { lat, lng } = null
    setLocation(lat, lng) {
        if (Capacitor.isNativePlatform()) {
            let newValue = { lat: lat.toFixed(4), lng: lng.toFixed(4) }
            if (this._previousLocation) {
                if (JSON.stringify(this._previousLocation) != JSON.stringify(newValue)) {
                    this._previousLocation = newValue
                    this.cleverTap.setLocation(lat, lng).then()
                }
            } else {
                this._previousLocation = newValue
                this.cleverTap.setLocation(lat, lng).then()
            }
        }
    }

    recordPurchase(receipt, props) {
        if (Capacitor.isNativePlatform()) {
            if (!props)
                props = {}
            if (!environment.production)
                props["traffic_type"] = "internal"
            this.cleverTap.recordChargedEventWithDetailsAndItems(receipt, props).then(res => {
                console.log("recordPurchase success => ", JSON.stringify(res));
            }).catch(err => {
                console.log("recordPurchase error => ", JSON.stringify(err));
            })
        }
    }
}