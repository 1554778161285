import { computed, signal } from "@angular/core";
import FarmControl from "./farm-control.class";
import { SatelliteImages } from "../satellite-images";
import { SatelliteImageTypes } from "src/app/core/models/enums/satellite-image-types";
import { DateTimeService } from "src/app/core/services/date-time.service";
import { DateFormats } from "src/app/core/models/enums/date-formats";
import Spraying from "../spraying";

export const signalGetters = {
    satelliteCurrentDate: signal(null as SatelliteImages),
    currentImageType: signal(SatelliteImageTypes.HEALTH),
    selectedFarm: signal(null as FarmControl),
    farmList: signal(null as FarmControl[]),
    sprayingSignal: signal([] as Array<Spraying>)
}

// {
//     equal: (previous, next) => {
//         if (!previous)
//             return false
//         else {
//             if (previous.currentImageType !== next.currentImageType)
//                 return false
//             else if (previous.satelliteCurrentDate.Id !== next.satelliteCurrentDate.Id) {
//                 return false
//             } else if (previous.selectedFarm.farm.TempFarmAttId !== next.selectedFarm.farm.TempFarmAttId) {
//                 return false
//             } else {
//                 return true
//             }
//         }
//     }
// }

// export const farmSignal = signal<FarmControl>(null)


// const selectedFarmSignal = signal(null as FarmControl)
// const farmListSignal = signal(null as FarmControl[])

export const mutations = {
    farmSelection: (prop: FarmControl) => {
        prop.GetSatelliteImages(DateTimeService.FormatDate("", DateFormats.FULL_DATE_DASH_REVERSE))
        signalGetters.selectedFarm.set(prop)
        // signalGetters.selectedFarm.mutate((val) => {
        //     prop.GetSatelliteImages(DateTimeService.FormatDate("", DateFormats.FULL_DATE_DASH_REVERSE))
        //     val = prop
        // })
    },
    changeCurrentDate: (prop: SatelliteImages) => {
        // if (prop)
        //     prop.GetAnalysisData()
        signalGetters.satelliteCurrentDate.set(prop)
        // signalGetters.satelliteCurrentDate.mutate(val => {
        //     prop.GetAnalysisData()
        //     val = prop
        // })
    },
    changeCurrentImagetype: (prop: SatelliteImageTypes) => {
        signalGetters.currentImageType.set(prop)
        // signalGetters.currentImageType.mutate(val => {
        //     val = prop
        // })
    },
    // farmUpdated: (prop: FarmControl) => {
    //     getters.mutate((val) => {

    //     })
    // },
    farmsReloaded: (prop: Array<FarmControl>) => {
        signalGetters.farmList.set(prop)
        // signalGetters.farmList.mutate((val) => {
        //     val = prop
        // })
    }
}