import { Component, OnInit } from '@angular/core';
import { CameraSource } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { IListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { AltBaseModal } from '../../class/alt-base-modal.component';



@Component({
  selector: 'app-add-media-sheet',
  templateUrl: './add-media-sheet.component.html',
  styleUrls: ['./add-media-sheet.component.scss'],
})
export class AddMediaSheetComponent extends AltBaseModal implements OnInit  {

  list: IListModalModel[] = [
    {
      id: 1,
      value: this.langService.instant("media-options.camera"),
      key: "camera",
      icon: "gallery"
    },
    {
      id: 2,
      value: this.langService.instant("media-options.video"),
      key: "video",
      icon: "videocam"
    },
    {
      id: 2,
      value: this.langService.instant("media-options.audio"),
      key: "audio",
      icon: "voice"
    }
  ]
  constructor(private modalService: ModalService, private langService: TranslateService) {
    super(modalService)
    // super._modalService = modalService
  }


  ngOnInit() {
    super.ngOnInit()
  }
  itemClicked(prop) {
    this.dismiss(prop, 'itemSelected')
  }
}
