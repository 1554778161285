export enum DateFormats{
    FULL_DATE_DOTS = "DD.MM.YYYY",
    FULL_DATE_SLASH = "DD/MM/YYYY",
    FULL_DATE_DASH = "DD-MM-YYYY",
    FULL_DATE_DOTS_REVERSE = "YYYY.MM.DD",
    FULL_DATE_DASH_REVERSE = "YYYY-MM-DD",
    FULL_DATE_SLASH_REVERSE = "YYYY/MM/DD",
    DAY_AND_MONTH = "DD MMM",
    FULL_DATE_SPACES = "DD MMM YYYY",
    FULL_DATE_SPACES_SHORT_YEAR = "DD MMM YY",
    BASIC_TIME = "hh:mm",
    FULL_DATE_FULL_TIME_NOSPACES = "DDMMYYYYHHmmss",
    MONTH_LONG = "MMMM",
    DAY_SIMPLE = "D",
    DAY_LONG = "dddd",
    HOUR_SIMPLE = "HH",
    FULL_DATE_TIME_COMA = "DD.MM.YYYY, HH:mm",
    FULL_DATE_TIME = "DD.MM.YYYY HH:mm",
    FULL_DATE_BASIC_TIME_NOSPACES = "DDMMYYYYHHmm",
}