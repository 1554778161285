import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary')


@Injectable({
    providedIn: "root"
})
export class ClientStatusBarService {

    public statusBarOverlay(){
        StatusBar.setOverlaysWebView({
            overlay:true
        }).then()
    }

    public setStatusBarThemeUltra(isDarkMode) {
        return new Promise(resolve => {
            if (Capacitor.getPlatform().toLowerCase().includes("web"))
                resolve(true)
            else {
                StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light })
                StatusBar.setBackgroundColor({ color: isDarkMode ? "#00000000" : "#FFFFFF00" })
                resolve(true)
            }
        })
    }

    public setStatusBarTheme(isDarkMode) {
        return new Promise(resolve => {
            if (Capacitor.getPlatform().toLowerCase().includes("web"))
                resolve(true)
            else {
                StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light })
                StatusBar.setBackgroundColor({ color: isDarkMode ? "#22242800" : "#f2f4f500" })
                resolve(true)
            }
        })
    }
}