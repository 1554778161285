import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { IListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-generic-list-modal',
  templateUrl: './generic-list-modal.component.html',
  styleUrls: ['./generic-list-modal.component.scss'],
})
export class GenericListModalComponent extends BaseModal implements OnInit {
  @Input() title:string = ""
  @Input() list: Array<IListModalModel> = []
  @Input() callback: any

  constructor(private modalService:ModalService) { 
    super()
    super._modalService = modalService
    this.contentClass = "bg-default";
  }

  ngOnInit() {
    super.ngOnInit()
  }
  itemClicked(item){
    this.callback(item.key)
    // this.modalService.SubjectNext(this.id, item.key)
  }
}
