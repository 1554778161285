<orbit-modal-base>
  <ion-toolbar bg="none">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss(null)" possibleOutcome="close modal" id="gallery-modal-back-btn">
        <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{"photo_gallery_title" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss(null)" possibleOutcome="close modal" id="gallery-modal-close-btn">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-card-content [ngStyle]="{maxHeight: 'calc(100vh - '+areaHeight+'px)'}" class="ion-no-padding card-content">
    <ion-grid>
      <ion-row *ngIf="imageList && imageList.length > 0; else noImageArea">
        <ng-container *ngFor="let item of imageList; let i = index">
          <ion-col size-xs="6" size-sm="3" size-md="2" size-lg="2" size-xl="1" style="padding: 8px;" *ngIf="item">
            <ion-card class="ion-no-margin ion-no-padding gallery-item-height" (click)="previewImage(item)"
              [id]="'gallery-modal-item-' + i">
              <div class="gallery-item" [style]="{'background-image': 'url(' + item.src + ')'}">
                <ion-fab class="action-btn" vertical="top" horizontal="end" slot="fixed">
                  <ion-fab-button size="small" color="light" (click)="$event.stopPropagation(); deleteImage(item)"
                    [id]="'gallery-modal-item-' + i + '-delete-btn'">
                    <ion-icon name="trash-outline" size="small" color="primary"></ion-icon>
                  </ion-fab-button>
                </ion-fab>
              </div>
            </ion-card>
          </ion-col>
        </ng-container>
      </ion-row>
      <ng-template #noImageArea>
        <ion-row>
          <ion-col size="12" class="p-vertical-20">
            <!-- not_found_image_1.svg -->
            <div class="d-flex column align-center">
              <ion-img class="m-top-20 m-bottom-10" style="width: 45%;"
                src="../../../../../assets/images/not_found_image_1.svg"></ion-img>
              <span class="font-size-16 font-weight-1000 m-top-10 m-bottom-20">
                {{"no_photo_added_alert" | translate}}
              </span>
            </div>
          </ion-col>
        </ion-row>
      </ng-template>
      <ion-row>
        <ion-col size="12">
          <ion-button (click)="addPhoto()" color="primary" expand="block" fill="solid" shape="round"
            id="gallery-modal-add-photo-btn">
            <ion-icon slot="start" name="add"></ion-icon> {{"general.add_photo" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</orbit-modal-base>

<ng-container *ngIf="selectedImage && selectedImage !== ''">
  <image-preview [image]="selectedImage" (close)="selectedImage = null"></image-preview>
</ng-container>