import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientBaseApiService } from '../api/client-base-api.service';
import { IAppConstants } from '../models/interfaces/i-app-constants';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ClientAlertService } from './client-alert.service';
import { IButtonOpts } from '../models/interfaces/modal-components-models';
import { TranslateService } from '@ngx-translate/core';
import { NativeMarket } from "@capacitor-community/native-market";

@Injectable({ providedIn: 'root' })
export class ForceUpdateService {

    private _appId: string = Capacitor.getPlatform().toLowerCase() == 'ios' ? environment.iosAppId : environment.androidAppId

    constructor(private clientApiService: ClientBaseApiService, private clientAlertService: ClientAlertService, private translate: TranslateService) {
    }

    public checkForNewVersion() {
        let constantJsonUrl = environment.constantJsonUrl + "?v=" + (Math.random() * 1000)
        if (Capacitor.isNativePlatform())
            this.clientApiService.externalApiCall<{ orbit: IAppConstants }>({ payload: null, requestType: HttpRequestTypes.GET, url: constantJsonUrl }).subscribe(response => {
                if (response) {
                    let data = response.orbit.appUpdateInfo;
                    if (data && data.mustUpdate) {
                        App.getInfo().then(appInfo => {
                            let currentVersion: string = appInfo.version;
                            let marketVersion: string = data.marketVersions[Capacitor.getPlatform().toLowerCase()]
                            let currentSplitted = currentVersion.split(".")
                            let marketSplitted = marketVersion.split(".")
                            /**
                             * iflerin else durumlarının çalışması için bir önceki ifte market versiyonunun küçük veya eşit olması gerekmektedir.
                             * Aksi durumda ilk ifte aslında bitmesi gereken durum alttaki iflerde hatalı olarak sağlanabilmektedir.
                             * Buradaki if mantığı daha sağlıklı daha az koşul olan bir şekilde değiştirilebilir.
                             * 
                             */
                            if (+marketSplitted[0] > +currentSplitted[0]) {
                                this._newVersionAlert()
                            } else if (+marketSplitted[0] == +currentSplitted[0] && +marketSplitted[1] > +currentSplitted[1]) {
                                this._newVersionAlert()
                            } else if (+marketSplitted[0] == +currentSplitted[0] && +marketSplitted[1] == +currentSplitted[1] && +marketSplitted[2] > +currentSplitted[2]) {
                                this._newVersionAlert()
                            }
                        })
                    }
                }
            })
    }


    private _newVersionAlert() {
        this.clientAlertService.PresentAlert({
            buttons: [
                {
                    callback: () => {
                        NativeMarket.openStoreListing({ appId: this._appId }).then()
                    },
                    color: "primary",
                    fill: "solid",
                    text: this.translate.instant("force_update.open_market_btn")
                }
            ] as IButtonOpts[],
            title: this.translate.instant("force_update.major_title"),
            description: this.translate.instant("force_update.major_description"),
            hasBackdropdismiss: false,
            preventDismiss: true
        }, true).then(res => { })
    }


}