<ion-content (click)="hasBackdropdismiss ? dismissModal(id) : null"
  [possibleOutcome]="hasBackdropdismiss ? 'close modal' : 'not_allowed'" id="alert-modal-ion-content"
  [ngClass]="contentClass" class="bg-default">
  <ion-grid fixed style="height: 100%;" class="ion-no-padding">
    <ion-row class="ion-align-items-end ion-justify-content-center ion-no-padding" style="height: 100%;">
      <ion-col sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="10" style="border-radius: 40px 40px 0 0; margin:unset; ">
        <ion-card (click)="$event.stopPropagation()" possibleOutcome="not_allowed" id="alert-modal-container-card"
          color="light" shape="round"
          class="ion-no-margin p-horizontal-20 p-top-30 custom-ion-card border-radius-bottom-none">
          <ion-grid fixed>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col size="12" style="position: relative;">
                <ion-text color="dark" class="ion-text-center" *ngIf="mainTitle && mainTitle !== ''">
                  <h2 class="font-size-18-i font-weight-600 m-top-7" [innerHTML]="mainTitle"></h2>
                </ion-text>
                <ion-icon style="position: absolute; right:10px;top: calc(50% - 10px);font-size: 24px;" color="dark"
                  [src]="'closeCircle' | orbitIcons" (click)="dismissModal(id)" possibleOutcome="close modal"
                  id="alert-modal-dismiss-icon">
                </ion-icon>
              </ion-col>
              <ion-img imageLoad style="width: 50%;" class="width-max-125 m-vertical-10" [ngClass]="imageClassList"
                *ngIf="image && image != ''" [src]="image"></ion-img>
              <ion-col size="12" *ngIf="title && title !== ''">
                <ion-text color="dark">
                  <h2 class="ion-text-center m-vertical-8 font-size-20 font-weight-900" [innerHTML]="title"></h2>
                </ion-text>
              </ion-col>
              <ion-col size="12" class="ion-text-center" *ngIf="description && description !==  ''">
                <ion-text color="dark">
                  <p class="ion-text-center m-top-8 font-size-14 font-weight-500" [innerHTML]="description">
                  </p>
                </ion-text>
              </ion-col>
            <ng-container *ngIf="buttons && buttons.length > 0">
              <ion-col size="12" class="p-all-12">
                
              </ion-col>
              <ion-col size="12" class="m-bottom-16" *ngFor="let item of buttons">
                <ion-button (click)="buttonClick($event,item)" [id]="'alert-'+item.id" expand="block" [fill]="item.fill"
                  shape="round" [color]="item.color">
                  {{item.text}}
                </ion-button>
              </ion-col>
            </ng-container>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>