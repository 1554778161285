import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InlineLoadingComponent } from '../helper/components/inline-loading/inline-loading.component';
import { LoadingComponent } from '../helper/components/loading/loading.component';


@NgModule({
    imports: [ 
        IonicModule,
        CommonModule],
    exports: [LoadingComponent,InlineLoadingComponent],
    declarations: [LoadingComponent,InlineLoadingComponent],
    providers: [],
})
export class CustomLoadingModule { }
