import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AltBaseModal } from 'src/app/core/helper/class/alt-base-modal.component';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { AccountService } from 'src/app/core/services/account.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends AltBaseModal implements OnInit {
  passwordForm: UntypedFormGroup = new UntypedFormGroup({
    OldPassword: new UntypedFormControl(null, []),
    Password: new UntypedFormControl("", [(ev) => {
      if (this.passwordForm) {
        let control = this.passwordForm.controls["PasswordRepeat"]
        setTimeout(() => {
          if (control.value && control.value !== '') {
            control.markAsDirty({ onlySelf: true })
            control.enable({ emitEvent: true, onlySelf: true })
          }
        }, 500);
        let response = OrbitFormValidators.mismatchPasswordValidation(control)
        return response(ev)
      } else
        return null
    }, Validators.minLength(6), Validators.maxLength(14), Validators.required]),
    PasswordRepeat: new UntypedFormControl("", [(ev) => {
      if (this.passwordForm) {
        let control = this.passwordForm.controls["Password"]
        setTimeout(() => {
          if (control.value && control.value !== '') {
            control.markAsDirty({ onlySelf: true })
            control.enable({ emitEvent: true, onlySelf: true })
          }
        }, 500);
        let response = OrbitFormValidators.mismatchPasswordValidation(control)
        return response(ev)
      }
      else
        return null
    }, Validators.minLength(6), Validators.maxLength(14), Validators.required])
  });
  isSend: boolean = false;
  passwordMatch: boolean = true;
  wrongPassword: boolean = false;
  constructor(private modalService: ModalService,
    private accountService: AccountService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    super(modalService)
    // super._modalService = modalService
    // this.demoArray = new Array(2).fill(1).map(t => 1)

  }


  save() {
    if (this.passwordForm.value.Password == this.passwordForm.value.PasswordRepeat) {
      this.loadingService.showLoading();
      this.accountService.changeUserPassword({ OldPassword: this.passwordForm.value.OldPassword, NewPassword: this.passwordForm.value.Password }).subscribe(e => {
        this.loadingService.hideLoading();
        if (e.IsSuccess) {
          this.isSend = true;
        } else {
          if (e.ValidationErrors && e.ValidationErrors.length > 0) {
            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.ValidationErrors[0]), type: Message_Type.WARNING, duration: 3000 });
          } else {
            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.Message), type: Message_Type.DANGER, duration: 3000 });
          }
        }
      }, err => {
        this.loadingService.hideLoading();
        if (err.Message === 'old-password-is-incorrect')
          this.wrongPassword = true
        this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + err.Message), type: Message_Type.DANGER, duration: 3000 });
      });
    } else {
      this.passwordMatch = false;
    }
  }


  ngOnInit() {
    super.ngOnInit()
  }
}
