import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { Capacitor } from '@capacitor/core';
import { AccountService } from '../services/account.service';
import { UnitPreferenceService } from '../services/unit-preference.service';
import { catchError, tap } from 'rxjs/operators';
import { LanguageService } from '../services/lang.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.baseApiUrl) || req.url.startsWith(environment.gatewayApiUrl)) {
      let devicePlatform = Capacitor.getPlatform(); //Capacitor.platform ?? 'other';      
      let headers =
        [
          { name: "platform", value: "mobile" },
          { name: "apiVersion", value: "1.1" },
          { name: "roleId", value: "37" },
          { name: "companyId", value: environment.companyId }, //"193"
          { name: "campaignId", value: environment.campaignId }, //"113"
          { name: "lang", value: LanguageService.CurrentLangCode ? LanguageService.CurrentLangCode : "en" }, //LanguageService.currentLanguage.code ||
          { name: "devicePlatform", value: devicePlatform },
          // {name: "Authorization", value: ""}
          // { name: "speedUnit", value: AccountService.selectedSpeedUnit || "km/h" },
          // { name: "areaUnit", value: AccountService.selectedAreaUnit || "da" },
          // { name: "temperatureUnit", value: AccountService.selectedTemperatureUnit || "C" },
          // { name: "rainfallUnit", value: AccountService.selectedRainfallUnit || "mm" }
        ]

      if (AccountService.selectedSpeedUnit) headers.push({ name: "speedUnit", value: AccountService.selectedSpeedUnit })
      if (AccountService.selectedAreaUnit) headers.push({ name: "areaUnit", value: AccountService.selectedAreaUnit })
      if (AccountService.selectedTemperatureUnit) headers.push({ name: "temperatureUnit", value: AccountService.selectedTemperatureUnit })
      if (AccountService.selectedRainfallUnit) headers.push({ name: "rainfallUnit", value: AccountService.selectedRainfallUnit })


      let tokenValue = AccountService.token;
      if (tokenValue) {
        headers.push({ name: "token", value: tokenValue })
        // req = req.clone({
        //   setHeaders: {
        //     "token": tokenValue,
        //   },
        // });
      }
      let bearerValue = AccountService.bearer;
      if (bearerValue) {
        headers.push({ name: "Authorization", value: bearerValue })
        // headers.push({ name: "token", value: tokenValue })
        // req = req.clone({
        //   setHeaders: {
        //     "token": tokenValue,
        //   },
        // });
      }
      if (Capacitor.getPlatform() === 'web') {
        //headers.push({ name: "deviceToken", value: "web_dev_notification_device_token" })
      }
      else {
        let deviceToken = StorageService.DeviceToken;
        if (deviceToken) {
          headers.push({ name: "deviceToken", value: deviceToken })
          // headers["deviceToken"] = deviceToken
          // req = req.clone({
          //   setHeaders: {
          //     "deviceToken": deviceToken,
          //   },
          // });
        }
      }

      let reqHeaders = req.headers;
      headers.forEach(el => {
        reqHeaders = reqHeaders.set(el.name, el.value)
      })
      let _req = req.clone({
        headers: reqHeaders
      });
      return next.handle(_req) //.pipe(catchError(t => of(t)));
    } else
      return next.handle(req) //.pipe(catchError(t => of(t)));
  }
}

