<orbit-modal-base (onDismiss)="dismiss(null)">
  <ion-grid fixed>
    <ion-fab vertical="top" horizontal="end" class="top-minus-15 right-minus-15" size="small">
      <ion-fab-button fill="clear" bg="dark" size="small" (click)="dismiss(null)" id="deleted-farms-alert-close-btn">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-row *ngIf="farmList.length == 1; else multipleFarm" class="ion-justify-content-center ion-align-items-center">
      <ion-col style="height: 110px; " size="12">
        <div style="text-align: center;width: 100%;height: 100px;" *ngIf="farmList[0].GeoJSON">
          <svg class="farm-svg" viewBox="0 0 300 300" [fromGeoJson]="farmList[0].GeoJSON"></svg>
        </div>
      </ion-col>
      <ion-col size="12" class="m-bottom-24">
        <ion-text color="dark">
          <h2 class="ion-text-center m-bottom-0 m-top-0 font-size-16 font-weight-800">
            {{"my_fields.shared_field_removed_title_singular" | translate: ({farm:farmList[0].FarmName})}}
          </h2>
        </ion-text>
      </ion-col>
      <ion-col size="12" class="ion-text-center m-bottom-32">
        <ion-text color="dark">
          <p class="ion-text-center font-size-14 font-weight-500">
            {{"my_fields.shared_field_removed_desc" | translate}}
          </p>
        </ion-text>
      </ion-col>
      <ion-col size="12">
        <ion-button (click)="dismiss(null)" possibleOutcome="close modal" id="shared-field-removed-alert-close-btn"
          expand="block" [fill]="'solid'" shape="round" [color]="'primary'">
          {{'my_fields.shared_field_removed_primary_btn' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ng-template #multipleFarm>
      <ion-row>
        <ion-col size="12" class="d-flex justify-center align-center m-bottom-12">
          <ion-img imageLoad style="width: 50%;" class="width-max-125" class="m-vertical-10"
            src="./../../../assets/images/add_field_empty_page.svg"></ion-img>
        </ion-col>
        <ng-container *ngIf="!showFarmList">
          <ion-col size="12" class="ion-text-center m-bottom-18">
            <ion-text color="dark">
              <h2 class="ion-text-center m-bottom-0 m-top-0 font-size-16 font-weight-700">
                {{"my_fields.n_of_your_fields_have_been_deleted" | translate: ({count: farmList.length})}}
              </h2>
            </ion-text>
          </ion-col>
          <ion-col size="12" class="ion-text-center m-bottom-32">
            <ion-text color="dark">
              <p class="ion-text-center m-bottom-20 m-top-8 font-size-14 font-weight-500">
                {{'my_fields.multiple_shared_fields_removed_desc' | translate}}
              </p>
            </ion-text>
          </ion-col>
          <ion-col size="12">
            <ion-button (click)="dismiss(null)" possibleOutcome="close modal" id="shared-fields-removed-alert-close-btn"
              expand="block" [fill]="'solid'" shape="round" [color]="'primary'">
              {{'my_fields.shared_field_removed_primary_btn' | translate}}
            </ion-button>
          </ion-col>
          <ion-col size="12">
            <ion-button (click)="showFarmList = true" id="show-deleted-shared-fields-list-btn" expand="block"
              fill="clear" shape="round" [color]="'primary'">
              {{'my_fields.shared_field_removed_secondary_btn' | translate}}
            </ion-button>
          </ion-col>
        </ng-container>

        <ng-container *ngIf="showFarmList">
          <ion-col size="12">
            <ion-text color="dark">
              <p class="m-bottom-20 m-top-8 font-size-16 font-weight-800">
                {{'my_fields.followed_fields_removed' | translate : ({count: farmList.length})}}
              </p>
            </ion-text>
          </ion-col>
          <ion-col size="12">
            <ul class="p-left-20 m-vertical-20">
              <li *ngFor="let item of farmList">{{item.FarmName}}</li>
            </ul>
          </ion-col>
          <ion-col size="12">
            <ion-button (click)="dismiss(null)" possibleOutcome="close modal" id="close-deleted-farms-alert-btn"
              expand="block" fill="solid" shape="round">
              {{"my_fields.got_it_btn" | translate}}
            </ion-button>
          </ion-col>
        </ng-container>
      </ion-row>
    </ng-template>
  </ion-grid>
</orbit-modal-base>