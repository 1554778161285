import { Directive, HostListener } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';

@Directive({ selector: '[canCloseKeyboard]' })
export class InputKeypressListenerDirective {

    @HostListener('keyup', ['$event'])
    keyup(event) {
        if (event.code === "Enter")
            Keyboard.hide().then()
    }

    constructor() {

    }
}