<ion-header>
  <ion-toolbar class="p-horizontal-16">
    <ion-buttons slot="start">
      <ion-button class="custom-back-button" (click)="dismiss(null)" color="ultra-light" id="forget-password-back-btn">
        <ion-icon [slot]="'icon-only'" class="custom-back-icon" [src]="'./../../../../../assets/icon/orbit-custom-back-icon.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="openModal()" id="forget-password-open-lang-selection">
        <span class="font-size-14 font-weight-700">
          {{"lang.default" | translate}} <ion-icon class="m-left-5" style="margin-bottom:-3px"
            name="chevron-down-outline"></ion-icon>
        </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="p-horizontal-25" *ngIf="!isSendCode">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <h2 class="font-size-28 font-weight-900 m-top-6">{{"auth.forget_password_title" | translate}}</h2>
        <p class="font-size-14">{{"auth.forget_password_text" | translate}}</p>
      </ion-col>
      <ion-col size="12">
        <form [formGroup]="forgotForm">
          <ion-col size="12">
            <div class="d-flex row gap-12">
              <div>
                <ion-item shape="round" class="border-basic focus-border full-height arpa-input-item" color="light" lines="none" (click)="openFlagsModal()" id="forget-password-country-item">
                  <img slot="start" class="height-20 m-right-8-i" [src]=" ('countryFlags' | environment) + (selectedCountry?.iso2 | uppercase)+'.svg'" alt="" srcset="">
                  <ion-label class="font-size-14-i" color="dark">+{{selectedCountry?.dialCode}}</ion-label>
                  <ion-icon class="font-size-14-i" color="dark" slot="end" name="chevron-forward-outline"></ion-icon>
                </ion-item>
              </div>
              <div class="flex-1">
                <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="light" lines="none">
                  <ion-label  class="form-label" style="margin-top:-12px" position="floating">{{'auth.phone_number' | translate}}</ion-label>
                  <ion-input required formControlName="phone" type="tel" [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate"></ion-input>
                </ion-item>
              </div>
            </div>
            <ion-text *ngIf="forgotForm | isFormValid : 'phone' : ['phoneValidation']" color="danger" class="form-error">
              <span>{{"auth.enter_valid_email_or_phone" | translate}}</span>
            </ion-text>
          </ion-col>
        </form>
      </ion-col>
      <ion-col size="12">
        <ion-button text-transform="none" [disabled]="!(forgotForm.controls['phone'].value)" (click)="requestCode()" id="forget-password-request-code-btn" expand="block"
          style="height: 55px;">
          {{"auth.request_code_btn" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <sms-validation #smsValidation *ngIf="isSendCode" [class]="!isSendCode ? 'hidden':'hidden hidden-open'" [phoneNumber]="lastPhonenumber" [rawPhoneNumber]="rawPhoneNumber" (onConfirm)="confirm($event)" (onSendAgain)="requestCode()">
  </sms-validation>
  
  <ion-text color="dark" *ngIf="showCodeSendedMessage">
    <h3 class="font-size-16-i font-weight-600-i p-horizontal-32">
      <ion-icon name="alert-outline" color="danger"></ion-icon> 
      {{'errors.password-has-not-expired' | translate}}
    </h3>
  </ion-text>
</ion-content>