import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../services/date-time.service';

@Pipe({
    name: 'isToday'
})

export class IsTodayPipe implements PipeTransform {

    constructor(private dateTimeService: DateTimeService) {
    }

    transform(date: string | Date): "today" | "yesterday" | "tomorrow" | "unknown" | "invalid" {
        if (date) {
            let today = new Date()
            let tempDate = new Date(date);
            let todayStr = DateTimeService.FormatDate(today, "YYYY-MM-DD"), tempDateStr = DateTimeService.FormatDate(tempDate, "YYYY-MM-DD")
            if (todayStr === tempDateStr)
                return "today"
            else if (DateTimeService.FormatDate(today.setDate(today.getDate() + 1), "YYYY-MM-DD") === tempDateStr)
                return "tomorrow"
            else if (DateTimeService.FormatDate(today.setDate(today.getDate() - 1), "YYYY-MM-DD") === tempDateStr)
                return "yesterday"
            else
                return "unknown"
        } else {
            return "invalid"
        }
    }
}