import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IAppConstants } from "../models/interfaces/i-app-constants";
import { ClientBaseApiService } from "../api/client-base-api.service";
import { HttpRequestTypes } from "../models/enums/http-request-types";

@Injectable({
    providedIn: "root"
})
export class AppConstantsService {
    orbitConstants: IAppConstants

    /**
     *
     */
    constructor(private clientApiService: ClientBaseApiService) {
    }

    public GetAppConstants<T = any>(refresh = true, prop?: string): Observable<T> | Observable<any> {
        return new Observable(observe => {
            let requestDataAgain = false;
            if (!this.orbitConstants)
                requestDataAgain = true;
            requestDataAgain = refresh ? true : requestDataAgain;
            if (requestDataAgain) {
                let constantJsonUrl = environment.constantJsonUrl + "?v=" + (Math.random() * 1000)
                this.clientApiService.externalApiCall<{ orbit: IAppConstants }>({ payload: null, requestType: HttpRequestTypes.GET, url: constantJsonUrl }).subscribe(response => {
                    if (response) {
                        this.orbitConstants = response.orbit
                        try {
                            this.orbitConstants.testUserList = this.orbitConstants.testUserIds && this.orbitConstants.testUserIds !== '' ?
                                this.orbitConstants.testUserIds.split(",").map(t => +t) : []
                        } catch (error) {
                            this.orbitConstants.testUserList = []
                        }
                        if (prop && prop !== '')
                            observe.next(response.orbit[prop])
                        else
                            observe.next(response.orbit);
                    } else
                        observe.next(null)
                    observe.complete()
                }, err => {
                    observe.complete()
                })
            } else {
                if (prop && prop !== '')
                    observe.next(this.orbitConstants[prop])
                else
                    observe.next(this.orbitConstants);
                observe.complete()
            }
        })
    }

}