import { Injectable } from "@angular/core";
import { UserApiService } from "../api/user-api.service";
import { UserData } from "../models/classes/user-data";
import { StorageKeys } from "../models/enums/storage-keys";
import { ILoginModel } from "../models/interfaces/login-model";
import { IAuthResponse } from "../models/interfaces/auth-response";
import { IRegisterModelV2 } from "../models/interfaces/register-model";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { NavigationService } from "./navigation.service";
import { ISmsRequestModel } from "../models/interfaces/sms-models";
import { Message_Type } from "../models/enums/message-type";
import { PurchasePlansService } from "./purchase-plans.service";
import { ICurrentUserDetails, IUserDetailRequestBody } from "../models/interfaces/user-detail-models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ModalService } from "./modal.service";
import { GuestRegisterModalComponent } from "src/app/pages/auth/components/guest-register-modal/guest-register-modal.component";
import { ClientAlertService } from "./client-alert.service";
import { IButtonOpts, IListModalModel } from "../models/interfaces/modal-components-models";
import { TranslateService } from "@ngx-translate/core";
import { IGuestRegisterModel } from "../models/interfaces/guest-models";
import { Subject } from "rxjs";
import { INewImageModel } from "../models/interfaces/new-image-model";
import { AnalyticService } from "./analytic.service";
import { WeatherForecastService } from "./weather-forecast.service";
import { FarmService } from "./farm.service";
import { UtilsService } from "./utils.service";
import { ToastService } from "./toast.service";
import { Location } from "@angular/common";
import { NotificationsService } from "./notification.service";
import { GuestAlertTemplates } from "../helper/class/guest-alert-templates";
import { CleverTapService } from "./clevertap.service";
import { OnboardingStorageService } from "./onboarding-storage.service";
import { GoogleFirebaseAnalyticsService } from "./google-firebase-analytics.service";
import { LocationService } from "./location.service";
import { AnalyticEvents, AppsflyerAnalyticEvents } from "../models/enums/analytic-events";
import { DoktarAppsFlyer } from "./appsflyer.service";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    static token: string = null
    static bearer: string = null
    static IsGuest: boolean = false
    static NameSurname: string = null
    hasLogin: boolean = false
    lang: string = "en"
    userData: UserData
    private _currentUser: ICurrentUserDetails;
    public get currentUser(): ICurrentUserDetails {
        return this._currentUser;
    }
    public set currentUser(value: ICurrentUserDetails) {
        this._currentUser = value;
    }

    private _userDataSubject: Subject<UserData> = new Subject()

    static selectedSpeedUnit
    static selectedAreaUnit
    static selectedTemperatureUnit
    static selectedRainfallUnit
    static selectedWeightUnit
    /**
    * {
    *  "FieldComparisonPermission": false,
    *  "AskEngineerPermission": false,
    *  "WeatherPermission": true,
    *  "RainAndStormPermission": true,
    *  "IsFreeOfCharge": false
    * }
    */
    static UsagePermissions: { [key: string]: boolean } = {}
    /**
     *
     */
    constructor(
        private storageService: StorageService,
        private weatherService: WeatherForecastService,
        private analyticService: AnalyticService,
        private alertService: ClientAlertService,
        private navigationService: NavigationService,
        private purchaseService: PurchasePlansService,
        private langService: TranslateService,
        private router: Router,
        private userApiCall: UserApiService,
        private modalService: ModalService,
        private translate: TranslateService,
        private farmService: FarmService,
        private locationService: Location,
        private toastService: ToastService,
        private notificationService: NotificationsService,
        private clevertapService: CleverTapService,
        private onboardingStorage: OnboardingStorageService,
        private googleFirebaseService: GoogleFirebaseAnalyticsService
    ) {
        AccountService.instance = this;
        storageService.get<string>(StorageKeys.TOKEN).then(response => {
            if (response.IsSuccess)
                AccountService.token = response.Data
        })
        storageService.get<string>(StorageKeys.BEARER).then(response => {
            if (response.IsSuccess)
                AccountService.bearer = response.Data
        })
        storageService.get<UserData>(StorageKeys.USER).then(response => {
            if (response.IsSuccess)
                this.userData = new UserData(response.Data)
        })
    }

    private static _instance: AccountService;
    public static get instance(): AccountService {
        return AccountService._instance;
    }
    private static set instance(value: AccountService) {
        AccountService._instance = value;
    }


    public UserDataListener() {
        return this._userDataSubject.asObservable()
    }

    public async Login(payload: ILoginModel): Promise<UserData> {
        return new Promise((resolve, reject) => {
            // this.userApiCall.login(payload).subscribe(response => {
            this.userApiCall.loginV2(payload.email, payload.password).subscribe({
                next: response => {
                    console.log("loginV2 => ", payload.email)
                    // this.userApiCall.loginV2(payload.email, payload.password).subscribe(response => {
                    if (response.IsSuccess && response.Data) {
                        this._setUserData(response.Data);
                        this.googleFirebaseService.sendEvetLog("user_login", { userId: response.Data.UserRoleId.toString(), loginStatus: true })
                        this.StartPermissionRequests();
                        let uxcamUserId = response.Data.NameSurname + "_" + response.Data.UserRoleId
                        DoktarAppsFlyer.AfEventLog(AppsflyerAnalyticEvents.login, { userId: response.Data.UserRoleId.toString(), loginStatus: true })
                        // this.openWelcomeAlert("login")
                        // let phone = this.userData.UserTelNo.toString();
                        // this.storageService.get(StorageKeys.PHONE_CODE).then(storeData => {
                        //     if (storeData.IsSuccess && storeData.Data) {
                        //         // phone.replace(storeData.Data, "");
                        //         let splittedPhone = phone.split(storeData.Data)
                        //         if (splittedPhone[0] == '')
                        //             splittedPhone.shift()
                        //         phone = splittedPhone.join(storeData.Data)
                        //     }
                        // }).catch(err => {
                        //     this.clevertapService.setUserProfile({
                        //         Email: this.userData.UserMail,
                        //         Identity: this.userData.UserRoleId.toString(),
                        //         Name: this.userData.NameSurname,
                        //         Phone: phone,
                        //         stuff: {}
                        //     })
                        //     this._sendLoginLog(response.IsSuccess, phone)
                        // })
                        resolve(this.userData);
                    } else {
                        reject(response.Message)
                    }
                    this._sendLoginLog(response.IsSuccess, payload.email)
                }, error: err => {
                    this._sendLoginLog(false, payload.email)
                    reject(err)
                }
            })
        })
    }

    private async _sendLoginLog(status, phone) {
        let ipResponse = { ip: "n/a", location: "unknown", coords: `unknown` }
        try {
            ipResponse = await UtilsService.GetUserIpInfo()
        } catch (error) {

        }
        this.analyticService.logEvent(AnalyticEvents.LOGIN, {
            Status: status ? "Success" : "Fail",
            "OTP_sent": false,
            Username: this.userData.UserMail,
            Phone: "+" + phone,
            "User_ID": this.userData.UserRoleId,
            Timestamp: Date.now(),
            "IP_Address": ipResponse.ip,
            Device: UtilsService.DeviceDetailsInfo,
            Location: ipResponse.location,
            Secure: false
        })
    }

    public async Register(payload: IRegisterModelV2) {
        return new Promise((resolve, reject) => {
            this.userApiCall.register(payload).subscribe(response => {
                if (response.IsSuccess) {
                    response.Data.UserMail = payload.Mail
                    this._setUserData(response.Data);
                    this.googleFirebaseService.sendEvetLog("user_register", { userId: response.Data.UserRoleId.toString(), loginStatus: true })
                    this.StartPermissionRequests();

                    let uxcamUserId = response.Data.NameSurname + "_" + response.Data.UserRoleId
                    DoktarAppsFlyer.AfEventLog(AppsflyerAnalyticEvents.complete_registration, { userId: response.Data.UserRoleId.toString(), loginStatus: true })
                    this.onboardingStorage.clearOnboadings()
                    resolve(this.userData);
                } else {
                    reject(response.Message)
                }
                this._sendRegisterLog(response.IsSuccess, payload.PhoneNumber)
            }, err => {
                this._sendRegisterLog(false, payload.PhoneNumber)
                reject(err)
            })
        })
    }

    private async _sendRegisterLog(status, phone) {
        let ipResponse = { ip: "n/a", location: "unknown", coords: `unknown` }
        try {
            ipResponse = await UtilsService.GetUserIpInfo()
        } catch (error) {

        }
        this.analyticService.logEvent(AnalyticEvents.REGISTER, {
            Status: status ? "Success" : "Fail",
            Username: this.userData.UserMail,
            Phone: phone,
            Timestamp: Date.now(),
            "IP_Address": ipResponse.ip,
            Device: UtilsService.DeviceDetailsInfo,
            Location: ipResponse.location,
            Secure: false
        })
    }

    public async RegisterInvitationCode(invitationCode: string) {
        this.userApiCall.RegisterInvitationCode(invitationCode).subscribe(res => {

        })
    }

    public async Logout() {
        var _clearStorage = () => {
            if (this.userData && this.userData.UserRoleId)
                this.googleFirebaseService.sendEvetLog("user_logout", { userId: this.userData.UserRoleId.toString(), loginStatus: false })
            var promiseList = [
                this.storageService.remove(StorageKeys.USER),
                this.storageService.remove(StorageKeys.TOKEN),
                this.storageService.remove(StorageKeys.PERMISSIONS)
            ]
            var optionals = [
                this.storageService.remove(StorageKeys.IMPORT_INTRO),
                this.storageService.remove(StorageKeys.IRRIGATION_INTRO),
                this.storageService.remove(StorageKeys.NOTIFICATION_STATUS),
                this.storageService.remove(StorageKeys.PHONE_CODE),
                this.storageService.remove(StorageKeys.IS_REGISTERED)
            ]

            this.weatherService.weatherList = []
            Promise.all(optionals).then(t => { }).catch(err => { })
            Promise.all(promiseList).then(response => {
                this.navigationService.clearHistory()
                this.StopPermissionRequests()
                AccountService.token = null
                AccountService.bearer = null
                StorageService.DeviceToken = null
                StorageService.UserInfo = null;
                AccountService.UsagePermissions = {}
                AccountService.token = null
                this.router.navigate(["/auth"], { replaceUrl: true })
                try {
                    this.locationService.replaceState(null)
                } catch (err) {
                    console.error(err)
                }
            })
        }
        this.storageService.get(StorageKeys.DEVICE_TOKEN).then(token => {
            if (token.IsSuccess && token.Data && token.Data !== '') {
                this.userApiCall.DeletePushDeviceToken(token.Data).subscribe({
                    next: res => {
                        _clearStorage();
                    }, error: err => {
                        _clearStorage();
                    }, complete: () => {
                    }
                })
            } else {
                _clearStorage();
            }
        }).catch(err => {
            _clearStorage()
        })

    }

    public async setUserProfileData(refresh = true) {
        let props = {
            "MSG_email": true,
            "MSG_push": true,
            "MSG_sms": true,
            "MSG_webpush": false,
            "MSG_whatsapp": true,
            Age: "N/A",
            Birthday: "N/A",
            "Customer_Type": FarmService.ActiveHighestPackageType || "N/A",
            Email: AccountService.instance.userData.UserMail,
            Gender: "N/A",
            Language: AccountService.instance.translate.currentLang,
            Location: "N/A",
            Name: AccountService.instance.userData.NameSurname,
            Phone: "+" + AccountService.instance.userData.UserTelNo,
            Timezone: new Date().getTimezoneOffset() / -60,
            UserID: AccountService.instance.userData.UserRoleId
        }

        // let phone: string = AccountService.instance.userData.UserTelNo
        // let phoneCode: string = ""
        // try {
        //     phoneCode = (await this.storageService.get(StorageKeys.PHONE_CODE)).Data.dialCode
        //     let splittedPhone = phone.split(phoneCode)
        //     if (splittedPhone[0] == '')
        //         splittedPhone.shift()
        //     phone = splittedPhone.join("")
        // } catch (error) {

        // }


        if (!(this.currentUser && this.currentUser.UserRoleProperties))
            refresh = true

        await LocationService.GetMyCurrentLocation()
        let locationObj = LocationService.UserLatestLocationDetails
        if (!locationObj || Object.keys(locationObj).length == 0)
            locationObj = await LocationService.GetLocationDetailsFromCoords()

        props.Location = locationObj.place_name
        if (refresh) {
            this.GetCurrentUser().subscribe({
                next: res => {
                    if (res.IsSuccess && res.Data) {
                        let age: any = 'N/A'
                        let birthData = res.Data.UserRoleProperties.find(t => t.Key === "birthYear")
                        if (birthData) {
                            age = (new Date().getFullYear()) - +birthData.Value
                        }
                        props.Age = age
                        props.Birthday = birthData ? new Date(birthData.Value).toString() : ""
                    }
                    this.clevertapService.setUserProfile(props)
                    this.googleFirebaseService.setUserParameters(props)
                }, error: err => {
                    this.clevertapService.setUserProfile(props)
                    this.googleFirebaseService.setUserParameters(props)
                }
            })
        } else {
            let age: any = 'N/A'
            let birthData = this.currentUser.UserRoleProperties.find(t => t.Key === "birthYear")
            if (birthData) {
                age = (new Date().getFullYear()) - +birthData.Value
            }
            props.Age = age
            props.Birthday = new Date(birthData.Value).toString()
            this.clevertapService.setUserProfile(props)
            this.googleFirebaseService.setUserParameters(props)
        }
    }

    private _setUserData(props: IAuthResponse) {
        this.userData = new UserData(props)
        this.storageService.set(StorageKeys.TOKEN, this.userData.Token).then(response => {
            // if (!props.IsGuest)
            // this.purchaseService.registerProducts();
        })
        this.storageService.set(StorageKeys.BEARER, this.userData.AuthToken).then(response => {
            // if (!props.IsGuest)
            // this.purchaseService.registerProducts();
        })
        this.setUserProfileData()
        StorageService.UserInfo = this.userData
        AccountService.bearer = this.userData.AuthToken
        AccountService.token = this.userData.Token
        AccountService.IsGuest = this.userData.IsGuest
        AccountService.NameSurname = this.userData.NameSurname
        try {
            this.googleFirebaseService.setUserId(StorageService.UserInfo.UserRoleId.toString()).then().catch()
        } catch (error) {

        }
        this.storageService.get(StorageKeys.DEVICE_TOKEN).then(token => {
            if (token.IsSuccess && token.Data) {
                StorageService.DeviceToken = token.Data
            }
        })
        this.purchaseService.registerProducts();
        this.weatherService.GetWeatherByPointFarm(true).subscribe()
        this.storageService.set(StorageKeys.USER, this.userData).then(response => { })
        this._userDataSubject.next(this.userData)
        this.notificationService.GetNotificationPermissions().subscribe()
        this.farmService.CheckIfAnyFarmDeletedByOwner()
    }

    public async RequestSmsCode(payload: ISmsRequestModel): Promise<{ type: Message_Type, data: string[] | boolean }> {
        return new Promise((resolve, reject) => {
            this.userApiCall.UserForgotPasswordProcess(payload).subscribe({
                next: response => {
                    if (response.IsSuccess)
                        resolve({ type: Message_Type.SUCCESS, data: response.Data })
                    else if (response.ValidationErrors && response.ValidationErrors.length > 0) {
                        reject({ type: Message_Type.WARNING, data: response.ValidationErrors })
                    } else
                        reject({ type: Message_Type.DANGER, data: [response.Message] })
                },
                error: err => {
                    reject({ type: Message_Type.DANGER, data: [err] })
                }
            })
        })
    }

    public async ConfirmSmsCode(payload: ISmsRequestModel): Promise<{ otpKey: string, phoneNumber: string }> {
        return new Promise((resolve, reject) => {
            if (!payload.Otp)
                return reject({ type: Message_Type.WARNING, data: "otp_code_required" })
            this.userApiCall.UserForgotPasswordProcess(payload).subscribe(response => {
                if (response.IsSuccess)
                    return resolve({ otpKey: payload.Otp, phoneNumber: payload.PhoneNumber })
                else
                    return reject({ type: Message_Type.DANGER, data: response.Message })
            }, err => {
                return reject({ type: Message_Type.DANGER, data: "error_on_sms_validation" })
            })
        })
    }

    public async SetNewPassword(payload: ISmsRequestModel): Promise<{ type: Message_Type, data: string[] | boolean }> {
        return new Promise((resolve, reject) => {
            if (!payload.Otp)
                return reject({ type: Message_Type.WARNING, data: "otp_code_required" })
            if (!payload.NewPassword)
                return reject({ type: Message_Type.WARNING, data: "password_required" })
            this.userApiCall.UserForgotPasswordProcess(payload).subscribe(response => {
                if (response.IsSuccess) {
                    return resolve({ type: Message_Type.SUCCESS, data: response.Data })
                } else if (response.ValidationErrors && response.ValidationErrors.length > 0) {
                    return reject({ type: Message_Type.WARNING, data: response.ValidationErrors })
                } else
                    return reject({ type: Message_Type.DANGER, data: [response.Message] })
            }, err => {
                return reject({ type: Message_Type.DANGER, data: [err] })
            })
        })
    }


    /**
     * 
     * @returns Promise
     * resolve => IAuthResponse
     * reject => string
     */
    public MakeGuestLogin() {
        return new Promise((resolve, reject) => {
            this.userApiCall.MakeGuestLogin().subscribe(res => {
                if (res.IsSuccess) {
                    res.Data.IsGuest = true;
                    // this.storageService.set(StorageKeys.USER, res.Data).then(str => {
                    AccountService.token = res.Data.Token
                    AccountService.UsagePermissions = {}
                    AccountService.IsGuest = true
                    this._setUserData(res.Data)
                    resolve(res.Data)

                } else {
                    reject("general.error_on_guest_login")
                }
            }, err => {
                reject("general.error_on_guest_login")
            })
        })
    }

    public RegisterGuestUser(payload: IGuestRegisterModel) {
        return new Promise((resolve, reject) => {
            this.userApiCall.RegisterGuestUser(payload).subscribe(response => {
                if (response.IsSuccess) {
                    this._setUserData(response.Data)
                    resolve(this.userData)
                } else {
                    reject(response.Message)
                }
            })
        })
    }


    public GetCurrentUser() {
        return this.userApiCall.GetCurrentUser().pipe(map(t => {
            if (t.IsSuccess && t.Data) {
                // t.Data.UserRoleProperties = []
                this.currentUser = t.Data
            }
            return t
        }))
    }

    public UpdateCurrentUser(payload: IUserDetailRequestBody) {
        return new Observable(observer => {
            this.userApiCall.UpdateUserDetail(payload).subscribe(resp => {
                if (resp.IsSuccess) {
                    this.GetCurrentUser().subscribe({
                        next: resp2 => {
                            observer.next(true)
                            observer.complete()
                        }, error: err => {
                            observer.next(false)
                            observer.complete()
                        }
                    })
                } else {
                    observer.error(resp.Message)
                    observer.complete()
                }
            }, err => {
                observer.error(err)
                observer.complete()
            })
        })
    }

    public SetProfilePhoto(image: INewImageModel) {
        return new Promise((resolve, reject) => {
            if (this.userData) {
                let formPayload = new FormData();
                formPayload.append('File', image.image, `profil-image-${Date.now()}.${image.format}`)
                formPayload.append('FullName', this.userData.NameSurname);
                formPayload.append('Email', this.userData.UserMail);
                formPayload.append('UserTitle', "");
                this.userApiCall.UpdateUserInfoWithPhoto(formPayload, this.userData.UserRoleId).subscribe(response => {
                    if (response.IsSuccess)
                        this.userData.ProfilePictureUrl = response.Data.ProfilePictureUrl
                    this.storageService.set(StorageKeys.USER, this.userData).then()
                    resolve(true)
                })
            } else {
                reject("no_user_data")
            }
        })
    }

    public DeleteAccount() {
        return new Promise((resolve, reject) => {
            this.userApiCall.DeleteAccount().subscribe(resp => {
                if (resp.IsSuccess) {
                    let promiseList = [
                        this.storageService.remove(StorageKeys.TOKEN),
                        this.storageService.remove(StorageKeys.USER),
                        this.storageService.remove(StorageKeys.IMPORT_INTRO),
                        this.storageService.remove(StorageKeys.IRRIGATION_INTRO),
                        this.storageService.remove(StorageKeys.IRRIGATION_RAIN_STATUS),
                        this.storageService.remove(StorageKeys.NOTIFICATION_STATUS),
                        this.storageService.remove(StorageKeys.PERMISSIONS)
                    ]
                    Promise.all(promiseList).then(all => {
                        resolve(true)
                    }).catch(err => {
                        reject(false)
                    })
                }
                else
                    reject(false)
            }, err => {
                reject(false)
            })
        })
    }

    public ShowGuestAlert(props = GuestAlertTemplates.BasicGuestAlert) {
        let buttons: IButtonOpts[] = [
            {
                callback: () => {
                    this.PresentGuestRegisterModal("register")
                },
                id: "guest-alert-register-btn",
                color: props.buttons.registerBtn.color as any,
                fill: props.buttons.registerBtn.fill as any,
                text: this.langService.instant(props.buttons.registerBtn.text) //this.translate.instant("guest.sign_up_btn")
            },
            {
                callback: () => {
                    this.PresentGuestRegisterModal("login")
                },
                id: "guest-alert-login-btn",
                color: props.buttons.loginBtn.color as any,
                fill: props.buttons.loginBtn.fill as any,
                text: this.langService.instant(props.buttons.loginBtn.text) //this.translate.instant("guest.already_have_Account_btn")
            }
        ]
        this.alertService.PresentAlert({
            buttons,
            image: props.image, //"assets/images/guest-register-alert.svg",
            description: this.langService.instant(props.description), //this.translate.instant("guest.alert_desc"),
            title: this.langService.instant(props.title) //this.translate.instant("guest.alert_title")
        }, false).then(response => {

        })
    }

    public PresentGuestRegisterModal(currentPage: "login" | "register" = "register") {
        this.modalService.presentModal({
            component: GuestRegisterModalComponent,
            componentProps: { currentPage: currentPage },
            options: ModalService.defaultOptions
        }).then(resp => {
            resp.modalObj.onDidDismiss().then(dismiss => {
                let roleOptions = ["login", "register"]
                if (roleOptions.some(t => t === dismiss.role)) {

                }
            })
        })
    }

    _permissionSubject: Subject<{ [key: string]: boolean }> = new Subject()
    PermissionsListener() {
        return this._permissionSubject.asObservable()
    }

    public GetCurrentUserPermission(isManualCheck: boolean = true) {
        this.userApiCall.UserPermissions().subscribe({
            next: response => {
                if (isManualCheck || this._permissionIntervalObj) {
                    this._permissionSubject.next(response.IsSuccess ? response.Data : {})
                    AccountService.UsagePermissions = response.IsSuccess ? response.Data : {}
                } else {
                    this._permissionSubject.next({})
                    AccountService.UsagePermissions = {}
                }
            }, error: error => {
                this._permissionSubject.next({})
                AccountService.UsagePermissions = {}
            }
        })
    }

    private _permissionIntervalObj
    public StartPermissionRequests() {
        this.GetCurrentUserPermission(true);
        if (!this._permissionIntervalObj)
            this._permissionIntervalObj = setInterval(() => {
                this.GetCurrentUserPermission(false)
            }, 1000 * 60);
    }

    public StopPermissionRequests() {
        if (this._permissionIntervalObj) {
            clearInterval(this._permissionIntervalObj)
            this._permissionIntervalObj = undefined
        }
    }

    public changeUserPassword(props: { OldPassword, NewPassword }) {
        return this.userApiCall.ChangePassword(props)
    }

    public getUserReferenceCode() {
        return this.userApiCall.GetReferanceCode()
    }

    public getUserReferenceDetails() {
        return this.userApiCall.GetRefereceUserData()
    }

    public finishAccountGame() {
        return this.userApiCall.QuestionsFinished()
    }

    public async loginWithSocial(payload): Promise<UserData> {
        return new Promise((resolve, reject) => {
            // this.userApiCall.login(payload).subscribe(response => {
            this.userApiCall.SocialLogin(payload).subscribe(response => {
                // this.userApiCall.loginV2(payload.email, payload.password).subscribe(response => {
                if (response.IsSuccess) {
                    this._setUserData(response.Data);
                    this.StartPermissionRequests();
                    this.openWelcomeAlert("login")
                    resolve(this.userData);
                } else {
                    reject(response.Message)
                }
            }, err => {
                reject(false)
            })
        })
    }

    public openContactusOptions(options: string[], message?: string) {
        let itemOptions: Array<IListModalModel<any>> = [
            {
                id: 1,
                value: this.translate.instant("account.whatsapp_lbl"),
                key: "whatsapp",
                isChecked: true
            },
            {
                id: 2,
                value: this.translate.instant("account.email_lbl"),
                key: "email",
                isChecked: false
            },
            {
                id: 3,
                value: this.translate.instant("account.website_lbl"),
                key: "website",
                isChecked: false
            }
        ]
        let that = this

        this.modalService.presentListModal({
            isFullscreen: false,
            hasConfirmBtn: true,
            isMultiple: false,
            confirmBtnText: this.translate.instant("general.ok"),
            showSearchbar: false,
            itemList: itemOptions,
            title: this.translate.instant("general.contact_us")
        }).then(response => {
            response.modalObj.onDidDismiss().then(dismiss => {
                if (dismiss.role === 'itemSelected') {
                    if (dismiss.data && dismiss.data.selectedItem) {
                        that.contactUs(dismiss.data.selectedItem.key, message)
                    }
                }
            })
        })
    }

    public contactUs(key: string, message?: string) {

        const actions: { [key: string]: any } = {
            whatsapp: (ev) => {
                let href = this.translate.instant("account.contact_us_whatsapp");
                if (ev && ev.message) {
                    let encodedText = encodeURI(ev.message);
                    href = href + "?text=" + encodedText;
                }
                window.location.href = href
            },
            email: (ev) => {
                window.location.href = this.translate.instant("account.contact_us_email");
            },
            website: (ev) => {
                window.location.href = this.translate.instant("account.contact_us_visit");
            },
        }

        let tempAction = actions[key];
        if (tempAction) {
            tempAction({ message })
        }
    }

    public openWelcomeAlert(state: 'login' | 'register') {
        let count = 0;
        let isOpened: boolean = false;
        if (state === 'register') {
            let buttons: IButtonOpts[] = [
                {
                    callback: () => {
                        this.router.navigate(["add-field"]);
                    },
                    id: "welcome-alert-add-field-btn",
                    color: "primary",
                    fill: "solid",
                    text: this.translate.instant("welcome.add_field_btn")
                },
                {
                    callback: () => {
                    },
                    id: "welcome-alert-later-btn",
                    color: "dark",
                    fill: "clear",
                    text: this.translate.instant("welcome.later_btn")
                }
            ]

            this.alertService.PresentAlert({
                buttons,
                description: this.translate.instant("welcome.alert_desc"),
                image: "assets/images/rock_sign.svg",
                mainTitle: this.translate.instant("welcome.alert_main_title"),
                title: this.translate.instant("welcome.alert_title")
            }, false).then(response => {
                response.modalObj.onDidDismiss().then(dismiss => {
                    count++;
                    this.storageService.set(StorageKeys.LOGIN_COUNT, count).then()

                })
            })
        } else {
            let listener = this.farmService.FarmListListener().subscribe(farmResp => {

                if (!farmResp || farmResp.length <= 0) {
                    this.storageService.get<number>(StorageKeys.LOGIN_COUNT).then(ev => {
                        if (!ev.IsSuccess || !ev.Data || ev.Data <= 0) {
                            if (!isOpened) {
                                isOpened = true
                                let buttons: IButtonOpts[] = [
                                    {
                                        callback: () => {
                                            this.router.navigate(["add-field"]);
                                        },
                                        id: "welcome-alert-add-field-btn",
                                        color: "primary",
                                        fill: "solid",
                                        text: this.translate.instant("welcome.add_field_btn")
                                    },
                                    {
                                        callback: () => {
                                        },
                                        id: "welcome-alert-later-btn",
                                        color: "dark",
                                        fill: "clear",
                                        text: this.translate.instant("welcome.later_btn")
                                    }
                                ]


                                this.alertService.PresentAlert({
                                    buttons,
                                    description: this.translate.instant("welcome.alert_desc"),
                                    image: "assets/images/rock_sign.svg",
                                    mainTitle: this.translate.instant("welcome.alert_main_title"),
                                    title: this.translate.instant("welcome.alert_title")
                                }, false).then(response => {
                                    response.modalObj.onDidDismiss().then(dismiss => {
                                        count++;
                                        this.storageService.set(StorageKeys.LOGIN_COUNT, count).then()

                                    })
                                })
                            }
                        }
                    })
                } else {
                    this.toastService.PresentBasicToast({
                        message: this.langService.instant("auth.login_succeed")
                    }).then(response => {
                    })
                }
                listener.unsubscribe();
                listener = undefined;
            })
        }
    }
}