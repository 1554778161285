import { ASatelliteImages, ISatelliteImages } from "../../models/interfaces/satellite-images.interface";
import { Observable } from "rxjs";
import { FarmApiService } from "../../api/farm-api.service";
import { SatelliteImageTypes } from "../../models/enums/satellite-image-types";
import { UtilsService } from "../utils.service";
import { ISatelliteAnalysisResponse } from "../../models/interfaces/satellite-analysis-response";


export class SatelliteImages extends ASatelliteImages {

    farmApiService: FarmApiService
    isLoading: boolean
    hasErrorOnApiRequest: boolean = false

    constructor(farmApiService) {
        super()
        this.farmApiService = farmApiService
    }

    build(props: ISatelliteImages): void {
        super.build(props)
        // if (this.Id < 0)
            // this.GetImagesDetail().subscribe()
    }

    GetImagesDetail(): Observable<ISatelliteImages> {
        this.isLoading = true;
        return new Observable(observer => {
            if (this.Id > 0) {
                this.isLoading = false
                observer.next(this)
                observer.complete()
            } else {
                let payload: ISatelliteImages = {
                    Id: this.Id,
                    UniqueId: this.UniqueId,
                    SensingDate: this.SensingDate,
                    Type: this.Type,
                    TempFarmAttId: this.TempFarmAttId,
                    CloudRatio: this.CloudRatio,
                    NdviMap: this.NdviMap,
                    InspectionMap: this.InspectionMap,
                    SoilMoistureMap: this.SoilMoistureMap,
                    TrueColorMap: this.TrueColorMap
                } as ISatelliteImages
                this.farmApiService.GetSatelliteImagesAync(payload).subscribe({
                    next: response => {
                        if (response.IsSuccess && response.Data) {
                            this.build(response.Data)
                            this.hasErrorOnApiRequest = false
                            observer.next(this)
                        } else {
                            this.hasErrorOnApiRequest = true
                            observer.next(this)
                        }
                        observer.complete()
                    }, error: err => {
                        // this.isLoading = false
                        this.hasErrorOnApiRequest = true
                        if (err.Message === 'processing-image-error')
                            observer.error('processing-image-error')
                        else
                            observer.next(this)
                        observer.complete()
                    }, complete: () => {
                        this.isLoading = false
                    }
                })
            }
        })
    }

    GetAnalysisData(): Promise<ISatelliteAnalysisResponse[]> {
        return new Promise<ISatelliteAnalysisResponse[]>((resolve, reject) => {
            this.farmApiService.GetSatelliteAnalysis(this.Id, this.Type).subscribe({
                next: res => {
                    if (res.IsSuccess) {
                        res.Data.forEach(el => {
                            el.Values.forEach(el2 => {
                                el2.TextColor = UtilsService.BlackAndWhiteColor(el2.Color)
                            })
                        })
                        resolve(res.Data)
                    } else {
                        resolve(null)
                    }
                }, error: err => {
                    reject(err)
                }
            })
        })
    }

    hasCurrentImageType(type: SatelliteImageTypes) {
        if (this.Id > 0) {
            if (type)
                return this[type.toString()]
            else
                return true
        } else
            return true
    }
}