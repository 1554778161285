import { Injectable } from "@angular/core";
import { ModalController, ModalOptions } from "@ionic/angular";
import { AlertModalComponent } from "../helper/components/alert-modal/alert-modal.component";
import { FloatAlertModalComponent } from "../helper/components/float-alert-modal/float-alert-modal.component";
import { IBaseAlertModel, IButtonOpts } from "../models/interfaces/modal-components-models";
import { ModalService } from "./modal.service";
import { ComponentRef } from "@ionic/core";
import { ContactUsModalComponent } from "../helper/components/contact-us-modal/contact-us-modal.component";

@Injectable({
    providedIn: "root"
})
export class ClientAlertService {
    /**
     *
     */
    constructor(private modalController: ModalController, private modalService: ModalService) {

    }

    public async PresentAlert(props: IBaseAlertModel, isFloat: boolean = true, hasBackdrop:boolean = true) {
        // const modal = await this.modalController.create({
        //     component: isFloat ? FloatAlertModalComponent : AlertModalComponent,
        //     componentProps: props,
        //     backdropDismiss: true,
        //     cssClass: "modal-transparent"
        // });
        // await modal.present();
        if(!Object.keys(props).some(t => t == "hasBackdropdismiss"))
        props.hasBackdropdismiss = hasBackdrop;
        const modal = await this.modalService.presentModal({
            component: isFloat ? FloatAlertModalComponent : AlertModalComponent,
            componentProps: props,
            options: { backdropDismiss: props.hasBackdropdismiss, cssClass: "modal-transparent modal-alert" } as ModalOptions<ComponentRef>
        })
        return modal;
    }

    public async confirmAlert(props: IBaseAlertModel, isFloat: boolean = false) {
        // const modal = await this.modalController.create({
        //     component: isFloat ? FloatAlertModalComponent : AlertModalComponent,
        //     componentProps: props,
        //     backdropDismiss: true,
        //     cssClass: "modal-transparent"
        // });
        // await modal.present();
        const modal = await this.modalService.presentModal({
            component: isFloat ? FloatAlertModalComponent : AlertModalComponent,
            componentProps: props,
            options: { backdropDismiss: true, cssClass: "modal-transparent modal-alert" } as ModalOptions<ComponentRef>
        })
        return modal;
    }

    public async PresentContactUsAlert(props: any, hasBackdrop: boolean = true): Promise<HTMLIonModalElement> {
        return new Promise((resolve, reject) => {
            this.modalService.presentModal({
                component: ContactUsModalComponent,
                componentProps: props,
                options: { backdropDismiss: hasBackdrop, cssClass: "modal-transparent modal-alert" } as ModalOptions<ComponentRef>
            }).then(res => {
                resolve(res.modalObj)
            }).catch(err => {
                reject(err)
            })
        })
    }

}