import { IonicModule } from '@ionic/angular';
import { OnboardingTutorialComponent } from './onboarding-tutorial.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { HtmlTranslatePipe } from 'src/app/core/pipes/custom-translate.pipe';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { SelectLanguageComponent } from '../select-language/select-language.component';

@NgModule({
    imports: [
        IonicModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        SwiperModule,
        HtmlTranslatePipe,
        SharedModule,
        SelectLanguageComponent
    ],
    exports: [],
    declarations: [OnboardingTutorialComponent],
    providers: [],
})
export class OnboardingTutorialModule { }
