
<ion-header style="background: rgba(0,0,0,0)">
  <ion-toolbar class="modal-card-area">
    <ion-buttons slot="start" class="m-left-16">
      <ion-button color="dark" (click)="openModal()" id="auth-open-lang-options-btn" class="font-size-14 font-weight-600">
        {{"lang.default" | translate}} <ion-icon class="m-left-5" style="margin-bottom:-3px" name="chevron-down-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="font-size-20 font-weight-900">{{"app_title" | translate}}</ion-title>
    <ion-buttons slot="end" class="m-right-8">
      <ion-button color="medium" (click)="dismiss(null)" id="auth-modal-close-btn">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<doktar-intl-country-list [isMobile]="true" [title]="'countries' | translate"
  [placeholder]="'general.search_countries' | translate" id="auth_modal"></doktar-intl-country-list>

<ion-content [style]="{'--padding-bottom': (bottomPadding > 0 ? 'calc(' + bottomPadding + '% + var(--ion-safe-area-bottom))' : 0)}">

  <ion-grid fixed *ngIf="refreshComponents">
    <ion-row class="ion-justify-content-center"  *ngIf="!socialUserInfo">
      <ion-col size="11">
        <ion-segment class="p-all-3" [value]="segment" (ionChange)="segmentChanged($event)">
          <ion-segment-button value="login">
            <ion-label>{{"auth.login" | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="register">
            <ion-label>{{"auth.register" | translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="segment == 'login'">
      <ion-col size="12">
        <app-login idPrefix="auth_modal" (onLogin)="onLogin($event)"  (socialLogin)="socialLogin($event)" ></app-login>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="segment == 'register'">
      <ion-col size="12">
        <app-register idPrefix="auth_modal"  [socialUserInfo]="socialUserInfo" (onRegister)="onRegister($event)"></app-register>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>