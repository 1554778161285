<!-- <ion-header class="agreement-header">
  <ion-toolbar bg="none" mode="md">
    <ion-buttons slot="end">
      <ion-button (click)="cancelModal()" color="dark" id="user-agreement-cancel-btn">
        <ion-icon class="agreement-close-icon" [src]="'closeCircle' | orbitIcons"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="agreement-content">
  <ion-grid class="custom-container p-all-0">
    <ion-row class="p-all-0">
      <ion-col class="p-all-0">
        <iframe class="custom-frame" [src]="documentSecureSource"></iframe>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="showConfirmBtn" class="agreement-footer">
  <ion-button  expand="block" class="m-all-10" (click)="agree()" id="user-agreement-agree-btn">
   {{ "auth.confirm_btn" | translate }}
  </ion-button>
</ion-footer> -->

<orbit-modal-base [hasCloseBtn]="true" [isBottomSheet]="true"  [hasHandle]="true" (onDismiss)="cancelModal()">

  
  <ion-grid class="custom-container p-horizontal-0 p-bottom-0 p-top-24 ">
    <ion-row class="p-all-0">
      <ion-col class="p-all-0">
        <iframe class="custom-frame" [src]="documentSecureSource"></iframe>
      </ion-col>
    </ion-row>
  </ion-grid>
  
  <ion-footer *ngIf="showConfirmBtn" class="agreement-footer">
    <ion-button expand="block" class="m-all-10" (click)="agree()" id="user-agreement-agree-btn">
      {{ "auth.confirm_btn" | translate }}
    </ion-button>
  </ion-footer>
</orbit-modal-base>