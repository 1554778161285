export class FarmNotes {
    tempFarmAttId: number = -1
    flagColor?: string
    flagType?: number
    searchText?: string
    startDate?: string
    endDate?: string
}

export class FlagModel {
    flagId: number;
    title: string;
    description: string;
    inspectTypeId: number;
    lat: number;
    lng: number;
    tempFarmAttId: number;
    color: string;
    tags: Array<string> | string;
    flagDate: string;
    IsConsult: boolean // bunu sonradan ekledim.
    constructor() {
        this.tags = new Array<string>();
    }
}

export interface IFlagSaveModel {
    Id: number; //0,
    Description: string;
    Lat: number; //0,
    Lon: number; //0,
    TempFarmAttId: number //0,
    Title: string;
    FlagDate: string; //"2022-09-07T07:29:07.367Z",
    InspectTypeId: number; // 0,
    Tags: string; // "string",
    // IsFarmerProblem: boolean; // true
    IsFarmerProblem: boolean
    FromWhere: string
}