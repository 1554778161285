import { AppConstantsService } from './../../../../core/services/app-constants.service';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { LoadingService } from 'src/app/core/services/loading.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Capacitor } from '@capacitor/core';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'orbit-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss', './../../auth.page.scss'],
})
export class ExternalLoginComponent implements OnInit {
  @Input() showOr: boolean = true;
  // fbLogin: Facebook;
  token = null;
  user = null;
  socialMediaToken = null;
  fbUser: any;
  platform: string = Capacitor.getPlatform();
  userInfo
  showOptions: boolean = false
  socialLoginItems: { [key: string]: boolean }
  @Output() socialLoginExternal: EventEmitter<any> = new EventEmitter();
  constructor(private loading: LoadingService,
    private http: HttpClient,
    private storageService: StorageService,
    private toast: ToastService,
    private langService: TranslateService,
    private constantsService: AppConstantsService,
    private fbLogin: Facebook) { }

  ngOnInit() {
    this.constantsService.GetAppConstants().subscribe(res => {
      this.showOptions = !this.constantsService.orbitConstants.inreview
      this.socialLoginItems = this.constantsService.orbitConstants.socialLogins
      this.showOptions = false;
      if (this.platform !== "ios")
        this.socialLoginItems["apple"] = false
      Object.keys(this.socialLoginItems).forEach(el => {
        this.showOptions = this.showOptions || this.socialLoginItems[el]
      });
    })
  }


  async signInGoogle() {
    const googleUser = await GoogleAuth.signIn() as any;
    if (googleUser) {
      this.user = {
        Id: googleUser.id,
        NameSurname: googleUser.name,
        ImageUrl: googleUser.imageUrl,
        Email: googleUser.email,
        Type: "google"
      };
      this.socialLoginExternal.emit(this.user);
    }
  }



  async loadUserData() {
    const url = `https://graph.facebook.com/${this.token.userId}?fields=id,name,picture.width(720),birthday,email&access_token=${this.token.token}`;
    // this.fbLogin.getProfile({ fields: ["id", "name", "first_name", "last_name", "email"] }).then();
    this.loading.showLoading();

    this.http.get(url).subscribe(res => {

      this.user = res;
      if (this.user) {
        this.storageService.set(StorageKeys.GOOGLE_USER, this.user).then();
        this.userInfo = {
          Id: this.user.id,
          NameSurname: this.user.first_name + " " + this.user.last_name,
          ImageUrl: "",
          Email: this.user.email,
          Type: "facebook"
        };
        this.socialLoginExternal.emit(this.userInfo);
      }
      // this.userApi.FacebookUser(this.user.id).subscribe(elem => {
      //   if (elem.IsSuccess) {
      //     this.loading.hideLoading();
      //     this.storageService.set(StorageKeys.USER, elem.Data).then();
      //     this.storageService.set(StorageKeys.TOKEN, elem.Data.Token).then();
      //     this.router.navigateByUrl("dashboard");
      //   } else {
      //     this.toast.PresentBasicToast({ message: this.langService.instant('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
      //   }
      // })
    });
  }
  async getCurrentToken() {
    const result = await this.fbLogin.getAccessToken();

    if (result) {
      this.token = result;
      this.loadUserData();
    } else {
      this.toast.PresentBasicToast({ message: this.langService.instant('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
    }
  }
  async loginFacebook() {
    const FACEBOOK_PERMISSIONS = ['public_profile', "email"];
    const result = await this.fbLogin.login(FACEBOOK_PERMISSIONS);
    if (result.authResponse.accessToken && result.authResponse.userID) {
      this.socialMediaToken = result.authResponse.userID;
      this.token = result.authResponse.accessToken;
      this.fbLogin.getCurrentProfile().then(res => {
        this.fbUser = res;
        this.storageService.set(StorageKeys.FACEBOOK_USER, this.fbUser).then();
        if (this.fbUser.userID) {
          // this.userApi.FacebookUser(this.fbUser.id).subscribe(elem => {
          //   let user = elem.Data;
          //   if (elem.IsSuccess) {
          //     this.loading.hideLoading();
          //     if (user.UserRoleId > 0) {
          //       this.storageService.set(StorageKeys.USER, user).then();
          //       this.storageService.set(StorageKeys.TOKEN, user.Token).then();
          //       this.router.navigateByUrl("/common/pick-device");
          //     } else {
          //       var userInfo = {
          //         FullName: this.fbUser.name,
          //         NameSurname: this.fbUser.first_name,
          //         NameSurname1: this.fbUser.last_name,
          //         phoneNumber: user.UserTelNo,
          //         mail: this.fbUser.email,
          //         password1: '',
          //         password2: '',
          //         fbId: this.fbUser.id
          //       };
          //       this.router.navigate(["/auth/login-register"], { queryParams: userInfo });
          //       //lütfen üyeliğinizi tamamlayın gibi bi uyarı vermemiz gerekecek. Alttaki key değiştirilecek
          //       this.toast.PresentBasicToast({ message: this.langService.instant('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
          //     }
          //   } else {
          //     this.loading.hideLoading();
          //     this.toast.PresentBasicToast({ message: this.langService.instant('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
          //   }
          // })
          this.user = res;
          if (this.user) {
            this.userInfo = {
              Id: this.user.userID,
              NameSurname: this.user.firstName + " " + this.user.lastName,
              ImageUrl: "",
              Email: this.user.email,
              Type: "facebook"
            };
            this.socialLoginExternal.emit(this.userInfo);
          }
        }
      });
    } else if (result.authResponse.accessToken && !result.authResponse.userID) {
      this.getCurrentToken();
    } else {
      this.loading.hideLoading();
      this.toast.PresentBasicToast({ message: this.langService.instant('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
    }
  }

  signInApple() {
  }

  signWithApple() {
    let options: SignInWithAppleOptions = {
      clientId: 'com.doktar.tarlam365',
      redirectURI: 'https://tarlam365.firebaseapp.com/__/auth/handler',
      scopes: 'email name'
    };

    SignInWithApple.authorize(options).then((e: SignInWithAppleResponse) => {
      let action = (userData) => {
        this.user = userData // e.response;
        if (this.user) {
          this.userInfo = {
            Id: this.user.user,
            NameSurname: (this.user.givenName || '') + " " + (this.user.familyName || ''),
            ImageUrl: "",
            Email: this.user.email,
            Type: "apple"
          };
          // this.userInfo = {
          //   Id: 123456,// this.user.user,
          //   NameSurname:"mahmut usta",// (this.user.givenName || '') + " " + (this.user.familyName || ''),
          //   ImageUrl: "",
          //   Email: "mahmut@usta.app", // this.user.email,
          //   Type: "apple"
          // };
          this.storageService.set(StorageKeys.APPLE_USER, this.user).then();
          this.socialLoginExternal.emit(this.userInfo);
        }
      }
      if (e.response.familyName || e.response.givenName || e.response.email) {
        this.storageService.set(StorageKeys.APPLE_LOGIN_DATA, e.response).then();
        action(e.response)
      } else
        this.storageService.get(StorageKeys.APPLE_LOGIN_DATA).then(storageResp => {
          if (storageResp && storageResp.IsSuccess && storageResp.Data) {
            /**
             * 
             * user: string | null;
             * email: string | null;
             * givenName: string | null;
             * familyName: string | null;
             * identityToken: string;
             * authorizationCode: string;
             * 
             */
            action(storageResp.Data)
          } else {
            action(e.response)
          }
        }, err => {
          action(e.response)
        })
    });
  }



}
