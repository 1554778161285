import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { UnitPreferenceService } from '../services/unit-preference.service';

@Pipe({
  name: 'rainfall',
  pure: false
})
export class RainfallPipe implements PipeTransform, OnDestroy {

  onTypeChanged: Subscription
  previousResponse: any
  previousType: any
  previouesData: any

  /**
   *
   */
  constructor(private unitService: UnitPreferenceService, private _ref: ChangeDetectorRef) {

  }

  transform(value: number, type?: string, hasTypeText: boolean = true): string {

    if (!(type && type !== ''))
      type = this.unitService.selectedRainfallUnit;

    if (value == null || value == undefined || isNaN(value)) {
      return value + "";
    }

    if (this.previouesData && this.previouesData == value && this.previousType == type) {
      return this.previousResponse;
    }
    else {

      this._dispose()
      if (!this.onTypeChanged) {
        this.onTypeChanged = this.unitService.changeSubject.subscribe(res => {
          if (type !== this.unitService.selectedRainfallUnit) {
            type = this.unitService.selectedRainfallUnit;
            this._ref.detectChanges()
            this._ref.markForCheck()
          }
        })
      }
      this.previousType = type;
      this.previouesData = value
      let response = this.unitService.RainfallConvert(value, type, hasTypeText);
      this.previousResponse = response
      return response//this.unitService.RainfallConvert(value, type);
    }
  }

  private _dispose() {
    if (typeof this.onTypeChanged != "undefined") {
      this.onTypeChanged.unsubscribe();
      this.onTypeChanged = undefined
    }
  }
  ngOnDestroy(): void {
    this._dispose()
  }
}
