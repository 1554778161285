import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { GuestGuard } from './core/guards/guest.guard';
import { VraMapsService } from './core/services/vra-maps.service';
import { HasFarmGuard } from './core/guards/has-farm.guard';
import { AccountSettingsPage } from './pages/account-settings/account-settings.page';
import { NotificationSettingsComponent } from './pages/notification-settings/notification-settings.component';
import { AnalyticService } from './core/services/analytic.service';
import { SCREEN_NAME_BY_COMPONENT_NAME } from './core/constants/url-screenname.constant';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/app-start/app-start.module').then(m => m.AppStartPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'add-field',
    loadChildren: () => import('./pages/add-field/add-field.module').then(m => m.AddFieldPageModule),
    // canActivate: [AuthGuard]
  },
  // {
  //   path: 'import-field',
  //   loadChildren: () => import('./pages/import-field/import-field.module').then(m => m.ImportFieldPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'weather',
    loadChildren: () => import('./pages/weather-forecast/weather-forecast.module').then(m => m.WeatherForecastPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./tabs/account/pages/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard, GuestGuard]
  },
  {
    path: 'refer-friend',
    loadChildren: () => import('./pages/refer-friend/refer-friend.module').then(m => m.ReferFriendPageModule)
  },
  {
    path: 'tabs/my-fields/detail/:id',
    loadChildren: () => import('./tabs/my-fields/components/farm-detail/farm-detail.module').then(m => m.FarmDetailComponentModule)
  },
  {
    path: "tabs/explore/my-guide",
    loadChildren: () => import("./tabs/explore/pages/my-guide/my-guide.module").then(m => m.MyGuideComponentModule)
  },
  {
    path: "tabs/explore/new-features",
    loadChildren: () => import("./tabs/explore/pages/new-features/new-features.module").then(m => m.NewFeaturesComponentModule)
  },
  {
    path: 'tabs/explore/faquestions',
    loadChildren: () => import("./tabs/explore/pages/fa-questions/fa-questions.module").then(m => m.FaQuestionsModule)
  },
  {
    path: "tabs/account/profile-settings",
    loadChildren: () => import("./pages/profile-settings/profile-settings.module").then(m => m.ProfileSettingsModule)
  },
  // {
  //   path: "tabs/account/engineer-support/detail",
  //   loadChildren: () => import("./tabs/account/pages/engineer-support/components/question-details/question-detail.module").then(m => m.QuestionDetailsModule)
  // },
  {
    path: "tabs/my-fields/note-detail",
    loadChildren: () => import("./tabs/my-fields/components/note-detail-modal/note-detail.module").then(m => m.NoteDetailModalModule)
  },
  {
    path: 'vra-maps',
    loadChildren: () => import('./pages/vra-maps/vra-maps.module').then(m => m.VraMapsPageModule),
    canDeactivate: [() => { VraMapsService.VraMapServiceInstance.clearVraList() }]
  },
  {
    path: 'spraying-hours',
    loadChildren: () => import('./pages/spraying-hours/spraying-hours.module').then(m => m.SprayingHoursPageModule),
    canDeactivate: [() => { }]
  },
  {
    path: 'irrigation-program',
    loadChildren: () => import('./pages/irrigation-program/irrigation-program.module').then(m => m.IrrigationProgramPageModule),
    canDeactivate: [() => { }]
  },
  {
    path: 'compare',
    loadChildren: () => import('./pages/compare/compare.module').then(m => m.ComparePageModule)
  },
  {
    path: 'bluetooth-test',
    loadChildren: () => import('./pages/bluetooth-test/bluetooth-test.module').then( m => m.BluetoothTestPageModule)
  },
  {
    path: 'purchase-plans',
    loadChildren: () => import('./pages/purchase-plans/purchase-plans.module').then( m => m.PurchasePlansPageModule)
  },
  {
    path: 'account-settings',
    component: AccountSettingsPage,
    loadChildren: () => import('./pages/account-settings/account-settings.module').then( m => m.AccountSettingsPageModule)
  },

  {
    path: 'notification-settings',
    loadChildren: () => import('./pages/notification-settings/notification-settings.module').then(m => m.NotificationSettingsModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'engineer-support',
    loadChildren: () => import('./pages/engineer-support/engineer-support.module').then( m => m.EngineerSupportPageModule)
  },
  // {
  //   path: 'field-comparison',
  //   loadChildren: () => import('./pages/field-comparison/field-comparison.module').then( m => m.FieldComparisonPageModule),
  //   canDeactivate: [() => {}]
  // }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
