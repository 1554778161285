import { Injectable } from "@angular/core";
import { TextZoom } from "@capacitor/text-zoom";
import { StorageKeys } from "../models/enums/storage-keys";
import { ClientStatusBarService } from "./client-status-bar.service";
import { StorageService } from "./storage.service";
import { Capacitor } from "@capacitor/core";
import { KeyboardActionsService } from "./keyboard-actions.service";

@Injectable({
    providedIn: "root"
})
export class VisualThemeModeService {
    private static _currentThemeMode: 'light' | 'dark';
    public static get currentThemeMode(): 'light' | 'dark' {
        return VisualThemeModeService._currentThemeMode;
    }
    private static set currentThemeMode(value: 'light' | 'dark') {
        VisualThemeModeService._currentThemeMode = value;
    }
    /**
     *
     */
    constructor(private storageService: StorageService, private statusbarService: ClientStatusBarService) {

    }
    public initApplicationTheme() {
        this.storageService.get<boolean>(StorageKeys.DARK_MODE).then(response => {
            if (response.IsSuccess) {
                VisualThemeModeService.currentThemeMode = response.Data ? 'dark' : 'light'
                this.toggleDarkTheme(response.Data).then()
            }
            else {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    // dark mode
                    VisualThemeModeService.currentThemeMode = 'dark'
                    this.toggleDarkTheme(true).then()
                } else
                    this.toggleDarkTheme(false).then()
            }
        })
    }

    public setTextZoom() {
        if (Capacitor.isNativePlatform())
            TextZoom.set({
                value: 1
            }).then()
    }

    public setDarkTheme() {
        VisualThemeModeService.currentThemeMode = 'dark'
        this.toggleDarkTheme(true);
    }

    public setLightTheme() {
        VisualThemeModeService.currentThemeMode = 'light'
        this.toggleDarkTheme(false)
    }


    private toggleDarkTheme(isEnabled: boolean) {
        return new Promise(resolve => {
            KeyboardActionsService.SetKeyboardTheme(isEnabled)
            document.body.classList.toggle('dark', isEnabled);
            this.storageService.set<boolean>(StorageKeys.DARK_MODE, isEnabled).then(response => {
            })
            
            this.statusbarService.setStatusBarTheme(isEnabled).then(e => {
                resolve(true);
            });
        });
    }
}