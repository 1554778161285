import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../services/date-time.service';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  constructor(private dateTimeService: DateTimeService, private translate: TranslateService) {
  }

  transform(date: string | Date, format: string, isTodayCheck: boolean = false, preFormat?: string): string {
    if (date != null || date != undefined) {
      if (isTodayCheck) {
        // let today = DateTimeService.ClearHours(new Date())
        // let tempDate = DateTimeService.ClearHours(date)
        let today = new Date()
        let tempDate = new Date(date);

        let todayStr = DateTimeService.FormatDate(today, "YYYY-MM-DD"), tempDateStr = DateTimeService.FormatDate(tempDate, "YYYY-MM-DD")

        if (todayStr === tempDateStr)
          return this.translate.instant("general.today")
        else if (DateTimeService.FormatDate(today.setDate(today.getDate() + 1), "YYYY-MM-DD") === tempDateStr)
          return this.translate.instant("general.tomorrow")
        else if (DateTimeService.FormatDate(today.setDate(today.getDate() - 1), "YYYY-MM-DD") === tempDateStr)
          return this.translate.instant("general.yesterday")
        // else if (today.setDate(today.getDate() + 1) === tempDate.getTime())
        //   return this.translate.instant("general.tomorrow")
        // else if (today.setDate(today.getDate() - 1) === tempDate.getTime())
        //   return this.translate.instant("general.yesterday")
        else
          return DateTimeService.FormatDate(date, format, preFormat)
      } else {
        let formattedDate = DateTimeService.FormatDate(date, format, preFormat)
        return formattedDate
      }
    }
    else
      return ""
  }

}
