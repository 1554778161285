import { Injectable } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Subject } from "rxjs"
import { environment } from "src/environments/environment"
import { UserApiService } from "../api/user-api.service"
import { ClientBaseApiService } from "../api/client-base-api.service"
import { UnitPrefModalComponent } from "../helper/components/unit-pref-modal/unit-pref-modal.component"
import { HttpRequestTypes } from "../models/enums/http-request-types"
import { StorageKeys } from "../models/enums/storage-keys"
import { UnitKeys } from "../models/enums/unit-keys"
import { AccountService } from "./account.service"
import { ModalService } from "./modal.service"
import { StorageService } from "./storage.service"

@Injectable({
    providedIn: "root"
})
export class UnitPreferenceService {

    public changeSubject: Subject<any> = new Subject<any>()

    defaultValues = ["ha", "km/h", "C", "mm", "kg"]

    speedUnits = ['km/h', 'mph', 'm/s']

    areaUnits = ["da", "ha", "ac"]

    temperatureUnits = ["C", "F"]

    rainfallUnits = ["mm", "inch"]

    weightUnits = { "da": "kg", "ha": "ton", "ac": "bushel" }
    weightUnitsArray = ["kg", "ton", "bushel"]
    selectedSpeedUnit = null //"km/h"
    selectedAreaUnit = null //"da"
    selectedTemperatureUnit = null //"C"
    selectedRainfallUnit = null //"mm"
    selectedWeightUnit = null // "kg"

    get selectedTempUnitText(){
        return this.selectedTemperatureUnit == 'C' ? '°C' : '°F'
    }

    /**
     *
     */
    constructor(private translate: TranslateService, private clientApiService: ClientBaseApiService, private storage: StorageService, private modalService: ModalService) {

    }

    public initializeUnitValues() {
        let promises = [
            this.storage.get(UnitKeys.AREA),
            this.storage.get(UnitKeys.SPEED),
            this.storage.get(UnitKeys.TEMPERATURE),
            this.storage.get(UnitKeys.RAINFALL)
        ]
        Promise.all(promises).then(response => {
            if (Array.isArray(response) && response.length == 4) {
                this.changeAreaUnit(response[0].Data || "da")
                this.changeSpeedUnit(response[1].Data || "km/h")
                this.changeTemperatureUnit(response[2].Data || "C")
                this.changeRainfallUnit(response[3].Data || "mm")
            }
        })
    }

    public GetAccountUnits() {
        let url = environment.baseApiUrl + "get/account/units";
        this.clientApiService
            .baseApiCall({ url, payload: null, requestType: HttpRequestTypes.GET })
            .subscribe(res => {
                if (res.IsSuccess) {
                    var rawValues = res.Data.ConstantValue
                    if (rawValues && rawValues !== '') {
                        let values = rawValues.split(",");
                        if (Array.isArray(values) && values.length > 0) {
                            this.changeAreaUnit(values[0])
                            this.changeSpeedUnit(values[1])
                            this.changeTemperatureUnit(values[2])
                            this.changeRainfallUnit(values[3])
                        }
                    }
                }
            })
    }

    public openPrefModal() {
        this.modalService.presentModal({
            component: UnitPrefModalComponent,
            componentProps: {},
            options: ModalService.defaultOptionsTransparent
        }).then(res => {
        }).catch(err => {
        })
    }

    changeSpeedUnit(unitKey) {
        this.selectedSpeedUnit = unitKey;
        AccountService.selectedSpeedUnit = unitKey;
        this.storage.set(UnitKeys.SPEED, unitKey).then(res => { })
        this.changeSubject.next(true)
    }
    changeAreaUnit(unitKey) {
        this.selectedAreaUnit = unitKey;
        AccountService.selectedAreaUnit = unitKey;
        this.storage.set(UnitKeys.AREA, unitKey).then(res => { })
        this.changeSubject.next(true)
    }
    changeTemperatureUnit(unitKey) {
        this.selectedTemperatureUnit = unitKey;
        AccountService.selectedTemperatureUnit = unitKey;
        this.storage.set(UnitKeys.TEMPERATURE, unitKey).then(res => { })
        this.changeSubject.next(true)
    }
    changeRainfallUnit(unitKey) {
        this.selectedRainfallUnit = unitKey;
        AccountService.selectedRainfallUnit = unitKey;
        this.storage.set(UnitKeys.RAINFALL, unitKey).then(res => { })
        this.changeSubject.next(true)
    }
    changeWeightUnit(unitKey) {
        this.selectedWeightUnit = unitKey;
        AccountService.selectedWeightUnit = unitKey;
        this.storage.set(UnitKeys.WEIGHT, unitKey).then(res => { })
        this.changeSubject.next(true)
    }


    TemperatureConvert(value, type, fractionDigits,hasTypeText:boolean = true) {

        // (0°C × 9/5) + 32 = 32°F
        let result = 'N/A';
        // if (!(type && type !== ''))
        //     return "N/A";
        let strValue = ""
        switch (type) {
            case "°F":
            case "F":
                let calculatedValue = (value * 9 / 5) + 32
                strValue = this.numberFormat(calculatedValue, false, fractionDigits)
                result = `${strValue}${ hasTypeText ? ' °F' : ''}`
                break;

            // strValue = this.numberFormat(value, false, fractionDigits)
            // result = `${strValue} °C`
            // break;
            case "°C":
            case "C":
            default:
                strValue = this.numberFormat(value, false, fractionDigits)
                result = `${strValue}${ hasTypeText ? ' °C' : ''}`
                break;
        }
        return result;
    }

    AreaConvert(value, type, withUnitText = true, toFixed: boolean = false) {
        let result = "N/A"
        let strValue = ""
        switch (type) {
            case "ha":
                let calculatedHa = value / 10;
                strValue = this.numberFormat(calculatedHa, false, toFixed ? 0 : 2)
                result = withUnitText ? `${strValue} ha` : `${strValue}`
                break;
            case "ac":
                let calculatedAc = (value / 10) * 2.47105;
                strValue = this.numberFormat(calculatedAc, false, toFixed ? 0 : 2)
                result = withUnitText ? `${strValue} ac` : `${strValue}`
                break;
            case "da":
            default:
                strValue = this.numberFormat(value, false, toFixed ? 0 : 2)
                result = withUnitText ? `${strValue} da` : `${strValue}`
                break;
        }
        return result;
    }

    SpeedConvert(value, type, fractionDigits: number = 2) {

        let result = "N/A";
        let strValue = "";
        switch (type) {
            // case "km/h":
            //     strValue = this.numberFormat(value)
            //     result = strValue + " " + type;
            //     break;
            case "mph":
                let calculated = value / 1.609
                strValue = this.numberFormat(calculated,false,fractionDigits)
                result = strValue + " " + type;
                break;

            case "km/h":
            default:
                strValue = this.numberFormat(value,false,fractionDigits)
                result = strValue + " " + type;
                break;
        }

        return result;
    }

    RainfallConvert(value, type, hasTypeText: boolean = true) {
        var result = "N/A"

        // result = value / 25.4

        switch (type) {
            //         result = `${value} mm`
            // break;

            case "inch":
                let calculatedValue = value / 25.4
                let strValue = this.numberFormat(calculatedValue, false)
                result = `${strValue}${ hasTypeText ? ' inch' : ''}`
                break;

            case "mm":
            default:
                let asd = this.numberFormat(value, false)
                result = `${asd}${ hasTypeText ? ' mm' : ''}`
                break;
        }

        return result;
    }

    WeightConvert(value, type) {
        var result = "N/A";
        let calculatedValue = 0;
        switch (type) {
            case "ton":
                calculatedValue = value / 1000
                result = this.numberFormat(calculatedValue, false) + " ton"
                break;
            case "bushel":
                calculatedValue = value / 27.22
                result = this.numberFormat(calculatedValue, false) + " bushel"
                break;
            case "kg":
            default:
                result = this.numberFormat(value, false) + " kg"
                break;
        }
        return result
    }


    YieldProductionUnitConvert(value, type, digits = 2) {
        var result = "N/A";
        let calculatedValue = 0;
        switch (type) {
            case "ha":
                calculatedValue = value * 0.01
                result = this.numberFormat(calculatedValue, false, digits) + " ton/ha"
                break;
            case "ac":
                calculatedValue = value * 0.1444
                result = this.numberFormat(calculatedValue, false, digits) + " bushel/ac"
                break;
            case "da":
            default:
                result = this.numberFormat(value, false, digits) + " kg/da"
                break;
        }
        return result
    }

    // kg/da => ton/ha *0.01
    // kg/da => bushel/ac *0.1444

    numberFormat(value: number | string, addPercentage: boolean = false, fractionDigits = 2): string {

        if (value === null || value === undefined)
            return "N/A";
        let _value: number;
        if (typeof value === "string") {
            _value = parseFloat(value);
        } else if (typeof value === "number") {
            _value = value;
        } else {
            return value + "";
        }

        let langCulture: string = this.translate.instant("lang.culture_info")
        var formattedValue = new Intl.NumberFormat('en-En', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(_value).toString()
        if (langCulture && langCulture !== '' && !(langCulture.includes("lang_culture") || langCulture.includes("lang")))
            formattedValue = new Intl.NumberFormat(langCulture, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(_value).toString()
        return addPercentage ? this.translate.instant("general.percentage", { value: formattedValue }) : formattedValue;
    }

}