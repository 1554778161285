import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy, IonRouterOutlet } from '@ionic/angular';
import { TranslateModule, TranslateLoader, TranslateService, MissingTranslationHandler } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { CustomTranslateLoader } from './core/middleware/ngx-transateLoader';
import { OnboardingTutorialComponent } from './core/helper/components/onboarding-tutorial/onboarding-tutorial.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NetworkAlertModalComponent } from './core/helper/components/network-alert-modal/network-alert-modal.component';
// import { IConfig, NgxMaskModule } from 'ngx-mask';
import { HttpInterceptorService } from './core/middleware/http-interceptor.service';
import { AuthPageModule } from './pages/auth/auth.module';
import { CustomLoadingModule } from './core/modules/custom-loading.module';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx/';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AppInitService, OnAppInit } from './core/services/on-app-init.service';
import { GuestHideDirective } from './core/directives/guest-hide.directive';
import { ForceOverScrollDirective } from './core/directives/force-over-scroll.directive';
// import { GuestRegisterModalModule } from './pages/auth/components/guest-register-modal/guest-register-modal.module';
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
// import { Adjust } from '@awesome-cordova-plugins/adjust/ngx';
import { SplashScreenModule } from './core/helper/components/splash-screen/splash-screen.module';
import { CustomMissingTranslationHandlerService } from './core/middleware/custom-missing-translation-handler.service';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx'
import { pageAnimation } from './core/animations/nav-animations';
// import { HTTP } from '@ionic-native/http/ngx';
import { DeletedFarmsAlertModalModule } from './components/deleted-farms-alert-modal/deleted-farms-alert-modal.module';
import { OnboardingTutorialModule } from './core/helper/components/onboarding-tutorial/onboarding-tutorial.module';
//import * as SentryAngular from '@sentry/angular-ivy'
import { ERROR_HANDLERS, FirebaseErrorHandlerService, GlobalErrorhandlerService } from './core/middleware/error-handler.service';
import { RequestTimeLogInterceptor } from './core/middleware/request-time-log-interceptor.service';
import { ErrorLogInterceptor } from './core/middleware/error-log-interceptor.service';

// export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};
export function createTranslateLoader(http: HttpClient) {
  return new CustomTranslateLoader(http, environment.i18nUrl, '.json' /*?v=' + Math.random()*/);
  // return new TranslateHttpLoader(http, environment.i18nUrl, '.json?v=' + Math.random());
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [AppComponent, ForceOverScrollDirective, NetworkAlertModalComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: "ios",
            // animated: false,
            swipeBackEnabled: true,
            scrollPadding: false,
            hardwareBackButton: true,
            scrollAssist: true,
            navAnimation: pageAnimation,
            innerHTMLTemplatesEnabled: true
        }),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        // NgxMaskModule.forRoot(maskOptions),
        AuthPageModule,
        CustomLoadingModule,
        SplashScreenModule,
        DeletedFarmsAlertModalModule,
        OnboardingTutorialModule
        // GuestRegisterModalModule
        //   IntlPhoneInputModule.forRoot({
        //     countryListUrl: "assets/country-list.json",
        //     flagDirUrl: './assets/flags/'
        // })
    ],
    providers: [
        // Adjust,
        InAppPurchase2,
        MediaCapture,
        AndroidPermissions,
        File,
        Facebook,
        OpenNativeSettings,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestTimeLogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorLogInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: OnAppInit, multi: true, deps: [AppInitService] },
        {provide: ERROR_HANDLERS, useExisting: GlobalErrorhandlerService, multi: true},
        {provide: ERROR_HANDLERS, useExisting: FirebaseErrorHandlerService, multi: true},
        // {provide: ERROR_HANDLERS, useExisting: SentryAngular.createErrorHandler(), multi: true},
        // {provide:SentryAngular.TraceService, deps:[Router] },
        // { provide: APP_INITIALIZER, useFactory: OnAppInit, multi: true, deps: [AppInitService, SentryAngular.TraceService] },
        { provide: APP_INITIALIZER, useFactory: OnAppInit, multi: true, deps: [AppInitService] },
        CleverTap
        // HTTP
    ],
    bootstrap: [AppComponent],
    exports: [ForceOverScrollDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

