export enum PurchaseCustomEvents{
    STORE_REFRESHED = 1,
    PRODUCTS_VERIFIED = 2,
    PRODUCTS_APPROVED = 3,
    PURCHASE_COMPLETED = 4,
    PRICE_CALCULATED = 5,
    PRODUCTS_REGISTERED = 6,
    PRODUCT_REGISTER_ERROR = 7,
    PRODUCTS_INVALID = 8,
    PRODUCTS_VALID = 9,
    REGISTER_ACTION_START = 10
}