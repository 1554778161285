export interface IAppConstantSatelliteOption {
    imagery: string
    key: string
    text: string
    ParamName: string | number
    val: string
    title: string
    imageClass: string
    status: string
    class: string
}

export enum SatelliteParamNames {
    PLANET = "1157",
    SENTINEL = "1158"
}