import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { App } from "@capacitor/app";
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { NetworkService } from 'src/app/core/services/network.service';
import { SwiperComponent } from 'swiper/angular';
import { AutoplayOptions } from 'swiper/types';
import { Router } from '@angular/router';
import { ClientBaseApiService } from 'src/app/core/api/client-base-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpRequestTypes } from 'src/app/core/models/enums/http-request-types';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/core/services/account.service';
import { OnboardingStorageService } from 'src/app/core/services/onboarding-storage.service';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { AnalyticEvents, AppsflyerAnalyticEvents } from 'src/app/core/models/enums/analytic-events';
import { DoktarAppsFlyer } from 'src/app/core/services/appsflyer.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage implements OnInit {
  @ViewChild("swipeArea") swipeArea: ElementRef
  @ViewChild("swiperItem") swiperItem: SwiperComponent

  list: Array<any> = []
  current: number = 0
  interval: any = null
  isStoped: boolean = false
  isUserLogged: boolean = false
  swiperEffectStyle = {
    prev: {
      shadow: true,
      translate: ['-100%', 0, 1]
    },
    next: {
      translate: [0, 0, 0]
    }
  }

  autoPlayOpts: AutoplayOptions = {
    delay: 1000,
    stopOnLastSlide: true,
    disableOnInteraction: false,
    pauseOnMouseEnter: false
  }
  constructor(public translate: TranslateService, private analyticService:AnalyticService, private onboardingStorage: OnboardingStorageService, public langService: LanguageService, private clientApi: ClientBaseApiService, private domSanitizer: DomSanitizer, private storageService: StorageService, private modalService: ModalService, private router: Router) {
    this.list = []
  }

  ngOnInit() {
    // this.storageService.get(StorageKeys.USER).then(userResp => {
    //   if (userResp.IsSuccess && userResp.Data && Object.keys(userResp.Data).length > 0) {
    //     this.isUserLogged = true;
    //     this.router.navigate(["/tabs/map"], { replaceUrl: true })
    //     // this.storageService.get(StorageKeys.TOKEN).then(response => {
    //     //   if (response.IsSuccess && response.Data && response.Data !== '') {
    //     //     this.isUserLogged = true;
    //     //   }
    //     // })
    //   }
    // })

    this.clientApi.externalApiCall<any>({ url: `${environment.onboardingDataUrl}?q=${Math.random()}`, payload: null, requestType: HttpRequestTypes.GET }).subscribe({
      next: rawData => {
        let lang = this.translate.currentLang // LanguageService.currentLanguage?.code.toLowerCase() || "en"
        let langPrefix = rawData.availableLanguages.find(t => t === lang) || rawData.availableLanguages[0]
        let imageBaseUrl = environment.onboardingImagesBaseUrl
        this.list = rawData.data.map(t => {
          t.imageUrl = imageBaseUrl + langPrefix + "/" + t.imageName
          t.value = 0
          return t
        })
        this.list.sort((a, b) => {
          let valueA = a["id"], valueB = b["id"];
          if (valueA > valueB)
            return 1;
          if (valueA < valueB)
            return -1
          return 0
        })
        this.addDocumentListeners()
        // DoktarAppsFlyer.AfEventLog(AppsflyerAnalyticEvents.tutorial_completion)
        this.initAutomation()
      },
      error: err => { },
      complete: null
    })
  }
  showStartFreeTrial: boolean = false
  ionViewWillEnter() {
    return;
    this.onboardingStorage.checkAppOnboardingStatus().then(res => {
      if(res){
        
        this.analyticService.RecordeScreenView("Onboarding")
      }
      this.showStartFreeTrial = res
      setTimeout(() => {
        this.addDocumentListeners()
      }, 200);
    }).catch(err => {
      this.showStartFreeTrial = true
      setTimeout(() => {
        this.addDocumentListeners()
      }, 200);
    })
  }

  initAutomation() {
    if (this.swiperItem) {
      console.log(this.swiperItem)
      setTimeout(() => {
        this.swiperItem
        // elementRef.nativeElement.slideNext()
      }, 1000);
    } else {
      this.initAutomation()
    }
  }
  openModal() {
    this.langService.openOptionsMenu()
  }

  openAddField() {
    this.router.navigate(["add-field"], { queryParams: { createguest: true } })
  }

  addDocumentListeners() {
    let element = document.getElementsByClassName("onboarding-btn-signin")
    if (element) {

      document.getElementsByClassName("onboarding-btn-signin")[0]?.addEventListener("click", (ev:any) => {
        let props = {
          "Element_ID": "onboarding-btn-signin",
          "Timestamp": Date.now(),
          "Screen_Name": "Register",
          "User_ID": "N/A",
          "Session_ID": AnalyticService.SessionId,
          "Event_Type": ev.type, //elRef.nativeElement.type,
          "Element_Type": ev.srcElement.tagName, //elRef.nativeElement.tagName,
          "Referrer_URL": this.analyticService.previousScreenName, //"n/a", //TODO buraya previousPage datası gelecek
          "Outcome": "navigate",
          "User_Profile": null,
          "Element_Text": ev.srcElement.textContent
        }
        this.analyticService.logEvent(AnalyticEvents.TAP, props)
        this.router.navigate(["/auth/login"], { replaceUrl: true })
      })

      document.getElementsByClassName("onboarding-btn-register")[0]?.addEventListener("click", (ev:any) => {
        let props = {
          "Element_ID": "onboarding-btn-register",
          "Timestamp": Date.now(),
          "Screen_Name": "Register",
          "User_ID": "N/A",
          "Session_ID": AnalyticService.SessionId,
          "Event_Type": ev.type, //elRef.nativeElement.type,
          "Element_Type": ev.srcElement.tagName, //elRef.nativeElement.tagName,
          "Referrer_URL": this.analyticService.previousScreenName, //"n/a", //TODO buraya previousPage datası gelecek
          "Outcome": "navigate",
          "User_Profile": null,
          "Element_Text": ev.srcElement.textContent
        }
        this.analyticService.logEvent(AnalyticEvents.TAP, props)
        this.router.navigate(["/auth/register"], { replaceUrl: true })
      })

    } else {
      this.addDocumentListeners()
    }
  }
  slideChanged(ev) {
    console.log(ev)
    this.current = ev[0].realIndex

  }
}
