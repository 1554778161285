import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { IListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.scss'],
})
export class ListModalComponent extends BaseModal implements OnInit {
  @Input() title: string = ""
  @Input() hasConfirmBtn: boolean = false
  @Input() confirmBtnText: string = "general.submit"
  @Input() isMultiple: boolean = false
  @Input() itemList: Array<IListModalModel> = []
  @Input() isFullscreen: boolean = false
  @Input() showSearchbar: boolean = false
  @Input() searchPlaceholder: string = this.translate.instant("general.make_search")
  @Input() sortItems: boolean = false;
  @Input() detailedToolbar: boolean = true;

  areaHeight = 0;
  demoArray = new Array(35)
  filteredList: Array<IListModalModel> = []

  selectedList: Array<IListModalModel> = []

  constructor(private modalService: ModalService, private translate: TranslateService) {
    super()
    super.contentClass = "bg-default"//
    super._modalService = modalService
    // this.demoArray = new Array(2).fill(1).map(t => 1)
  }

  ngOnInit() {
    super.ngOnInit()

    if (this.sortItems) {
      this.itemList = this.itemList.sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0)
    }

    var hasButton = this.hasConfirmBtn ? 1 : 0
    var hasSearch = this.showSearchbar ? 1 : 0

    this.areaHeight = 88 + 60 * hasSearch + 45 * hasButton + 16 + 30// 16 + 30 top + bottom padding çıkarıldı.

    this.filteredList = this.itemList
    this.selectedList = this.itemList.filter(t => t.isChecked)
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

  ionViewDidEnter() {
  }

  dismissModal() {
    super.dismiss({}, 'canceled')
    // this.modalService.dismissByModalId(this.id, {}).then(response => { })
  }
  applyBtn() {
    if (!this.isMultiple && this.hasConfirmBtn) {
      if (this.selectedList.length == 1)
        this.dismiss({ selectedItem: this.selectedList[0] }, "itemSelected")
    }
    else
      this.dismiss({ selectedItems: this.selectedList }, "itemsSelected")
  }
  clear() {
    this.selectedList = [];
    this.itemList.filter(t => t.isChecked).forEach(t => t.isChecked = false)
  }

  itemClicked(item) {
    if (this.isMultiple) {
      if (item.isChecked)
        this.selectedList = this.selectedList.filter(t => t.id !== item.id)
      else
        this.selectedList.push(item)
      item.isChecked = !item.isChecked;
    } else {
      this.itemList.filter(t => t.isChecked).forEach(t => t.isChecked = false)
      this.selectedList[0] = item
      if (!this.hasConfirmBtn)
        setTimeout(() => {
          this.dismiss({ selectedItem: item }, "itemSelected")
        }, 250);
    }
  }

  onSearchChange(ev) {
    if (!(ev?.detail?.value == null || ev?.detail?.value == undefined)) {
      let query = ev.detail.value.toLocaleLowerCase(LanguageService.CurrentLangCode);
      this.filteredList = this.itemList.filter(t => t.value.toLocaleLowerCase(LanguageService.CurrentLangCode).includes(query)).map(t => {
        let isChecked = this.selectedList.some(a => a.id == t.id)
        t.isChecked = isChecked
        return t;
      })
    }
    else {
      this.filteredList = this.itemList.filter(t => true)
    }
  }
}
