import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';
import { IButtonOpts } from '../interfaces/modal-components-models';

@Component({
    template: ""
})
export abstract class BaseAlertModal implements OnInit {

    @Input() id: number
    @Input() initialBreakpoint: number
    @Input() bottomPadding: number = 0;
    @Input() isBottomSheet: boolean = false
    @Input() mainTitle: string = null
    @Input() title: string = null
    @Input() description: string = null
    @Input() image: string = null
    @Input() buttons: Array<IButtonOpts> = []
    @Input() hasBackdropdismiss = true
    @Input() preventDismiss = false
    @Input() imageClassList = ""

    
    
    private _alertModalService: ModalService;
    public get alertModalService(): ModalService {
        return this._alertModalService;
    }
    public set alertModalService(value: ModalService) {
        this._alertModalService = value;
    }

    public contentClass: any = "bg-default"//

    constructor() {
        // super._modalService = alertModalService
    }

    ngOnInit(): void {
        if (this.initialBreakpoint) {
            let diff = 1 - this.initialBreakpoint;
            this.bottomPadding = 100 * diff;
        }
        setTimeout(() => {
            this.contentClass = "bg-opaque"
        }, 500);
    }

    async dismiss(data, role = "canceled") {
        // setTimeout(() => {
        this.contentClass = "bg-default"
        // }, 500);
        await this.alertModalService.dismissByModalId(this.id, data, role)
    }

    dismissModal(id) {
        this.alertModalService.dismissByModalId(id, {}).then(response => { })
    }
    buttonClick(ev, prop) {
        if(!this.preventDismiss)
        this.dismiss({ event: ev, data: prop }, "buttonClicked")
        prop.callback(ev, this.id);
    }
}
