import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArray'
})
/**
 * value ile tanımlanan arrayi filterFn ile tanımlanmış fonksiyona göre filtreler
 */
export class FilterArrayPipe<T> implements PipeTransform {
    transform(value: T[], filterFn: any, refresher?: number, ...args: any[]): T[] {
        if (Array.isArray(value)) {
            return value.filter(filterFn)
        } else {
            return []
        }
    }
}