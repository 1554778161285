import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core';
import { FirebaseCrashService } from '../services/firebase-crash.service';
import { Capacitor } from '@capacitor/core';
import { AppInsightsLoggingService } from '../services/logging/logging.service';

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');



@Injectable({ providedIn: 'root' })
export class FirebaseErrorHandlerService implements ErrorHandler {
    constructor(private firebaseCrash: FirebaseCrashService) {
    }

    handleError(error: any): void {
        const message = this.getMessageFromUnknownError(error);
        // console.log("firebase error")
        if (message && message !== '') {
            this.firebaseCrash.SendCrashLog(message).then().catch();
        }
        // throw new Error('Method not implemented.');
    }

    private getMessageFromUnknownError(error: unknown): string {
        let message = 'An unknown error has occurred.';
        try {
            if (error instanceof Object && 'rejection' in error) {
                error = (error as any).rejection;
            }
            if (error instanceof Error && error.message) {
                message = error.message;
            }
        } catch (err) {

        }
        return message;
    }
}


@Injectable({ providedIn: 'root' })
export class GlobalErrorhandlerService implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any): void {
        AppInsightsLoggingService.logException(error)
        const handlers = this.injector.get<ErrorHandler[]>(ERROR_HANDLERS, []);
        // console.error("error handled => ", error)
        if (Capacitor.isNativePlatform())
            handlers.forEach((_errorHandler: ErrorHandler) => {
                _errorHandler.handleError(error)
            })
        // else
        // console.error(error)
    }

}
