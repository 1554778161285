import { Component, Input, OnInit } from '@angular/core';
import { Instance } from 'src/app/core/directives/instance.abstract.directive';
import { BaseAlertModal } from 'src/app/core/models/classes/base-alert-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent extends BaseAlertModal implements OnInit {

  // @Input() mainTitle: string = "Asd"
  // @Input() title: string = ""
  // @Input() description: string = ""
  // @Input() image: string = ""
  // @Input() buttons: Array<IButtonOpts> = []

  constructor(private modalService: ModalService) {
    super()
    super.alertModalService = modalService
  }

  ngOnInit() {
    super.ngOnInit()
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

  dismissModal(id) {
    this.modalService.dismissByModalId(id, {}, "canceled").then(response => {})
  }
  buttonClick(ev, prop) {
    prop.callback(ev);
    if(!this.preventDismiss)
    this.dismiss({}, 'callbackClicked')
  }
}
