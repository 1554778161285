import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseModalModule } from 'src/app/core/modules/base-modal.module';
import { FarmService } from 'src/app/core/services/farm.service';
import FarmControl from '../../../services/class/farm/farm-control.class';
import { AltBaseModal } from '../../class/alt-base-modal.component';
import { ModalService } from 'src/app/core/services/modal.service';
import { UnitPreferenceModule } from 'src/app/core/modules/unit-preferences.module';
import { DatePipeModule } from 'src/app/core/modules/date-pipe.module';
import { FarmDrawingToolsModule } from 'src/app/core/modules/farm-drawing-tools.module';
import { mutations } from '../../../services/class/farm/farm.signal';
import { Router } from '@angular/router';
// import { IconModule } from 'src/app/core/modules/icon.module';
import { EmptyPageModule } from 'src/app/core/modules/empty-page.module';
import { FilterArrayPipeModule } from 'src/app/core/modules/filter-array-pipe.module';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { IButtonOpts } from 'src/app/core/models/interfaces/modal-components-models';
import { PurchasePlansService } from 'src/app/core/services/purchase-plans.service';
import { FieldListFilterService } from 'src/app/core/services/field-list-filter.service';

@Component({
  selector: 'orbit-field-list',
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    BaseModalModule,
    TranslateModule,
    UnitPreferenceModule,
    DatePipeModule,
    FarmDrawingToolsModule,
    // IconModule,
    SharedModule,
    EmptyPageModule,
    FilterArrayPipeModule
  ],
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss'],
})
export class FieldListComponent extends AltBaseModal implements OnInit, OnDestroy {
  /**
   * @description bu değer verilirken webde nasıl görüneceği hesap edilerek verilmelidir. androidde web ile aynı görünecektir. ios tarafındaki yükseklik değeri ise view tarafında hesaplanmaktadır.
   */
  @Input() height: number = -1
  @Input() changeCurrentField: boolean = true
  @Input() checkForPackage: boolean = true
  @Input() alertPrefix: string = ""
  @Input() customFilterFn: (t: FarmControl) => boolean = (t: FarmControl) => true
  @Input() multiSelect: boolean = false
  @Input() useConfirm: boolean = false
  @Input() preSelectedList: number[] = []

  isLoading: boolean = false
  protected _selectedFarmList: { [key: number]: FarmControl } = {}
  protected get _selectedCount(): number {
    return this._selectedFarmList ? Object.keys(this._selectedFarmList).length : null
  }
  constructor(public farmService: FarmService, private modalService: ModalService, private purchaseService: PurchasePlansService, private translateService: TranslateService, private clientAlert: ClientAlertService, private router: Router, public myFarmService: FieldListFilterService) {
    super(modalService)
    this._selectedFarmList = {}
  }
  ngOnDestroy(): void {
    this.myFarmService.previousFilter = []
  }

  ngOnInit() {
    this.isLoading = true
    this.farmService.GetAllFarms({ refreshFarms: false, showLoading: true }).subscribe({
      next: (res) => {
        this.myFarmService.searchQuery = ""
        this.myFarmService.initFarmList()
        if (this.preSelectedList && this.preSelectedList.length > 0) {
          this.preSelectedList.forEach(el => {
            let tempItem = this.farmService.allFarms.find(t => t.TempFarmAttId === el)
            if (tempItem)
              this._selectedFarmList[el] = tempItem
          })
        }
        this.isLoading = false
      }, error: (err) => {
        // console.error(err)
      }
    })
  }
  onSearchChange(ev) {
    //FIXME search eklememişiz rocky!!!
    let query = ev.details.value
  }

  trackByFn(index: number, prop: FarmControl) {
    return prop.farm.TempFarmAttId
  }

  selectFarm(item: FarmControl) {

    let selectionFn = () => {
      if (this.multiSelect) {
        if (this._selectedFarmList[item.TempFarmAttId])
          delete this._selectedFarmList[item.TempFarmAttId]
        else
          this._selectedFarmList[item.TempFarmAttId] = item
      } else {
        if (this.useConfirm) {
          this._selectedFarmList = {}
          this._selectedFarmList[item.TempFarmAttId] = item
        } else
          if (this.changeCurrentField) {
            this.router.navigate(["/tabs/map"], { queryParams: { tempFarmAttId: item.farm.TempFarmAttId, q: Math.random() } })
            mutations.farmSelection(item)
            // .mutate((val) => {
            //   val = item
            // })
          }
        if (!this.useConfirm)
          this.dismiss(item, "farm_selected")
      }
    }
    if (this.checkForPackage) {
      if ((item.farm.PlanName.toLowerCase() !== 'pro')) {
        this.noPurchaseAlert(item)
      } else
        selectionFn()
    } else
      selectionFn()
  }

  completeSelection() {
    let farmList = Object.values(this._selectedFarmList)
    this.dismiss(farmList, "farm_selected")
  }

  noPurchaseAlert(prop: FarmControl) {
    if (this.alertPrefix && this.alertPrefix != '')
      this.alertPrefix = this.alertPrefix + "_"
    this.clientAlert.PresentAlert({
      buttons: [
        {
          callback: () => {
            this.purchaseService.openPurchasePlans({ farmList: [prop.farm], isOnlyPackageDetails: false }).then(res => { })
          },
          color: "primary",
          fill: "solid",
          id: "field-list-modal-purchase-btn",
          text: this.translateService.instant("purchase_alerts.purchase_btn")
        }
      ] as IButtonOpts[],
      title: this.translateService.instant("purchase_alerts." + this.alertPrefix + "alert_title"),
      description: this.translateService.instant("purchase_alerts." + this.alertPrefix + "alert_description")
    }, true, true).then(res => {
      // res.modalObj.onDidDismiss().then(dismiss => {})
    })
  }
}
