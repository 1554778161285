import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { IonicModule } from '@ionic/angular';
import { IonModal } from '@ionic/angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AltBaseModal } from 'src/app/core/helper/class/alt-base-modal.component';
import { AppsflyerAnalyticEvents } from 'src/app/core/models/enums/analytic-events';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { IFarmModel } from 'src/app/core/models/interfaces/farm-model';
import { ICalculationFarmModel, ISelectedPlanModel } from 'src/app/core/models/interfaces/in-app-purchase-models';
import { IButtonOpts } from 'src/app/core/models/interfaces/modal-components-models';
import { BaseModalModule } from 'src/app/core/modules/base-modal.module';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { AccountService } from 'src/app/core/services/account.service';
import { AppConstantsService } from 'src/app/core/services/app-constants.service';
import { DoktarAppsFlyer } from 'src/app/core/services/appsflyer.service';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { PurchasePlansService } from 'src/app/core/services/purchase-plans.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'orbit-purchase-modal',
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, SharedModule, BaseModalModule],
  templateUrl: './purchase-modal.component.html',
  styleUrls: ['./purchase-modal.component.scss'],
})
export class PurchaseModalComponent extends AltBaseModal implements OnInit {
  @ViewChild("exceededPlanModal") exceededPlanModal: IonModal
  @Input() farmList: IFarmModel[]
  @Input() isOnlyPackageDetails: boolean = false
  selectedPlan: string = "pro"
  selectedPrice: ISelectedPlanModel
  allPlanOptions = {}
  devicePlatform = Capacitor.getPlatform().toLowerCase();
  rawPlanOptions
  selectedContactOption: { key: string, text: string, icon: any } = null
  contactOptions: { key: string, text: string, icon: any }[] = [
    { key: "whatsapp", text: "plans_pricing.request_opts_whatsapp", icon: "whatsapp_filled" },
    { key: "email", text: "plans_pricing.request_opts_email", icon: "email" },
    { key: "phone", text: "plans_pricing.request_opts_phone", icon: "telephone" }
  ]
  constructor(private modalService: ModalService, private router: Router, private toastService: ToastService, private purchaseService: PurchasePlansService, private accountService: AccountService, private loading: LoadingService, private appConstants: AppConstantsService, private translate: TranslateService, private alertService: ClientAlertService) {
    super(modalService)
  }

  ngOnInit() {

    // let planList = this.allPlanOptions[this.selectedPlan].filter(t => t.isPackageAvailable);
    // console.log(planList)
    // if (planList.length > 0) {
    //   planList.sort((a, b) => a.Duration > b.Duration ? -1 : a.Duration < b.Duration ? 1 : 0)
    //   this.selectedPrice = planList[0]
    // } else {
    //   this.selectedPrice = this.allPlanOptions[this.selectedPlan].find(t => t.Duration == 6)
    //   // this.showLimitExceededAlert(null);
    // }
    if (!this.isOnlyPackageDetails)
      this._GetPrice()
  }

  private _GetPrice() {
    this.loading.showLoading();
    let payload: ICalculationFarmModel[] = this.farmList.map(t => {
      return { FarmArea: t.CalculatedArea, TempFarmAttId: t.TempFarmAttId, SelectedPackage: this.selectedPlan } as ICalculationFarmModel
    })
    let langCulture: string = this.translate.instant("lang.culture_info")
    this.purchaseService.CalculatePriceBulk(payload, true).subscribe(
      {
        next: response => {
          this.loading.hideLoading();
          this.rawPlanOptions = response.Data;
          // let allPlanOptions = {
          //   basic: [
          //   ],
          //   pro: []
          // }
          if (!this.purchaseService.validProducts)
            this.purchaseService.validProducts = []
          response.Data.forEach(el => {
            if (!this.allPlanOptions[el.PackageType])
              this.allPlanOptions[el.PackageType] = []
            let tempPackage = {
              Duration: el.Duration,
              PackageName: el.Package?.PackageName || null,
              Price: "",
              planName: el.PackageType,
              priceMicros: 0,
              isPackageAvailable: false,
              durationText: "",
              currency: "",
              priceText: "",
              monthlyPrice: ""
            }
            if (el.Package) {
              let packageName = this.devicePlatform === 'android' ? el.Package.PackageName.toLowerCase() : el.Package.PackageName
              let tempProduct = this.purchaseService.getProduct(packageName)
              // alert(tempProduct.pricing.currency)
              if (tempProduct) {
                tempProduct["countryCode"] ? this.purchaseService.countryCode = tempProduct["countryCode"] : null
                this.purchaseService.validProducts.push(tempProduct)
              }
              console.log("validProducts => ", tempProduct)
            }
            if (el.Package && this.purchaseService.validProducts && this.purchaseService.validProducts.length > 0) {
              let packageName = this.devicePlatform === 'android' ? el.Package.PackageName.toLowerCase() : el.Package.PackageName
              let registeredPackage = this.purchaseService.validProducts.find(t => t.id === packageName)
              if (registeredPackage) {
                tempPackage.isPackageAvailable = true //registeredPackage.
                tempPackage.Price = registeredPackage.pricing.price
                tempPackage.priceMicros = registeredPackage.pricing.priceMicros
                let calculatedPrice = tempPackage.priceMicros / 1000000
                tempPackage.priceText = calculatedPrice.toFixed(2)
                try {
                  tempPackage.monthlyPrice = new Intl.NumberFormat(langCulture, { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: registeredPackage.pricing.currency }).format(calculatedPrice / el.Duration).toString()
                } catch (error) {
                  tempPackage.monthlyPrice = (calculatedPrice / el.Duration).toFixed(2)
                }
                tempPackage.currency = registeredPackage.pricing.price.replace(/\d+/g, "").replace(".", "").replace(",", "").replace(" ", "")
              } else

                if (this.appConstants.orbitConstants.inreview && this.appConstants.orbitConstants.inreviewUsers.some(t => t === this.accountService.userData?.UserRoleId)) {
                  let pkg = this.purchaseService.validProducts[0]
                  tempPackage.PackageName = pkg.id
                  tempPackage.Price = pkg.pricing.price
                  tempPackage.priceMicros = pkg.pricing.priceMicros
                  tempPackage.isPackageAvailable = true
                } else {
                  tempPackage.isPackageAvailable = false
                }
            } else
              if (this.appConstants.orbitConstants.inreview && this.appConstants.orbitConstants.inreviewUsers.some(t => t === this.accountService.userData?.UserRoleId) && this.purchaseService.validProducts && this.purchaseService.validProducts.length > 0) {
                let pkg = this.purchaseService.validProducts[0]
                tempPackage.PackageName = pkg.id
                tempPackage.Price = pkg.pricing.price
                tempPackage.priceMicros = pkg.pricing.priceMicros
                tempPackage.isPackageAvailable = true
              } else {
                tempPackage.isPackageAvailable = false
              }
            tempPackage.durationText = this.translate.instant(`plans_pricing.${el.Duration == 1 ? 'duration_month' : 'duration_months'}`, { value: el.Duration })
            this.allPlanOptions[el.PackageType].push(tempPackage)
          });
          let planList = this.allPlanOptions[this.selectedPlan].filter(t => t.isPackageAvailable);
          console.log(planList)
          if (planList.length > 0) {
            planList.sort((a, b) => a.Duration > b.Duration ? -1 : a.Duration < b.Duration ? 1 : 0)
            this.selectedPrice = planList[0]
          } else
            this.showLimitExceededAlert()
        },
        error: err => {
          this.loading.hideLoading()
        }
      }
    )
  }


  showLimitExceededAlert(tryCount = 5) {
    if (this.exceededPlanModal)
      this.exceededPlanModal.present().then(ev => {
        this.exceededPlanModal.onDidDismiss().then(onDismiss => {

        })
      })
    else
      setTimeout(() => {
        if (tryCount > 0) {
          tryCount--
          this.showLimitExceededAlert(tryCount)
        }
      }, 500);
  }

  selectPrice(item) {
    this.selectedPrice = item
  }

  startPurchaseAction() {
    if (this.selectedPrice?.isPackageAvailable)
      this._CompletePurchase(this.selectedPrice)
    else
      this.showLimitExceededAlert()
  }
  _selectedPlanModel: string
  private _CompletePurchase(ev: ISelectedPlanModel) {
    DoktarAppsFlyer.AfEventLog(AppsflyerAnalyticEvents.initiated_checkout, ev)
    let farmIds = this.farmList.map(t => t.TempFarmAttId).sort().join("_");
    let tempControlStr = farmIds + ":" + ev.PackageName + ":" + ev.Duration
    if (tempControlStr === this._selectedPlanModel) {
      // alert("aynı paket alma")
      return
    }
    else {
      this._selectedPlanModel = tempControlStr;
      this.loading.showLoading()
      let payload: ICalculationFarmModel[] = this.farmList.map(t => { return { Duration: ev.Duration, FarmArea: t.CalculatedArea, SelectedPackage: ev.planName, TempFarmAttId: t.TempFarmAttId } })
      let farms = this.farmList.map(t => t.FarmName)
      this.purchaseService.purchaseConsumable(ev.PackageName, payload, farms).subscribe({
        next: response => {
          // this.farmList = []
          this._selectedPlanModel = "";
          this.loading.hideLoading()
          this.dismiss({}, "purchase_completed")
        },
        error: error => {
          this.loading.hideLoading()

          if (error.state !== 'order:cancelled') {
            let buttons: Array<IButtonOpts> = [{
              callback: () => {
                // this.dismiss(null, "purchase_success")
              },
              id: "purchase-alert-cancel-btn",
              color: "dark",
              fill: "clear",
              text: this.translate.instant("plans_pricing.alert_close_btn")
            }]
            this.alertService.PresentAlert({
              buttons,
              description: this.translate.instant("plans_pricing." + error.state.replace(":", "_")),
              title: this.translate.instant("plans_pricing.fail_alert_title")
            }, true, false).then()
            if (error.state === "order:error_billing") {
              this._selectedPlanModel = "";
            }
          } else {
            this._selectedPlanModel = "";
          }
        }
      })
    }
  }

  createContactRequest() {
    let payload = {
      TempFarmAttIds: this.farmList.map(t => t.TempFarmAttId),
      PlanName: this.selectedPrice ? this.selectedPrice.planName : "",
      Duration: this.selectedPrice ? this.selectedPrice.Duration : -1,
      Preference: this.selectedContactOption.key
    }
    this.purchaseService.RequestFile(payload).subscribe(res => {
      if (res.Data) {
        this.alertService.PresentAlert({
          buttons: [{
            callback: () => {
              this.dismiss(null, "contactRequestSended")
            },
            id: "purchase-contact-us-completed-btn",
            color: "primary",
            fill: "solid",
            text: this.translate.instant("plans_pricing.contact_us_success_btn")
          }],
          description: this.translate.instant("plans_pricing.contact_us_success_desc"),
          title: this.translate.instant("plans_pricing.contact_us_success_title"),
          // image: "./../../../../../assets/images/rocket.svg"
        }, false).then(alert => { })
      } else {
        this.toastService.PresentBasicToast({
          message: this.translate.instant("http_request_unexpected_error"),
          type: Message_Type.DANGER
        })
      }
    }, err => {
      this.toastService.PresentBasicToast({
        message: this.translate.instant("http_request_unexpected_error"),
        type: Message_Type.DANGER
      })
    })
  }

  addFieldRedirection() {
    this.dismiss(null, "add_field_redirection")
    this.router.navigateByUrl("/add-field")
  }
  goHomeRedirection() {
    this.dismiss(null, "home_page_redirection")
    this.router.navigateByUrl("/")
  }
}