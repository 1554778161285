import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthPage } from './auth.page';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { SCREEN_NAME_BY_COMPONENT_NAME } from 'src/app/core/constants/url-screenname.constant';

const routes: Routes = [
  // {
  //   path: '',
  //   component: AuthPage,
  //   children: [
  //     {
  //       path: "login",
  //       component: LoginComponent
  //     },
  //     {
  //       path: "register",
  //       component: RegisterComponent
  //     }      
  //     ,{
  //       path: 'auth',
  //       component: AuthPage,
  //       pathMatch: 'full'
  //     }
  //   ]
  // },
  // {
  //   path: 'auth',
  //   redirectTo: '/auth',
  //   pathMatch: 'full'
  // },



  // {
  // path: 'auth',
  // component: AuthPage,
  // children: [
  {
    path: '',
    component: AuthPage
  },
  {
    path: 'auth/login',
    component: LoginComponent,
    canActivate: [() => {
      var analyticService = inject(AnalyticService);
      analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.LoginComponent.screenName
    }]
    // loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
    // loadComponent: () => import('./components/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'auth/register',
    component: RegisterComponent,
    canActivate: [() => {
      var analyticService = inject(AnalyticService);
      analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.RegisterComponent.screenName
    }]
  }
  // {
  //   path: '',
  //   redirectTo: '/auth',
  //   pathMatch: 'full'
  // }
  //   ]
  // },
  // {
  //   path: '',
  //   redirectTo: '/auth',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthPageRoutingModule { }
