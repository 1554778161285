<ion-header style="background-color: #F4BB57;">
</ion-header>
<ion-content class="onboarding-main-content">
  <swiper [slidesPerView]="1"  [direction]="'horizontal'" class="farm-map-slider" [effect]="'creative'" [grabCursor]="true" [centeredSlides]="true"
    [slidesPerView]="'auto'" [creativeEffect]="swiperEffectStyle" [pagination]="true" class="onboarding-swiper">
    <ng-template swiperSlide *ngFor="let item of list;let i=index;">
      <div class="onboarding-container">
        <div class="onboarding-image" >
          <!-- [ngStyle]="{backgroundImage: 'url('+ item.imageUrl +')'}" -->
          <img [src]="item.imageUrl" alt="" >
          <div class="bottom-degrade"></div>
        </div>
        <div class="onboarding-description" (click)="expandParagraph(item.index)" [id]="'page-onboarding-description-area-'+i">
          <div [ngClass]="expandItem == item.index ? 'expand' : ''">
            <ion-text color="white">
              <h1 class="onboarding-title-text">{{item.title | translate}}</h1>
            </ion-text>
            <ion-text color="white">
              <p class="onboarding-description-text">
                {{item.description | translate}}
              </p>
            </ion-text>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
  <div class="footer-area">
    <ion-grid fixed class="full-height p-vertical-0">
      <ion-row class="p-vertical-0">
        <ion-col size="12" class="p-vertical-0">
          <ion-button class="start-btn" (click)="continueToApp()" id="page-onboarding-continue-btn" expand="block" fill="solid" shape="round">
            {{"onboarding.start_btn" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>