import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { TranslateService } from '@ngx-translate/core';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { ILoginModel } from 'src/app/core/models/interfaces/login-model';
import { ListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { AccountService } from 'src/app/core/services/account.service';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { FarmService } from 'src/app/core/services/farm.service';
import { IntlPhoneInputService } from 'src/app/core/services/intl-phone.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { OnboardingStorageService } from 'src/app/core/services/onboarding-storage.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { LocationService } from './../../../../core/services/location.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './../../auth.page.scss'],
})
export class LoginComponent implements OnInit {
  @Output() onLogin: EventEmitter<any> = new EventEmitter<any>()
  @Input() idPrefix: string

  phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();

  countryCodes: string = ""
  selectedCountry
  passwordVisibility: boolean = false
  componentType = ComponentType.FULL_INPUT_SEPERATED
  loginForm: FormGroup<{ phone: FormControl<string>, password: FormControl<string> }>;
  isPhoneValid: boolean = true
  @Output() socialLogin: EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: UntypedFormBuilder, private loadingService: LoadingService,
    private intlPhoneService: IntlPhoneInputService,
    private farmService: FarmService, private modalService: ModalService,
    private translateService: TranslateService,
    private analyticService: AnalyticService,
    private onboardingStorageService: OnboardingStorageService,
    private storageService: StorageService,
    private router: Router, private changeDetRef: ChangeDetectorRef,
    private langService: LanguageService, private toastService: ToastService, private accountService: AccountService,
    private alertService: ClientAlertService) {
    let rawList = translateService.getBrowserCultureLang().split("-")
    let _countryCodes = "us";
    if (rawList.length > 1) {
      let code = rawList[1].toLowerCase();
      _countryCodes = code + "," + _countryCodes
    } else {
      _countryCodes = rawList[0] + "," + _countryCodes
    }

    if (LanguageService.CountryCodeFromIP && LanguageService.CountryCodeFromIP !== '') {
      this.countryCodes = LanguageService.CountryCodeFromIP + "," + this.countryCodes
      this.generateMaskAndPlaceholder()
    }
    else
      LocationService.GetLocationFromIp().then(res => {
        if (res?.country_code)
          _countryCodes = res.country_code.toLowerCase() + "," + _countryCodes
        this.countryCodes = _countryCodes
        this.generateMaskAndPlaceholder()
      }).catch(err => {
        this.countryCodes = _countryCodes
        this.generateMaskAndPlaceholder()
      })
    // if (LanguageService.CountryCodeFromIP && LanguageService.CountryCodeFromIP !== '')
    //   this.countryCodes = LanguageService.CountryCodeFromIP + "," + this.countryCodes

  }
  placeholder
  generateMaskAndPlaceholder() {
    this.intlPhoneService.GetCountryCodes().subscribe({
      next: (ev: Array<any>) => {

        if (!this.selectedCountry)
          this.selectedCountry = ev.find(t => t.iso2 == this.countryCodes.split(",")[0])

        let results = this.intlPhoneService.GeneratePlaceholderAndMask(this.selectedCountry)
        let splittedMask = results.mask.split("").map(t => {
          return Number.parseInt(t) >= 0 ? /\d/ : t
        })
        this.phoneMask = { mask: splittedMask }
        this.dialCode = this.selectedCountry.dialCode
        this.placeholder = results.placeholder
      }
    })
  }

  ngOnInit() {
    this.setupForm()
  }
  setupForm() {
    this.loginForm = this.formBuilder.group({
      phone: ["", [OrbitFormValidators.phoneNumberValidator(), Validators.required]],
      password: ["", [Validators.required]],
    });
    this.loginForm.valueChanges.subscribe(res => {

    })
  }
  currentCountryInfo
  dialCode
  lastPhonenumber: string = ""

  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        this.generateMaskAndPlaceholder()
        this.storageService.set(StorageKeys.PHONE_CODE, { dialCode: this.dialCode }).then(res => { })
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.lastPhonenumber = phone
        if (this.currentCountryInfo) {
          this.loginForm.patchValue({ phone: phone }, { emitEvent: true, onlySelf: true })
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          this.isPhoneValid = false;
          this.loginForm.patchValue({ phone: null }, { emitEvent: true, onlySelf: true })
          // this.loginForm.controls["phone"].markAsDirty()
        } else {
          this.isPhoneValid = true;
        }
        break;
      default:
        break;
    }
  }

  areCrendentialsWrong: boolean = false
  errorMessage = "auth.wrong_credts_error"
  onSubmit() {
    this.areCrendentialsWrong = false
    if (this.loginForm.valid) {
      this.loadingService.showLoading()
      console.log(this.loginForm.get("phone").value)
      let phone = this.loginForm.get("phone").value.split("").map(t => !Number.isNaN(Number.parseInt(t)) ? t : null).filter(t => t).join("")
      let payload: ILoginModel = {
        email: this.dialCode + phone + "",
        password: this.loginForm.get("password").value
      }
      this.accountService.Login(payload).then(res => {
        this.onboardingStorageService.completeAppOnboarding()
        // this.purchaseService.registerProducts();
        this.farmService.GetAllFarms({ refreshFarms: true, showLoading: true });
        this.onLogin.emit(true)
        this.router.navigate(["/tabs/map"], { replaceUrl: true }).then(ev => {
          this.loadingService.hideLoading();
        })
        // this.toastService.PresentBasicToast({
        //   message: this.langService.instant("auth.login_succeed")
        // }).then(response => {
        // })
      }).catch(err => {
        this.loadingService.hideLoading();
        this.areCrendentialsWrong = true
        err.Message
        // : "phone-number-or-password-is-incorrect"
        let errMessage = typeof err === 'string' ? err : (err.message || err.Message)
        if (this.translateService.instant(`auth.${errMessage}`) !== `auth.${errMessage}`)
          this.errorMessage = `auth.${errMessage}`
        else
          this.errorMessage = "auth.wrong_credts_error"
        console.log("login error => ", err)
        // let errMessage = typeof err === 'string' ? err : (err.message || err.Message)
        // this.toastService.PresentBasicToast({
        //   message: this.translateService.instant(`auth.${errMessage}`)
        // }).then(response => {

        // })
      })
    } else {
      this.areCrendentialsWrong = true
      // this.toastService.PresentBasicToast({
      //   message: this.translateService.instant("auth.login_not_valid")
      // }).then(response => {

      // })
    }
  }

  forgetPassword() {
    let opts = ModalService.defaultOptionsTransparent;
    opts.cssClass = "modal-fullscreen modal-auth"
    this.modalService.presentModal({
      component: ForgetPasswordComponent,
      componentProps: {},
      options: opts
    }).then(modalFP => {
      modalFP.modalObj.onDidDismiss().then(dismiss => {

      })
    })
  }
  openModal() {
    this.langService.openOptionsMenu()
  }

  loginWithSocial(ev) {
    this.loadingService.showLoading();
    let payload = {
      SocialType: ev.Type,
      SocialToken: ev.Id
    }
    this.accountService.loginWithSocial(payload).then(res => {
      this.loadingService.hideLoading();
      this.farmService.GetAllFarms({ refreshFarms: true, showLoading: false });
      this.onLogin.emit(true)
      this.router.navigate(["/tabs/map"], { replaceUrl: true })
      this.loadingService.hideLoading();
      this.toastService.PresentBasicToast({
        message: this.translateService.instant("auth.login_succeed")
      }).then(response => {
      })
    }).catch(e => {
      this.loadingService.hideLoading();
      this.socialLogin.emit(ev);
      // let scope = this
      // let opts: IBaseAlertModel = {
      //   buttons: [{
      //     callback: () => {
      //       scope.socialLogin.emit(ev);
      //     },
      //     color: "primary",
      //     fill: "solid",
      //     text: this.langService.instant("account.register")
      //   },
      //   {
      //     callback: () => { },
      //     color: "dark",
      //     fill: "clear",
      //     text: this.langService.instant("account.logout_alert_btn_cancel")
      //   }
      //   ],
      //   title: this.langService.instant("auth.want-register")
      // }
      // this.alertService.confirmAlert(opts).then(res => { });

    })
  }

  setPwd(event) {
    // this.password = event.target.value;
    this.loginForm.patchValue({ password: event.target.value }, { emitEvent: true, onlySelf: true })
  }

  openFlagsModal() {
    this.loadingService.showLoading()
    this.intlPhoneService.GetCountryCodes().subscribe(codesRes => {
      this.modalService.presentListModal({
        hasConfirmBtn: false,
        isFullscreen: true,
        isMultiple: false,
        showSearchbar: true,
        searchPlaceholder: this.translateService.instant("general.search_countries"),
        itemList: codesRes.map(t => new ListModalModel({ id: t.iso2, data: t, value: `${t.name} (+${t.dialCode})`, icon: environment.countryFlags + t.iso2.toUpperCase() + '.svg', iconSource: "image", iconStyle: "width:28px;height:20px", iconSlot: "start" })),
        title: this.translateService.instant("general.countries"),
        confirmBtnText: null,
      }).then(resPresenter => {
        this.loadingService.hideLoading()
        resPresenter.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.role == "itemSelected") {
            this.selectedCountry = dismiss.data.selectedItem.data
            this.countryCodes = this.selectedCountry.iso2
            this.generateMaskAndPlaceholder()
          }
        })
      })
    })
  }
  inputChanged(ev) {
    console.log(ev)
  }
}