import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AnalyticService } from '../services/analytic.service';
import { AccountService } from '../services/account.service';

@Directive({ selector: '[routerLink]' })
export class RouterLinkListenerDirective {
    @HostListener('click', ['$event'])
    onClick(e) {
        this.analyticService.logTapEvent(e, this.elRef, "navigate", this.id)
        // console.log("click catched", e)
        e.preventDefault();
        // console.log("click catched elRef", this.elRef)
        // this.analyticService.logEvent
    }

    @Input() id:string = "router-link"
    /**
     *
     */
    constructor(private elRef: ElementRef, private analyticService: AnalyticService) { }
}