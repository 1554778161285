import { Injectable } from "@angular/core";
import { Camera } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { Observable } from "rxjs";
import { AndroidPermissionService, PermissionType } from "./android-permission.service";
import { ClientAlertService } from "./client-alert.service";
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { IButtonOpts } from "../models/interfaces/modal-components-models";
import { TranslateService } from "@ngx-translate/core";

type TPermissionTypes = "location" | "camera" | "photos"

const PLATFORM = Capacitor.getPlatform()

@Injectable({
    providedIn: "root"
})
export class PermissionService {
    public static instanceOfService: PermissionService
    /**
     *
     */
    constructor(private androidPermissionService: AndroidPermissionService, private translate: TranslateService, private alertService: ClientAlertService, private openNativeSettings: OpenNativeSettings) {
        PermissionService.instanceOfService = this
    }

    public static async CheckAndRequestLocation({ granted, rejected }) {
        try {
            await Geolocation.checkPermissions().then(async permissionStatus => {
                if (permissionStatus.location == "granted") {
                    return granted()
                } else {
                    if (PLATFORM.toLocaleLowerCase() == "ios" || PLATFORM.toLocaleLowerCase() == "android")
                        await Geolocation.requestPermissions().then(requestStatus => {
                            if (requestStatus.location == "granted")
                                return granted()
                            else {
                                // PermissionService.instanceOfService.permissionDeniedAlert("location")
                                return rejected(requestStatus)
                            }
                        })
                    else {
                        return granted() //rejected("not_available_for_web")
                    }
                    // let requestStatus = (await Geolocation.requestPermissions()).location

                    // if (requestStatus == "granted")
                    //     return granted()
                    // else
                    //     return rejected(requestStatus)
                }
            })
            // let permissionStatus = (await Geolocation.checkPermissions()).location
            // if (permissionStatus == "granted") {
            //     return granted()
            // } else {
            //     let requestStatus = (await Geolocation.requestPermissions()).location

            //     if (requestStatus == "granted")
            //         return granted()
            //     else
            //         return rejected(requestStatus)
            // }
        } catch (error) {
            return rejected("")
        }
    }

    public async CheckAndRequestLocaion({ granted, rejected }): Promise<void> {
        try {
            await Geolocation.checkPermissions().then(permissionStatus => {

            })
        } catch (err) {

        }
    }

    requestCameraPermission(type: string): Observable<boolean> {
        return new Observable(observer => {
            switch (PLATFORM) {
                case "android":
                    try {
                        Camera.checkPermissions().then(e => {
                            if (type == "CAMERA") {
                                if (e.camera == "granted") {
                                    observer.next(true)
                                } else {
                                    this.androidPermissionService.requestPermisson(PermissionType.Camera).subscribe(res => {
                                        if (res) {
                                            observer.next(true);
                                        } else {
                                            this.permissionDeniedAlert("camera")
                                            observer.next(false);
                                        }
                                    }, err => {
                                        observer.next(false);
                                    });
                                }
                            } else if (type == "PHOTOS") {
                                if (e.photos == "granted") {
                                    observer.next(true)
                                } else {
                                    this.androidPermissionService.requestPermisson(PermissionType.Storage).subscribe(res => {
                                        if (res) {
                                            observer.next(true);
                                        } else {
                                            this.permissionDeniedAlert("photos")
                                            observer.next(false);
                                        }
                                    }, err => {
                                        this.permissionDeniedAlert("photos")
                                        observer.next(false);

                                    });
                                }
                            }
                        })
                    } catch (error) {

                    }
                    break;
                case "ios":
                    Camera.checkPermissions().then(permissionCheck => {
                        // if (type == "CAMERA") {
                        // } else if (type == "PHOTOS") { 

                        // }
                        Camera.requestPermissions().then(e => {
                            if (e[type.toLowerCase()] == "granted") {
                                observer.next(true);
                            } else {
                                this.permissionDeniedAlert(type.toLowerCase() as TPermissionTypes)
                                observer.next(false);
                            }
                        })
                    })
                    break;
                case "web":
                    return observer.next(true)
                    break;
            }
        })
    }

    requestVideoPermission(): Observable<boolean> {
        return new Observable(observer => {
            switch (PLATFORM) {
                case "android":
                    try {
                        Camera.checkPermissions().then(e => {
                            if (e.photos == "granted") {
                                observer.next(true)
                            } else {
                                this.androidPermissionService.requestPermisson(PermissionType.Video).subscribe(res => {
                                    if (res) {
                                        observer.next(true);
                                    } else {
                                        this.permissionDeniedAlert("camera")
                                        observer.next(false);
                                    }
                                }, err => {
                                    this.permissionDeniedAlert("camera")
                                    observer.next(false);

                                });
                            }
                        });
                    } catch (error) {
                        observer.next(false);
                    }
                    break;
                case "ios":
                    Camera.requestPermissions().then(e => {
                        if (e.camera == "granted") {
                            observer.next(true);
                        } else {
                            this.permissionDeniedAlert("camera")
                            observer.next(false);
                        }
                    })
                    break;
                case "web":
                    return observer.next(true)
                    break;
            }
        });
    }

    permissionDeniedAlert(type: TPermissionTypes) {
        let buttons: IButtonOpts[] = [
            {
                callback: () => {
                    this.openNativeSettings.open("application_details").then()
                },
                id: "device-permissions-open-settings-btn",
                color: "primary",
                fill: "solid",
                text: this.translate.instant("permission_alerts.open_settings_btn")
            },
            {
                callback: () => { },
                id: "device-permissions-close-alert-btn",
                color: "dark",
                fill: "clear",
                text: this.translate.instant("permission_alerts.close_btn")
            }]
        this.alertService.PresentAlert({
            buttons,
            description: this.translate.instant(`permission_alerts.${type}_permisson_denied_desc`),
            title: this.translate.instant(`permission_alerts.${type}_permisson_denied_title`)
        }, false).then()
    }

}