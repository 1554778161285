import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'isFormValid',
  pure: false
})
export class IsFormValidPipe implements PipeTransform {

  transform(formGroup: UntypedFormGroup, formName: string, errorTypes?: any[]): boolean {
    if (!formGroup)
      return false
    if (!formGroup.get(formName)?.dirty || !formGroup.get(formName)?.touched)
      return false
    let control: AbstractControl = formGroup.controls[formName]
    // var isDirty = control.valid && control.dirty
    if (Array.isArray(errorTypes) && errorTypes.length > 0) {
      if (!control.errors)
        return false
      let errorKeys = []
      Object.keys(control.errors).forEach(el => {
        // if (control.errors[el] === 'minlength')
        if (control.errors[el])
          errorKeys.push(el)
      })
      if (errorKeys.length == 0)
        return false
      var filteredErrorKeys = errorKeys.filter(t => errorTypes.find(a => a == t)) //errorTypes.some(t => errors.find(a => a == t))   // errors.some(t => errorTypes.find(a => a == t))
      return filteredErrorKeys && filteredErrorKeys.length > 0
    }
    else
      return false
  }

}
