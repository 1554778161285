import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SplashScreenComponent } from './splash-screen.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule
    ],
    exports: [SplashScreenComponent],
    declarations: [SplashScreenComponent],
    providers: [],
})
export class SplashScreenModule { }
