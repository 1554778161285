import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({ selector: '[imageLoad]' })
export class ImageLoadDirective implements OnInit {
    // (ionError)="imgStatus = 'error'" (ionImgDidLoad)="imgStatus = 'loaded'"

    @Input() loadingImage = "assets/images/rocket.svg"
    @Input() errorImage = "assets/images/not_found_image_1.svg"

    @HostListener("ionImgDidLoad", ["$event"])
    ionImgDidLoad(event) {
        // this.el.nativeElement.style.visibility = "visible";
        // this.clonedElement.style.display = "none"
        this.toggleOriginal()
    }

    @HostListener("load", ["$event"])
    load(event) {
        // this.el.nativeElement.style.visibility = "visible";
        // this.clonedElement.style.display = "none"
        this.toggleOriginal()
    }

    @HostListener("ionError", ["$event"])
    ionError(event) {
        // this.el.nativeElement.style.visibility = "visible";
        // this.clonedElement.style.display = "none"
        this.toggleOriginal()
        this.el.nativeElement.src = this.errorImage
    }

    @HostListener("error", ["$event"])
    error(event) {
        // this.el.nativeElement.style.visibility = "visible";
        // this.clonedElement.style.display = "none"
        this.toggleOriginal()
        this.el.nativeElement.src = this.errorImage
    }

    previousPosition: string = "inherit"
    previousVisibility: string = "visible"
    parentElement: any
    loaderElement: any
    // clonedElement
    constructor(private renderer: Renderer2, private el: ElementRef) {
    }
    ngOnInit(): void {
        // this.clonedElement = this.el.nativeElement.cloneNode(true);
        // this.clonedElement.src = this.loadingImage
        // this.clonedElement.style.height = "100%";
        // this.clonedElement.style.maxHeight = "150px"
        this.loaderElement = document.createElement("div")
        this.loaderElement.className = "loader-animation"
        // this.loaderElement.style.display = "flex";
        // this.loaderElement.style.justifyContent = "center";
        // this.loaderElement.appendChild(this.clonedElement);

        this.el.nativeElement.parentElement.insertBefore(this.loaderElement, this.el.nativeElement.nextSibling)
        this.previousVisibility = this.el.nativeElement.style.visibility;
        this.el.nativeElement.style.visibility = "hidden";
        this.previousPosition = this.el.nativeElement.style.position || 'inherit';
        this.el.nativeElement.style.position = "fixed"
    }

    toggleOriginal() {
        this.el.nativeElement.style.visibility = "visible"; //this.previousVisibility
        this.el.nativeElement.style.position = "inherit"; //this.previousPosition
        // this.clonedElement.style.display = "none"
        if (this.el.nativeElement.parentElement.hasChildNodes())
            try {
                this.el.nativeElement.parentElement.removeChild(this.loaderElement)
            } catch (error) {

            }
    }

}