import { Injectable } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { FarmNoteResponse } from "../models/classes/farm-note-response";
import { FarmNotes, FlagModel, IFlagSaveModel } from "../models/classes/farm-notes";
import { SatelliteImages } from "../services/class/satellite-images";
import { HttpRequestTypes } from "../models/enums/http-request-types";
import { IFarmModel } from "../models/interfaces/farm-model";
import { IFarmEditModel, IFarmSaveModel } from "../models/interfaces/farm-save-model";
import { IParcelRequestModel, IParcelResponseModel } from "../models/interfaces/parcel-model";
import { ISatelliteAnalysisResponse } from "../models/interfaces/satellite-analysis-response";
import { ISatelliteImages } from "../models/interfaces/satellite-images.interface";
import { ISatelliteRequestModel, ISatelliteResponseModel } from "../models/interfaces/satellite-models";
import { FarmShareResponseModel, IFarmSharedAppModel, MainShareResponse } from "../models/interfaces/share-models";
import { IAvailableTimeModel } from "../models/interfaces/spraying-hours";
import { ClientBaseApiService } from "./client-base-api.service";
import { ProbSpotDetection } from "../models/interfaces/prob-spot-detection.interface";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { IPastSprayings } from "../services/class/spraying";

@Injectable({
    providedIn: "root"
})
export class FarmApiService {
    /**
     *
     */
    constructor(private clientApi: ClientBaseApiService) {

    }

    public GetAll(): Observable<IGenericResponse<Array<IFarmModel>>> {
        // let url = environment.baseApiUrl + "get/fieldwork/farms?isAllFarm=true"
        let url = environment.gatewayApiUrl + "v2/api/farm/list/by/orbit/currentuser"
        return this.clientApi.baseApiCall<Array<IFarmModel>>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public FarmSave(payload: IFarmSaveModel): Observable<IGenericResponse<number>> {
        let url = environment.baseApiUrl + "update/fieldwork/farms";
        return this.clientApi.baseApiCall<number>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public ParselRequest(payload: IParcelRequestModel): Observable<IGenericResponse<IParcelResponseModel>> {
        let url = `${environment.baseApiUrl}get/parcel/detail?placeId=${payload.placeId}&ada=${payload.ada}&parsel=${payload.parsel}`
        return this.clientApi.baseApiCall<IParcelResponseModel>({ url, payload: null, requestType: HttpRequestTypes.POST })
    }

    // public FarmEditRequest(payload: IFarmEditModel): Observable<IGenericResponse<IParcelResponseModel>> {
    //     let url = environment.baseApiUrl + "update/farm/information";
    //     return this.clientApi.baseApiCall<IParcelResponseModel>({ url, payload, requestType: HttpRequestTypes.POST })
    // }
    public FarmEditRequest(payload: IFarmEditModel): Observable<IGenericResponse<boolean>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/update/farm/property";
        return this.clientApi.baseApiCall<boolean>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public ChangeArchiveStatus(tempFarmAttId, status): Observable<IGenericResponse<any>> {
        let url = `${environment.baseApiUrl}get/swap/activetoarchieve?tempFarmAttId=${tempFarmAttId}&isArchieved=${status}`
        return this.clientApi.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET });
    }

    // let url = environment.baseApiUrl + "save/farm/flags";
    public AddNotes(payload: IFlagSaveModel): Observable<IGenericResponse<any>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/flags/save";
        return this.clientApi.baseApiCall<any>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public GetNotes(payload: FarmNotes): Observable<IGenericResponse<Array<FarmNoteResponse>>> {
        let url = environment.baseApiUrl + "get/farm/flagsv2_"
        return this.clientApi.baseApiCall<Array<FarmNoteResponse>>({ url, payload: payload, requestType: HttpRequestTypes.POST })
    }

    public RemoveNote(noteId: number): Observable<IGenericResponse<number>> {
        let url = environment.baseApiUrl + "remove/flag"
        return this.clientApi.baseApiCall<number>({ url, payload: { flagId: noteId }, requestType: HttpRequestTypes.POST });
    }

    public GetImportList(): Observable<IGenericResponse<Array<FarmShareResponseModel>>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/importable/farm/list" //`${environment.baseApiUrl}get/farm/share/campaign/list`;
        return this.clientApi.baseApiCall<Array<FarmShareResponseModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public RemoveImportedFarm(tempFarmAttId: number, farmerSubscriberId: number): Observable<IGenericResponse<any>> {
        let url = `${environment.baseApiUrl}remove/shared/0/farm?tempFarmAttId=${tempFarmAttId}&farmerSubscriberId=${farmerSubscriberId}`
        return this.clientApi.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public ShareFarm(payload): Observable<IGenericResponse<any>> {
        let url = `${environment.baseApiUrl}share/farm`;
        return this.clientApi.baseApiCall<any>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public GetSatelliteList(payload: ISatelliteRequestModel): Observable<IGenericResponse<ISatelliteResponseModel>> {
        let url = `${environment.baseApiUrl}get/satelite/list`;
        return this.clientApi.baseApiCall<ISatelliteResponseModel>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public GetSatelliteAnalysis(id: number, type: string): Observable<IGenericResponse<Array<ISatelliteAnalysisResponse>>> {
        let url = `${environment.baseApiUrl}get/satellite/analyze/response?id=${id}&type=${type}`;
        return this.clientApi.baseApiCall<Array<ISatelliteAnalysisResponse>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetSprayingHours({ lat, lng }): Observable<IGenericResponse<IAvailableTimeModel>> {
        let url = `${environment.baseApiUrl}get/available/time?lat=${lat}&lng=${lng}&isOneWeek=true&isAllData=true`
        return this.clientApi.baseApiCall<IAvailableTimeModel>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetPastSprayings(tempFarmAttId): Observable<IGenericResponse<IPastSprayings[]>> {
        let url = `${environment.gatewayApiUrl}v2/api/device/list/spraying/${tempFarmAttId}`;
        return this.clientApi.baseApiCall<IPastSprayings[]>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public SaveSprayingData(props: { Id, Date, ChemicalTypeId, TempFarmAttId,AppliedDose,UnitId }) {
        let url = environment.gatewayApiUrl + "v2/api/device/save/spraying"
        return this.clientApi.baseApiCall<boolean>({ url, payload: props, requestType: HttpRequestTypes.POST })
    }

    public SearchPlaces(query, lang, types = 'place'): Observable<IGenericResponse<any>> {
        let url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + query + ".json?limit=10&proximity=ip&types=" + types + "%2Cpostcode%2Caddress&access_token="
            + environment.mapbox.accessToken + "&language=" + lang;
        return this.clientApi.externalApiCall<any>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public SearchPlcesWithoutType(query, lang, limit = 10): Observable<any> {
        let url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + query + ".json?limit=" + limit + "&proximity=ip&access_token="
            + environment.mapbox.accessToken + "&language=" + lang;
        return this.clientApi.externalApiCall<any>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public SaveAsFree(id): Observable<IGenericResponse<boolean>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/save/as/free";
        return this.clientApi.baseApiCall<boolean>({ url, payload: { TempFarmAttId: id }, requestType: HttpRequestTypes.POST })
    }

    //#region Satellite

    /**
     * gets 2 months range value, starts with lastDate value
     * @param tempFarmAttId 
     * @param lastDate YYYY-MM-DD
     * @param source
     * @returns 
     */
    public GetSatelliteDates(tempFarmAttId: number, lastDate: string, source?): Observable<IGenericResponse<Array<SatelliteImages>>> {
        let url = `${environment.gatewayApiUrl}v2/api/satellite/get/dates` //?tempFarmAttId=${tempFarmAttId}&lastDate=${lastDate}`
        let queryParams: string[] = [];
        if (tempFarmAttId)
            queryParams.push(`tempFarmAttId=${tempFarmAttId}`)
        if (lastDate)
            queryParams.push(`lastDate=${lastDate}`)
        if (source)
            queryParams.push(`source=${source}`)
        if (queryParams.length > 0) {
            let queryString = queryParams.join("&");
            url = url + "?" + queryString
        }
        return this.clientApi.baseApiCall<Array<SatelliteImages>>({ url, payload: { tempFarmAttId, lastDate }, requestType: HttpRequestTypes.GET })
    }
    public GetNdmiDates(tempFarmAttId: number, lastDate: string): Observable<IGenericResponse<Array<SatelliteImages>>> {
        let url = `${environment.gatewayApiUrl}v2/api/satellite/get/ndmi/list` //?tempFarmAttId=${tempFarmAttId}&lastDate=${lastDate}`
        let queryParams: string[] = [];
        if (tempFarmAttId)
            queryParams.push(`tempFarmAttId=${tempFarmAttId}`)
        if (lastDate)
            queryParams.push(`lastDate=${lastDate}`)
        if (queryParams.length > 0) {
            let queryString = queryParams.join("&");
            url = url + "?" + queryString
        }
        return this.clientApi.baseApiCall<Array<any>>({ url, payload: { tempFarmAttId, lastDate }, requestType: HttpRequestTypes.GET })
    }

    public GetSatelliteImages(props: ISatelliteImages): Observable<IGenericResponse<ISatelliteImages>> {
        let url = `${environment.gatewayApiUrl}v2/api/satellite/get/images`
        return this.clientApi.baseApiCall<ISatelliteImages>({ url, payload: props, requestType: HttpRequestTypes.POST })
    }

    satelliteRequestQuery: Array<{ request: Observable<IGenericResponse<ISatelliteImages>>, subject: Subject<IGenericResponse<ISatelliteImages>> }> = []
    isRequestActive: boolean = false
    public GetSatelliteImagesAync(props: ISatelliteImages) {
        let url = `${environment.gatewayApiUrl}v2/api/satellite/get/images`
        let request = this.clientApi.baseApiCall<ISatelliteImages>({ url, payload: props, requestType: HttpRequestTypes.POST })
        let subject = new Subject<IGenericResponse<ISatelliteImages>>()
        this.satelliteRequestQuery.push({ request, subject })
        if (!this.isRequestActive) {
            this.isRequestActive = true
            this.sendRequest()
        }
        return subject.asObservable()
    }

    private sendRequest = () => {
        if (this.satelliteRequestQuery.length > 0) {
            let tempItem = this.satelliteRequestQuery[0]
            if (tempItem) {
                this.isRequestActive = true
                tempItem.request.subscribe({
                    next: next => {
                        tempItem.subject.next(next)
                        this.satelliteRequestQuery.shift()
                        tempItem.subject.complete()
                        this.sendRequest()
                    }, error: err => {
                        tempItem.subject.error(err)
                        this.satelliteRequestQuery.shift()
                        tempItem.subject.complete()
                        this.sendRequest()
                    }
                })
            } else {
                this.isRequestActive = false
            }
        } else {
            this.isRequestActive = false
        }
    }


    //#endregion

    public GetIapFreeFarm(tempFarmAttId): Observable<IGenericResponse<boolean>> {
        let url = environment.gatewayApiUrl + "v2/api/iap/use/claim?tempFarmAttId=" + tempFarmAttId
        return this.clientApi.baseApiCall<boolean>({ url, payload: {}, requestType: HttpRequestTypes.POST })
    }

    public DeleteFarm(tempFarmAttId): Observable<IGenericResponse<boolean>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/delete";
        return this.clientApi.strictedApiCall<boolean>({ url, payload: { tempFarmAttId }, requestType: HttpRequestTypes.POST })
    }

    public WhereMyFieldShared(tempFarmAttId): Observable<IGenericResponse<Array<IFarmSharedAppModel>>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/shared/list/by/tfaId?tempFarmAttId=" + tempFarmAttId;
        return this.clientApi.baseApiCall<Array<IFarmSharedAppModel>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public IsThereAnyDeletedFarm(): Observable<IGenericResponse<any>> {
        let url = environment.gatewayApiUrl + "v2/api/farm/shared/list/by/deleted/farmowner";
        return this.clientApi.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    //#region spot detection

    /**
     * 
     * @param tempFarmAttId number
     * @param imageDate string as yyyy-MM-dd
     * @returns 
     */
    public GetSpotDetectionList(tempFarmAttId: number, imageDate: string): Observable<IGenericResponse<Array<ProbSpotDetection>>> {
        let url = environment.gatewayApiUrl + `v2/api/spot/detection/list?tempFarmAttId=${tempFarmAttId}&imageOn=${imageDate}`
        return this.clientApi.baseApiCall<Array<ProbSpotDetection>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public UpdateSpotStatus(spotId: number, checkStatus: boolean): Observable<IGenericResponse<boolean>> {
        let url = environment.gatewayApiUrl + "v2/api/spot/detection/update/state"
        return this.clientApi.baseApiCall<boolean>({ url, payload: { Id: spotId, CheckState: checkStatus }, requestType: HttpRequestTypes.POST })
    }

    public DeleteSpotDetection(spotId: number, checkStatus: boolean): Observable<IGenericResponse<boolean>> {
        let url = environment.gatewayApiUrl + "v2/api/spot/detection/delete"
        return this.clientApi.baseApiCall<boolean>({ url, payload: { Id: spotId, CheckState: checkStatus }, requestType: HttpRequestTypes.POST })
    }

    //#endregion
}