import { Component, OnInit, Input } from '@angular/core';
import { Share } from '@capacitor/share';
import { TranslateService } from '@ngx-translate/core';
import { BaseAlertModal } from 'src/app/core/models/classes/base-alert-modal';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { IButtonOpts } from 'src/app/core/models/interfaces/modal-components-models';
import { ModalService } from 'src/app/core/services/modal.service';
import { AltBaseModal } from '../../class/alt-base-modal.component';

@Component({
  selector: 'orbit-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent extends AltBaseModal implements OnInit {
  @Input() buttonList: string[]
  @Input() mainTitle: string = null
  @Input() title: string = null
  @Input() description: string = null
  @Input() image: string = null
  @Input() buttons: Array<IButtonOpts> = []
  message: string
  buttonOptions: { [key: string]: IButtonOpts } = {
    "whatsapp": {
      color: "primary",
      fill: "solid",
      text: this.translate.instant("account.whatsapp_lbl"),
      icon: "logo-whatsapp",
      iconSource: "ionIcon",
      id: "contact-options-whatsapp",
      callback: (ev) => {
        let href = this.translate.instant("account.contact_us_whatsapp");
        if (this.message && this.message !== '') {
          let encodedText = encodeURI(this.message);
          href = href + "?text=" + encodedText;
        }
        window.location.href = href
        this.dismiss({}, 'callbackClicked')
      }
    },
    "email": {
      color: "primary",
      fill: "solid",
      icon: "email",
      iconSource: 'asset',
      text: this.translate.instant("account.email_lbl"),
      id: "contact-options-email",
      callback: (ev) => {
        let href = this.translate.instant("account.contact_us_email");
        if (this.message && this.message !== '') {
          let encodedText = encodeURI(this.message);
          let subject = this.translate.instant("plans.purchase_email_subject")
          href = href + "?subject=" + subject + "&body=" + encodedText;

        }
        window.location.href = href
        this.dismiss({}, 'callbackClicked')
      }
    },
    "website": {
      color: "primary",
      fill: "solid",
      icon: "website",
      iconSource: "asset",
      text: this.translate.instant("account.website_lbl"),
      id: "contact-options-web",
      callback: (ev) => {
        window.location.href = this.translate.instant("account.contact_us_visit");
        this.dismiss({}, 'callbackClicked')
      }
    },
  }

  constructor(private modalService: ModalService, private translate: TranslateService) {
    super(modalService)
    // super.alertModalService = modalService
  }

  ngOnInit() {
    super.ngOnInit()
    super.attachChildComponent()
    // setTimeout(() => {
    //   this.contentClass = "bg-opaque"
    // }, 350);
    if (!Array.isArray(this.buttonList) || this.buttonList.length <= 0)
      this.buttonList = Object.keys(this.buttonOptions)

  }
  dismissModal(id) {
    this.modalService.dismissByModalId(id, {}).then(response => { })
  }
  buttonClick(ev, prop) {
    prop.callback(ev);
    this.dismiss({}, 'callbackClicked')
  }

}
