<ion-item class="arpa-input-item" lines="none" [ngClass]="borderStyle">
  <ion-label *ngIf="label && label != ''" [position]="labelPlacement">
    {{label}}
  </ion-label>
  <ion-input 
  #inputElement
  [(ngModel)]="value"
  (ionFocus)="onFocused()" 
  (ionBlur)="borderStyle = ''" 
  (ionInput)="onInputChanged($event)"
  (ionChange)="onChangeListener($event)"
  [type]="type" 
  [placeholder]="placeholder"
  [maskito]="maskito"
  >
  </ion-input>
</ion-item>