export const GuestAlertTemplates = {
    BasicGuestAlert: {
        buttons: {
            registerBtn:{text: "guest.sign_up_btn", color: "primary", fill: "solid"},
            loginBtn:{text: "guest.already_have_Account_btn", color:"dark", fill: "clear"}
        },
        image: "assets/images/guest-register-alert.svg",
        description: "guest.alert_desc",
        title: "guest.alert_title"
    },
    PurchaseGuestAlert: {
        buttons: {
            
            registerBtn:{text: "guest.purchase_sign_up_btn", color: "primary", fill: "solid"},
            loginBtn:{text: "guest.purchase_already_have_account_btn", color:"primary", fill: "clear"}
        },
        image: "assets/images/happy-wallet-image.svg",
        description: "guest.purchase_alert_desc",
        title: "guest.purchase_alert_title"
    }
}