import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AnalyticService } from '../services/analytic.service';
import { AccountService } from '../services/account.service';

@Directive({ selector: '[click]' })
export class OrbitClickDirective {
    @HostListener('click', ['$event'])
    onClick(e) {
        e.preventDefault();
        /**
         * kontrolün sebebi openFarmList directiveinde de aynı loglama fonksiyonu çalışıyor. Olası çiftlemeleri önlemek için burada kontrol sağlandı.
         * benzer şekilde daha fazla directive olması durumunda buradaki kontrol mekanizması geliştirilebilir
         */
        if (this.possibleOutcome !== 'not_allowed') {
            let attributes: NamedNodeMap = this.elRef.nativeElement.attributes
            if (!attributes.getNamedItem("openFarmList"))
                this.analyticService.logTapEvent(e, this.elRef, this.possibleOutcome, this.id)
        }
    }

    @Input() possibleOutcome: CLICK_FUNCTION_TYPES = "action"
    @Input() id:string = "unknown"

    constructor(private elRef: ElementRef, private analyticService: AnalyticService) { }
}

/**
 * not_allowed click işleminin kod içerisinde yok sayıldığı durumlar için tanımlanmıştır.
 * action: genel kullanım
 * open modal: modal açtırdığım clickler
 * close modal: modalı kapattığım clickler
 * navigate: sayfa yönlendirmeleri yaptığım clickler
 * continue: tek işlevi sonraki adıma geçmek gibi olan clickler için
 * form submit: form gönderen veya kayıt işlemini tamamlayan clickler için
 */
export type CLICK_FUNCTION_TYPES = "action" | "open modal" | "close modal" | "navigate" | "continue" | "form submit" | "not_allowed"