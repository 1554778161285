import { TranslateService } from "@ngx-translate/core";
import * as mapboxgl from "mapbox-gl";
import { DateFormats } from "src/app/core/models/enums/date-formats";
import { IFarmModel, SatalliteInfoResponse } from "src/app/core/models/interfaces/farm-model";
import { DateTimeService } from "src/app/core/services/date-time.service";
import { environment } from "src/environments/environment";

export default class FarmDataModel implements IFarmModel {
    /**
     *
     */
    constructor() {
    }

    private translateService: TranslateService

    TempFarmAttId?: number;
    GeoJson?: string;
    FarmName?: string;
    SowingDate?: string;
    HarvestDate?: string;
    private _CropName?: string;
    public get CropName(): string {
        return this._CropName;
    }
    public set CropName(value: string) {
        this._CropName = value;
    }
    private _CropVarietyName?: string;
    public get CropVarietyName(): string {
        return this._CropVarietyName;
    }
    public set CropVarietyName(value: string) {
        this._CropVarietyName = value;
    }
    CalculatedArea?: number;
    CountryName?: string;
    CityName?: string;
    CityId?: number;
    DistrictName?: string;
    VillageName?: string;
    VillageId?: number;
    CropId?: number;
    CropVarietyId?: number;
    FarmId?: number;
    GeoType?: string;
    MeteoPointId?: number;
    PurchaseStatus?: string;
    SatelliteInfo?: SatalliteInfoResponse[];
    PhotosCount?: number;
    FlagCount?: number;
    private _GeoJsonObj?: any;
    public get GeoJsonObj(): any {
        if (this.GeoJson) {
            let item = this.GeoJson && this.GeoJson !== '' ? JSON.parse(this.GeoJson) : {};
            item.properties.fill = environment.planBasedMapLayerStyle[this.PlanName.toLowerCase()]?.fill || environment.mapLayerStyle.fill
            item.properties.opacity = environment.planBasedMapLayerStyle[this.PlanName.toLowerCase()]?.opacity || environment.mapLayerStyle.opacity
            item.properties.stroke = environment.planBasedMapLayerStyle[this.PlanName.toLowerCase()]?.stroke || environment.mapLayerStyle.stroke
            item.properties['stroke-width'] = environment.planBasedMapLayerStyle[this.PlanName.toLowerCase()]?.['stroke-width'] || environment.mapLayerStyle['stroke-width']
            return item
        } else
            return {};
    }
    // private set GeoJsonObj(value: any) {
    //     this._GeoJsonObj = value;
    // }
    private _Center?: { lat: any; lng: any; };
    public get Center(): { lat: any; lng: any; } {
        let bounds = new mapboxgl.LngLatBounds(this.GeoJsonObj.geometry.coordinates[0][0], this.GeoJsonObj.geometry.coordinates[0][0]);
        this.GeoJsonObj.geometry.coordinates[0].forEach(element => {
            bounds.extend(element);
        });
        return bounds.getCenter();
    }
    public set Center(value: { lat: any; lng: any; }) {
        this._Center = value;
    }
    private _SowingDateStr?: string;
    public get SowingDateStr(): string {
        return DateTimeService.FormatDate(this.SowingDate, DateFormats.FULL_DATE_DOTS);
    }
    // public set SowingDateStr(value: string) {
    //     this._SowingDateStr = value;
    // }
    private _HarvestDateStr?: string;
    public get HarvestDateStr(): string {
        return DateTimeService.FormatDate(this.HarvestDate, DateFormats.FULL_DATE_DOTS);
    }
    public set HarvestDateStr(value: string) {
        this._HarvestDateStr = value;
    }
    CountryCode?: string;
    FlagCountByUserId?: number;
    IsArchived: boolean;
    SharedFarm: boolean;
    SoilTypeId?: number;
    private _SoilTypeName?: string;
    public get SoilTypeName(): string {
        return this._SoilTypeName;
    }
    public set SoilTypeName(value: string) {
        this._SoilTypeName = value;
    }
    HasIrrigationProgram?: boolean;
    IrrigationTypeId?: number;
    private _IrrigationTypeName?: string;
    public get IrrigationTypeName(): string {
        return this._IrrigationTypeName;
    }
    public set IrrigationTypeName(value: string) {
        this._IrrigationTypeName = value;
    }
    TransmissionId: number;
    ClassName?: string;
    DistrictId: any;
    FarmState: any;
    IrrigationProgramPermission: boolean;
    FlagPermission: boolean;
    SprayingHoursPermission: boolean;
    PlanName?: string;
    CreateVRAMapPermission: boolean;
    ImportedFrom?: string;
    Ownership?: boolean;
    SharedToAnother?: boolean;
    IsSpotDetectionAvailable?: boolean;

}