import { Injectable, inject } from "@angular/core";
import { Geolocation, Position, PositionOptions, WatchPositionCallback } from "@capacitor/geolocation";
import { Observable } from "rxjs";
import { StorageKeys } from "../models/enums/storage-keys";
import { IDoktarLocationDetails, IGeoLocationDbModel, IMapboxFeature } from "../models/interfaces/coords";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { DoktarAppsFlyer } from "./appsflyer.service";
import { PermissionService } from "./permission.service";
import { StorageService } from "./storage.service";
import { Capacitor } from "@capacitor/core";
import { CleverTapService } from "./clevertap.service";
import { LocationApiService } from "../api/location-api.service";
import { MapService } from "./map.service";

@Injectable({
    providedIn: "root"
})
export class LocationService {
    private static _currentCoords: Position = {
        timestamp: Date.now(),
        coords: {
            accuracy: -1,
            altitude: -1,
            altitudeAccuracy: -1,
            heading: -1,
            speed: -1,
            longitude: 29.0311613,
            latitude: 41.1077137
        }
    };
    public static UserLatestLocationDetails: IMapboxFeature
    public static LatestLocationInfo: IDoktarLocationDetails
    public static isLocationAvailable: boolean = false;
    public static get currentCoords() {
        return LocationService._currentCoords;
    }
    public static set currentCoords(value) {
        LocationService._currentCoords = value;
    }
    public static serviceInstance: LocationService
    /**
     *
     */

    private static storageService: StorageService
    private static clevertapService: CleverTapService
    private static permissionService: PermissionService
    private static locationApi: LocationApiService
    private static mapService: MapService

    constructor(/*private storageService: StorageService, private clevertapService: CleverTapService, private permissionService: PermissionService*/) {
        // LocationService.serviceInstance = this;
        LocationService.storageService = inject(StorageService)
        LocationService.clevertapService = inject(CleverTapService)
        LocationService.permissionService = inject(PermissionService)
        LocationService.locationApi = inject(LocationApiService)
        LocationService.mapService = inject(MapService)
    }

    public static async GetMyCurrentLocation(props: PositionOptions = { enableHighAccuracy: true, timeout: 10000, maximumAge: 180000 }): Promise<IGenericResponse<GeolocationCoordinates>> {
        return new Promise((resolve, reject) => {
            let scope = this;
            LocationService.isLocationAvailable = false;
            // scope.isLocationAvailable = false
            try {
                PermissionService.CheckAndRequestLocation({
                    granted: async () => {
                        let options: PositionOptions = props
                        // {
                        //     enableHighAccuracy: true,
                        //     maximumAge: 1000 * 60 * 3 // 3 dakikalık bir cacheleme ekledim.
                        // }
                        try {
                            let location = await Geolocation.getCurrentPosition(options);
                            let resp = { IsSuccess: true, Data: location.coords, Message: "success", StatusCode: 1 } as IGenericResponse<GeolocationCoordinates> //location.coords
                            // StorageService.Set(StorageKeys.MY_LATEST_LOCATION, location.coords)
                            // scope.isLocationAvailable = true;
                            LocationService.currentCoords = location
                            LocationService.isLocationAvailable = true;
                            if (Capacitor.getPlatform() !== 'web')
                                DoktarAppsFlyer.LogLocation(location.coords.latitude, location.coords.longitude)
                            resolve(resp)
                        } catch (error) {

                            let errResp = { IsSuccess: false, Data: error, Message: "error_on_get_position", StatusCode: 0 } as IGenericResponse<GeolocationCoordinates> //location.coords
                            reject(errResp)
                        }
                    },
                    rejected: async (error) => {
                        let errResp = { IsSuccess: false, Data: null, Message: "permissionDenied", StatusCode: 0 } as IGenericResponse<GeolocationCoordinates> //location.coords
                        reject(errResp)
                    }
                })


            } catch (error) {
                let errResp = { IsSuccess: false, Data: null, Message: "permissionDenied", StatusCode: 0 } as IGenericResponse<GeolocationCoordinates> //location.coords
                reject(errResp)
            }

        })
    }

    public static async GetLocationFromIp(): Promise<IGeoLocationDbModel> {
        try {
            let fetchResponse = await fetch("https://geolocation-db.com/json/")
            // IPv4: "88.247.210.178"
            // city: "Mugla"
            // country_code: "TR"
            // country_name: "Turkey"
            // latitude: 37.2181
            // longitude: 28.3665
            // postal: "48585"
            // state: "Muğla"
            let data = await fetchResponse.json() as IGeoLocationDbModel
            DoktarAppsFlyer.LogLocation(data.latitude, data.longitude)
            // StorageService.Set(StorageKeys.MY_LATEST_LOCATION, { accuracy: 0, altitude: 0, altitudeAccuracy: 0, heading: 0, latitude: data.latitude, longitude: data.longitude, speed: 0 } as GeolocationCoordinates)
            return data
        } catch (error) {
            return null
        }
    }

    public static WatchMyLocation(props: PositionOptions = { enableHighAccuracy: true, timeout: 10000, maximumAge: 30000 }): Observable<Position> {
        /**
         * bu değişken oturduğum yerden konum değişti demek içindi
         */
        // let prevCount = 0;
        return new Observable<Position>(observer => {
            // options: PositionOptions, callback: WatchPositionCallback
            let options: PositionOptions = {
                enableHighAccuracy: props.enableHighAccuracy,
                timeout: props.timeout,
                maximumAge: props.maximumAge // 1000 * 30 * 1 // 30 saniyelik bir cacheleme ekledim.
            }

            let callback: WatchPositionCallback = (ev) => {
                if (ev) {
                    // ev.coords.latitude += prevCount
                    // ev.coords.longitude += prevCount
                    // prevCount += 0.01
                    // if (this._currentCoords.coords.latitude != ev.coords.latitude || this._currentCoords.coords.longitude != ev.coords.longitude) {
                    DoktarAppsFlyer.LogLocation(ev.coords.latitude, ev.coords.longitude)
                    if (this.clevertapService)
                        this.clevertapService.setLocation(ev.coords.latitude, ev.coords.longitude)
                    // }
                    this._currentCoords = ev
                    // StorageService.Set(StorageKeys.MY_LATEST_LOCATION, ev.coords)
                    observer.next(ev)
                } else {
                    observer.next(null)
                }
            }
            Geolocation.checkPermissions().then(res => {
                if (res.location === "denied")
                    observer.next(this._currentCoords)
                else {
                    Geolocation.watchPosition(options, callback).then(res => {

                    })
                }
            })
        })
    }

    public static async GetLocationDetailsFromCoords(): Promise<IMapboxFeature> {
        return new Promise((resolve, reject) => {
            let searchFn = () => {
                this.locationApi.SearchPlacesWithCoords({ latitude: this.currentCoords.coords.latitude, longitude: this.currentCoords.coords.longitude }).subscribe({
                    next: res => {
                        console.log("mapbox location => ", res)
                        if (res && Array.isArray(res.features) && res.features.length > 0) {
                            var placeDetailArray = res.features.filter(t => t.place_type.some(a => a == "locality" || a == "district" || a == "place" || a == "region" || a == "country"))
                            if (Array.isArray(placeDetailArray) && placeDetailArray.length > 0) {
                                this.UserLatestLocationDetails = placeDetailArray[0]
                            } else {
                                this.UserLatestLocationDetails = res.features[0]
                            }
                            this.storageService.set(StorageKeys.MAPBOX_LOCATION_FEATURE, this.UserLatestLocationDetails).then()
                            resolve(this.UserLatestLocationDetails)
                        } else {
                            reject(null)
                        }
                        // if (res.IsSuccess && res.Data) {

                        //     // this.LatestLocationInfo = res.Data
                        //     resolve(res.Data)
                        // }
                        // else
                        //     reject(res)
                    }, error: err => {
                        let errorObj: IGenericResponse = {
                            Data: err,
                            IsSuccess: false,
                            Message: "error",
                            StatusCode: 0,
                            ValidationErrors: null
                        }
                        reject(errorObj)
                    }
                })
            }

            /**
             * @description check if current coords is in bbox
             */
            let checkCoords = () => {
                let boundryBox = this.mapService.getBoundingBoxFromBboxCoords(this.UserLatestLocationDetails.bbox)
                let isCoordsValid = boundryBox.contains({ lng: this.currentCoords.coords.longitude, lat: this.currentCoords.coords.latitude })
                if (isCoordsValid)
                    resolve(this.UserLatestLocationDetails)
                else
                    searchFn()
            }

            // let boundryBox = this.mapService.getBoundingBoxFromBboxCoords()
            if (this.UserLatestLocationDetails) {
                checkCoords()
            }
            else {
                this.storageService.get(StorageKeys.MAPBOX_LOCATION_FEATURE).then(res => {
                    if (res.IsSuccess && res.Data) {
                        this.UserLatestLocationDetails = res.Data
                        checkCoords()
                    } else
                        searchFn()
                }).catch(err => {
                    searchFn()
                })

            }
        })
    }

}