import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';
import { UnitPreferenceService } from '../services/unit-preference.service';

@Pipe({
  name: 'numberCulture'
})
export class NumberCulturePipe implements PipeTransform {

  /**
   *
   */
  constructor(private storage: StorageService, private unitService: UnitPreferenceService) {
  }

  transform(value: number | string, addPercentage: boolean = false, fractionDigits = 2): string {
    return this.unitService.numberFormat(value, addPercentage, fractionDigits)
  }

}
