<ion-content (click)="dismiss(null)" possibleOutcome="close modal" [ngClass]="contentClass" forceOverscroll="false" id="modal-base-ion-content"
  class="bg-default modal-main-content">
  <ion-grid fixed style="height: 100%;" class="ion-no-padding">
    <ion-row class="ion-align-items-end ion-justify-content-center ion-no-padding" style="height: 100%;">
      <ion-col sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="10" style="border-radius: 40px 40px 0 0; margin:unset; ">
        <ion-card [id]="'card_item_' + handleBarId" (click)="$event.stopPropagation()" possibleOutcome="not_allowed"
          [ngStyle]="{'max-height':'calc('+(initialBreakpoint * 100) + 'vh - 25px - var(--ion-safe-area-top))'}" color="ultra-light" shape="round"
          class="ion-no-margin p-top-10 base-modal-card p-bottom-0">
          <ion-card-header *ngIf="hasHandle" [id]="handleBarId" class="p-all-0">
            <ion-card-subtitle>
              <div class="handle-bar"></div>
            </ion-card-subtitle>
            <ion-icon *ngIf="hasCloseBtn" (click)="dismiss(null)" id="modal-base-close-btn" class="modal-close-btn" color="dark" src="./../../../../../assets/icon/circle-close-icon.svg"></ion-icon>
            <ng-content select="[headerarea]"></ng-content>
          </ion-card-header>
          <ion-card-content class="p-top-0 p-bottom-16 p-horizontal-20" >
            <ng-content>

            </ng-content>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>