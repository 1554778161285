import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AltBaseModal } from '../../class/alt-base-modal.component';
import { ImageDrawingComponent } from '../image-drawing/image-drawing.component';
import { ModalService } from '../../../services/modal.service';
import { LoadingService } from '../../../services/loading.service';

const _window: any = window;

@Component({
  selector: 'orbit-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
})
export class DrawingComponent extends AltBaseModal implements OnInit {
  @ViewChild("imagedrawingelement") imageDrawing: ImageDrawingComponent
  // @Input() height: number = 320
  // @Input() width: number = 320
  @Input() elementId: string = "canvas"
  @Input() imageUrl: string = ""
  darkGray = "#333333"

  height = window.innerHeight - 200
  width = window.innerWidth
  outputQuality: number = 1
  langs = {
    saveBtn: "Kaydet",
    cancelBtn: "Vazgeç",
    loadImage: "load",
    loadImageUrl: "load url",
    loading: "loading",
    loadError: "load error",
    removeImage: "remove",
    sizes: {
    },
    undo: "undo",
    redo: "redo",
    clear: "clear",
    colors: {
      "ffffff": "#ffffff",
      "000000": "#000000",
      "FFB83D": "#FFB83D",
      "2EC54A": "#2EC54A",
      "F40988": "#F40988",
      "0064E5": "#0064E5",
      "EBEE5B": "#EBEE5B",
      "A49263": "#A49263",
      "15FFF1": "#15FFF1"
    },
    tools: {
      brush: 'Pinceau'
    }
  }
  colors = {
    "ffffff": "#ffffff",
    "000000": "#000000",
    "FFB83D": "#FFB83D",
    "2EC54A": "#2EC54A",
    "F40988": "#F40988",
    "0064E5": "#0064E5",
    "EBEE5B": "#EBEE5B",
    "A49263": "#A49263",
    "15FFF1": "#15FFF1"
  }
  constructor(private modalService: ModalService, private loadingService: LoadingService) {
    super(modalService)
  }

  ngOnInit() {
    let image = new Image();
    image.onload = (ev) => {
      var MAX_WIDTH = window.innerWidth;
      var MAX_HEIGHT = window.innerHeight - 200;

      var width = image.width;
      var height = image.height;

      // Change the resizing logic
      if (width > height) {
        if (width > MAX_WIDTH) {
          height = height * (MAX_WIDTH / width);
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = width * (MAX_HEIGHT / height);
          height = MAX_HEIGHT;
        }
      }

      this.width = width;
      this.height = height;

    }
    image.src = this.imageUrl;

    if (!this.elementId || this.elementId === '' || this.elementId === 'canvas') {
      this.elementId = 'canvas_' + Math.ceil(Math.random() * 100)
    }

    let setupComponent = () => {
      let that = this
      if (this.imageDrawing) {
        let configureDesign = () => {
          let toolbarElement: any = document.getElementsByClassName("toolbar")[0]
          if (toolbarElement) {
            toolbarElement.style.position = "absolute"
            toolbarElement.style.bottom = "0"
            // toolbarElement.style.width = "100%"
            let btnElement: any = document.getElementsByClassName("buttons")[0]
            btnElement.style.display = "none"
            let toolsElement: any = document.getElementsByClassName("tools")[0]
            toolsElement.style.width = "100%"
            toolsElement.style.margin = "10px 0"
            toolsElement.style.boxShadow = "unset"
            toolsElement.style.borderRadius = "0px"
            toolsElement.style.backgroundColor = this.darkGray

            let colorsList: any = document.getElementsByClassName("color")
            for (let index = 0; index < colorsList.length; index++) {
              const element = colorsList[index];
              element.style.borderWidth = "2px"
              element.style.borderColor = "white"
              element.style.borderStyle = "solid"
            }
            // let containerDoc: any = document.getElementsByClassName("canvas-container")[0]
            // containerDoc.style.width = "100vw"
          }
          else {
            setTimeout(() => {
              configureDesign()
            }, 300);
          }
        }
        configureDesign()
        // this.imageDrawing.selectColor("000000")
        // this.imageDrawing.currentColor = "#000000"
      } else {
        setTimeout(() => {
          setupComponent();
        }, 500);
      }
    }
    setupComponent()

  }


  save(ev) { }
  cancel() { }
  cancelDrawing() {
    this.dismiss(this.imageUrl, "canceled")
  }
  confirmDrawing() {
    this.loadingService.showLoading()
    let drawindSubscription = this.imageDrawing.save.subscribe(response => {
      this.dismiss(response, "drawed")
      this.loadingService.hideLoading()
    })
    setTimeout(() => {
      this.imageDrawing.saveImage()
    }, 1000);
  }

}
