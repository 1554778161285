import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { orbitIcons } from '../helper/class/orbit-custom-icons';

@Pipe({
  name: 'orbitIcons',
  // standalone: true,
  pure: false
})
export class OrbitIconsPipe implements PipeTransform {

  transform(iconName: keyof typeof orbitIcons | null ): string {
    return orbitIcons[iconName] || ""
  }

}
