<orbit-modal-base>
  <ion-header headerarea>
    <ion-toolbar bg="none">
      <!-- <ion-buttons slot="start">
        <ion-button (click)="dismiss(null ,'canceled')" possibleOutcome="close modal" id="calendar-picker-dismiss-btn" color="dark">
          <ion-icon slot="icon-only" name="chevron-back">
          </ion-icon>
        </ion-button>
      </ion-buttons> -->
      <ion-title>{{title}}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismiss(null ,'canceled')" possibleOutcome="close modal" id="calendar-picker-dismiss-btn" color="dark">
          <ion-icon slot="icon-only" [src]="'closeCircle' | orbitIcons"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="calendar-container" [ngStyle]="{'height': isBottomSheet ? '':'100%', 'margin-top': isBottomSheet ? '' : '2%'}">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <orbit-calendar (dayClicked)="daySelected($event)" (onRangeChange)="rangeChanged($event)"
            [startDate]="startDate" [min]="min" [max]="max" [preSelected]="preSelected"
            [selectionMode]="selectionMode"></orbit-calendar>
        </ion-col>
        <ion-col size="12">
          <ion-button (click)="confirm()" id="calendar-picker-confirm-btn" expand="block" fill="solid" shape="round">
            {{"general.save" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</orbit-modal-base>