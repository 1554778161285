<ion-content (click)="dismiss(null)" [possibleOutcome]="'close modal'" [ngClass]="contentClass" class="bg-default" id="generic-list-modal-ion-content"
  [style]="{'--padding-bottom': (bottomPadding > 0 ? 'calc(' + bottomPadding + '% + var(--ion-safe-area-bottom))' : 0)}">
  <div style="display: flex; height:100%; flex-direction: column; justify-content: flex-end">
    <ion-card (click)="$event.stopPropagation()" possibleOutcome="not_allowed" color="light" id="generic-list-modal-contaner-card"
      class="ion-no-margin ion-padding custom-ion-card modal-card-area max-height-98">
      <ion-toolbar bg="none">
        <ion-buttons slot="end">
          <ion-button (click)="dismiss(null)" color="dark" id="generic-list-modal-close-btn">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title *ngIf="title && title !== ''">{{title | translate}}</ion-title>
      </ion-toolbar>
      <ion-card-content style="max-height: calc(100% - var(--ion-safe-area-bottom) - 44px); overflow: auto;"
        class="ion-no-padding p-bottom-20">
        <ion-grid fixed class="ion-no-padding">
          <ion-row class="ion-justify-content-center ion-no-padding ion-align-items-center">
            <ion-col size="12" class="ion-no-padding">
              <ion-list bg="none">
                <ion-item shape="round" class="m-vertical-5" lines="none" *ngFor="let item of list; let index = index"
                  (click)="itemClicked(item)" [id]="'genric-list-modal-item-'+item.id">
                  <ion-label>{{item.value}}</ion-label>
                  <ion-icon color="dark" slot="end" name="chevron-forward-outline"></ion-icon>
                  <!-- <ion-radio mode="md"  color="primary" slot="end"></ion-radio> -->
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>