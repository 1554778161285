import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalService } from "../../services/modal.service";
import { ModalBaseComponent } from "../components/modal-base/modal-base.component";

@Component({
    template: "<ng-container></ng-container>"
})
export class AltBaseModal implements OnInit, AfterViewInit {
    @ViewChild(ModalBaseComponent) modalBaseComponent: ModalBaseComponent
    @Input() id: number
    @Input() initialBreakpoint: number
    @Input() bottomPadding: number = 0;
    
    private _isBottomSheet: boolean = false;
    public get isBottomSheet(): boolean {
        return this._isBottomSheet;
    }
    @Input() public set isBottomSheet(value: boolean) {
        this._isBottomSheet = value;
    }
    private attachmentTryCount: number = 0;

    private _contentClass: any = "bg-default";//
    public get contentClass(): any {
        return this._contentClass;
    }
    public set contentClass(value: any) {
        this._contentClass = value;
    }
    /**
     *
     */
    constructor(protected _basemodalService: ModalService) {
        // super(modalService);
    }
    ngAfterViewInit(): void {
        // throw new Error("Method not implemented.");

        this.attachChildComponent()
    }

    ngOnInit(): void {
        if (this.initialBreakpoint) {
            let diff = 1 - this.initialBreakpoint;
            this.bottomPadding = 100 * diff;
        }

        setTimeout(() => {
            this.contentClass = "bg-opaque"
            if (this.modalBaseComponent)
                this.modalBaseComponent.contentClass = this.contentClass;
        }, 500);
    }


    public attachChildComponent(controlCount = 0) {        
        if (this.modalBaseComponent) {
            this.modalBaseComponent.bottomPadding = this.bottomPadding
            this.modalBaseComponent.initialBreakpoint = this.initialBreakpoint
            this.modalBaseComponent.isBottomSheet = this.isBottomSheet
            this.modalBaseComponent.id = this.id
            this.modalBaseComponent.contentClass = this.contentClass;
            let dismissSubscription = this.modalBaseComponent.onDismiss.subscribe(res => {
                this.dismiss(null)
                dismissSubscription.unsubscribe();
            })
        } else {
            if (controlCount < 5) {
                controlCount++
                setTimeout(() => {
                    this.attachChildComponent(controlCount)
                }, 400);
            }
        }
    }

    async dismiss(data, role = "canceled") {
        // setTimeout(() => {
        this.contentClass = "bg-default"
        if (this.modalBaseComponent)
            this.modalBaseComponent.contentClass = this.contentClass;
        // }, 500);
        await this._basemodalService.dismissByModalId(this.id, data, role)
    }

}