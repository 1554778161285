import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../services/navigation.service';
import { IonBackButton } from '@ionic/angular';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
   @ViewChild('originalButton') originalButton: IonBackButton
  disabled = false
  @Input() defaultRoute?: string = null
  @Input() routerDirection?: string = null
  @Input() iconSlot: "start" | "end" | "icon-only" = "icon-only"
  @Input() iconName: string = "chevron-back-outline"
  @Input() btnText: string = ""
  @Input() size: "small" | "default" | "large" = "small"
  @Input() color = "primary"
  @Input() shape = ""
  @Input() useCustom:boolean = false
  constructor(private navigationService: NavigationService, private translate: TranslateService) {
    if (this.iconSlot !== "icon-only" && (!this.btnText || this.btnText == '')) {
      this.btnText = translate.instant("general.back_btn")
    }
  }

  ngOnInit() { }

  goBack() {
    // this.disabled = true;
    // this.navigationService.goBack(this.defaultRoute);
    // this.disabled = false;
  }
}
