export const NETWORK_ERROR_LANG_TEXTS: { [key: string]: { [key: string]: string } } = {
    "tr": {
      "no_internet_title": "İnternet bağlantısı yok.",
      "no_internet_desc": "Uygulamayı kullanabilmek için internete bağlı olmalısınız.",
      "retry_connection": "Tekrar Dene"
    },
    "en": {
      "no_internet_title": "No internet connection",
      "no_internet_desc": "You must be connected to the internet to use the application.",
      "retry_connection": "Retry"
    },
    "bg": {
      "no_internet_title": "Няма интернет връзка",
      "no_internet_desc": "Трябва да сте свързани с интернет, за да използвате приложението.",
      "retry_connection": "Повторение"
    },
    "es": {
      "no_internet_title": "Sin conexión a Internet",
      "no_internet_desc": "Debe estar conectado a Internet para usar la aplicación.",
      "retry_connection": "Rever"
    },
    "fr": {
      "no_internet_title": "Pas de connexion Internet",
      "no_internet_desc": "Vous devez être connecté à Internet pour utiliser l'application.",
      "retry_connection": "Recommencez"
    },
    "it": {
      "no_internet_title": "Nessuna connessione internet",
      "no_internet_desc": "È necessario essere connessi a Internet per utilizzare l'applicazione.",
      "retry_connection": "Riprovare"
    },
    "pt": {
      "no_internet_title": "Sem conexão com a Internet",
      "no_internet_desc": "Você deve estar conectado à Internet para usar o aplicativo.",
      "retry_connection": "Tente novamente"
    },
    "ro": {
      "no_internet_title": "Fără conexiune internet",
      "no_internet_desc": "Trebuie să fiți conectat la Internet pentru a utiliza aplicația.",
      "retry_connection": "Din nou"
    },
    "ru": {
      "no_internet_title": "Нет соединения с интернетом",
      "no_internet_desc": "Вы должны быть подключены к Интернету для использования приложения.",
      "retry_connection": "Повторно"
    },
    "uk": {
      "no_internet_title": "Немає підключення до Інтернету",
      "no_internet_desc": "Ви повинні бути підключені до Інтернету для використання програми.",
      "retry_connection": "Повторити"
    },
  }