import { NgModule } from '@angular/core';

import { DeletedFarmsAlertModalComponent } from './deleted-farms-alert-modal.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BaseModalModule } from 'src/app/core/modules/base-modal.module';
import { FarmDrawingToolsModule } from 'src/app/core/modules/farm-drawing-tools.module';
import { SharedModule } from 'src/app/core/modules/shared.module';

@NgModule({
    imports: [IonicModule, CommonModule, TranslateModule, FormsModule, BaseModalModule, FarmDrawingToolsModule, SharedModule],
    exports: [],
    declarations: [DeletedFarmsAlertModalComponent],
    providers: [],
})
export class DeletedFarmsAlertModalModule { }
