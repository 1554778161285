import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyPageComponent } from '../helper/components/empty-page/empty-page.component';

@NgModule({
  imports: [
    IonicModule,
    FormsModule,
    CommonModule,
    TranslateModule,
  ],
  declarations: [EmptyPageComponent],
  exports: [EmptyPageComponent]
})
export class EmptyPageModule { }
