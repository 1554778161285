export enum AnalyticEvents{
    SCREEN_VIEW = "Screen_View",
    LOGIN = "Login",
    REGISTER = "Register",
    TAP = "Tap",
    APP_LAUNCH = "App_Launch",
    FORM_SUBMIT = "Form_Submit",
    FIELD_IMPORT = "Field_Import",
    CHARGED = "Charged",
    SESSION_START = "session_start",
    SESSION_END = "session_end",
    PURCHASE_COMPLETED = "purchase_completed"
}

export enum AppsflyerAnalyticEvents{
    complete_registration = "af_complete_registration",
    tutorial_completion = "af_tutorial_completion",
    initiated_checkout = "af_initiated_checkout",
    purchase = "af_purchase",
    search = "af_search",
    content_view = "af_content_view",
    share = "af_share",
    login = "af_login",
    re_engage = "af_re_engage",
    update = "af_update",
    start_trial = "af_start_trial", //(guest olarak devam edenler için) -> bu bizde yok
    opened_from_push_notification = "af_opened_from_push_notification"
}