import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/core/services/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss'],
})
export class UserAgreementComponent implements OnInit {
  @Input() document: string = "privacy-policy";
  @Input() title: string = "auth.aggrement_userpolicy_title";
  @Input() showConfirmBtn: boolean = true;
  @Input() id: number;
  documentSecureSource: SafeResourceUrl;
  documentSourceBase = environment.agreementsBaseUrl;
  lang = "en"
  constructor(private modalService: ModalService, private sanitizer: DomSanitizer, private translate: TranslateService) { }

  ngOnInit() {
    let documentLangSource = this.translate.instant("agreements." + this.document);
    if (documentLangSource === "agreements." + this.document) {
      documentLangSource = "/en_" + this.document  + ".html"

    }
    let encodedUri = encodeURI(this.documentSourceBase + documentLangSource);

    encodedUri = encodedUri.split("%c4%b0").join("I%CC%87");
    this.documentSecureSource = this.sanitizer.bypassSecurityTrustResourceUrl(encodedUri);
  }
  cancelModal() {
    this.modalService.dismissByModalId(this.id, false);
  }

  agree() {
    this.modalService.dismissByModalId(this.id, true);
  }
}
