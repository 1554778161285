import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orbit-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent implements OnInit {
  @Input() style: any = {}
  @Input() image: string
  @Input() title: string
  @Input() description: string

  constructor() { }

  ngOnInit() { }

}
