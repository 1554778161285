<ion-grid fixed *ngIf="showOptions">
  <ion-row class="ion-justify-content-center">
    <ion-col *ngIf="showOr" size="12" class="ion-text-center">
      {{"general.or" | translate}}
    </ion-col>
    <ion-col size="12">
      <div class="d-flex row justify-evenly">
        <ion-card *ngIf="socialLoginItems['facebook']" class="signin-card" (click)="loginFacebook()" id="login-facebook-btn">
          <ion-grid fixed>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-icon [src]="'facebook' | orbitIcons" class="m-vertical-20" style="color:blue" size="large"></ion-icon>
            </ion-row>
          </ion-grid>
        </ion-card>
        <ion-card *ngIf="socialLoginItems['google']" class="signin-card" (click)="signInGoogle()" id="login-google-btn">
          <ion-grid fixed>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-icon [src]="'google' | orbitIcons" class="m-vertical-20" style="color:red" size="large"></ion-icon>
            </ion-row>
          </ion-grid>
        </ion-card>
        <ion-card class="signin-card" (click)="signWithApple()"  *ngIf="platform === 'ios' && socialLoginItems['apple']" id="login-apple-btn">
          <ion-grid fixed>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-icon name="logo-apple" class="m-vertical-20" style="color:black" size="large"></ion-icon>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
    </ion-col>
 
  </ion-row>
</ion-grid>