import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CustomMissingTranslationHandlerService implements MissingTranslationHandler {
    retryCount: { [key: string]: number } = {}
    constructor() { }

    handle(params: MissingTranslationHandlerParams) {
        const missingKey = params.key;
        if (missingKey.includes("http_request_unexpected_error")) {
            let _count = this.retryCount["http_request_unexpected_error"] || 0;
            _count += 1;
            this.retryCount["http_request_unexpected_error"] = _count;
            if (_count < 5)
                return params.translateService.instant("http_request_unexpected_error")
            else {
                return missingKey
            }
        }
        else if (missingKey.startsWith("crop_variety_translate")) {
            let originalKey = `${missingKey}`.replace("crop_variety_translate.", "")
            return originalKey
        }
        else if (missingKey.startsWith("crops.")) {
            let originalKey = `${missingKey}`.replace("crops.", "")
            return originalKey
        }
        else
            return missingKey
    }

}