import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchArray'
})
/**
 * array içerisindeki belirli bir string propertye göre includes bazında filtreleme yapar
 */
export class SearchOnArrayPipe implements PipeTransform {
    transform(value: Array<any>, key: string, query: string, ...args: any[]): any {
        if (query)
            return value.filter(t => t[key].toLowerCase().includes(query.toLowerCase()))
        else
            return value
    }
}