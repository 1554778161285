import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppInsightsLoggingService } from '../services/logging/logging.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class RequestTimeLogInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse && environment.production) {
                    const elapsed = Date.now() - started;
                    //TODO login register için kullanıcı bilgilerini gönderme
                    AppInsightsLoggingService.logMetric("request_time", elapsed, {time: elapsed, uri: req.urlWithParams, payload: req.body})
                    // console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`,{uri: req.urlWithParams, payload: req.body});
                }
            })
        );
    }
}