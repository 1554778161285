<orbit-modal-base>
  <ion-toolbar bg="none" mode="md" class="border-basic-bottom">
    <ion-title class="font-size-20 font-weight-850 ion-no-padding">{{"account.change_password" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss(null)" id="change-password-dismiss-btn">
        <ion-icon slot="icon-only" name="close" class="opacity-5"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-card-content style="max-height: calc(100% - var(--ion-safe-area-bottom) - 44px); overflow: auto;" class="ion-no-padding">
    <ion-grid fixed *ngIf="!isSend">
      <ion-row class="ion-justify-content-center ion-align-items-center text-center">
        <!-- <ion-col size="12" class="ion-no-padding p-horizontal-16">
          <h3 class="font-size-20-i font-weight-700-i ion-text-center" color="dark">
            {{"account.new_password_title" | translate}}
          </h3>
        </ion-col> -->
        <ion-col size="12" class="ion-no-padding m-bottom-25">
          <h5 class="font-size-14-i font-weight-400-i" color="dark">
            {{"account.new_password_desc" | translate}}
          </h5>
        </ion-col>
        <ion-col size="12">
          <!-- form -->
          <form [formGroup]="passwordForm">
            <ion-grid class="full-height p-horizontal-0">
              <ion-row>
                <ion-col size="12" class="m-vertical-5">
                  <ion-item lines="none" shape="round" class="arpa-input-item" [ngClass]="wrongPassword ? 'ion-item-invalid ' : 'ion-item-valid '">
                    <ion-label style="font-size: 12px;" class="form-label" position="floating">
                      {{"profile.old_password" | translate}}
                    </ion-label>
                    <ion-input (ionFocus)="wrongPassword = false" enterkeyhint="done" canCloseKeyboard formControlName="OldPassword" type="password" #password2>
                    </ion-input>
                    @if(wrongPassword){
                    <ion-icon slot="end" class="ion-align-self-center" color="danger" name="alert-circle"></ion-icon>
                    }@else{
                    <ion-icon class="ion-align-self-center" id="change-pass-toggle-pass2-icon" (click)="password2.type == 'text'?password2.type = 'password':password2.type = 'text'" slot="end" #eye
                      [src]="(password2.type == 'text' ?'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
                    }
                  </ion-item>
                </ion-col>
                <ion-col size="12" class="m-vertical-5">
                  <ion-item lines="none" shape="round" class="arpa-input-item" [ngClass]="(passwordForm | isFormValid : 'Password':['minlength','maxlength'])  ? 'ion-item-invalid ' : 'ion-item-valid '">
                    <ion-label style="font-size: 12px;" class="form-label" position="floating">
                      {{"auth.password" | translate}}
                    </ion-label>
                    <ion-input enterkeyhint="done" canCloseKeyboard required formControlName="Password" type="password" #password3>
                    </ion-input>
                    <ion-icon class="ion-align-self-center" id="change-pass-toggle-pass3-icon" (click)="password3.type == 'text'?password3.type = 'password':password3.type = 'text'" slot="end" #eye
                      [src]="(password3.type == 'text' ?'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
                    <!-- <ion-icon slot="end" id="change-pass-toggle-confirm-pass-icon" (click)="password3.type = 'text';" *ngIf="password3.type == 'password'" #eye
                      name="eye-outline" class="ion-align-self-center"></ion-icon>
                    <ion-icon slot="end" id="change-pass-toggle-confirm-pass-icon" (click)="password3.type = 'password';" *ngIf="password3.type == 'text'"
                      name="eye-off-outline" class="ion-align-self-center"></ion-icon> -->
                  </ion-item>
                  <ion-text *ngIf="passwordForm | isFormValid : 'Password':['minlength','maxlength']" color="danger" class="form-error d-block">
                    <span>{{"auth.password_validation_error" | translate}}</span>
                  </ion-text>
                  <!-- <ng-container *ngIf="passwordForm.controls['PasswordRepeat'] && passwordForm.controls['PasswordRepeat'].invalid && passwordForm.controls['PasswordRepeat'].value ">
                    <ion-text *ngIf="passwordForm | isFormValid : 'Password':['mismatchPassword']" color="danger"
                      class="form-error d-block">
                      <span>{{"auth.password_mismatch_error" | translate}}</span>
                    </ion-text>
                  </ng-container> -->
                </ion-col>
                <ion-col size="12" class="m-vertical-5">
                  <ion-item lines="none" shape="round" class="arpa-input-item"
                    [ngClass]="(passwordForm | isFormValid : 'PasswordRepeat': ['mismatchPassword','minlength','maxlength']) ? 'ion-item-invalid ' : 'ion-item-valid' + (passwordForm.controls['PasswordRepeat'].value!== '' && passwordForm.controls['PasswordRepeat'].value == passwordForm.controls['Password'].value  ? ' confirmed' : '')">
                    <ion-label style="font-size: 12px;" class="form-label" position="floating">
                      {{"auth.password_again" | translate}}
                    </ion-label>
                    <ion-input required enterkeyhint="done" canCloseKeyboard formControlName="PasswordRepeat" type="password" #password>
                    </ion-input>
                    @if(!(passwordForm | isFormValid : 'PasswordRepeat': ['mismatchPassword','minlength','maxlength'])
                    && passwordForm.controls['PasswordRepeat'].value != '' && passwordForm.controls['PasswordRepeat'].value == passwordForm.controls['Password'].value){
                    <ion-icon class="ion-align-self-center" color="success" slot="end" name="checkmark-circle"></ion-icon>
                    }@else{
                    <ion-icon class="ion-align-self-center" id="change-pass-toggle-pass-icon" (click)="password.type == 'text'?password.type = 'password':password.type = 'text'" slot="end"
                      [src]="(password.type == 'text' ?'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
                    }
                  </ion-item>
                  <ion-text *ngIf="passwordForm | isFormValid : 'PasswordRepeat':['minlength','maxlength']" color="danger" class="form-error d-block">
                    <span>{{"auth.password_validation_error" | translate}}</span>
                  </ion-text>
                  @if(passwordForm.controls['Password'] && passwordForm.controls['PasswordRepeat'].value &&
                  passwordForm | isFormValid :'PasswordRepeat':['mismatchPassword']){
                  <ion-text color="danger" class="form-error d-block">
                    <span>{{"auth.password_mismatch_error" | translate}}</span>
                  </ion-text>
                  }@else if(passwordForm.controls['PasswordRepeat'].value && passwordForm.controls['Password'].value
                  && passwordForm.controls['PasswordRepeat'].value == passwordForm.controls['Password'].value){
                  <ion-text color="success" class="form-success d-block">
                    <span>{{"auth.password_matched_info" | translate}}</span>
                  </ion-text>
                  }
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
          <!-- form -->
        </ion-col>
        <ion-col size="12">
          <ion-button text-transform="none" [disabled]="!passwordForm.controls['OldPassword'].value || passwordForm.controls['Password'].invalid || passwordForm.controls['PasswordRepeat'].invalid" color="primary" (click)="save()"
            id="change-pass-save-btn" expand="block" style="height: 55px;" shape="round">
            {{"general.save_changes" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid *ngIf="isSend" fixed class="p-horizontal-25" [class]="!isSend ? 'hidden':'hidden hidden-open'">
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <!-- <ion-col size="12" class="ion-no-padding p-all-20 text-center">
              <h2 class="fp-title">{{"account.success_change_password" | translate}}</h2>
            </ion-col> -->
        <ion-col size="12" class="text-center d-flex row justify-center">
          <img src="./../../../../../../../assets/images/password_changed_image.svg">
        </ion-col>
        <ion-col size="12" class="ion-no-padding p-all-20 text-center d-flex row justify-center">
          <h2 class="fp-title">{{"account.password_changed_alert" | translate}}</h2>
        </ion-col>
        <ion-col size="12">
          <ion-button text-transform="none" color="primary" (click)="dismiss(null)" expand="block" style="height: 55px;" id="change-pass-close-btn">
            {{"account.got_it_btn" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</orbit-modal-base>
<!-- 
    </ion-card>
  </div>
</ion-content> -->