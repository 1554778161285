import { Directive, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, Renderer2, OnInit } from '@angular/core';
import { AllGeoJSON, bbox as tBbox, projection } from '@turf/turf';
// import * as geojson2svg from "geojson2svg"
import { GeoJSON2SVG } from 'geojson2svg';

@Directive({
  selector: '[fromGeoJson]'
})
export class FromGeoJsonDirective implements OnInit {

  @Input('fromGeoJson') geojson: AllGeoJSON | any;
  @Input() recalculate: boolean = false
  private path: SVGPathElement;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.path = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    this.renderer.appendChild(this.el.nativeElement, this.path);
  }
  ngOnInit(): void {
    if (!this.geojson)
      return
    let geoType = this.geojson["geometry"]["type"];
    if (geoType == "Point") {
      // this.renderer.setAttribute(this.path, 'd', "M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z");
      return;
    }
    else {// if(this.geojson)
      const mercator = projection.toMercator(this.geojson);
      const bbox = tBbox(mercator);
      const converter = new GeoJSON2SVG({
        mapExtent: bboxToExtent(bbox),
        output: 'path'
      })
      // geojson2svg({
      //   mapExtent: bboxToExtent(bbox),
      //   output: 'path'
      // });
      let viewBox = [0, 0, converter["viewportSize"]["width"], converter["viewportSize"]["height"]]
      this.el.nativeElement.setAttribute("viewBox", viewBox)
      this.renderer.setAttribute(this.path, 'd', converter.convert(mercator)[0]);
      if (this.recalculate)
        setTimeout(() => {
          let svgHeight = this.el.nativeElement.clientHeight, svgWidth = this.el.nativeElement.clientWidth;
          let pathHeight = this.el.nativeElement.firstChild.getBoundingClientRect().height, pathWidth = this.el.nativeElement.firstChild.getBoundingClientRect().width;
          let viewBoxX = (pathHeight - svgHeight) / 2, viewBoxY = (pathWidth - svgWidth) / 2, ratioX = svgHeight / pathHeight, ratioY = svgWidth / pathWidth
          let y = viewBoxY * ratioY * 2, x = viewBoxX * ratioX * 1.5
          if (!(isNaN(x) || isNaN(y))) {
            viewBox = [y, x, converter["viewportSize"]["width"], converter["viewportSize"]["height"]]
            this.el.nativeElement.setAttribute("viewBox", viewBox)
          }

          // let scale_x = converter.viewportSize.width/svgWidth, scaleY = converter.viewportSize.height/svgHeight
          // let translateX = this.el.nativeElement.getBoundingClientRect().x, translateY = this.el.nativeElement.getBoundingClientRect().y

          // this.el.nativeElement.firstChild.style.translateX = translateX
          // this.el.nativeElement.firstChild.style.translateY = translateY
          // this.el.nativeElement.style.height = pathHeight
          // this.el.nativeElement.style.width = pathWidth
        }, 300);
      //this.renderer.setAttribute(this.el.nativeElement, 'viewBox', bboxToViewBox(this.path.getBBox()));
      return;
    }
  }

}

function bboxToExtent(bbox) {
  return {
    left: bbox[0],
    bottom: bbox[1],
    right: bbox[2],
    top: bbox[3]
  };
}

function bboxToViewBox(bbox) {
  // return [bbox.x, bbox.y, bbox.width, bbox.height]
  return `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
}