import { Injectable } from '@angular/core';
import { WeatherForecastApiService } from '../api/weather-forecast-api.service';
import { Observable, Subject } from "rxjs";
import { WeatherFarmItem } from '../models/interfaces/weather-response';


@Injectable({ providedIn: 'root' })
export class WeatherForecastService {

    weatherList: Array<WeatherFarmItem>
    constructor(private weatherApi: WeatherForecastApiService) { }

    isRequestPending: boolean = false
    private requestListener: Subject<Array<WeatherFarmItem>>
    public GetWeatherByPointFarm(refresh: boolean = false): Observable<Array<WeatherFarmItem>> {
        // return new Observable(observer => {
        if (Array.isArray(this.weatherList) && this.weatherList.length > 0 && !refresh) {
            return new Observable(observer => {
                observer.next(this.weatherList)
                observer.complete()
            })
        } else {
            if (!this.requestListener) {
                this.requestListener = new Subject<Array<WeatherFarmItem>>()
                this.weatherApi.GetWeatherByPointFarm().subscribe({
                    next: response => {
                        if (response.IsSuccess && response.Data && response.Data) {
                            this.weatherList = response.Data;
                            this.requestListener.next(response.Data);
                            // observer.next(response.Data);
                        } else {
                            this.requestListener.next([])
                            // observer.next([])
                        }
                        this.requestListener.complete()
                        setTimeout(() => {
                            this.requestListener = undefined
                        }, 1000);
                        // observer.complete()
                    }, error: error => {
                        this.requestListener.error(error)
                        this.requestListener.complete()
                        setTimeout(() => {
                            this.requestListener = undefined
                        }, 1000);
                        // observer.error(error)
                        // observer.complete()
                    }
                })
            }
            return this.requestListener.asObservable()
        }
        // })
    }

    public getRainViewerLayer() {
        return this.weatherApi.GetRainViewerLayer()
    }

    public getWeatherByPoint(pointId) {
        return this.weatherApi.GetWeatherByPoint({ pointId, requestType: "all", fromDate: 7 })
    }

}