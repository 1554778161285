import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AuthGuard } from './auth.guard';

@Injectable({ providedIn: 'root' })
export class GuestGuard  {
    constructor(private accountService: AccountService, private authGuard: AuthGuard) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.accountService.userData && this.accountService.userData?.IsGuest) {
            this.accountService.ShowGuestAlert()
            return false
        } else
            return true;
    }
}