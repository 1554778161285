export const orbitIcons = {
    "": "",
    "refresh_draw": 'assets/icon/refresh_draw.svg',  // "../../../assets/icon/refresh_draw.svg"
    "marker_v1": 'assets/icon/marker_v1.svg',
    "marker_v2": 'assets/icon/marker_v2.svg',
    "marker_v3": 'assets/icon/marker_v3.svg',
    "not_found_1": "assets/images/not_found_image_1.svg",
    "tabs_map": 'assets/icon/tabs_map.svg',
    "tabs_farm": 'assets/icon/tabs_farm.svg',
    "tabs_explore": 'assets/icon/tabs_explore.svg',
    "tabs_user": 'assets/icon/tabs_user.svg',
    "tabs_compare": 'assets/icon/tabs_compare.svg',
    "tabs_tools": 'assets/icon/tabs_tools.svg',
    "filter": 'assets/icon/filter.svg',
    "cloud_moon": 'assets/icon/cloud_moon.svg',
    "lock": 'assets/icon/lock.svg',
    "lock_alt": 'assets/icon/lock-icon.svg',
    "delete_v1": "assets/images/delete_icon_v1.svg",
    "no_result_found": "assets/images/no_result_found.svg",
    "chat_user": "assets/icon/chat_user.svg",
    "edit": "assets/icon/edit.svg",
    "edit_pencil": "assets/icon/edit_pencil_icon.svg",
    "wind": "assets/icon/wind.svg",
    "contact_us": "assets/icon/contact_us.svg",
    "irrigation": "assets/icon/irrigation_icon.svg",
    "scouting_notes": "assets/icon/scounting_notes_icon.svg",
    "spraying_hours": "assets/icon/spraying_hours_icon.svg",
    "yield_calculation": "assets/icon/yield_calculation_icon.svg",
    "irrigation_calendar": "assets/icon/season-timeline-btn.svg",
    "sparkles": "assets/icon/sparkles.svg",
    "sparkles_alt": "assets/icon/sparkles_alt.svg",
    "grid": "assets/icon/grid-rect.svg",
    "crop": "assets/icon/crop-icon.svg",
    "select-radio": "assets/icon/single-select-icon.svg",
    "select-radio-empty": "assets/icon/single-select-empty.svg",
    "satellite": "assets/icon/satellite.svg",
    "done": "assets/icon/done.svg",
    "game_star": "assets/icon/game_star.svg",
    "unread_notifications": "assets/icon/notification_unread.svg",
    "notifications": "assets/icon/notification.svg",
    "support_icon": "assets/icon/support-icon.svg",
    "copy": "assets/icon/copy_icon.svg",
    "diamond-icon": "assets/icon/diamond-icon.svg",
    "google": "assets/icon/google-icon.svg",
    "facebook": "assets/icon/facebook.svg",
    "read": "assets/icon/read.svg",
    "whatsapp": "assets/icon/whatsapp.svg",
    "whatsapp_filled": "assets/icon/whatsapp-filled.svg",
    "email": "assets/icon/envelope.svg",
    "website": "assets/icon/world.svg",
    "info": "assets/icon/info.svg",
    "cloud": "assets/icon/cloud.svg",
    "debit_card": "assets/icon/debit-card-icon.svg",
    "telephone": "assets/icon/telephone.svg",
    "vra_tool": "assets/icon/vra-tool-icon.svg",
    "spraying_alt": "assets/icon/spraying-alt.svg",
    "irrigation_alt": "assets/icon/irrigation_alt.svg",
    "octahedron": "assets/icon/octahedron-icon.svg",
    "scouting_notes_alt": "assets/icon/scouting-notes.svg",
    "fertilizer": "assets/icon/fertilizer_icon.svg",
    "spraying": "assets/icon/spraying_icon.svg",
    "seeding": "assets/icon/seeding_icon.svg",
    "hour": "assets/icon/hour-icon.svg",
    "calendar": "assets/icon/calendar_icon.svg",
    "agrirouter": "assets/icon/agrirouter-icon.svg",
    "trash": "assets/icon/trash-icon.svg",
    "infoCircleTransparent": "assets/icon/info-circle-filled-transparent.svg",
    "spot_alert": "assets/icon/spot_alert_icon.svg",
    "spot_alert_reverse": "assets/icon/spot_alert_icon_reverse.svg",
    "scouting_notes_alt2": "assets/icon/notes_new_icon.svg",
    "new_note_icon": "assets/icon/note_icon.svg",
    "problem_921": "assets/icon/problem_921.svg",
    "problem_1164": "assets/icon/problem_1164.svg",
    "problem_1457": "assets/icon/problem_1457.svg",
    "problem_920": "assets/icon/problem_920.svg",
    "problem_1458": "assets/icon/problem_1458.svg",
    "problem_919": "assets/icon/problem_919.svg",
    "add_photos_icon": "assets/icon/add_photos_icon.svg",
    "cursor_fill": "assets/icon/cursor-fill.svg",
    "marker_solid_fill": "assets/icon/marker_solid_fill.svg",
    "note_pin_icon": "assets/icon/note-pin-icon.svg",
    "eye_icon_filled": "assets/icon/eye-icon-filled.svg",
    "eye_closed_icon_filled": "assets/icon/eye-closed-icon-filled.svg",
    "draw_farm_icon": "assets/icon/farm-draw-icon.svg",
    "parcel_inquiry_icon": "assets/icon/parcel-icon.svg",
    "import_field_icon": "assets/icon/upload-cloud-icon.svg",
    "filter_round": "assets/icon/filter-round-icon.svg",
    "arrow_left": "assets/icon/arrow-left-icon.svg",
    "connect_icon": "assets/icon/ep_connection.svg",
    "arrow_right_round": "assets/icon/right-arrow.svg",
    "arrow_right_round_solid": "assets/icon/arrow-right-solid-round.svg",
    "field_location": "assets/icon/field-location.svg",
    "square_back_icon": "assets/icon/orbit-custom-back-icon.svg",
    "clock_previous": "assets/icon/clock_previous.svg",
    "clock_next": "assets/icon/clock_next.svg",
    "test_tube": "assets/icon/test_tubes_icon.svg",
    "closeCircle": "assets/icon/circle-close-icon.svg",
    "arrow_right": "assets/icon/arrow-right-icon.svg",
    "apple-maps": "assets/icon/apple.svg",
    "google-maps": "assets/icon/google.svg",
    "yandex-maps": "assets/icon/yandex.svg",
    "delete": "assets/icon/delete-icon.svg",
    "settings": "assets/icon/settings-icon.svg",
    "money_stack": "assets/icon/money-stack-icon.svg",
    "wallet": "assets/icon/wallet-icon.svg",
    "customer_support": "assets/icon/customer-support.svg",
    "phone": "assets/icon/phone-icon.svg",
    "file": "assets/icon/file-icon.svg",
    "log-out": "assets/icon/log-out-icon.svg",
    "edit_pencil_filled": "assets/icon/pencil-filled-icon.svg",
    "gallery": "assets/icon/gallery.svg",
    "camera": "assets/icon/camera.svg",
    "pdf_file": "assets/icon/pdf-icon.svg",
    "search-filled": "assets/icon/search-filled-icon.svg",
    "current_location": "assets/icon/current-location.svg",
    "log-out-alt": "assets/icon/log-out-alt.svg",
    "send": "assets/icon/send_icon.svg",
    "videocam": "assets/icon/videocam.svg",
    "voice": "assets/icon/voice.svg"
}