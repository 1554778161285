import { TranslateService } from '@ngx-translate/core';
import { Injectable, inject } from "@angular/core";
import { forkJoin, of, Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { finalize, map, } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FarmApiService } from "../api/farm-api.service";
import { FarmNoteResponse } from "../models/classes/farm-note-response";
import { FarmNotes, IFlagSaveModel } from "../models/classes/farm-notes";
import { SatelliteImages } from "./class/satellite-images";
import { DateFormats } from "../models/enums/date-formats";
import { IFarmModel, SatalliteInfoResponse } from "../models/interfaces/farm-model";
import { IFarmEditModel } from "../models/interfaces/farm-save-model";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { INewImageModel } from "../models/interfaces/new-image-model";
import { ISatellite, ISatelliteResponseModel } from "../models/interfaces/satellite-models";
import { DateTimeService } from "./date-time.service";
import { DoktarConstantsService } from "./doktar-constants.service";
import { LoadingService } from "./loading.service";
import { MediaService } from "./media.service";
import { AccountService } from './account.service';
import { ClientAlertService } from './client-alert.service';
import { ModalService } from './modal.service';
import { DeletedFarmsAlertModalComponent } from 'src/app/components/deleted-farms-alert-modal/deleted-farms-alert-modal.component';
import { spotDummyData } from '../helper/class/spot-dummy-data';
import { ProbSpotDetection } from '../models/interfaces/prob-spot-detection.interface';
import FarmControl from './class/farm/farm-control.class';
import { mutations } from './class/farm/farm.signal';
import { FieldListComponent } from '../helper/components/field-list/field-list.component';
import { WeatherForecastService } from './weather-forecast.service';
import Spraying from './class/spraying';
import { AnalyticService } from './analytic.service';
import { AnalyticEvents } from '../models/enums/analytic-events';
// import { AccountService } from './account.service';

@Injectable({
    providedIn: "root"
})
export class FarmService {

    error = {
        status: false,
        message: null
    }
    allFlags: Array<FarmNoteResponse> = null
    /**
     * @deprecated
     */
    farmList: Array<IFarmModel> = null
    allFarms: Array<FarmControl> = null
    // archivedFarms: Array<FarmControl>

    unregisteredFarmCounts: number = -1;
    archivedFarmList: Array<IFarmModel> = null
    // farmImages: Array<ISatellite>
    spotDetectedFarms: Array<number>

    currentFarmNotes: Array<FarmNoteResponse> = null
    currentSatellite: ISatellite
    imageCoords

    currentNoteFlag: FarmNoteResponse = null

    satelliteDates: Array<SatelliteImages>
    currentDate: SatelliteImages
    savedFarmTempId: number = -1
    sprayingItems: Array<Spraying>

    public get farmApi() {
        return this.farmApiService
    }

    static ActiveHighestPackageType: 'basic' | 'pro' | 'ultra' | 'free' = 'free'

    private _farmSubject: Subject<Array<IFarmModel>> = new Subject<Array<IFarmModel>>()
    /**
     *
     */
    constructor(private farmApiService: FarmApiService, private analyticService: AnalyticService, private weatherService: WeatherForecastService, private modalService: ModalService, private alertService: ClientAlertService, private translate: TranslateService, private mediaService: MediaService, private loadingService: LoadingService, public doktarConstants: DoktarConstantsService, private dateTimeService: DateTimeService) {

    }

    public FarmListListener() {
        return this._farmSubject.asObservable();
    }

    private getAllFarmRequest: Subject<Array<IFarmModel>> = new Subject<Array<IFarmModel>>()
    isRequestOn: boolean = false
    public GetAllFarms({ refreshFarms = false, showLoading = false }): Observable<Array<IFarmModel>> {
        if (showLoading)
            this.loadingService.showLoading()
        if (this.farmList && !refreshFarms) {
            return new Observable(observer => {
                observer.next(this.farmList)
                if (showLoading)
                    this.loadingService.hideLoading()
                observer.complete()
            })
        } else {

            if (!this.isRequestOn) {
                if (this.getAllFarmRequest.isStopped)
                    this.getAllFarmRequest = new Subject<Array<IFarmModel>>()
                this.isRequestOn = true
                this.farmApiService.GetAll()
                    .subscribe({
                        next: farmResponse => {
                            if (farmResponse.IsSuccess && farmResponse.Data) {
                                if (farmResponse.Data.some(t => t.PlanName && t.PlanName.toLowerCase() === 'ultra')) {
                                    FarmService.ActiveHighestPackageType = 'ultra';
                                } else if (farmResponse.Data.some(t => t.PlanName && t.PlanName.toLowerCase() === 'pro')) {
                                    FarmService.ActiveHighestPackageType = 'pro';
                                } else if (farmResponse.Data.some(t => t.PlanName && t.PlanName.toLowerCase() === 'basic')) {
                                    FarmService.ActiveHighestPackageType = 'basic';
                                } else {
                                    FarmService.ActiveHighestPackageType = 'free';
                                }
                                let farms = farmResponse.Data.sort((a, b) => {
                                    let keyA = a["FarmName"], keyB = b["FarmName"];
                                    return keyA.localeCompare(keyB, "en")
                                })
                                // farms.forEach(t => {
                                //     // if (t.PlanName.toLowerCase() == 'basic')
                                //     //     t.PlanName = "Free"
                                //     if (!t.SatelliteInfo)
                                //         t.SatelliteInfo = [{
                                //             "ParamName": "1158",
                                //             "ParamValue": "S",
                                //             "Title": "Sentinel",
                                //             "AnalyzeData": "{\"Id\":0,\"TypeId\":\"S\",\"DifferenceMap\":null,\"NdviMap\":[1.0,0.0,0.0,0.0,0.0]}",
                                //             "LastDate": "2023-12-03T17:32:44",
                                //             "ExpireDate": "2025-12-31T23:59:59"
                                //         }]
                                // })
                                this.farmList = farms.filter(t => !t.IsArchived);
                                this.archivedFarmList = farms.filter(t => t.IsArchived)
                                this.allFarms = farms.map(el => {
                                    // if (el.PlanName.toLowerCase() == 'basic')
                                    //     el.PlanName = "Free"
                                    let tempFarm = new FarmControl(this.farmApiService);
                                    tempFarm.buildFarm(el);
                                    return tempFarm;
                                })
                                mutations.farmsReloaded(this.allFarms)
                                this.farmList.forEach(e => {
                                    if (e.SatelliteInfo) {
                                        if (e.PlanName.toLowerCase() === 'free')
                                            e["ClassName"] = "stop-tracking";
                                        else
                                            e["ClassName"] = "purchase";
                                    } else {
                                        if (e.PurchaseStatus == "NoPurchased") {
                                            e["ClassName"] = "stop-tracking";
                                        } else {
                                            e["ClassName"] = "purchase";
                                        }
                                    }
                                    e["SowingDateStr"] = this.dateTimeService.formatDate(e.SowingDate, DateFormats.FULL_DATE_DOTS);
                                    e["HarvestDateStr"] = this.dateTimeService.formatDate(e.HarvestDate, DateFormats.FULL_DATE_DOTS);
                                    e["GeoJsonObj"] = e.GeoJson && e.GeoJson !== '' ? JSON.parse(e.GeoJson) : {};
                                    e["GeoJsonObj"].properties.fill = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.fill || environment.mapLayerStyle.fill
                                    e["GeoJsonObj"].properties.opacity = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.opacity || environment.mapLayerStyle.opacity
                                    e["GeoJsonObj"].properties.stroke = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.stroke || environment.mapLayerStyle.stroke
                                    e["GeoJsonObj"].properties['stroke-width'] = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.['stroke-width'] || environment.mapLayerStyle['stroke-width']
                                    e.GeoJson = JSON.stringify(e["GeoJsonObj"]);
                                });

                                this.unregisteredFarmCounts = this.farmList.filter(t => t.PlanName.toLowerCase() !== 'pro').length
                                this.archivedFarmList.forEach(e => {
                                    e["SowingDateStr"] = this.dateTimeService.formatDate(e.SowingDate, DateFormats.FULL_DATE_DOTS);
                                    e["HarvestDateStr"] = this.dateTimeService.formatDate(e.HarvestDate, DateFormats.FULL_DATE_DOTS);
                                    e["GeoJsonObj"] = e.GeoJson && e.GeoJson !== '' ? JSON.parse(e.GeoJson) : {};
                                    e["GeoJsonObj"].properties.fill = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.fill || environment.mapLayerStyle.fill
                                    e["GeoJsonObj"].properties.opacity = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.opacity || environment.mapLayerStyle.opacity
                                    e["GeoJsonObj"].properties.stroke = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.stroke || environment.mapLayerStyle.stroke
                                    e["GeoJsonObj"].properties['stroke-width'] = environment.planBasedMapLayerStyle[e.PlanName.toLowerCase()]?.['stroke-width'] || environment.mapLayerStyle['stroke-width']
                                    e.GeoJson = JSON.stringify(e["GeoJsonObj"]);
                                });
                                this.getAllFarmRequest.next(this.farmList)
                                if (showLoading)
                                    this.loadingService.hideLoading()
                                this.getAllFarmRequest.complete()
                                this.isRequestOn = false
                                // if (refreshFarms)
                                this._farmSubject.next(this.farmList)
                            } else {
                                this.error = {
                                    status: true,
                                    message: farmResponse.Message
                                }
                                this.getAllFarmRequest.error(this.error)
                                if (showLoading)
                                    this.loadingService.hideLoading()
                                this.getAllFarmRequest.complete()
                                this.isRequestOn = false
                            }
                        }, error: err => {
                            this.error = {
                                status: true,
                                message: err
                            }
                            this.getAllFarmRequest.error(this.error)
                            if (showLoading)
                                this.loadingService.hideLoading()
                            this.getAllFarmRequest.complete()
                            this.isRequestOn = false
                        }, complete: () => {
                            if (showLoading)
                                this.loadingService.hideLoading()
                        }
                    })
            } else {
                if (showLoading)
                    this.loadingService.hideLoading()
            }

            this.getAllFarmRequest.pipe(finalize(() => {
                this.isRequestOn = false
            }))
            return this.getAllFarmRequest.asObservable()
        }
    }

    public UpdateFarm(newFarmInfos: IFarmEditModel, reloadFarmList: boolean = false) {
        let subject: Subject<boolean> = new Subject<boolean>()
        this.farmApiService.FarmEditRequest(newFarmInfos).subscribe(response => {
            if (response.IsSuccess && response.Data) {
                subject.next(true)
            } else
                subject.next(false)
            subject.complete()
        }, err => {
            subject.error(err)
            subject.complete()
        })
        return subject.asObservable();
    }

    public DeleteFarm(tempFarmAttId: number) {
        return new Observable(observer => {
            this.farmApiService.DeleteFarm(tempFarmAttId).subscribe({
                next: res => {
                    if (res.IsSuccess) {
                        this.GetAllFarms({ refreshFarms: true, showLoading: false }).subscribe()
                        this.weatherService.weatherList = this.weatherService.weatherList.filter(t => t.Id !== tempFarmAttId)
                        this.weatherService.GetWeatherByPointFarm(true).subscribe()
                        observer.next(true)
                    }
                    else
                        observer.next(false)
                    observer.complete()
                },
                error: err => {
                    observer.error(err)
                    observer.complete()
                }
            })
        })
    }

    public ArchiveFarm(tempFarmAttId: number) {
        return new Observable(observer => {
            this.farmApiService.ChangeArchiveStatus(tempFarmAttId, true).subscribe(res => {
                if (res.IsSuccess) {
                    this.GetAllFarms({ refreshFarms: true, showLoading: false })
                    observer.next(true)
                }
                else
                    observer.next(false)
                observer.complete()
            }, err => {
                observer.error(err)
                observer.complete()
            })
        })
    }

    public UnArchiveFarm(tempFarmAttId: number) {
        return new Observable(observer => {
            this.farmApiService.ChangeArchiveStatus(tempFarmAttId, false).subscribe(res => {
                if (res.IsSuccess) {
                    this.GetAllFarms({ refreshFarms: true, showLoading: false })
                    observer.next(true)
                }
                else
                    observer.next(false)
                observer.complete()
            }, err => {
                observer.error(err)
                observer.complete()
            })
        })
    }

    private _flagsSubject: Subject<Array<FarmNoteResponse>> = new Subject()

    public FlagListListener() {
        return this._flagsSubject.asObservable()
    }

    public GetAllNotes(triggerSubject: boolean = false): Observable<FarmNoteResponse[]> {
        return new Observable(observer => {
            this.farmApiService.GetNotes(new FarmNotes()).subscribe(response => {
                if (response.IsSuccess && Array.isArray(response.Data)) {
                    forkJoin({ farmList: this.GetAllFarms({ refreshFarms: false, showLoading: false }), tagList: this.doktarConstants.RequestTagList(), problems: this.doktarConstants.RequestProblemList() }).subscribe(forkResponse => {
                        let farms = forkResponse.farmList;
                        let tagResponse = forkResponse.tagList;
                        let problemPresponse: any = forkResponse.problems;

                        let responseData = response.Data.filter(t => t.FlagTypeId != 'spot-detection').sort((a, b) => DateTimeService.CreateSortFn(a, b, 'FlagDate')).reverse().filter(t => farms.some(f => f.TempFarmAttId === t.TempFarmAttId && !f.IsArchived))
                        this.currentFarmNotes = responseData
                            .map(note => {
                                if (Array.isArray(note.Tags)) {
                                    note.TagObjs = note.Tags.map(t => tagResponse.find(r => r.ConstantId == t))
                                }
                                if (note.InspectTypeId && note.InspectTypeId > 0) {
                                    note.InsectObj = problemPresponse.find(t => t.ConstantId === note.InspectTypeId)

                                }
                                return note;
                            })
                        if (this.currentNoteFlag) {
                            let tempFlag = this.currentFarmNotes.find(t => t.Id === this.currentNoteFlag.Id)
                            if (tempFlag)
                                this.currentNoteFlag = tempFlag
                        }
                        this.allFlags = responseData
                        observer.next(this.currentFarmNotes)
                        observer.complete()
                        if (triggerSubject)
                            this._flagsSubject.next(this.currentFarmNotes)
                    })

                } else {
                    observer.error("no_flags_found")
                    observer.complete()
                }
            }, err => {
                observer.error(err)
                observer.complete()
            })
        })
    }

    public RemoveNote(noteId): Observable<number> {
        return new Observable(observer => {
            this.farmApiService.RemoveNote(noteId).subscribe(response => {
                this.currentNoteFlag = null;
                if (response.IsSuccess) {
                    this.GetAllNotes(true).subscribe(notesResponse => {
                        observer.next(response.Data)
                        observer.complete()
                    }, err => {
                        if (Array.isArray(this.allFlags))
                            this.allFlags = this.allFlags.filter(t => t.Id !== noteId)
                        observer.next(response.Data)
                        observer.complete()
                    })
                } else {
                    observer.error(response)
                    observer.complete()
                }
            }, err => {
                observer.error(err)
                observer.complete()
            })
        })
    }

    public GetSatelliteList(payload) {
        return this.farmApiService.GetSatelliteList(payload)
            .pipe(
                map(t => {
                    return t;
                })
            )
    }

    public GetAllSatelliteInfos({ tempFarmAttId, satelliteInfo, totalDays, lastDate = null }): Observable<ISatelliteResponseModel> {
        return new Observable(observer => {
            let requestList: { [key: string]: any } = {}
            satelliteInfo.forEach((element: SatalliteInfoResponse) => {
                let request = this.farmApiService.GetSatelliteList({
                    sateliteType: element.ParamValue,
                    tempFarmAttId: tempFarmAttId,
                    totalDays: totalDays,
                    lastDate: lastDate
                }).pipe(catchError(val => of({ Data: null, IsSuccess: true, Message: "", StatusCode: 1 } as IGenericResponse<ISatelliteResponseModel>)))
                requestList[element.ParamValue] = request;
            });

            forkJoin(requestList).subscribe((joinedResponse: { [key: string]: IGenericResponse<ISatelliteResponseModel> }) => {
                let masterResponse: ISatelliteResponseModel = {
                    Coordinates: null,
                    InfoMessage: "",
                    SateliteList: [] as ISatellite[]
                } as ISatelliteResponseModel;
                Object.keys(joinedResponse).forEach(el => {
                    let response = joinedResponse[el];
                    masterResponse.Coordinates = response.Data?.Coordinates
                    if (response.IsSuccess && response.Data && Array.isArray(response.Data.SateliteList)) {
                        masterResponse.SateliteList = [
                            ...masterResponse.SateliteList,
                            ...response.Data.SateliteList.map(t => { t.SatelliteType = el; t.tempFarAttrId = tempFarmAttId; return t; }).reverse()
                        ]
                    }
                })
                observer.next(masterResponse);
                observer.complete();
            }, err => {
                observer.error();
                observer.complete();
            })
        })
    }

    public GetSatelliteAnalysis(id: number, type: string) {
        return this.farmApiService.GetSatelliteAnalysis(id, type).pipe(map(t => {
            if (t.IsSuccess) {
                t.Data = t.Data.filter(a => a.Title === 'analyze-page-health-map-header')
            }
            return t
        }))
    }

    public GetFarmNotes(tempFarmAttId: number, refresh: boolean = true): Observable<Array<FarmNoteResponse>> {
        return new Observable(observer => {
            if (refresh || !(Array.isArray(this.currentFarmNotes) && this.currentFarmNotes.length > 0)) {
                this.farmApiService.GetNotes({ tempFarmAttId }).subscribe(response => {
                    if (response && response.IsSuccess) {
                        forkJoin({ tagList: this.doktarConstants.RequestTagList(), problems: this.doktarConstants.RequestProblemList() }).subscribe(forkResponse => {
                            let tagResponse = forkResponse.tagList;
                            let problemPresponse = forkResponse.problems;
                            this.currentFarmNotes = response.Data
                                .map(note => {
                                    if (Array.isArray(note.Tags)) {
                                        note.TagObjs = note.Tags.map(t => tagResponse.find(r => r.ConstantId == t))
                                    }
                                    if (note.InspectTypeId && note.InspectTypeId > 0) {
                                        problemPresponse.find(t => {
                                            if (t.ConstantId === note.InspectTypeId) {
                                                note.InsectObj = t
                                            }
                                        })
                                    }
                                    return note;
                                })
                            if (this.currentNoteFlag) {
                                let tempFlag = this.currentFarmNotes.find(t => t.Id === this.currentNoteFlag.Id)
                                if (tempFlag)
                                    this.currentNoteFlag = tempFlag
                            }
                            observer.next(this.currentFarmNotes)
                            observer.complete()
                        })
                    } else {
                        observer.next(null)
                        observer.complete()
                    }
                }, err => {
                    observer.error(err)
                    observer.complete()
                })
            } else {
                observer.next(this.currentFarmNotes)
                observer.complete()
            }
        })
    }


    public AddScoutingNote(payload: IFlagSaveModel, imageList: Array<INewImageModel>): Observable<any> {

        return new Observable(observer => {
            this.farmApiService.AddNotes(payload).subscribe(response => {
                if (response.IsSuccess) {
                    this.analyticService.logEvent(AnalyticEvents.FORM_SUBMIT, {
                        "Form_Name": "Scouting note save",
                        "Username": AccountService.instance.userData.UserMail,
                        "User_ID": AccountService.instance.userData.UserRoleId,
                        "Session_ID": AnalyticService.SessionId,
                    })
                    if (Array.isArray(imageList)) {
                        let requestList = []
                        let imagesFormData = new FormData()
                        imageList = imageList.filter(t => t && t.type === 'new')
                        if (imageList.length > 0) {
                            imageList.forEach(el => {
                                // imagesFormData.append('photo', el.image, `orbit_image_${Date.now()}.${el.format}`)
                                imagesFormData.append('Files', el.image, `orbit_image_${Date.now()}.${el.format}`)
                                // let formData = prepareImagesFormData();
                            })
                            if (payload.IsFarmerProblem) {
                                imagesFormData.append("IsFarmerProblem", `${payload.IsFarmerProblem}`)
                            }
                            imagesFormData.append("Lon", payload.Lon.toString())
                            imagesFormData.append("Lat", payload.Lat.toString())
                            imagesFormData.append("TempFarmAttId", payload.TempFarmAttId.toString())
                            imagesFormData.append("FlagId", response.Data.toString())
                            imagesFormData.append("Description", payload.Description)

                            let flagId = response.Data
                            // let uploadUrl = environment.baseApiUrl + "upload/" + flagId + "/photo"
                            let uploadUrl = environment.gatewayApiUrl + "v2/api/farm/flags/photos/save"
                            // observer.next({ flag: ['completed', response], images: ['start'] })
                            this.mediaService.uploadPhoto(uploadUrl, imagesFormData).subscribe(imageResponse => {
                                if (imageResponse.IsSuccess)
                                    observer.next({ flag: ['completed', response], images: ['completed', imageResponse] })
                                else
                                    observer.next({ flag: ['completed', response], images: ['failed', imageResponse] })
                                this.GetFarmNotes(payload.TempFarmAttId)
                                observer.complete()
                            })
                        } else {
                            observer.next({ flag: ['completed', response], images: ['no_image'] })
                            this.GetFarmNotes(payload.TempFarmAttId)
                            observer.complete()
                        }
                    } else {
                        observer.next({ flag: ['completed', response], images: ['no_image'] })
                        this.GetFarmNotes(payload.TempFarmAttId)
                        observer.complete()
                    }
                } else {
                    observer.next({ flag: ['failed', response], images: [] })
                    this.GetFarmNotes(payload.TempFarmAttId)
                    observer.complete()
                }
            }, err => {
                observer.next({ flag: ['error', err], images: [] })
                observer.complete()
            })
        })
    }

    public GetSprayingHours({ lat, lng }) {
        return this.farmApiService.GetSprayingHours({ lat, lng })
    }

    public GetPlacesSearch(query, types?: string) {
        return new Promise((resolve, reject) => {
            let lang = this.translate.currentLang //LanguageService.currentLanguage
            this.farmApiService.SearchPlcesWithoutType(query, lang).subscribe(response => {
                resolve(response.features)
            }, err => {
                reject(err)
            })
        })
    }

    public SaveFreeFarm(tempFarmAttId) {
        let farmCheck = this.farmList.find(t => t.TempFarmAttId === tempFarmAttId);
        if (farmCheck)
            return this.farmApiService.SaveAsFree(tempFarmAttId)
        else {
            let errorResponse: IGenericResponse = {
                Data: null,
                IsSuccess: false,
                Message: "farm_not_found",
                StatusCode: -1
            }
            return new Observable(observer => observer.error(errorResponse))
        }
    }


    /**
     * 
     * @param tempFarmAttId 
     * @param lastDate YYYY-MM-DD
     */
    public GetSatelliteDates(tempFarmAttId: number, lastDate: string): Observable<Array<SatelliteImages>> {
        return new Observable(observer => {
            this.farmApiService.GetSatelliteDates(tempFarmAttId, lastDate).subscribe(response => {
                if (response.IsSuccess) {
                    // response.Data = []
                    if (response.Data) {
                        let tempArray = response.Data.map(t => {
                            let tempItem = new SatelliteImages(this.farmApiService);
                            tempItem.build(t);
                            return tempItem;
                        })
                        observer.next(tempArray);
                    } else {
                        observer.next([])
                    }
                } else {
                    observer.error(response)
                }
                observer.complete()
            }), err => {
                observer.error(err)
                observer.complete()
            }
        })
    }
    ClaimFreeFarm(props: number[]) {
        return new Promise((resolve, reject) => {
            let requestsObj = {}
            props.forEach(el => {
                requestsObj[el] = this.farmApiService.GetIapFreeFarm(el).pipe(catchError(val => of({ Data: false, IsSuccess: true, Message: "", StatusCode: 1 } as IGenericResponse<boolean>)))
            })
            forkJoin(requestsObj).subscribe(response => {
                resolve(true);
            }, err => {
                reject(false)
            })
        })
    }

    RemoveImportedFarm(tempFarmAttId) {
        let farmerSubId = AccountService.instance.userData.FarmerSubscriberId; //this.accountService.userData.FarmerSubscriberId
        return this.farmApiService.RemoveImportedFarm(tempFarmAttId, farmerSubId);
    }

    public CheckIfAnyFarmDeletedByOwner() {

        this.farmApiService.IsThereAnyDeletedFarm().subscribe(res => {
            if (res.IsSuccess) {
                let data: Array<{ TempFarmAttId, FarmName, GeoItem }> = res.Data;
                if (Array.isArray(data) && data.length) {
                    let farmNames = data.map(t => t.FarmName).join(", ");
                    this.modalService.presentModal({
                        component: DeletedFarmsAlertModalComponent,
                        componentProps: { farmList: data },
                        options: ModalService.defaultOptionsTransparent
                    }).then()
                }
            }
        })
    }


    currentFarmProbSpots: Array<ProbSpotDetection>
    GetProbSpots(tempFarAttrId: number, imageDate: string) {
        return new Observable(observer => {
            this.farmApiService.GetSpotDetectionList(tempFarAttrId, imageDate).subscribe({
                next: response => {
                    let tempArray = response.Data.map(t => {
                        let GeoJSONObj = JSON.parse(t.GeoJson)
                        GeoJSONObj.properties.spotId = t.Id
                        GeoJSONObj.properties.fill = "#d52d00"
                        GeoJSONObj.properties.opacity = 0.646
                        GeoJSONObj.properties.stroke = "#8F5DF9"
                        GeoJSONObj.properties['stroke-width'] = 1.5
                        t.GeoJSONObj = GeoJSONObj
                        // t.CheckState = null
                        return t
                    })
                    this.currentFarmProbSpots = tempArray.filter(t => t.CheckState == null || t.CheckState == true)
                    // this.currentFarmProbSpots[0].CheckState = null
                    observer.next(this.currentFarmProbSpots)
                    observer.complete()
                },
                error: err => {
                    observer.error(err)
                    observer.complete()
                }
            })
        })
    }

    ReportMistakenSpotDetection(spotId) {
        return new Promise((resolve, reject) => {
            this.farmApiService.UpdateSpotStatus(spotId, false).subscribe(res => {
                if (res.IsSuccess && res.Data)
                    resolve(true)
                else
                    reject(false)
            })
        })
    }

    ConfirmProblemOnSpotDetection(spotId) {
        return new Promise((resolve, reject) => {
            this.farmApiService.UpdateSpotStatus(spotId, true).subscribe(res => {
                if (res.IsSuccess && res.Data)
                    resolve(true)
                else
                    reject(false)
            })
        })
    }

    OpenFarmListModal(height: number = -1, changeCurrentField: boolean = true, customFilterFn: (t: FarmControl) => boolean = (t: FarmControl) => true, multiSelect: boolean = false, preSelectedList: number[] = [], checkForPackage: boolean, useConfirm: boolean) {
        let observer = new Subject()
        this.modalService.presentModal({
            component: FieldListComponent,
            componentProps: { height, changeCurrentField, customFilterFn, multiSelect, preSelectedList, checkForPackage, useConfirm },
            options: ModalService.defaultOptionsTransparent
        }).then(res => {
            res.modalObj.onDidDismiss().then(onDismiss => {
                if (onDismiss.role == "farm_selected")
                    observer.next(onDismiss.data)
                else
                    observer.next(null)
                observer.complete()
            }).catch(err => {
                observer.error(err)
                observer.complete()
            })
        }).catch(err => {
            observer.error(err)
            observer.complete()
        })
        return observer.asObservable()
    }

    SaveSpraying(props: { Id, Date, ChemicalTypeId, TempFarmAttId, AppliedDose, UnitId }) {
        return this.farmApi.SaveSprayingData(props)
    }

}