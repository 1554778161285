import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import * as googleLibPhone from 'google-libphonenumber'
import { Observable, Subject } from 'rxjs';
import ICountryModel from '../models/interfaces/intl-phone-input.interface';
import { ClientBaseApiService } from '../api/client-base-api.service';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';


@Injectable({
    providedIn: 'root'
})
export class IntlPhoneInputService {
    controlCountryList: Array<ICountryModel> = []
    filteredCountries: Array<ICountryModel> = []
    countryCodesObservable: Observable<any> = new Observable<any>()
    private countryCodeSubject: Subject<Array<ICountryModel>> = new Subject<Array<ICountryModel>>()
    //   private _intlPhoneComponentSubject: Subject<IntlSubjectResponse> = new Subject<IntlSubjectResponse>();
    private _countryListUrl: string = ""
    public flagDirUrl: string = ""
    /**
     * 0 => no process
     * 1 => downloading
     * 2 = downloaded
     */
    private countryListDownloadStatus: 0 | 1 | 2 = 0

    constructor(private clientApi: ClientBaseApiService) {

    }

    //   public intlPhoneComponentListener(): Observable<IntlSubjectResponse> {
    //     return this._intlPhoneComponentSubject.asObservable();
    //   }

    //   public intlPhoneComponentSend(value: IntlSubjectResponse) {
    //     this._intlPhoneComponentSubject.next(value)
    //   }

    GetCountryCodes() : Observable<Array<ICountryModel>> {
        return new Observable(observer => {
            if (this.controlCountryList && this.controlCountryList.length > 0) {
                observer.next(this.controlCountryList)
                observer.complete()
            } else {
                this.clientApi.externalApiCall<Array<any>>({
                    url: environment.countryList,
                    payload: null,
                    requestType: HttpRequestTypes.GET
                }).subscribe(response => {
                    for (var i = 0; i < response.length; i++) {
                        var c = response[i];
                        var model = {
                            name: c[0],
                            iso2: c[1],
                            dialCode: c[2],
                            priority: c[3] || 0,
                            areaCodes: c[4] || null
                        };
                        let check = this.controlCountryList.find(t => t.iso2 == model.iso2)
                        if (!(check && Object.keys(check).length > 0))
                            this.controlCountryList.push(model)
                    }
                    observer.next(this.controlCountryList)
                    observer.complete()
                }
                )
            }
        })
    }

    GetUtilInstance() {
        return googleLibPhone.PhoneNumberUtil.getInstance()
    }

    GetPhonePlaceholderPhonenumber(countryCode: string, phoneNumberType: googleLibPhone.PhoneNumberType = googleLibPhone.PhoneNumberType.MOBILE) {
        try {
            if (!countryCode || countryCode == '')
                return "";
            let phone = this.GetUtilInstance().getExampleNumberForType(countryCode, phoneNumberType);
            return this.GetUtilInstance().format(phone, 1)
        } catch (error) {
            return null
        }
    }

    /**
     * 
     * @param countryCode tr, gb, us
     * @returns 1 valid, 0 invalid, -1 countryList not loaded or not found
     */
    CheckIfCountryCodeValid(countryCode: string): Number {
        if (!this.controlCountryList || this.controlCountryList.length == 0)
            return -1
        let check = this.controlCountryList.filter(t => t.iso2?.toLocaleLowerCase() == countryCode.toLocaleLowerCase())
        if (check && check.length > 0)
            return 1
        else
            return 0
    }

    /**
     * 
     * @param phone  
     * @param countryCode 
     * @returns 
     */
    CheckValidation(phone: string, countryCode?: string) {
        try {
            let phoneNumberObj = this.GetUtilInstance().parse(phone, countryCode)
            let isValid = this.GetUtilInstance().isValidNumber(phoneNumberObj)
            return isValid
        } catch (error) {
            return false
        }
    }

    GeneratePlaceholderAndMask(selectedCountry: any): { placeholder: string, mask: string, prefix: string } {
        let controlNumers = "1234567890"
        let inputPrefix = `+${selectedCountry?.dialCode} `
        let telPlaceholder = this.GetPhonePlaceholderPhonenumber(selectedCountry.iso2, 1)
        let splittedPlaceholder = telPlaceholder.replace(inputPrefix, '').split('').map(t => {
            if (controlNumers.includes(t))
                return '0'
            else
                return t
        }).join('')
        telPlaceholder = telPlaceholder.replace(inputPrefix, '').trim()
        let inputMask = splittedPlaceholder
        return { placeholder: telPlaceholder, mask: inputMask, prefix: inputPrefix }
    }

    GenerateGoogleNumber(phone: string, countryCode: string): googleLibPhone.PhoneNumber {
        try {
            let phoneNumberObj = this.GetUtilInstance().parseAndKeepRawInput(phone, countryCode)
            return this.GetUtilInstance().isValidNumber(phoneNumberObj) ? phoneNumberObj : null
        } catch (error) {
            return null
        }
    }
}