import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { IDoktarTransactionModel, InAppPurchaseResponse, IPriceCalculateBodyModel, IPriceCalculationRespBulk, IPriceCalculationResponse, IPurchaseSaveBodyModel } from '../models/interfaces/in-app-purchase-models';
import { ClientBaseApiService } from './client-base-api.service';

@Injectable({providedIn: 'root'})
export class AppStoreApiService {
    constructor(private clientApi: ClientBaseApiService) { }

    public GetConsumableList(){
        let url = environment.gatewayApiUrl + "v2/api/iap/packages"
        return this.clientApi.baseApiCall<Array<InAppPurchaseResponse>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public CalculatePrice(payload:IPriceCalculateBodyModel){
        let url = environment.gatewayApiUrl + "v2/api/iap/calculate/price"
        return this.clientApi.baseApiCall<IPriceCalculationResponse>({url,payload, requestType:HttpRequestTypes.POST})
    }

    public CalculatePriceBulk(payload:IPriceCalculateBodyModel){
        let url = environment.gatewayApiUrl + "v2/api/iap/calculate/price/bulk"
        return this.clientApi.baseApiCall<IPriceCalculationRespBulk[]>({url,payload, requestType:HttpRequestTypes.POST})
    }

    public CompletePurchaseOperation(payload:IPurchaseSaveBodyModel){
        let url = environment.gatewayApiUrl + "v2/api/iap/save"
        return this.clientApi.baseApiCall<boolean>({url,payload, requestType:HttpRequestTypes.POST});
    }

    public ListTransactions(){
        let url = environment.gatewayApiUrl + "v2/api/iap/transactions"
        return this.clientApi.baseApiCall<Array<IDoktarTransactionModel>>({url, payload:null, requestType:HttpRequestTypes.GET})
    }

    public RequestExceedsLimitFile(props){
        let url = environment.gatewayApiUrl + "v2/api/iap/save/callcenter"
        return this.clientApi.baseApiCall({url, payload:props, requestType: HttpRequestTypes.POST})
    }
    
    public SendPurchaseErrorPayload(Payload:string){
        let url = environment.gatewayApiUrl + "v2/api/iap/save/error";
        return this.clientApi.baseApiCall({url, payload: {Payload}, requestType: HttpRequestTypes.POST})
    }
}