import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PopoverTemplateComponent } from "../helper/class/popover-template.component";
import { AlertModalComponent } from "../helper/components/alert-modal/alert-modal.component";
import { ContactUsModalComponent } from "../helper/components/contact-us-modal/contact-us-modal.component";
import { FloatAlertModalComponent } from "../helper/components/float-alert-modal/float-alert-modal.component";
import { BaseModalModule } from "./base-modal.module";
import { SharedModule } from "./shared.module";
import { ModalBaseComponent } from "../helper/components/modal-base/modal-base.component";
// import { IconModule } from "./icon.module";

@NgModule({
    imports: [IonicModule,FormsModule,CommonModule,TranslateModule, SharedModule, BaseModalModule],
    declarations:[AlertModalComponent, FloatAlertModalComponent, PopoverTemplateComponent,ContactUsModalComponent],
    exports:[AlertModalComponent, FloatAlertModalComponent, PopoverTemplateComponent,ContactUsModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AlertModule{}