export const SCREEN_NAME_BY_COMPONENT_NAME = {
    MapPage: {
        screenName: "Map"
    },
    ExplorePage: {
        screenName: "ExplorePage"
    },
    AddFieldPage: {
        screenName: "Adding Field Method"
    },
    AddFieldPageStepDrawing: {
        screenName: "Draw a field"
    },
    AddFieldPageStepDrawingSearch: {
        screenName: "Draw a field-search"
    },
    AddFieldPageStepDrawingStep2: {
        screenName: "Add Field Drawing-2"
    },
    AddFieldPageStepDrawingStep3: {
        screenName: "Add Field Drawing-3"
    },
    AddFieldPageStepDrawingStep4: {
        screenName: "Add Field Drawing-4"
    },
    AddFieldPageStepDrawingStep5: {
        screenName: "Add Field Drawing-5"
    },
    LoginComponent: {
        screenName: "Sign-in"
    },
    RegisterComponent: {
        screenName: "RegisterName"
    },
    RegisterComponentStep2: {
        screenName: "RegisterNumber"
    },
    RegisterComponentStep3: {
        screenName: "RegisterPassword"
    },
    FieldComparisonPage: {
        screenName: "FieldComparisonPage"
    },
    IrrigationProgramPage: {
        screenName: "IrrigationProgramPage"
    },
    ReferFriendPage: {
        screenName: "ReferFriendPage"
    },
    ReferDetailsComponent: {
        screenName: "ReferDetailsComponent"
    },
    SprayingHoursPage: {
        screenName: "Spraying hours"
    },
    SprayingDetailsComponent: {
        screenName: "Spraying hours-detail"
    },
    AddSprayingComponent: {
        screenName: "Spraying hours-Add date"
    },
    NewVraMapComponent: {
        screenName: "New VRA Map"
    },
    NewVraMapComponentDetail: {
        screenName: "VRA-details"
    },
    NewVraMapComponentAddDate: {
        screenName: "VRA-add a date"
    },
    NewVraMapComponentAddDateImage: {
        screenName: "VRA-add a date-image"
    },
    VraMapsPage: {
        screenName: "VRA Story"
    },
    VraMapsEmptyPage: {
        screenName: "VRA-empty"
    },
    VraDetailsComponent: {
        screenName: "VRA-details-ready"
    },
    IrtemApplicationComponent:{
        screenName: "Irtem"
    },
    WeatherForecastPage: {
        screenName: "WeatherForecastPage"
    },
    WeatherDetailComponent: {
        screenName: "WeatherDetailComponent"
    },
    AccountSettingsPage: {
        screenName: "AccountSettingsPage"
    },
    QuestionDetailsComponent: {
        screenName: "QuestionDetailsComponent"
    },
    EngineerSupportPage: {
        screenName: "EngineerSupportPage"
    },
    MyPlansPage: {
        screenName: "MyPlansPage"
    },
    NotificationsComponent: {
        screenName: "NotificationsComponent"
    },
    ProfileSettingsComponent: {
        screenName: "ProfileSettingsComponent"
    },
    AccountPage: {
        screenName: "AccountPage"
    },
    NotificationSettingsComponent: {
        screenName: "NotificationSettingsComponent"
    },
    NotificationSettingsDetailComponent: {
        screenName: "NotificationSettingsDetailComponent"
    },
    ComparePage: {
        screenName: "ComparePage"
    },
    CompareFarmsComponent: {
        screenName: "CompareFarmsComponent"
    },
    ComparisonResultComponent: {
        screenName: "ComparisonResultComponent"
    },
    FaQuestionsComponent: {
        screenName: "FaQuestionsComponent"
    },
    MyGuideComponent: {
        screenName: "MyGuideComponent"
    },
    NewFeaturesComponent: {
        screenName: "NewFeaturesComponent"
    },
    FarmDetailComponent: {
        screenName: "Field Detail"
    },
    NoteDetailModalComponent: {
        screenName: "NoteDetailModalComponent"
    },
    MyFieldsPage: {
        screenName: "Fields"
    },
    ToolsPage: {
        screenName: "Tools"
    },
    ForgetPasswordComponent: {
        screenName: "Forget password"
    },
    ForgetPasswordComponentSmsValidation: {
        screenName: "Enter Code"
    },
    ForgetPasswordComponentNewPassword: {
        screenName: "New password"
    },
    PurchasePlansComponent: {
        screenName: "Plans&Pricing"
    },
    ImageryPage: {
        screenName: "Map"
    },
    AppStartPage: {
        screenName: "Splash"
    },
    AuthPage: {
        screenName: "Onboarding"
    },
    FieldImportComponent:{
        screenName: "Import"
    },
    FieldImportComponentStepSelection:{
        screenName: "Import-selection"
    },
    FieldImportComponentStepFailed:{
        screenName: "Import-failed"
    },
    FieldImportComponentStepNotFound:{
        screenName: "Import-not-found"
    },
    FieldImportComponentStepSuccess:{
        screenName: "Import-successfully"
    },
    ParcelInquiryComponent:{
        screenName: "Parcel inquiry"
    },
    ParcelInquiryComponentNotFind:{
        screenName: "Parcel inquiry-not find"
    },
    ContactUsComponent:{
        screenName: "Contact Us"
    }
}