import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAgreementComponent } from 'src/app/core/helper/components/user-agreement/user-agreement.component';
import { IButtonOpts } from 'src/app/core/models/interfaces/modal-components-models';
import { AccountService } from 'src/app/core/services/account.service';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { FarmService } from 'src/app/core/services/farm.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UnitPreferenceService } from 'src/app/core/services/unit-preference.service';
import { environment } from 'src/environments/environment';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { VisualThemeModeService } from 'src/app/core/services/visual-theme-mode.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import IMenuConstants from 'src/app/core/models/interfaces/menu-constants';
import { IonModal } from '@ionic/angular';


@Component({
  selector: 'orbit-account-settings',
  templateUrl: './account-settings.page.html',
  styleUrls: ['./account-settings.page.scss'],
})
export class AccountSettingsPage implements OnInit {
  @ViewChild("appearanceModal") appearanceModal: IonModal
  platform = Capacitor.getPlatform()
  appVersion = null;

  settingsConstants: Array<IMenuConstants> = [
    {
      key: "profile-change-password-item",
      title: "account.change_password_lbl",
      subtitle: "account.change_password_desc",
      action: () => {
        this.modalService.presentModal({
          component: ChangePasswordComponent,
          componentProps: {},
          options: ModalService.defaultOptionsTransparent
        }).then(res => {
        }).catch(err => {
        })
      },
      actionOutcomeType: 'open modal'
    },
    {
      key: "account-unit-pref-item",
      title: "account.unit_pref_lbl",
      subtitle: "account.unit_pref_desc",
      action: this.changeUnitPreferances.bind(this),
      actionOutcomeType: "open modal"
    },
    {
      key: "account-lang-item",
      title: "account.language_lbl",
      subtitle: "account.language_desc",
      action: this.changeLanguage.bind(this),
      actionOutcomeType: "open modal"
    },
    {
      key: "account-setting-notifications-item",
      title: "account.notifications_lbl",
      subtitle: "account.notifications_desc",
      action: () => this.router.navigateByUrl("/notification-settings"),
      actionOutcomeType: "navigate"
    },
    {
      key: "account-theme-item",
      title: "account.change_theme_lbl",
      subtitle: "account.change_theme_desc",
      action: () => this.appearanceModal.present(),
      actionOutcomeType: "open modal"
    }
  ]



  isDarkModeEnabled: boolean = false
  constructor(public storageService: StorageService, public langService: LanguageService, private themeService: VisualThemeModeService, private analyticService: AnalyticService, private farmService: FarmService, private toastService: ToastService, private router: Router, private loadingService: LoadingService, private accountService: AccountService, private translate: TranslateService, private modalService: ModalService, private alertService: ClientAlertService, private unitPrefService: UnitPreferenceService) { }
  packageName: string
  ngOnInit() {
    if (Capacitor.isNativePlatform())
      App.getInfo().then(response => {
        this.appVersion = response.version + " - " + response.build
      })
    else
      this.appVersion = environment.version
  }
  ionViewWillEnter() {
    this.storageService.get(StorageKeys.DARK_MODE).then(res => {
      this.isDarkModeEnabled = res.Data || false
    })
  }

  changeUnitPreferances() {
    this.unitPrefService.openPrefModal();
  }
  changeLanguage() {
    this.langService.openOptionsMenu()
  }
  deleteAccountAction() {
    this.loadingService.showLoading()
    this.accountService.DeleteAccount().then(res => {
      this.farmService.allFlags = null
      this.farmService.farmList = null
      this.farmService.unregisteredFarmCounts = -1;
      this.farmService.archivedFarmList = null
      // this.farmService.farmImages = null
      this.farmService.currentSatellite = null
      this.farmService.imageCoords = null
      setTimeout(() => {
        this.loadingService.hideLoading()
      }, 1000);
      this.router.navigate(["/auth"])
    }).catch(err => {
      let buttons: IButtonOpts[] = [
        {
          callback: () => { },
          id: "delete-account-error-alert-close-btn",
          color: "primary",
          fill: "clear",
          text: this.translate.instant("general.ok")
        }
      ]
      this.alertService.PresentAlert({
        buttons,
        description: this.translate.instant("account.delete_error_desc"),
        title: this.translate.instant("account.delete_error_title"),
        image: "../../../../../assets/images/delete_icon_v1.svg"
      }, false)
      this.loadingService.hideLoading()
    })
  }
  deleteAccount() {
    let buttons: IButtonOpts[] = [
      {
        callback: () => {
          // this.analyticService.logEvent("account.confirm_delete")
          this.deleteAccountAction()
        },
        id: "delete-account-confirm-btn",
        color: "danger",
        fill: "solid",
        text: this.translate.instant("account.confirm_delete_btn")
      },
      {
        callback: () => {
          // this.analyticService.logEvent("account.cancel_delete")
        },
        id: "delete-account-cancel-btn",
        color: "dark",
        fill: "clear",
        text: this.translate.instant("account.cancel_delete_btn")
      }
    ]
    this.alertService.PresentAlert({
      buttons,
      description: this.translate.instant("account.delete_desc"),
      title: this.translate.instant("account.delete_title")
      // image: "../../../../../assets/images/delete_icon_v1.svg"
    }, false)
  }
  changePassword() {
    this.modalService.presentModal({
      component: ChangePasswordComponent,
      componentProps: {},
      options: ModalService.defaultOptionsTransparent
    }).then(res => {
    }).catch(err => {
    })
  }

  changeTheme(status) {
    this.isDarkModeEnabled = status
    // this.isDarkModeEnabled = !this.isDarkModeEnabled
    if (this.isDarkModeEnabled)
      this.themeService.setDarkTheme()
    else
      this.themeService.setLightTheme()
    // this.isDarkModeEnabled = !status
  }
}
