import { Animation, AnimationController } from '@ionic/angular'

export const pageAnimation = (_: HTMLElement, opts: any): Animation => {
    var animationCtrl = new AnimationController()
    // create root transition
    const rootTransition = animationCtrl
        .create()
        .duration(opts.duration || 333)
        .easing('cubic-bezier(0.7,0,0.3,1)');

    const enterTransition = animationCtrl.create().addElement(opts.enteringEl);
    const exitTransition = animationCtrl.create().addElement(opts.leavingEl);

    enterTransition.fromTo('opacity', '0.5', '1');
    exitTransition.fromTo('opacity', '1', '0.5');

    if (opts.direction === 'forward') {
        enterTransition.fromTo('transform', 'translateX(100%)', 'translateX(0%)');
        exitTransition.fromTo('transform', 'translateX(0%)', 'translateX(-100%)');
    } else {
        enterTransition.fromTo('transform', 'translateX(-100%)', 'translateX(0%)');
        exitTransition.fromTo('transform', 'translateX(0%)', 'translateX(100%)');
    }

    rootTransition.addAnimation([enterTransition, exitTransition]);
    return rootTransition;
}