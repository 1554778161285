<ion-header>
  <ion-toolbar class="p-horizontal-16">
    <ion-buttons slot="start">
      <app-back-button [useCustom]="true" id="signIn_Back" color="dark" size="small" [routerLink]="['/auth']" routerDirection="back" fill="outline" shape="round"></app-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="openModal()" id="login-open-lang-options">
        <span class="font-size-14 font-weight-700">
          {{"lang.default" | translate}} <ion-icon class="m-left-5" style="margin-bottom:-3px" name="chevron-down-outline"></ion-icon>
        </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>

  <ion-grid fixed class="p-horizontal-15" *ngIf="loginForm">

    <form [formGroup]="loginForm" (keydown.enter)="$event.preventDefault()">
      <ion-row>
        <ion-col size="12">
          <h2 class="font-size-28 font-weight-900 letter-space-minus-013 m-top-6">{{"auth.login_title" | translate}}</h2>
          <p class="font-size-14">{{"auth.login_login_text" | translate}}</p>
        </ion-col>

        <ion-col size="12">
          <div class="d-flex row gap-12">
            <div>
              <ion-item shape="round" class="border-basic focus-border full-height arpa-input-item" id="signIn_PhoneCode" color="ultra-light" lines="none" (click)="openFlagsModal()" >
                <img slot="start" class="height-20 m-right-8-i" [src]=" ('countryFlags' | environment) + (selectedCountry?.iso2 | uppercase)+'.svg'" alt="" srcset="">
                <ion-label class="font-size-14-i font-weight-600" color="dark">+{{selectedCountry?.dialCode}}</ion-label>
                <ion-icon class="font-size-14-i" color="dark" slot="end" name="chevron-forward-outline"></ion-icon>
              </ion-item>
            </div>
            <div class="flex-1">
              <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="ultra-light" lines="none">
                <ion-label class="form-label" style="margin-top:-12px" position="floating">
                  {{'auth.phone_number' | translate}}
                </ion-label>
                <ion-input required formControlName="phone" type="tel" id="signIn_PhoneNumber" (ionInput)="inputChanged($event)" [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate"></ion-input>
              </ion-item>
            </div>
          </div>
        </ion-col>
        <ion-col size="12" class="m-top-11">
          <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="ultra-light" lines="none">
            <ion-label class="form-label" style="margin-top:-12px" position="floating">{{'auth.password' |translate}}</ion-label>
            <ion-input required formControlName="password" (change)="setPwd($event)" id="signIn_Password" [type]="passwordVisibility ? 'text' : 'password'"> </ion-input>
            <ion-icon class="ion-justify-content-center ion-align-self-center" slot="end" id="signIn_ShowPass" (click)="passwordVisibility = !passwordVisibility" [src]="( passwordVisibility ? 'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
          </ion-item>
          <ion-text color="danger" *ngIf="areCrendentialsWrong">
            <h1 class="font-size-12">{{ errorMessage | translate}}</h1>
          </ion-text>
        </ion-col>
        <ion-col size="12">
          <ion-text color="dark" class="ion-text-right d-block ">
            <span (click)="forgetPassword()" id="signIn_ForgotPass" class="font-size-13 text-decoration-underline">{{'auth.forget_password' | translate}}</span>
          </ion-text>
        </ion-col>
        <ion-col size="12" class="m-top-39">
          <ion-button (click)="onSubmit()" id="signIn_signInButton" [disabled]="loginForm.invalid" expand="block" fill="solid" shape="round">
            {{"auth.login_btn" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>

  <!-- <orbit-external-login (socialLoginExternal)="loginWithSocial($event)"></orbit-external-login> -->

</ion-content>