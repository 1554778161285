import { Component, OnInit } from '@angular/core';
import { BaseAlertModal } from 'src/app/core/models/classes/base-alert-modal';
import { ModalService } from 'src/app/core/services/modal.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-float-alert-modal',
  templateUrl: './float-alert-modal.component.html',
  styleUrls: ['./float-alert-modal.component.scss'],
})
export class FloatAlertModalComponent extends BaseAlertModal implements OnInit {
  contentClass: any = "bg-default"//
  constructor(private modalService:ModalService) {
    super()
    super.alertModalService = modalService
   }


   ngOnInit() {
    super.ngOnInit()
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

  ionViewDidEnter() {
  }

  // dismissModal() {
  //   this.modalService.dismissByModalId(this.id, {}).then(response => { })
  // }

  applyBtn() { }
  clear() { }

  dismissModal(id) {
    this.dismiss(null)
    // this.modalService.dismissByModalId(id, {}, "canceled").then(response => {})
  }
  
  buttonClick(ev, prop) {
    prop.callback(ev);
    if(!this.preventDismiss)
    this.dismiss({}, 'callbackClicked')
  }
}
