import { VraZoneColors } from "src/app/pages/vra-maps/components/vra.constants";
import { IVRASaveModel, VraMapApiService } from "../../api/vra-map-api.service";
import { IFertilizerRateZone } from "../../models/interfaces/fertilizer-rate";
import { IGenericConstantModel } from "../../models/interfaces/generic-constant-model";
import { IVraResponse } from "../../models/interfaces/vra-map-response";
import FarmDataModel from "./farm/farm-data.class";


export default class VraItem implements IVraResponse {

    /**
     * farm info
     * operation type
     * additioanal info depends on operation type
     * - product info
     * - implementation strategy
     * - planned dose
     * - dose unit
     * those 3 may change
     * 
     * map info
     * - zone count
     * - tiff info
     * - dose difference
     * 
     * export options
     * 
     * agri smth 
     * 
     * irtem entegrations
     * 
     */

    private _vraApi: VraMapApiService


    public farm: FarmDataModel // may be FarmControl


    /**
     *
     */
    constructor(vraApiService?) {
        this._vraApi = vraApiService
    }

    Id: number;
    PlannedAverageDose: number;
    CreatedOn: string;
    ProductName: string;
    State: number;
    StateName: string;
    StrategyId: number;
    StrategyName: string;
    UnitId: number;
    UnitName: string;
    VraImageUrl: string;
    VraTypeId: number;
    VraTypeName: string;
    FarmName: string;
    SensingDates: string[];
    FertilizerTypeId:number
    
    private _LastChoosePercentage: number;
    public get LastChoosePercentage(): number {
        return this._LastChoosePercentage || 50;
    }
    public set LastChoosePercentage(value: number) {
        this._LastChoosePercentage = value;
    }

    private _LastChooseZone: number;
    public get LastChooseZone(): number {
        return this._LastChooseZone || 3;
    }
    public set LastChooseZone(value: number) {
        this._LastChooseZone = value;
    }

    SuggestedTotalDose?: number;
    ExportOptions?: IGenericConstantModel[];
    TotalFarmArea?: number
    TempFarmAttId?: number
    LeftCalibrationValue?: number
    RightCalibrationValue?: number

    Zones: IFertilizerRateZone[]

    public get zoneData(): IFertilizerRateZone {
        if (this.Zones) {
            let _lastChooseZone = this.LastChooseZone || 3
            return this.Zones.find(t => t.ZoneNumber == _lastChooseZone)
        } else
            return null
    }

    injectVraApiService(vraApiService: VraMapApiService) {
        this._vraApi = vraApiService
    }

    buildItem(props: IVraResponse) {
        this.Id = props.Id
        this.PlannedAverageDose = props.PlannedAverageDose
        this.CreatedOn = props.CreatedOn
        this.ProductName = props.ProductName
        this.State = props.State
        this.StateName = props.StateName
        this.StrategyId = props.StrategyId
        this.StrategyName = props.StrategyName
        this.UnitId = props.UnitId
        this.UnitName = props.UnitName
        this.VraImageUrl = props.VraImageUrl
        this.VraTypeId = props.VraTypeId
        this.VraTypeName = props.VraTypeName
        this.FarmName = props.FarmName
        this.SensingDates = props.SensingDates
        this.LastChoosePercentage = props.LastChoosePercentage
        this.LastChooseZone = props.LastChooseZone
        this.SuggestedTotalDose = props.SuggestedTotalDose
        this.ExportOptions = props.ExportOptions
        this.TotalFarmArea = props.TotalFarmArea
        this.TempFarmAttId = props.TempFarmAttId

    this.LeftCalibrationValue = props.LeftCalibrationValue
    this.RightCalibrationValue = props.RightCalibrationValue

        if (this.State !== 1511) {
            setTimeout(() => {
                this.checkIsReady()
            }, 1000 * 30);
        }
    }

    public checkIsReady() {
        this._vraApi.GetVraListRequest({ id: this.Id }).subscribe(res => {
            if (res.IsSuccess && res.Data) {
                let data = res.Data.find(t => t.Id === this.Id)
                if (data) {
                    this.buildItem(data)
                }
            }
        })
    }

    public get avatarImage(): string {
        let tempUrl = this.VraImageUrl
        return tempUrl ? tempUrl.replace("!zoneid", (this.LastChooseZone || 3).toString()) : ''
    }

    public get staticImage(): Promise<string> {

        return null
    }

    getVraRateValue(doseDiff, zone) {
        if (this._vraApi)
            throw new Error("")
        //bu servisin bitmesi durumunu takip etmedim. Anlık olarak önemli olmayan bir kayıt. O yüzden takip edilmiyor.
        this._vraApi.setLastProdZoneAndDoseDiff(this.Id, doseDiff, zone).subscribe()
        return this._vraApi.getFertilizerRate(this.Id, doseDiff).subscribe(res => {
            if (res.IsSuccess && res.Data) {
                this.LastChooseZone = zone
                this.LastChoosePercentage = doseDiff
            } else {

            }
        })
    }

    getVraRate({ selectedZone = 3 }) {
        if (this.State === 1510)
            return
        this.LastChoosePercentage = this.LastChoosePercentage == null || this.LastChoosePercentage == undefined ? 50 : this.LastChoosePercentage
        this.LastChooseZone = this.LastChooseZone || selectedZone
        this._vraApi.getFertilizerRate(this.Id, this.LastChoosePercentage).subscribe({
            next: next => {
                this.Zones = next.Data.Zones.map(t => {
                    t.ZoneList.forEach(el => {
                        el.Color = VraZoneColors["zones" + t.ZoneNumber][el.OrderNumber - 1]
                    })
                    return t;
                })
            }
        })
    }

    async setLastProdZoneAndDoseDiff({ zonePercentage, selectedZone }) {
        if (zonePercentage && zonePercentage > 0)
            this.LastChoosePercentage = zonePercentage
        if (selectedZone && selectedZone > 0)
            this.LastChooseZone = selectedZone
        this._vraApi.setLastProdZoneAndDoseDiff(this.Id, this.LastChoosePercentage, this.LastChooseZone).subscribe()
    }

    async updateVraData({ strategy = null, dose = null, unit = null }): Promise<void> {
        return new Promise((resolve, reject) => {
            if (dose) {
                this.PlannedAverageDose = dose
            }
            if (strategy) {
                this.StrategyId = strategy.ConstantId
                this.StrategyName = strategy.ConstantName
            }
            if (unit) {
                this.UnitId = unit.ConstantId
                this.UnitName = unit.ConstantName
            }
            let payload: IVRASaveModel = {
                Id: this.Id,
                PlannedAverageDose: this.PlannedAverageDose,
                ProductName: this.ProductName,
                SensingDates: this.SensingDates,
                StrategyId: this.StrategyId,
                UnitId: this.UnitId,
                TempFarmAttId: this.TempFarmAttId,
                FertilizerTypeId: this.FertilizerTypeId,
                VraTypeId: this.VraTypeId,
                LeftCalibrationValue: this.LeftCalibrationValue,
                RightCalibrationValue: this.RightCalibrationValue
            }
            
            this._vraApi.SaveVraRecord(payload).subscribe({
                next: next => {
                    this.checkIsReady()
                    resolve()
                }, error: (err) => {
                    reject()
                }
            })
        })
    }
}
