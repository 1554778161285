<ion-grid fixed class="p-horizontal-25">
  <ion-row class="ion-justify-content-center ion-align-items-center">
    <ion-col size="12">
      <h2 class="font-size-28 font-weight-900 letter-space-minus-013 m-top-6">{{"auth.enter_code_title" | translate}}
      </h2>
      <p class="font-size-14">{{"auth.enter_code_text" | translate:{number:number} }}</p>
    </ion-col>
    <ion-col class="ion-no-padding">
      <form [formGroup]="otpForm">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="OtpConfig">
        </ng-otp-input>
      </form>
    </ion-col>
    <ion-col size="12">
      <ion-button text-transform="none" (click)="requestCode()" id="sms-validation-confirm-btn" expand="block"
        style="height: 55px;">
        {{"auth.confirm_btn" | translate}}
      </ion-button>
    </ion-col>
    <ion-col size="12">
      <ion-button color="dark" (click)="sendAgain()" id="sms-validation-send-again-btn" class="font-size-14 font-weight-400" expand="block" fill="clear"
        shape="round" text-transform="none">
        <span class=" text-decoration-underline">{{"auth.send_again" | translate}} </span>
        &nbsp;&nbsp;
        <span class="timer-span"> 0{{counter.min}} :
          <ng-container *ngIf="counter.sec.toString().length == 1">0</ng-container>{{counter.sec}}
        </span>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>