import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'htmlTranslate',
    standalone: true,
    pure: false
})

export class HtmlTranslatePipe extends TranslatePipe implements PipeTransform {
    /**
     *this pipe only use with innerHtml
     */
    constructor(private translateService: TranslateService, private changeDet: ChangeDetectorRef, private sanitized: DomSanitizer) {
        super(translateService, changeDet)
    }
    
    override transform(value: string, ...args: any[]): any {
        // console.log("translate => ",args)
        let translate: string = super.transform(value, args)
        if(args && args.length > 0){
            Object.keys(args[0]).forEach(el => {
                translate = translate.replace(`{{${el}}}`, args[0][el])
            })
        }

        return this.sanitized.bypassSecurityTrustHtml(translate)
    }
}