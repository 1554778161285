import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { ClientBaseApiService } from './client-base-api.service';
import { IDoktarLocationDetails } from '../models/interfaces/coords';
import { Observable } from 'rxjs';
import { IMapboxLocationModel } from "../models/interfaces/coords";

@Injectable({ providedIn: 'root' })
export class LocationApiService {

    constructor(private clientApiService: ClientBaseApiService) { }

    public DoktarLocationFromLatLng(payload: { lat, lng }) {
        const url = environment.baseApiUrl + "location/me";
        return this.clientApiService.baseApiCall<any>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public MapboxLocationFromLatLng(longitude,latitude) {
        
        let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?&access_token=${environment.mapbox.accessToken}`
        // "https://api.mapbox.com/geocoding/v5/mapbox.places/" + query + ".json?limit=10&proximity=ip&types=place%2Cpostcode%2Caddress&access_token="
        //     + environment.mapbox.accessToken + "&language=" + lang;
        return this.clientApiService.externalApiCall<any>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public GetLocationDetailFromCoordinates({ lat, lng }) {
        const url = environment.gatewayApiUrl + `v2/api/village/get/information?lat${lat}&lng${lng}`;
        return this.clientApiService.baseApiCall<IDoktarLocationDetails>({url, payload: null, requestType: HttpRequestTypes.GET})
    }

    public SearchPlacesWithCoords({latitude,longitude, lang = "en-EN"}): Observable<IMapboxLocationModel> {
        // latitude = 34.283433
        // longitude = -5.111449
        let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?&access_token=${environment.mapbox.accessToken}&language=${lang};`
            // + environment.mapbox.accessToken // + "&language=" + lang;
        return this.clientApiService.externalApiCall<IMapboxLocationModel>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }


}