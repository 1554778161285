import { Injectable } from '@angular/core';
import { FarmApiService } from '../api/farm-api.service';
import { IVRASaveModel, VraMapApiService } from '../api/vra-map-api.service';
import { FarmService } from './farm.service';
import VraItem from './class/vra-item';
import { DateTimeService } from './date-time.service';

@Injectable({ providedIn: 'root' })
export class VraMapsService {

    private _vraList: Array<VraItem>
    public get vraList(): Array<VraItem> {
        return this._vraList
    }

    static VraMapServiceInstance: VraMapsService

    constructor(private farmService: FarmService, private vraApi: VraMapApiService, public farmApi: FarmApiService) {
        VraMapsService.VraMapServiceInstance = this
    }

    getAllVraMapItems(refresh?: boolean): Promise<Array<VraItem>> {
        return new Promise((resolve, reject) => {
            if (Array.isArray(this._vraList) && this._vraList.length > 0 && !refresh)
                resolve(this._vraList)
            else
                this.vraApi.GetVraListRequest({ id: 0, tempFarmAttId: 0 }).subscribe({
                    next: response => {
                        if (response.IsSuccess && response.Data) {
                            this.farmService.GetAllFarms({}).subscribe(res => {
                                this._vraList = response.Data
                                    .sort((a, b) => {
                                        let aCreated = a.CreatedOn, bCreated = b.CreatedOn
                                        return DateTimeService.CompareDates(aCreated, bCreated) * -1
                                    })
                                    .map(t => {
                                        let farm = this.farmService.allFarms.find(a => a.TempFarmAttId == t.TempFarmAttId)
                                        let el = new VraItem(this.vraApi)
                                        el.farm = farm.farm
                                        el.buildItem(t)
                                        return el
                                    })
                                resolve(this._vraList)
                            }, err => {
                                resolve(this._vraList)
                            })
                        } else {
                            reject("error")
                        }
                    }, error: err => {
                        console.log("error on vra => ", err)
                        reject(err)
                    }
                })
        })

    }

    clearVraList() {
        this._vraList = []
    }

    getSatelliteDates(tempFarmAttId, lastDate) {
        return this.farmApi.GetSatelliteDates(tempFarmAttId, lastDate, "vra")
    }

    createNewMapRequest(payload: IVRASaveModel) {
        this.getAllVraMapItems(true)
        return this.vraApi.SaveVraRecord(payload)
    }

}