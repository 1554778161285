import { FarmApiService } from "src/app/core/api/farm-api.service";
import { IFarmModel } from "src/app/core/models/interfaces/farm-model";
import FarmDataModel from "./farm-data.class";

export abstract class AbsFarmControl{

    private _farm: FarmDataModel = new FarmDataModel();
    public get farm(): FarmDataModel {
        return this._farm;
    }
    protected set farm(value: FarmDataModel) {
        this._farm = value;
    }

    public get TempFarmAttId(){
        return this._farm.TempFarmAttId
    }

    public get FarmName(){
        return this._farm.FarmName
    }

    public get CropId(){
        return this._farm.CropId
    }

    public get CropName(){
        return this._farm.CropName
    }


    abstract buildFarm(props:IFarmModel):void
    abstract GetSatelliteImages(lastDate:string): Promise<boolean>
    abstract GetAnalysisData(id:number): Promise<any>
    abstract GetStaticFarmImage(): string
    abstract GetFarmNotes(): void
    abstract GetFarmIrrigation(): void
    abstract GetVraInformation(): void
    abstract GetSprayingData(): void
    abstract GetStaticImage():void
}