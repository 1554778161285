import { Observable } from "rxjs";
import { SatelliteImageTypes } from "../enums/satellite-image-types";
import { ISatelliteAnalysisResponse } from "./satellite-analysis-response";

export interface ISatelliteImages {
    Id: number;
    UniqueId: string;
    SensingDate: string;
    Type: string;
    TempFarmAttId: number;
    CloudRatio: number;
    NdviMap: string;
    InspectionMap: string;
    SoilMoistureMap: string;
    TrueColorMap: string;
    MeanNdvi: number

    Date: string
    ImageUrl: string
    HealthMapImageUrl: string
    SoilMoistureUrl: string
    VisualImageUrl: string

    // GetImagesDetail();
}

export abstract class ASatelliteImages implements ISatelliteImages {
    Id: number;
    UniqueId: string;
    SensingDate: string;
    Type: string;
    TempFarmAttId: number;
    CloudRatio: number;
    NdviMap: string; //+
    InspectionMap: string;
    SoilMoistureMap: string;
    TrueColorMap: string;
    MeanNdvi: number;

    constructor() {

    }
    private _Date: string;
    public get Date(): string {
        return this.SensingDate;
    }
    public set Date(value: string) {
        this.SensingDate = value
        this._Date = value;
    }
    private _ImageUrl: string;
    public get ImageUrl(): string {
        return this.NdviMap
    }
    public set ImageUrl(value: string) {
        this.NdviMap = value
        this._ImageUrl = value;
    }
    private _HealthMapImageUrl: string;
    public get HealthMapImageUrl(): string {
        return this.InspectionMap;
    }
    public set HealthMapImageUrl(value: string) {
        this.InspectionMap = value
        this._HealthMapImageUrl = value;
    }
    private _SoilMoistureUrl: string;
    public get SoilMoistureUrl(): string {
        return this.SoilMoistureMap;
    }
    public set SoilMoistureUrl(value: string) {
        this.SoilMoistureMap = value
        this._SoilMoistureUrl = value;
    }
    private _VisualImageUrl: string;
    public get VisualImageUrl(): string {
        return this.TrueColorMap;
    }
    public set VisualImageUrl(value: string) {
        this.TrueColorMap = value
        this._VisualImageUrl = value;
    }

    build(props: ISatelliteImages) {
        this.Id = props.Id
        this.UniqueId = props.UniqueId
        this.SensingDate = props.SensingDate
        this.Type = props.Type
        this.TempFarmAttId = props.TempFarmAttId
        this.CloudRatio = props.CloudRatio
        this.NdviMap = props.NdviMap
        this.InspectionMap = props.InspectionMap
        this.SoilMoistureMap = props.SoilMoistureMap
        this.TrueColorMap = props.TrueColorMap
        this.MeanNdvi = props.MeanNdvi
    }

    abstract GetImagesDetail(): Observable<ISatelliteImages>
abstract GetAnalysisData(imageType: SatelliteImageTypes): Promise<ISatelliteAnalysisResponse[]>
}