import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ListItem } from 'src/app/core/models/classes/list-item';
import { BaseModalModule } from 'src/app/core/modules/base-modal.module';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { LanguageService } from 'src/app/core/services/lang.service';

@Component({
  selector: 'select-language',
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, BaseModalModule, TranslateModule, SharedModule],
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent extends BaseModal implements OnInit {
  langOptions = []
  isModalOpened: boolean = false;

  constructor(public langService: LanguageService, private translate: TranslateService) {
    super()
    super._modalService = langService.modalService
  }

  ngOnInit() {
    super.ngOnInit()
    let currentLang: string = (this.translate.instant("lang.default") as string).toLowerCase();
    this.langService.getLangOpts().subscribe(response => {
      this.langOptions = response.map(t => {
        return { Id: t.code, Value: t.lang, icon: "./assets/lang-flags/" + t.code + ".svg", isChecked: t.code === currentLang } as ListItem;
      })
    })
  }

  selectOption(item) {
    this.langService.setCurrentLang(item.Id)
    this.dismissModal()
  }

  dismissModal() {
    this.langService.dismissOptionsModal(this.id)
  }
}
