import { IAuthResponse } from "../interfaces/auth-response";

export class UserData implements IAuthResponse {
    public UserId?: number;
    public AuthToken?: string
    public FarmerId?: number;
    public NameSurname?: string;
    public RoleId?: number;
    public CompanyId?: number;
    public UserMail?: string;

    private _ProfilePictureUrl?: string;
    public get ProfilePictureUrl(): string {
        return this._ProfilePictureUrl;
    }
    public set ProfilePictureUrl(value: string) {
        if (value && value !== '' && value.startsWith("http"))
            value = value + "?v=" + Math.random()
        this._ProfilePictureUrl = value;
    }
    
    public Token?: string;
    public UserTelNo?: string;
    public UserTypeId?: number;
    public VillageId?: number;
    public DistrictId?: number;
    public CityId?: number;
    public UserName?: number;
    public RoleEndDate?: string;
    public UserRoleId?: number;
    public CampaignId?: number;
    public FarmerSubscriberId?: number;
    public IsNotificationEnabled?: boolean;
    public TotalFarmCount?: number;
    public TotalFarmArea?: number;
    public IsGuest?: boolean;
    // DevicePlatform?: string;
    // IsForceUpdate?: boolean;
    // IsServiceAuth?: boolean;
    // ServiceList?: any;
    // IsDisclaimerConfirm?: boolean;
    // GroupName?: string;
    // IsAdmin?: boolean;
    // AuthList?: any;

    constructor(props: IAuthResponse) {
        this.UserId = props.UserId
        this.FarmerId = props.FarmerId
        this.NameSurname = props.NameSurname
        this.RoleId = props.RoleId
        this.CompanyId = props.CompanyId
        this.UserMail = props.UserMail
        this.ProfilePictureUrl = props.ProfilePictureUrl
        if(props["_ProfilePictureUrl"])
        this.ProfilePictureUrl = props["_ProfilePictureUrl"]
        this.Token = props.Token
        this.AuthToken = props.AuthToken
        this.UserTelNo = props.UserTelNo
        this.UserTypeId = props.UserTypeId
        this.VillageId = props.VillageId
        this.DistrictId = props.DistrictId
        this.CityId = props.CityId
        this.UserName = props.UserName
        this.RoleEndDate = props.RoleEndDate
        this.UserRoleId = props.UserRoleId
        this.CampaignId = props.CampaignId
        this.FarmerSubscriberId = props.FarmerSubscriberId
        this.IsNotificationEnabled = props.IsNotificationEnabled
        this.TotalFarmCount = props.TotalFarmCount
        this.TotalFarmArea = props.TotalFarmArea
        this.IsGuest = props.IsGuest
    }
    IsAuthorize?: boolean;
    Password?: string;
    Platform?: string;
    DevicePlatform?: string;
    IsForceUpdate?: boolean;
    IsServiceAuth?: boolean;
    ServiceList?: any;
    IsDisclaimerConfirm?: boolean;
    GroupName?: string;
    IsAdmin?: boolean;
    AuthList?: any;
    UserTitle?: string;

    getBasicProfileInfo() {
        return {
            UserId: this.UserId,
            NameSurname: this.NameSurname,
            UserMail: this.UserMail,
            UserTelNo: this.UserTelNo,
            IsGuest: this.IsGuest
        }
    }
}