<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelDrawing()" id="cancel-drawing-btn">
        {{"general.cancel" | translate}}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="confirmDrawing()" id="confirm-drawing-btn">
        {{"general.finish" | translate}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="dark-gray-background">
  <orbit-image-drawing class="full-height" #imagedrawingelement [height]="height" [width]="width" [forceSizeCanvas]="true" [colors]="colors" [i18n]="langs" [src]="imageUrl"
    outputMimeType="image/jpeg" [outputQuality]="outputQuality" (save)="save($event)" (cancel)="cancel()">
  </orbit-image-drawing>
</ion-content>