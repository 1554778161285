import { AnimationController } from "@ionic/angular";

export const modalHorizontalEnterAnimation = (baseEl: HTMLElement) => {
    let animationCtrl = new AnimationController()
    const root = baseEl.shadowRoot;

    const backdropAnimation = animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0.99', transform: 'translateX(100%)' },
        { offset: 1, opacity: '0.99', transform: 'translateX(0%)' },
      ]);

    return animationCtrl
      .create()
      .addElement(baseEl)
      .easing('cubic-bezier(0.7,0,0.3,1)')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  export const modalHorizontalLeaveAnimation = (baseEl: HTMLElement) => {
    return modalHorizontalEnterAnimation(baseEl).direction('reverse');
  };