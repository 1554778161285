import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NetworkService } from 'src/app/core/services/network.service';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss', './../../auth.page.scss'],
})
export class AuthModalComponent extends BaseModal implements OnInit {
  socialUserInfo: any;
  segment = "login"
  refreshComponents: boolean = true
  constructor(private modalService: ModalService, public langService: LanguageService) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    super.ngOnInit()
    NetworkService.networkListener.subscribe(ev => {
      if(ev == 'online'){
        this.refreshComponents = false;
        setTimeout(() => {
          this.refreshComponents = true
        }, 300);
      }
    })
  }
  
  socialLogin(ev) {
    this.socialUserInfo = ev;
    this.segment = "register";
  }

  segmentChanged(ev) {
    this.segment = ev.detail.value
  }
  openModal() {
    this.langService.openOptionsMenu()
  }
  onLogin(ev) {
    this.dismiss(true,"loggedin")
  }
  onRegister(ev) {
    this.dismiss(true,"registered")
  }
}
