import { environment } from "src/environments/environment";
import { HttpRequestTypes } from "../models/enums/http-request-types";
import { ILoginModel } from "../models/interfaces/login-model";
import { IAuthResponse } from "../models/interfaces/auth-response";
import { IRegisterModel, IRegisterModelV2 } from "../models/interfaces/register-model";
import { ClientBaseApiService } from "./client-base-api.service";
import { Injectable } from "@angular/core";
import { ISmsRequestModel } from "../models/interfaces/sms-models";
import { IGuestRegisterModel } from "../models/interfaces/guest-models";
import { ICurrentUserDetails, IUserDetailRequestBody } from "../models/interfaces/user-detail-models";
import { IReferenceTransaction } from "../models/interfaces/reference-transaction.interface";

@Injectable({
    providedIn: "root"
})
export class UserApiService {
    baseApiUrl = environment.baseApiUrl
    /**
     *
     */
    constructor(private clientApiService: ClientBaseApiService) {

    }
    /**
     * @deprecated
     */
    public login(payload: ILoginModel) {
        let url = this.baseApiUrl + "login"
        return this.clientApiService.baseApiCall<IAuthResponse>({ payload, requestType: HttpRequestTypes.POST, url })
    }

    public loginV2(userName: string, password: string) {
        let url = `${environment.gatewayApiUrl}v2/api/authentication/login?userName=${userName}&password=${password}`
        return this.clientApiService.baseApiCall<any>({ payload: null, url, requestType: HttpRequestTypes.GET })
    }

    public register(payload: IRegisterModelV2) {
        // let url = this.baseApiUrl + "signup"
        // return this.clientApiService.baseApiCall({ payload, requestType: HttpRequestTypes.POST, url })
        let url = environment.gatewayApiUrl + "v2/api/authentication/register"
        return this.clientApiService.baseApiCall({ payload, requestType: HttpRequestTypes.POST, url })
    }

    public getAccountUnits() {
        let url = this.baseApiUrl + "get/account/units";
        return this.clientApiService.baseApiCall({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public UserForgotPasswordProcess(payload: ISmsRequestModel) {
        let url = environment.gatewayApiUrl + "v2/api/user/forgot/password"
        return this.clientApiService.baseApiCall<boolean>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public MakeGuestLogin() {
        let url = environment.gatewayApiUrl + "v2/api/authentication/make/guess";
        return this.clientApiService.baseApiCall<IAuthResponse>({ url, payload: null, requestType: HttpRequestTypes.POST });
    }

    public RegisterGuestUser(payload: IGuestRegisterModel) {
        let url = environment.gatewayApiUrl + "v2/api/user/change/guess/user/information";
        return this.clientApiService.baseApiCall<any>({ url, payload: payload, requestType: HttpRequestTypes.POST });
    }

    public GetCurrentUser() {
        let url = environment.gatewayApiUrl + "v2/api/user/currentuser/information";
        return this.clientApiService.baseApiCall<ICurrentUserDetails>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetReferanceCode() {
        let url = environment.gatewayApiUrl + "v2/api/user/get/reference/code";
        return this.clientApiService.baseApiCall<string>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public UpdateUserDetail(payload: IUserDetailRequestBody) {
        let url = environment.gatewayApiUrl + "v2/api/user/update/user/detail";
        return this.clientApiService.baseApiCall<boolean>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public UpdateUserInfoWithPhoto(payload, userRoleId) {
        let url = environment.baseApiUrl + "update/user/information?userRoleId=" + userRoleId;
        return this.clientApiService.baseApiCall({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
    }

    public DeleteAccount() {
        let url = environment.baseApiUrl + "delete/account";
        return this.clientApiService.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.POST })
    }

    public UserPermissions() {
        let url = environment.gatewayApiUrl + "v2/api/user/orbit/permission";
        return this.clientApiService.baseApiCall<{ [key: string]: boolean }>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public ChangePassword(payload: { OldPassword, NewPassword }) {
        let url = environment.gatewayApiUrl + "v2/api/user/change/password";
        return this.clientApiService.baseApiCall<any>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public GetReferenceCode() {
        let url = environment.gatewayApiUrl + "v2/api/user/get/reference/code"
        return this.clientApiService.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public RegisterInvitationCode(code: string) {
        let url = environment.gatewayApiUrl + "v2/api/user/save/reference/code"
        return this.clientApiService.baseApiCall<any>({ url, payload: { ReferenceCode: code }, requestType: HttpRequestTypes.POST })
    }

    public QuestionsFinished() {
        let url = environment.gatewayApiUrl + "v2/api/user/set/questions/finished";
        return this.clientApiService.baseApiCall<boolean>({ url, payload: null, requestType: HttpRequestTypes.POST });
    }

    public SocialLogin(payload: any) {
        let url = environment.gatewayApiUrl + "v2/api/authentication/social/login?socialType=" + payload.SocialType + "&socialToken=" + payload.SocialToken;
        return this.clientApiService.baseApiCall<IAuthResponse>({ url: url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public DeletePushDeviceToken(token: string) {
        let url = environment.baseApiUrl + "remove/device/token?campaignId=" + environment.campaignId + "&deviceToken=" + token;
        return this.clientApiService.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public GetRefereceUserData() {
        let url = environment.gatewayApiUrl + "v2/api/iap/get/reference/transactions" //"​v2​/api/iap​/get​/reference​/transactions"
        return this.clientApiService.baseApiCall<Array<IReferenceTransaction>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }
}