<div class="loading-container" id="loading_indicator" *ngIf="state">
  <div class="loading-backdrop"></div>
  <div class="loading-animation-step1" [ngClass]="animationClass">
    <div class="outer-circle">
      <div class="inner-circle">
        <div class="container">
          <ion-icon color="ultra-light" [ngClass]="hideShow ? 'hidden' : 'hidden-open'" [src]="iconList[iconIndex]"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</div>