import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

@Injectable({ providedIn: 'root' })
export class OnboardingStorageService {
    constructor(private storageService: StorageService) { }

    checkForStatus(prop) {
        return new Promise((resolve, reject) => {
            this.storageService.get<Array<string>>(StorageKeys.PAGE_ONBOARDINGS).then(res => {
                if (res.IsSuccess && Array.isArray(res.Data) && res.Data.length > 0) {
                    resolve(!res.Data.some(t => t === prop))
                } else {
                    resolve(true)
                }
            }).catch(err => {
                resolve(true)
            })
        })
    }

    completeOnboarding(prop) {
        return new Promise((resolve, reject) => {
            this.storageService.get<Array<string>>(StorageKeys.PAGE_ONBOARDINGS).then(getResponse => {
                if (getResponse.IsSuccess && Array.isArray(getResponse.Data) && getResponse.Data.length > 0) {
                    if (!getResponse.Data.some(t => t === prop))
                        getResponse.Data.push(prop)
                } else {
                    getResponse.Data = [prop]
                }
                this.storageService.set(StorageKeys.PAGE_ONBOARDINGS, getResponse.Data).then(setResponse => {
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
            }).catch(err => {
                reject(err)
            })
        })
    }

    deleteOnboarding(prop) {
        return new Promise((resolve, reject) => {
            this.storageService.get<Array<string>>(StorageKeys.PAGE_ONBOARDINGS).then(getResponse => {
                if (getResponse.IsSuccess && Array.isArray(getResponse.Data) && getResponse.Data.length > 0) {
                    getResponse.Data = getResponse.Data.filter(t => t != prop)
                }
                this.storageService.set(StorageKeys.PAGE_ONBOARDINGS, getResponse.Data).then(setResponse => {
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
            }).catch(err => {
                reject(err)
            })
        })
    }

    clearOnboadings() {
        this.storageService.remove(StorageKeys.PAGE_ONBOARDINGS).then(res => { })
    }


    /**
     * @description if onboarding not viewed, returns true else returns false
     * @returns Promise<boolean>
     */
    checkAppOnboardingStatus(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.storageService.get(StorageKeys.TUTORIAL_LAST_SHOWED).then(resp => {
                if (resp && resp.IsSuccess && resp.Data) {
                    resolve(false)
                } else {
                    resolve(true)
                }
            }).catch(err => {
                resolve(true)
            }).finally(() => {
            })
        })
    }

    completeAppOnboarding() {
        let fn = () => {
            if (Capacitor.isNativePlatform())
                App.getInfo().then(info => {
                    this.storageService.set(StorageKeys.TUTORIAL_LAST_SHOWED, info.build).then(storeResp => { })
                })
            else
                this.storageService.set(StorageKeys.TUTORIAL_LAST_SHOWED, "web.1.0.0").then(storeResp => { })
        }
        this.storageService.get(StorageKeys.TUTORIAL_LAST_SHOWED).then(resp => {
            if (!(resp && resp.IsSuccess && resp.Data)) {
                fn()
            }
        }).catch(err => {
            fn()
        }).finally(() => {
        })
    }
}