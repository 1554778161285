import { Injectable } from '@angular/core';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Keyboard, KeyboardResize, KeyboardStyle } from "@capacitor/keyboard";

@Injectable({ providedIn: 'root' })
export class KeyboardActionsService {
    public static get keyboardInstance() {
        return Keyboard;
    }
    constructor() { }

    static DisableScroll(): void {
        if (Capacitor.getPlatform() === 'ios')
            Keyboard.setScroll({ isDisabled: false }).then().catch(err => { })
    }

    static EnableScroll(): void {
        if (Capacitor.getPlatform() === 'ios')
            Keyboard.setScroll({ isDisabled: true }).then().catch(err => { })
    }

    static DisableResize(): void {
        if (Capacitor.getPlatform() === 'ios')
            Keyboard.setResizeMode({ mode: KeyboardResize.None }).then().catch(err => { })
    }

    static SetKeyboardTheme(isDark): void {
        if (Capacitor.getPlatform() === 'ios')
            Keyboard.setStyle({ style: isDark ? KeyboardStyle.Dark : KeyboardStyle.Light }).then().catch(err => { })
    }

    static GetCloseListener(handler) {
        if (Capacitor.getPlatform() !== 'web')
            return Keyboard.addListener("keyboardDidHide", handler)
        else
        return Promise.resolve()
    }

}