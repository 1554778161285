<orbit-modal-base [isBottomSheet]="true" (onDismiss)="dismiss(null)">
  <ion-grid fixed class="full-height">
    <div class="d-flex">
      <div class="sticky-top">
        <div #headerarea class="d-flex row justify-between align-center gap-12">

          <ion-icon (click)="dismiss('canceled')" possibleOutcome="close modal" id="field-list-dismiss-icon"
            [src]="'arrow_left' | orbitIcons" class="font-size-22"></ion-icon>
          <div class="flex-grow">
            <span class="font-size-18-i font-weight-850-i">
              {{"my_fields.field_list_segment" | translate}}
            </span>
          </div>
          <span *ngIf="multiSelect" class="font-size-14 font-weight-850"
            [attr.color]="_selectedCount ? 'primary' : 'medium'" (click)="_selectedFarmList = {}"
            id="field-list-clear-selected-items">{{"clear" | translate}}</span>

        </div>
        <div class="d-flex row justify-center align-center">
          <div class="flex-1">
            <ion-searchbar search-icon="./../../../../../assets/icon/search-filled-icon.svg" class="custom p-left-0"
              [placeholder]="'my_fields.search_farm_placeholder' | translate" [value]="myFarmService.searchQuery"
              enterkeyhint="search" (ionInput)="myFarmService.onFarmsSearchChange($event)"
              [debounce]="250"></ion-searchbar>
          </div>
          <div>
            <ion-button (click)="myFarmService.openFarmsFilters()" id="field-list-open-filters-btn" size="small"
              class="height-40 width-40" shape="circle" color="light">
              <ion-icon class="font-size-42" color="light" slot="icon-only"
                [src]="'filter_round' | orbitIcons"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoading" size="12" style="max-height: calc(100vh - 150px); overflow: scroll;"
        [style]="height > -1 ? ('height: calc(var(--ion-safe-area-bottom) * 0.5 + ' + height +'px)') : ''">
        <ion-list
          *ngIf="(myFarmService.searchResult | filterArray: customFilterFn) && (myFarmService.searchResult | filterArray: customFilterFn).length > 0; else fieldListEmptyPage">
          <ion-item lines="full"
            *ngFor="let item of (myFarmService.searchResult | filterArray: customFilterFn); trackBy:trackByFn; let index = index"
            [detail]="!(multiSelect || useConfirm)" details="opaque" (click)="selectFarm(item)"
            [id]="'field-list-farm-options-'+index">
            <ion-avatar slot="start">
              <svg class="farm-svg" viewBox="0 0 300 300" [fromGeoJson]="item.farm.GeoJsonObj"
                [recalculate]="true"></svg>
            </ion-avatar>
            <ion-label>
              <p color="dark" class="font-size-16-i font-weight-800-i">{{item.farm.FarmName}}</p>
              <h2 color="darker" class="font-size-14-i font-weight-500">
                {{('crops.' + item.farm.CropName) | translate}}
                &nbsp;-&nbsp;
                {{item.farm.CalculatedArea | fieldArea}}&nbsp;-&nbsp;{{item.farm.SowingDate | formatDate: "YYYY"}}
              </h2>
            </ion-label>
            <ion-icon *ngIf="multiSelect || useConfirm"
              [color]="_selectedFarmList[item.TempFarmAttId] ? 'primary' : 'dark'" slot="end"
              [name]="_selectedFarmList[item.TempFarmAttId] ? 'checkmark-circle' : 'ellipse-outline'">
            </ion-icon>
          </ion-item>
        </ion-list>
        <ng-template #fieldListEmptyPage>
          <orbit-empty-page *ngIf="farmService.allFarms.length > 0"
            [description]="'my_fields.farm_search_no_result_found_desc' | translate"
            [title]="'my_fields.farm_search_no_result_found_title' | translate"></orbit-empty-page>
          <orbit-empty-page *ngIf="farmService.allFarms.length == 0"
            [description]="'my_fields.no_farm_to_show_desc' | translate"
            [title]="'my_fields.no_farm_to_show_title' | translate"></orbit-empty-page>
        </ng-template>
      </div>
      <ion-button *ngIf="multiSelect || useConfirm" (click)="completeSelection()" expand="block"
        id="field-list-confirm-fields-btn" fill="solid" shape="round">
        {{"general.choose_btn_lbl" | translate}} {{ !useConfirm ? _selectedCount || null : null}}
      </ion-button>
    </div>
  </ion-grid>
</orbit-modal-base>