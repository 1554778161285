import { Injectable } from '@angular/core';
import { Capacitor } from "@capacitor/core";
import { ModalService } from './modal.service';
import { Observable, Subject } from 'rxjs';
import { PurchaseCustomEvents } from '../models/enums/in-app-purchase-custom-events';
import { ICalculationFarmModel, InAppPurchaseResponse, IPurchaseSaveBodyModel } from '../models/interfaces/in-app-purchase-models';
import { AppStoreApiService } from '../api/app-store-api.service';
import { OverlayEventDetail } from '@ionic/core';
import { AnalyticService } from './analytic.service';
import { Router } from '@angular/router';
import { FirebaseCrashService } from './firebase-crash.service';
import "cordova-plugin-purchase";
import { DoktarAppsFlyer } from './appsflyer.service';
import { AnalyticEvents, AppsflyerAnalyticEvents } from '../models/enums/analytic-events';
import { AccountService } from './account.service';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { PurchaseModalComponent } from 'src/app/components/purchase-modal/purchase-modal.component';
import { IFarmModel } from '../models/interfaces/farm-model';
import { App } from '@capacitor/app';
import { AppInsightsLoggingService } from './logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class PurchasePlansService {

  products: CdvPurchase.Product[];
  ownedProduct: CdvPurchase.Product = null;
  processProduct: CdvPurchase.Product = null;
  isRegisterCompleted: boolean = false;
  devicePlatform = Capacitor.getPlatform()
  rawPackageList: Array<InAppPurchaseResponse>
  productIdList
  validProducts: CdvPurchase.Product[] = []
  countryCode: string = "N/A"
  store: CdvPurchase.Store

  private _subject: Subject<{ event: PurchaseCustomEvents; data?: any; }> = new Subject<{ event: PurchaseCustomEvents; data?: any; }>();
  private _observer: Observable<{ event: PurchaseCustomEvents; data?: any; }>;

  public get observer(): Observable<{ event: PurchaseCustomEvents; data?: any; }> {
    return this._subject.asObservable();
  }
  public set observer(value: Observable<{ event: PurchaseCustomEvents; data?: any; }>) {
    this._observer = this._subject.asObservable();
  }

  private _storeErrorListener: Subject<any>; // = new Subject()
  private _appVersion: string = ""
  private _platform: string = ""
  constructor(private modalService: ModalService, private firebaseCrashService: FirebaseCrashService, private router: Router, private analyticService: AnalyticService, private appStoreApiService: AppStoreApiService) {
    this._platform = Capacitor.getPlatform()
    App.getInfo().then(res => {
      this._appVersion = res.version + " - " + res.build
    })

  }

  public initStoreObject() {
    if (Capacitor.getPlatform() === 'web')
      return null
    this.store = CdvPurchase.store
  }


  /**
   * 
   * @param componentProps 
   * @description role === 'purchase_success' => satın alım başarıyla gerçekleşmiştir.
   * @returns  OverlayEventDetail<T = any> { data?: T; role?: string; }
   */
  public openPurchasePlans(componentProps?: { farmList?: Array<IFarmModel>, isOnlyPackageDetails: boolean, notAllowedPlans?: Array<string>, isUpgrade?}): Promise<OverlayEventDetail> {
    //bu arkadaşı satın alım sayfasına gidecek şekilde düzenleyeceğiz
    return new Promise((resolve, reject) => {
      // reject("not implemented")
      if (componentProps && Array.isArray(componentProps.farmList) && componentProps.farmList.length > 0) {
        this.modalService.presentModal({
          component: PurchaseModalComponent,
          componentProps,
          options: ModalService.fullPageOptions
        }).then(modalResponse => {
          modalResponse.modalObj.onDidDismiss().then(dismiss => {
            resolve(dismiss)
          }).catch(err => {
            reject(err)
          })
        })
      }else{
        this.router.navigate(["purchase-plans"]).then(r => resolve(null))
      }
    })
  }

  public getAllStoreProducts() {
    if (Capacitor.getPlatform() === 'web')
      return null
    return this.store.products
  }

  public getProducts(): Observable<boolean> {
    return new Observable(observer => {
      this.appStoreApiService.GetConsumableList().subscribe(res => {
        if (res.IsSuccess) {
          let result = res.Data;
          this.rawPackageList = res.Data;
          this.productIdList = result.map(t => t.PackageName);
          observer.next(true);
        } else {
          observer.error(false)
        }
        observer.complete()
      })
    })
  }

  public registerProducts() {

    if (this.devicePlatform === 'web')
      return

    if (this._storeErrorListener == null || this._storeErrorListener == undefined) {
      this._storeErrorListener = new Subject()
      this.store.error(error => {
        this._storeErrorListener.next(error)
      })
    }

    this._subject.next({ event: PurchaseCustomEvents.REGISTER_ACTION_START, data: null })
    this.getProducts().subscribe(res => {
      this.products = this.store.products
      let productList = this.productIdList.map(t => {
        let product = {
          id: this.devicePlatform == "android" ? t.toLowerCase() : t,
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: Capacitor.getPlatform() == "ios" ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY
        } as CdvPurchase.IRegisterProduct;
        return product
      })
      this.store.register(productList)
      // this.store.refresh
      this.store.initialize().then(r => console.log("store register completed => ", r))
      this.isRegisterCompleted = true
      // this.products = this.store.products.filter(t => t.valid)

    })
    // }

  }

  private registerItemsCount = 0


  public CalculatePrice(farms: ICalculationFarmModel[], calculationType: boolean = true) {
    return this.appStoreApiService.CalculatePrice({ CalculationType: calculationType, Farms: farms })
  }

  public CalculatePriceBulk(farms: ICalculationFarmModel[], calculationType: boolean = true) {
    return this.appStoreApiService.CalculatePriceBulk({ CalculationType: calculationType, Farms: farms })
  }


  purchaseConsumable(rawProductId: string, farms: ICalculationFarmModel[], farmNames?: string[]) {
    let productId = this.devicePlatform === 'android' ? rawProductId.toString().toLowerCase() : rawProductId
    let product: CdvPurchase.Product = this.store.products.find(t => t.id === productId)
    return new Observable(observer => {
      let transaction: CdvPurchase.Transaction
      let approvedCallback = (_transaction: CdvPurchase.Transaction) => {
        console.log("transaction", transaction)
        if (!transaction) {
          transaction = _transaction
          transaction.verify()
        }
        this.store.off(approvedCallback)
      }
      let verifiedCallback = (receipt: CdvPurchase.VerifiedReceipt) => {
        console.log("receipt", receipt)
        receipt.finish();
        var signature = ""
        var transactionId = ""
        try {
          signature = receipt.sourceReceipt.transactions[0]["nativePurchase"]["signature"]
          if (signature == null || signature == undefined)
            signature = "N/A"
        } catch (error) {

        }
        try {
          transactionId = transaction.transactionId
          if (transactionId == null || transactionId == undefined)
            transactionId = "N/A"
        } catch (error) {

        }

        let payload: IPurchaseSaveBodyModel = {
          CountryCode: this.countryCode,
          Currency: product.pricing && product.pricing.currency ? product.pricing.currency : "N/A",
          Farms: farms,
          PackageName: rawProductId,
          Price: product.pricing ? product.pricing.priceMicros : -1, //  .priceMicros ? finished.priceMicros : order.priceMicros ? order.priceMicros : -1,
          TransactionId: transactionId, // && finished.transaction.id ? finished.transaction.id : "",
          Signature: this.devicePlatform == 'ios' ? "N/A" : signature, //this.devicePlatform == 'ios' ? "" : receipt.sourceReceipt.transactions[0].,  // this.devicePlatform == 'ios' && finished.transaction ? '' : finished.transaction["signature"],
          PurchaseToken: this.devicePlatform == 'ios' ? "N/A" : transaction.purchaseId, //this.devicePlatform == 'ios' ? '' : finished.transaction && finished.transaction["purchaseToken"] ? finished.transaction["purchaseToken"] : "",
          OTransactionId: transactionId, // receipt.raw["id"] //finished.transaction && finished.transaction["original_transaction_id"] ? finished.transaction["original_transaction_id"] : ""
          AppVersion: this._appVersion,
          Platform: this._platform
        }
        // console.log("purchase payload => ", payload)
        // observer.next({ Data: true } as IGenericResponse)
        // this.store.off(verifiedCallback)
        // observer.complete()
        this.appStoreApiService.CompletePurchaseOperation(payload)
          .subscribe({
            next: response => {

              this.sendAnalyticData({
                transaction,
                farmNames,
                farms,
                payload,
                product,
                rawProductId,
                receipt,
                signature,
                transactionId
              })

              observer.next(response)
              this.store.off(verifiedCallback)
              observer.complete()
            }, error: error => {
              this.appStoreApiService.SendPurchaseErrorPayload(JSON.stringify(payload)).subscribe(res => { }, err => { }, () => { })
              //hata durumu için payload storagea atılabilir. daha sonra tekrar istek yapılabilir. yada refund için ticket oluşturulabilir.
              observer.error({ state: "order:save_purchase_error", error: error })
              this.store.off(verifiedCallback)
              observer.complete()
            }
          })

      }
      var unverifiedCallback = (unverified) => {
        observer.error({ state: "order:error_error" })
        this.store.off(unverifiedCallback)
        observer.complete()
      }
      this.store.when().approved(approvedCallback.bind(this));
      this.store.when().verified(verifiedCallback.bind(this));
      this.store.when().unverified(unverifiedCallback.bind(this));

      let offerObj: CdvPurchase.Offer = product.getOffer()
      offerObj.order()
        .then(order => {
          if (order && order.isError) {
            this.store.off(approvedCallback.bind(this))
            this.store.off(unverifiedCallback.bind(this))
            this.store.off(verifiedCallback.bind(this))

            if (order.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED || order.message == "USER_CANCELED") {
              observer.error({ state: "order:cancelled", error: order })
              observer.complete()
            } else {
              observer.error({ state: "order:error_billing", error: order })
              observer.complete()
            }
          }
        })
        .catch(err => {
          observer.error({ state: "order:error", error: err })
          observer.complete()
        })

    })

  }

  public ListTransactions() {
    return this.appStoreApiService.ListTransactions()
  }

  public RequestFile(props) {
    return this.appStoreApiService.RequestExceedsLimitFile(props)
  }

  getProduct(id) {
    return this.store.get(id)
  }


  async sendAnalyticData({ transaction, payload, farmNames, rawProductId, transactionId, receipt, product, signature, farms }) {

    DoktarAppsFlyer.AfEventLog(AppsflyerAnalyticEvents.purchase, {
      af_adrev_ad_type: "N/A",
      af_adrev_network_name: "",
      af_content: product,
      af_country: "N/A",
      af_currency: payload.Currency,
      af_customer_user_id: AccountService.instance.userData.UserRoleId,
      af_registration_method: receipt.nativeTransactions[0].type,
      af_revenue: payload.Price
    })
    var args = {
      Amount: payload.Price,
      FieldName: farmNames.join(", "),
      ItemSold: rawProductId,
      PaymentMode: receipt.nativeTransactions[0].type,
      Quantity: farms[0].Duration,
      TransactionID: transaction.transactionId,
      Currency: payload.Currency,
      CustomerType: farms[0].SelectedPackage
    }
    this.analyticService.customPurchaseEventLogs(args)

    AppInsightsLoggingService.logEvent(AnalyticEvents.PURCHASE_COMPLETED, args)
    try {
      // burada crash olmasın diye payload oluşumunu da try-catch içine aldım.
      // test etmek lazım. bilemiyorum işe yarayacak mı
      let logEventParams = {
        currency: payload.Currency,
        product: payload.PackageName,
        value: payload.Price ? payload.Price / 1000000 : 0,
        transaction: transaction.nativePurchase.receipt,
        params: {
          CountryCode: this.countryCode
        }
      }
      // TransactionID, ItemSold (paket adı), Quantity (süre), Payment Mode, Amount, Segment (bunu clevertap için çekecekmişiz.), FieldName(arasına virgül atıp devam ederiz burada), referrer screen
      this.analyticService.purchaseEventLog(logEventParams)
      if (this.devicePlatform === 'android') {
        DoktarAppsFlyer.LogPurchaseForAndroid({
          currency: payload.Currency,
          price: product.pricing.price,
          purchaseData: transaction.nativePurchase.receipt, //JSON.stringify(receipt.raw),
          publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAje/HhAP78SgDJUXH5tw6JYw/UsLg2jfx65/1wn9SQQCzBlyETurnaH5zJex8xGBjMRzBdEAnFq7LcMTlxb9SXGC3tjbb+dyCunnGUujL3QFuV/f5Y4j4zVnNc/JuTxZfqL6FaUDfKalI2k6AT7rBbCDgchhNTr+8kSnrR50ZWv0+Pw6AIncoKSdaPlnWbIxhhH5RKP2ofHi7AisWBZCMInCpJGvH+o30Jhnmr3TRLwbdZe3PJFaTXUkKgHDClUmFJn0YBBgQyFw1oHHRCYQcvqZz/NTSHO5csz+bfiI7Zk7H/bBrvApKcn5X4S9rJ21H1YzPsZOtK9IYd3LNZKvNSwIDAQAB", //https://play.google.com/console/u/0/developers/8195475583283894384/app/4974581032361933291/monetization-setup bu linkteki licence key
          signature: transaction.nativePurchase.signature,
          additionalParameters: null
        })
      }

      if (this.devicePlatform === 'ios') {
        DoktarAppsFlyer.LogPurchaseForIos({
          currency: payload.Currency,
          inAppPurchase: transaction.nativePurchase.receipt, // JSON.stringify(receipt.raw),
          price: product.pricing.price,
          transactionId: transaction.transactionId,
          additionalParameters: null
        })
      }
    } catch (error) {

    }
  }


}
