<ion-content class="bg-default modal-main-content" style="--background: #FFF;height: calc(100vh - var(--ion-safe-area-bottom) * 0); overflow: hidden;">
  <ion-grid fixed style="padding:0;height: 100%" >
    <ion-row
      style="margin:0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;">
      <ion-col size="12" style="padding:0">
        <ion-card style="margin:0">
          <ion-grid fixed style="padding:16px">
            <ion-row>
              <h2 class="font-size-20 font-weight-900 ion-text-center text-align-center full-width m-bottom-45 "
                style="width:100%">
                {{langWords[currentLangCode].no_internet_title}}
              </h2>
              <img class="no-conn-image" src="./../../../../../assets/images/no-connection.svg"
                [ngClass]="shakeElements ? 'shake-element' : ''" alt="">
              <p class="font-size-16 font-weight-900 ion-text-center text-align-center full-width">
                {{langWords[currentLangCode].no_internet_desc}}
              </p>
  
              <ion-button (click)="retry()" id="network-alert-retry-btn" class="full-width" style="width:100%" size="large" expand="block" fill="solid"
                shape="round">
                {{langWords[currentLangCode].retry_connection}}
              </ion-button>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-col>
      <!-- <ion-col size="12">
      </ion-col> -->
    </ion-row>
  </ion-grid>
</ion-content>