import { Injectable } from '@angular/core';
import { NotificationsApiService } from '../api/notification-api.service';
import { NotificationResponse } from '../models/interfaces/notification-settings-model';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core'
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { catchError, map } from "rxjs/operators";
import { Router } from '@angular/router';
import { IContainItem, INotification, INotificationModel } from '../models/interfaces/notification-model';
import { of } from 'rxjs/internal/observable/of';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { UtilsService } from './utils.service';
import { ClientAlertService } from './client-alert.service';
import { IButtonOpts } from '../models/interfaces/modal-components-models';
import { TranslateService } from '@ngx-translate/core';
import { CleverTapService } from './clevertap.service';
import { AndroidPermissionService, PermissionType } from './android-permission.service';
import { DoktarAppsFlyer } from './appsflyer.service';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

    notificationPermissionItemsList: Array<INotification>
    platform = Capacitor.getPlatform()
    constructor(private notificationApi: NotificationsApiService, private androidPermissions: AndroidPermissionService, private storageService: StorageService, private translateService: TranslateService, private router: Router, private alertService: ClientAlertService, private cleverTapService: CleverTapService) { }

    //#region push notification settings

    checkForPermission() {
        return new Observable(observe => {
            switch (this.platform) {
                case "ios":
                    PushNotifications.checkPermissions().then(response => {
                        observe.next(response)
                    })
                    break;
                case "android":
                    /**
                     * Androidde bildirimler default olarak onaylı geliyor.
                     */
                    this.androidPermissions.requestPermisson(PermissionType.Notification).subscribe(res => {
                        console.log(JSON.stringify(res))
                        observe.next(res)
                    })
                    // Permissions.query({ name: PermissionType.Notifications }).then(response => {
                    //   if (response.state === "denied") {
                    //     this.permissionService.RequestNotificationPermission().subscribe();
                    //   }
                    // observe.next(true)
                    // })
                    break;
                case "web":
                default:
                    observe.next(true)
                    break;
            }
        })
    }

    AddPushNotificationRegistrationListeners() {
        if (Capacitor.getPlatform() === 'web')
            return;
        PushNotifications.checkPermissions().then(permStatus => {
            if (permStatus.receive === 'prompt') {
                PushNotifications.requestPermissions().then(reqPermStatus => {
                    if (reqPermStatus.receive !== 'granted') {
                        throw new Error('User denied permissions!');
                    } else {
                        PushNotifications.register();
                    }
                })
            } else if (permStatus.receive !== 'granted') {
                // throw new Error('User denied permissions!');
            } else {
                PushNotifications.register();
            }

            // if (permStatus.receive !== 'granted') {
            //     throw new Error('User denied permissions!');
            // }
        });

        PushNotifications.addListener('registration', token => {
            this.storageService.set(StorageKeys.DEVICE_TOKEN, token.value).then();
            // this.storageService.deviceToken = token.value;
            console.log("notification_token", token)
            if (Capacitor.getPlatform() == "ios") {
                if (token.value.startsWith("fcmtoken_")) {
                    let pushtoken = token.value.replace("fcmtoken_", "")
                    StorageService.DeviceToken = pushtoken;
                    this.storageService.deviceToken = pushtoken;
                    if (AccountService.token)
                        this.notificationApi.RegisterPushNotificationToken({ Platform: Capacitor.getPlatform(), PushToken: pushtoken }).subscribe(response => {

                        })
                } else {
                    StorageService.ApnsToken = token.value
                    this.cleverTapService.activate(token.value)
                    DoktarAppsFlyer.updateServiceUninstallToken(token.value)
                }
            } else if (Capacitor.getPlatform() == "android") {
                PushNotifications.createChannel({
                    id: "default-channel",
                    name: "default-channel"
                }).then()
                StorageService.DeviceToken = token.value;
                this.storageService.deviceToken = token.value;
                this.cleverTapService.activate(token.value)
                DoktarAppsFlyer.updateServiceUninstallToken(token.value)
                if (AccountService.token)
                    this.notificationApi.RegisterPushNotificationToken({ Platform: Capacitor.getPlatform(), PushToken: token.value }).subscribe(response => {

                    })
            }
            // this.storageService.get(StorageKeys.NOTIFICATION_STATUS).then(response => {
            //     StorageService.DeviceToken = response.IsSuccess && response.Data && response.Data === "off" ? null : token.value;
            //     this.storageService.deviceToken = response.IsSuccess && response.Data && response.Data === "off" ? null : token.value;
            // });
        })

        PushNotifications.addListener('registrationError', err => {

        });

    }

    NotificationReceivedListener(handler) {
        if (Capacitor.getPlatform() === 'web')
            return;
        PushNotifications.addListener('pushNotificationReceived', notification => {
            handler(notification)
        });
    }

    NotificationOnPerformedListener(handler) {
        if (Capacitor.getPlatform() === 'web')
            return;
        PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            handler(notification)
        });
    }

    //#endregion

    public GetNotifications(skipCount: number, takeCount: number = 10) {
        return this.notificationApi.GetNotifications(skipCount, takeCount)
    }

    public GetTotalUnreadCount() {
        return new Promise((resolve, reject) => {
            this.notificationApi.GetUnreadNotificationCount().subscribe(res => {
                if (res.IsSuccess && Array.isArray(res.Data)) {
                    try {
                        let count = res.Data.map(t => +t.ConstantValue).reduce((previous, current) => previous + current, 0)
                        resolve(count)
                    } catch (error) {
                        resolve(0)
                    }
                } else {
                    resolve(0)
                }
            }, err => {
                resolve(0)
            })
        })
    }

    public GetNotificationPermissions() {
        // return this.notificationApi.GetNotificationPermissions();
        return this.notificationApi.GetNotificationPermissionsV2().pipe(catchError(val => of(val as IGenericResponse<INotification[]>))); //.pipe(map(t => { this.notificationPermissionItemsList = t.Data; return t; }))
    }
    public SaveNotificationPermission(payload: { NotificationIds: number[] }) {
        // return UtilsService.EmptyObservableRequest(true, 1000)
        return this.notificationApi.SaveNotificationPermissionV2(payload)
    }

    public ReadSelectedOne(item) {
        return this.notificationApi.ReadSelectedNotification(item.NotificationId)
    }

    /**
     * Gelmiş bir bildirime tıklandığında yapılacak yönlendirme, servis isteği, event log gibi işlemlerin takip edilmesi için eklendi.
     */
    public HandleNotificationItemClick(notificationType, tempFarmAttId, entityId) {
        // this.analyticServic.sendState("notification_click_on").subscribe(res => { })
        // if (!item.IsRead) {
        //     item.IsRead = true;
        //     //Update logic here
        //     this.notificationApi.ReadSelectedNotification(item.NotificationId).subscribe()
        //     // this.clientProxy.serviceCall("update/notification/" + item.NotificationId + "/read/status", {}, "get")
        //     //     .subscribe(res => {

        //     //     });
        // }
        let queryString = tempFarmAttId ? tempFarmAttId : entityId
        switch (notificationType) {

            case "MeteorologicalBlitzAlert":
            case 1382:
                this.router.navigate(["tabs/map"], { queryParams: { tempFarmAttId: queryString, q: Math.random() } });
                break;
            case "PrecipitationAlert":
            case 1389:
                this.router.navigate(["weather/detail"], { queryParams: { id: queryString, pointId: 0 } });
                break;
            case "MeteorologicalStormAlert":
            case 1380:
                this.router.navigate(["weather"], { queryParams: { page: "rainviewer", tempfarmattid: queryString } });
                break;
            case "FarmerProblems":
            case 1170:
                if (entityId && entityId !== "")
                    this.router.navigate(["support/questions"], { queryParams: { questionId: entityId, q: Math.random() } });
                break;
            case "MeteorologicalAlert": //1246
            case 1246:
                // if (payLoad.TempFarmAttId && payLoad.TempFarmAttId !== "")
                this.router.navigate(["weather"], { queryParams: { page: "rainviewer", tempfarmattid: queryString } });
                break;
            case "Satellite":
            case 1160:
                this.router.navigate(["tabs/map"], { queryParams: { tempFarmAttId: queryString, q: Math.random() } });
                break;
            case "Other": //1031
            case 1031:
            default:
                // if (payLoad.TempFarmAttId && payLoad.TempFarmAttId !== "")
                if (queryString && queryString !== '')
                    this.router.navigate(["tabs/map"], { queryParams: { tempFarmAttId: queryString, q: Math.random() } });
                break;
        }
    }

    public ShowImageryNotificationAlert() {
        this.GetNotificationPermissions().subscribe(res => {

            let openedOnes = []
            let confirmAction = () => {
                // 1007 - New-Satellite-Images
                if (!openedOnes.some(t => t == 1007))
                    openedOnes.push(1007);
                this.SaveNotificationPermission({ NotificationIds: openedOnes }).subscribe()
            }
            let buttons: IButtonOpts[] = [
                {
                    callback: () => confirmAction(),
                    id: "notifications-notify-me-btn",
                    color: "primary",
                    fill: "solid",
                    text: this.translateService.instant("notifications.notify_me_btn")
                },
                {
                    callback: () => { },
                    id:"notifications-dont-notify-me-btn",
                    color: "dark",
                    fill: "clear",
                    text: this.translateService.instant("notifications.dont_notify_me_btn")
                }
            ]
            if (res && res.Data) {
                this.notificationPermissionItemsList = res.Data
                let imagerySlaveNotification: IContainItem = {} as IContainItem;
                this.notificationPermissionItemsList.forEach(el => {
                    imagerySlaveNotification = imagerySlaveNotification || el.ContainList.find(t => t.SlaveId == 1007)
                    openedOnes = [...el.ContainList.filter(t => t.IsPermission).map(t => t.SlaveId), ...openedOnes]
                })
                if (Object.keys(imagerySlaveNotification).length == 0 || !imagerySlaveNotification.IsPermission) {
                    this.alertService.PresentAlert({
                        buttons,
                        description: this.translateService.instant("notifications.get_imagery_ready_notification_desc"),
                        image: "./../../../assets/images/notify_image.svg",
                        title: this.translateService.instant("notifications.get_imagery_ready_notification_title")
                    }, false).then(res => { })
                }
            } else {
                this.alertService.PresentAlert({
                    buttons,
                    description: this.translateService.instant("notifications.get_imagery_ready_notification_desc"),
                    image: "./../../../assets/images/notify_image.svg",
                    title: this.translateService.instant("notifications.get_imagery_ready_notification_title")
                }, false).then(res => { })
            }
        })
    }
}