<ion-content (click)="dismissModal()" [ngClass]="contentClass" class="bg-default" forceOverscroll="false" id="pullup-item-list-ion-content"
  style="overflow: hidden;" [style]="{'--padding-top': (bottomPadding > 0 ?  bottomPadding + '%' : 0)}">
  <div style="display: flex; height:100%; flex-direction: column; justify-content: flex-end">
    <ion-card [id]="'card_item_' + handleBarId" (click)="$event.stopPropagation()" possibleOutcome="not_allowed"
      class="ion-no-margin p-top-0 p-horizontal-16 custom-ion-card modal-card-area"
      [ngClass]="{'full-height':isFullscreen, 'max-height-98':!isFullscreen, 'p-bottom-30': platformIos, 'p-bottom-16':!platformIos}">
      <ion-card-header [id]="handleBarId" class="p-vertical-0 m-bottom-24">
        <ion-card-title class="font-size-18 font-weight-700 ion-text-center" *ngIf="title && title !== ''">
          <span class="break-spaces">
            {{title | translate}}
          </span>
        </ion-card-title>
        <ion-card-subtitle class="m-top-8">
          <div class="handle-bar"></div>
        </ion-card-subtitle>
        <ion-icon (click)="dismissModal()" id="pullup-list-modal-close-icon" [possibleOutcome]="'close modal'" class="position-absolute-top-end font-size-25 z-index-50" color="dark" [src]="'closeCircle' | orbitIcons"></ion-icon>
      </ion-card-header>
      <ion-searchbar *ngIf="showSearchbar" class="custom" placeholder="" inputmode="search" type="text"
        (ionInput)="onSearchChange($event)" [debounce]="250" clearIcon></ion-searchbar>
      <ion-card-content class="ion-no-padding card-content"
        [ngClass]="{'with-button': hasConfirmBtn, 'with-searchbar': showSearchbar}">
        <ion-grid fixed [ngStyle]="{'max-height': 'calc(100% - ' + areaHeight + 'px)'}" class="ion-no-padding">
          <ion-row class="ion-justify-content-center ion-no-padding ion-align-items-center">
            <ion-col size="12" class="ion-no-padding">
              <ion-list bg="none">
                <ion-item bg="none" lines="full" *ngFor="let item of filteredList; let index = index" [id]="'pullup-list-items-'+index"
                  (click)="itemClicked(item);">
                  <ng-container *ngIf="item.icon">
                    <ion-icon *ngIf="item.iconSource === 'ionIcon'" [style]="item.iconStyle" [slot]="item.iconSlot"
                      [name]="item.icon">
                    </ion-icon>
                    <ion-icon *ngIf="item.iconSource === 'asset'" [style]="item.iconStyle" [slot]="item.iconSlot"
                      [src]="item.icon">
                    </ion-icon>
                  </ng-container>
                  <ion-label class="m-vertical-16">{{item.value}}</ion-label>

                  <ion-icon *ngIf="isMultiple; else singleSelect" color="primary" slot="end"
                    [name]="item.isChecked ? 'checkmark-circle' : 'ellipse-outline'">
                  </ion-icon>
                  <ng-template #singleSelect>
                    <ion-icon color="primary" slot="end"
                      [src]="( isMultiple ? (item.isChecked ? 'select-radio' : 'select-radio-empty') : selectedList && selectedList[0]?.id === item.id ? 'select-radio' : 'select-radio-empty') | orbitIcons">
                    </ion-icon>
                  </ng-template>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
      <ion-button *ngIf="hasConfirmBtn" (click)="applyBtn()" id="pullup-item-list-confirm-btn" expand="block" fill="solid" shape="round">
        {{confirmBtnText | translate}}
      </ion-button>
    </ion-card>
  </div>
</ion-content>