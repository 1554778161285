<orbit-modal-base [hasCloseBtn]="true" (onDismiss)="dismiss(null)">
  <!-- <ion-content (click)="dismiss(null)" [ngClass]="contentClass" forceOverscroll="false" id="select-lang-ion-content"
    class="bg-default modal-main-content">
    <ion-grid fixed style="height: 100%;" class="ion-no-padding">
      <ion-row class="ion-align-items-end ion-justify-content-center ion-no-padding" style="height: 100%;">
        <ion-col sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="10" style="border-radius: 40px 40px 0 0; margin:unset; ">
          <ion-card (click)="$event.stopPropagation()" possibleOutcome="not_allowed" id="select-lang-container-card"
            [ngStyle]="{'max-height':'calc('+(initialBreakpoint * 100) + 'vh - 25px)'}" color="light" shape="round"
            class="ion-no-margin ion-no-padding p-top-8 base-modal-card p-bottom-30"> -->
  <ion-toolbar bg="none" mode="md" class="border-basic-bottom">
    <ion-title class="font-size-20 font-weight-850 ion-no-padding">
      <span class="break-spaces">
        {{"general.change_language_title" | translate}}
      </span>
    </ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" color="dark" id="select-lang-dismiss-btn">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>

  <ion-list [inset]="true" lines="full" bg="none" mode="md" class="p-all-0 m-horizontal-0-inline m-vertical-0-i">
    <ion-item bg="none" lines="full" *ngFor="let item of langOptions" (click)="selectOption(item)"
      [id]="'select-lang-options-'+item.Value">
      <ion-label class="p-top-2 p-bottom-1 font-size-14-i font-weight-550-i ">{{item.Value}}</ion-label>
      @if(item.isChecked){
      <ion-icon slot="end" [src]="'select-radio' | orbitIcons" color="primary"></ion-icon>}
    </ion-item>
  </ion-list>
  <!-- </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content> -->
</orbit-modal-base>