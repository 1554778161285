import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsLoggingService {
    private static _appInsights: ApplicationInsights;
    public static get appInsights(): ApplicationInsights {
        if (!AppInsightsLoggingService._appInsights) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true // option to log all route changes
                }
            });
            this.appInsights.loadAppInsights();
        }
        return AppInsightsLoggingService._appInsights;
    }
    public static set appInsights(value: ApplicationInsights) {
        AppInsightsLoggingService._appInsights = value;
    }

    constructor() {
        if (environment.production) {
            AppInsightsLoggingService.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true, // option to log all route changes,
                    autoTrackPageVisitTime: true
                }
            });
            AppInsightsLoggingService.appInsights.loadAppInsights();
            AppInsightsLoggingService.appInsights.addTelemetryInitializer(en => {
                var tempItem = en.baseData
                tempItem.properties = tempItem.properties || {}
                tempItem.properties["ApplicationPlatform"] = "Web";
                tempItem.properties["ApplicationName"] = "Orbit";

            })
        }
    }

    static logPageView(name?: string, url?: string, properties?, startTime?) { // option to call manually
        if (environment.production)
            this.appInsights.trackPageView({
                name: name,
                uri: url,
                properties,
                startTime,
            });
    }

    static logEvent(name: string, properties?: { [key: string]: any }) {
        if (environment.production)
            this.appInsights.trackEvent({ name: name }, properties);
    }

    static logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (environment.production)
            this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    static logException(exception: Error, severityLevel?: number, properties?: { [key: string]: any }) {
        if (environment.production)
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, properties);
    }

    static logTrace(message: string, properties?: { [key: string]: any }) {
        if (environment.production)
            this.appInsights.trackTrace({ message: message }, properties);
    }
}