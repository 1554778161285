<ion-header [translucent]="true" class="no-backdrop" bg="none">
  <ion-toolbar bg="none">
    <ion-buttons slot="start">
      <ion-button size="small" class="custom-back-button" (click)="dismiss(null)" id="app-back-btn" fill="clear" color="primary">
        <!-- <ion-icon [slot]="'start'" class=""  [src]="'./../../../../../assets/icon/arrow-left-icon.svg'"></ion-icon> -->
        <ion-icon slot="start" class="font-size-14-i" src="./../../../assets/icon/arrow-left-icon.svg"></ion-icon>
        {{"general.back_lbl" | translate }}
      </ion-button>
      <!-- <ion-button color="primary" (click)="backAction()" id="plans-page-back-btn">
        <ion-icon slot="start" class="font-size-14-i" [src]="'arrow_left' | orbitIcons"></ion-icon>
        {{"general.back_lbl" | translate }}
      </ion-button> -->
    </ion-buttons>
    <ion-title class="p-horizontal-20" bg="none">
      {{("plans_pricing.purchase_btn") | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content forceOverscroll="false" [fullscreen]="true" fixed [ngStyle]="{'--offset-bottom': 0}">
  <ion-header collapse="condense" class="p-top-0" bg="none">
    <ion-toolbar bg="none">
      <ion-title class="custom p-left-8-i ion-text-left font-size-24 font-weight-850">{{("plans_pricing.purchase_btn") | translate}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <img class="fixed-image" src="./../../../assets/images/purchase-sample-image.png">
  <ion-grid fixed class="p-all-0">
    <ion-row>
      <ion-col size="12" class="p-horizontal-16">
        <span class="pricing-type-label">{{"plans_pricing.premium_lbl_uc" | translate}}</span>
        <p class="font-size-16 font-weight-850" [innerHTML]="'plans_pricing.premium_desc' | translate"></p>
      </ion-col>
      <ion-col size="12" class="p-horizontal-16">
        <div class="package-details">
          <ul>
            @for(item of ('plans_pricing.premium_benefits_list' | translate); track item){
            <li>{{item}}</li>
            }
          </ul>
        </div>
      </ion-col>
      <div class="full-width m-vertical-25"></div>

    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
<ion-grid fixed>
  <ion-row>
    @if(isOnlyPackageDetails){
      <ion-col size="12">
        <ion-button (click)="addFieldRedirection()" id="plan-pricing-alert-add-field-btn" expand="block" fill="solid" shape="round">
          {{"plans_pricing.nothing_to_activate_alert_add_field_btn" | translate}}
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-button (click)="goHomeRedirection()" id="plan-pricing-alert-go-home-btn" expand="block" fill="clear" shape="round">
          {{"general.go_to_homepage" | translate}}
        </ion-button>
      </ion-col>
      }@else{
      <ion-col size="12" class="p-horizontal-16">
        <ion-row>
          @for(item of allPlanOptions['pro']; track item){
          <ion-col size="4" class="p-all-0">
            <ion-card class="m-horizontal-5 p-vertical-16 m-vertical-0 full-height price-card" [ngClass]="selectedPrice === item ? 'active' : ''" (click)="selectPrice(item)" [id]="'purchase-options-card-' + item.Duration">
              <div class="d-flex column ion-text-center">
                <h3 class="font-size-20-i font-weight-850-i m-all-0" color="dark">
                  {{item.Duration}} <br>
                  <span class="font-size-14-i font-weight-450-i">{{(item.Duration == 1 ? "plans_pricing.month" : "plans_pricing.months") | translate}}</span>
                </h3>
                @if(item.isPackageAvailable){
                <h2 class="font-size-16-i font-weight-850-i m-all-0" color="dark"><span class="font-size-12-i">{{item.currency}}</span>{{item.priceText}}</h2>
                <span *ngIf="item.Duration > 1" class="font-size-12-i font-weight-450-i" color="medium">{{item.monthlyPrice+ "/" + ("plans_pricing.month_short" | translate)}}</span>
                }@else{
                <span class="font-size-16-i font-weight-850-i" color="dark">{{('plans_pricing.limit_exceeded' | translate)}}</span>
                }
              </div>
            </ion-card>
          </ion-col>
          } @empty{
          @for(item of [1,2,3]; track item){
          <ion-col size="4" class="p-all-0">
            <ion-card class="m-horizontal-5 p-all-16 m-vertical-0 height-130">
              <div class="d-flex column justify-center align-center full-height">
                <div class="d-flex align-center full-width">
                  <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
                </div>
              </div>
            </ion-card>
          </ion-col>
          }
          }
        </ion-row>
      </ion-col>
      <ion-col size="12" class="p-horizontal-16 p-bottom-20">
        <ion-button [disabled]="!selectedPrice" (click)="startPurchaseAction()" expand="block" fill="solid" shape="round" id="plan-select-start-purchase-btn">
          {{(selectedPrice && selectedPrice.isPackageAvailable ?
          "plans_pricing.purchase_btn" : "plans_pricing.get_offer_btn") | translate}}
        </ion-button>
      </ion-col>
      }
  </ion-row>
</ion-grid>
</ion-footer>

<ion-modal class="modal-transparent" #exceededPlanModal>
  <ng-template>
    <orbit-modal-base [hasCloseBtn]="true" (onDismiss)="exceededPlanModal.dismiss()">
      <!-- <ion-fab vertical="top" horizontal="end" class="unset-box-shadow custom-fab-position">
        <ion-fab-button (click)="exceededPlanModal.dismiss()" id="register-request-close-btn" color="light" size="small" bg="none" class="unset-box-shadow">
          <ion-icon name="close" ></ion-icon>
        </ion-fab-button>
      </ion-fab> -->
      <ion-grid fixed>
        <div class="d-flex column justify-center align-center">
          <div>
            <h2 class="ion-text-center font-size-16-i font-weight-800-i p-bottom-20" color="dark" [innerHTML]="'plans_pricing.contact_us_title' | translate"></h2>
          </div>
          <div>
            <p class="ion-text-center font-size-14-i font-weight-400" [innerHTML]="'plans_pricing.contact_us_desc' | translate"></p>
          </div>
        </div>
        <ion-row class="column">
          <ion-col size="12" class="d-flex row p-horizontal-16 gap m-bottom-30 m-top-24">
            <ion-card class="m-all-0 flex-1 d-flex column justify-center align-center request-option-card p-vertical-6" [ngClass]="selectedContactOption?.key === item.key ? 'active' : ''" (click)="selectedContactOption = item"
              [id]="'reqister-request-options-item-' + item.key" *ngFor="let item of contactOptions">
              <div class="">
                <ion-icon class="font-size-22" [color]="selectedContactOption?.key === item.key ? 'primary' : 'medium-light'" [src]="item.icon | orbitIcons"></ion-icon>
              </div>
              <span class="font-size-12 font-weight-700 ion-text-center" [attr.color]="selectedContactOption?.key === item.key ? 'primary' : 'medium-light'">{{item.text | translate}}</span>
            </ion-card>
          </ion-col>
          <ion-col size="12">
            <ion-button (click)="createContactRequest()" id="register-request-confirm-btn" [disabled]="!selectedContactOption" expand="block" fill="solid" shape="round">
              {{"plans_pricing.request_btn" | translate}}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </orbit-modal-base>
  </ng-template>
</ion-modal>