import { orbitIcons } from "../../helper/class/orbit-custom-icons"

export interface IListModalComponentModel {
    title: string
    isMultiple: boolean
    itemList: Array<IListModalModel>
    hasConfirmBtn: boolean
    confirmBtnText: string
    isFullscreen: boolean
    showSearchbar?: boolean
    searchPlaceholder?: string
    sortItems?: boolean,
    detailedToolbar?: boolean
}

export class ListModalModel<T = any> implements IListModalModel{
    id: string | number
    key?: string
    icon?: string
    iconSource?: "ionIcon" | "asset" | "image"
    iconSlot?: "start" | "end"
    iconStyle?: any
    value: string
    hasSortingPriority?: boolean
    isChecked?: boolean
    data?: T
    /**
     *
     */
    constructor(props:IListModalModel) {
        this.id = props?.id;
        this.key = props?.key;
        this.icon = props?.icon;
        this.iconSource = props?.iconSource;
        this.iconSlot = props?.iconSlot;
        this.iconStyle = props?.iconStyle;
        this.value = props?.value;
        this.hasSortingPriority = props?.hasSortingPriority;
        this.isChecked = props?.isChecked;
        this.data = props?.data;
    }
}

export interface IListModalModel<T = any> {
    id: string | number
    key?: string
    icon?: string | keyof typeof orbitIcons | any
    iconSource?: "ionIcon" | "asset" | "image"
    iconSlot?: "start" | "end"
    iconStyle?: any
    value: string
    hasSortingPriority?: boolean
    isChecked?: boolean
    data?: T
}

export interface IBaseAlertModel {
    mainTitle?: string
    title?: string
    description?: string
    image?: string
    buttons: Array<IButtonOpts>
    hasBackdropdismiss?: boolean,
    preventDismiss?: boolean
    imageClassList?: string
}

export interface IButtonOpts {
    color: TypeColor
    text: string
    fill: "solid" | "clear" | "outline"
    iconSource?: "ionIcon" | "asset"
    icon?: string | keyof typeof orbitIcons | any
    callback: any
    id: string
}

type TypeColor = "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "dark" | "medium" | "light" | "white" | "black" | "medium-light" | "medium-dark" | "ultra-light" | "ultra-dark" | "overlay" | "night-blue" 