// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 3,
  version: "3.2.10",
  iosAppId: "id1402129340",
  iosDebugAppId: "id242354199",
  androidAppId: "com.doktar.tarlam365",
  doktarApiDomain: "api.doktarim.com",
  // baseApiUrl: "https://doktarapiv1.azurewebsites.net/api/", //https://doktarapiv1.azurewebsites.net/get?
  baseApiUrl: "https://api.doktarim.com/api/", //https://doktarapiv1.azurewebsites.net/get?
  // gatewayApiUrl: "http://192.168.1.65:5029/",
  gatewayApiUrl: "https://api.doktarim.com/",
  companyId: "193",
  campaignId: "113",
  appInsights: {
    instrumentationKey: "fcf66a98-5475-4568-846b-d72283bdc467",
    ingestionEndpoint: "https://northeurope-2.in.applicationinsights.azure.com/",
    liveEndpoint: "https://northeurope.livediagnostics.monitor.azure.com/",
    applicationId: "ccf6b283-85f4-4e53-842d-0d8fedd96377",
  },
  i18nUrl: "../assets/i18n/",
  // i18nUrl: "https://api.doktarim.com/lang/orbit-v3/i18n/",
  mapbox: {
    accessToken: "pk.eyJ1IjoibXN0ZmNpbmFyIiwiYSI6ImNrbHQ3bGhjeTIzcDUydnF5c3BvZHE0bm8ifQ.ms7GiuDSI8ZqDI-EFp_D1Q",
    // accessToken: "pk.eyJ1Ijoib3NtYW4tc2VuIiwiYSI6ImNsazl3MmpuOTAxMmYzbHFvaWhpZTl2OTcifQ.UOnetkiuXSCW-3i4F1MmJA",
    mapStyle: "mapbox://styles/mstfcinar/clk9ellhd00or01qy2459ey29"
    // mapStyle: "mapbox://styles/osman-sen/clk9w782g00qo01pd8bvmdxzc"
  },
  // constantJsonUrl: "https://api.doktarim.com/lang/orbit-v3/constants.json",
  constantJsonUrl: "./assets/files/constants.json",
  countryFlags: "https://api.doktarim.com/software/content/images/country-flags/",
  countryList: "https://api.doktarim.com/software/content/images/country-flags/country-list.json",
  mapLayerStyle: { fill: "#FFB83D", opacity: 0.5, stroke: "#FFFFFF", "stroke-width": 2 },
  planBasedMapLayerStyle: { free: { fill: "#FFFFFF", opacity: 0.3, stroke: "#FFFFFF", "stroke-width": 3 }, basic: { fill: "#FFB83D", opacity: 0.5, stroke: "#FFFFFF", "stroke-width": 2 }, pro: { fill: "#FFB83D", opacity: 0.5, stroke: "#FFFFFF", "stroke-width": 2 } },
  homeMapZoomLimit: 12,
  baseImageUrl: "https://api.doktarim.com/software/content/",
  faqBaseUrl: "https://api.doktarim.com/lang/orbit/faq/",
  // faqBaseUrl: "./assets/files/faq/",
  // onboardingDataUrl: "https://api.doktarim.com/lang/orbit-v3/guide/onboarding-data.json",
  onboardingDataUrl: "./assets/files/onboarding-data.json",
  // onboardingImagesBaseUrl: "https://api.doktarim.com/lang/orbit-v3/guide/onboarding-images/",
  onboardingImagesBaseUrl: "./assets/images/onboarding/",
  guidesDataUrl: "./assets/files/guide-data.json",
  // guidesDataUrl: "https://api.doktarim.com/lang/orbit-v3/guide/guide-data.json",
  // guidesImagesBaseUrl: "./assets/guide/guide-images/",
  guidesImagesBaseUrl: "https://api.doktarim.com/lang/orbit-v3/guide/guide-images/",
  agreementsBaseUrl: "https://c.doktar.io/apps/agreements", //"https://doktar.com/mobile/docs/orbit/agreements",
  // privacy:"Privacy_Policy",
  // commercialElectronicMessage:"CommercialElectronicMessage",
  // kvkk:"Kullanim_Kosullari_Kullanici_Sozlesmesi",
  privacy: "Doktar_Orbit_202105_Kullanim_Kosullari_Kullanici_Sozlesmesi",
  distanceServiceSales: "Doktar_Orbit_202106_Mesafeli_Hizmet_Satis_Sozlesmesi",
  commercialElectronicMessage: "Doktar_Orbit_202106_Ticari_Elektronik_İleti_Onay_Metni",
  kvkk: "Doktar_Orbit_202201_Kisisel_Verileri_Koruma_Kurumu",
  adjustEvents: "https://api.doktarim.com/lang/orbit/adjust-events.json",
  logEvents: false,
  emptyHarvestDate: "1900-01-02T00:00:00",
  emptySowingDate: "1900-01-01T00:00:00",
  onelinkDomains: ["https://onelink.to/m77tyz", "https://orbit.onelink.me/58QG/orbitapp", "https://orbit.onelink.me/58QG/orbit", "https://orbit.onelink.me/58QG/yoykxj5e", "https://orbit.onelink.me/58QG/1e2lc4ug"]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
