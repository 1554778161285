import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Gesture, GestureController, IonCard, ModalController } from '@ionic/angular';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';
import { AltBaseModal } from '../../class/alt-base-modal.component';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'orbit-modal-base',

  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent implements OnInit {
  @Input() id: number
  @Input() initialBreakpoint: number = 1
  @Input() bottomPadding: number = 0;
  @Input() isBottomSheet: boolean = false
  @Input() hasHandle: boolean = true
  @Input() hasCloseBtn: boolean = false
  @Output() onDismiss: EventEmitter<any> = new EventEmitter()

  handleBarId = `handle-bar-${Date.now()}`
  public contentClass: any = "bg-default"//
  public gesture: Gesture
  constructor(private modalService: ModalService, private gestureCtrl: GestureController) {
  }


  ngOnInit(): void {
    if (this.initialBreakpoint) {
      let diff = 1 - this.initialBreakpoint;
      this.bottomPadding = 100 * diff;
    }
    setTimeout(() => {
      if(!this.isBottomSheet)
      this.contentClass = "bg-opaque"
      if (this.hasHandle)
        this.initGesture()
    }, 500);
  }

  previousHeight: number;
  private initGesture() {
    let component = document.getElementById(this.handleBarId);
    let cardItem = document.getElementById(`card_item_${this.handleBarId}`)
    if (component && cardItem) {
      this.previousHeight = cardItem.getBoundingClientRect().height;
      this.gesture = this.gestureCtrl.create({
        el: component,
        gestureName: `modal_${this.handleBarId}`,
        blurOnStart: false,
        direction: "y",
        onMove: (ev) => {
          // let height = cardItem.getBoundingClientRect().height;
          if (ev.deltaY > 0) {
            cardItem.style.height = `${this.previousHeight - ev.deltaY}px`
          }
        },
        onEnd: (ev) => {
          let tempHeight = cardItem.getBoundingClientRect().height;
          if (tempHeight < this.previousHeight * .4) {
            this.dismiss(null)
          } else {
            cardItem.style.height = this.previousHeight + "px"
          }
        },
        threshold: 10
      })
      this.gesture.enable()
    } else {
      setTimeout(() => {
        this.initGesture()
      }, 500);
    }
  }

  async dismiss(data?, role = "canceled") {
    // setTimeout(() => {
    this.contentClass = "bg-default"
    this.onDismiss.emit(true);
    // }, 500);
    // await this.modalService.dismissByModalId(this.id, data, role)
  }

}
