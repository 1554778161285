<!-- <doktar-intl-country-list id="auth-select-country" [isMobile]="true" [title]="'general.countries' | translate"
  [placeholder]="'general.search_countries' | translate" id="guest_auth"></doktar-intl-country-list> -->
<ion-content
  [style]="{'--padding-bottom': (bottomPadding > 0 ? 'calc(' + bottomPadding + '% + var(--ion-safe-area-bottom))' : 0)}">
  <ion-header>
    <ion-toolbar bg="none">
      <ion-buttons slot="end">
        <ion-button color="dark" (click)="dismiss(null)" id="guest-register-close-btn">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{"app_title" | translate}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-grid fixed class="full-height">
    <ion-row class="ion-justify-content-center">
      <ion-col size="11">
        <ion-segment class="p-all-3" [value]="segment" (ionChange)="segmentChanged($event)">
          <ion-segment-button value="login">
            <ion-label>{{"auth.login" | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="register">
            <ion-label>{{"auth.register" | translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="segment == 'login'">
      <ion-col size="12">
        <!-- <app-login (onLogin)="loginListener($event)" idPrefix="guest_auth"></app-login> -->
        <ion-col size="12">
          <form [formGroup]="loginForm" (keydown.enter)="$event.preventDefault()">
            <ion-grid>
              <ion-row>
                <ion-col size="12" class="m-vertical-5">
                  <div class="d-flex row gap-12">
                    <div>
                      <ion-item shape="round" class="border-basic focus-border full-height arpa-input-item"
                        color="ultra-light" lines="none" id="guest-login-phone-code" (click)="openFlagsModal()">
                        <img slot="start" class="height-20 m-right-8-i"
                          [src]=" ('countryFlags' | environment) + (selectedCountry?.iso2 | uppercase)+'.svg'" alt=""
                          srcset="">
                        <ion-label class="font-size-14-i font-weight-600" color="dark">+
                          {{selectedCountry?.dialCode}}</ion-label>
                        <ion-icon class="font-size-14-i" color="dark" slot="end"
                          name="chevron-forward-outline"></ion-icon>
                      </ion-item>
                    </div>
                    <div class="flex-1">
                      <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item"
                        color="ultra-light" lines="none">
                        <ion-label class="form-label" style="margin-top:-12px" position="floating">
                          {{'auth.phone_number' | translate}}
                        </ion-label>
                        <ion-input required formControlName="phone" id="guest-login-phone-input" type="tel"
                          [maskito]="phoneMask" [placeholder]="placeholder"
                          [maskitoElement]="maskPredicate"></ion-input>
                      </ion-item>
                    </div>
                  </div>
                </ion-col>
                <ion-col size="12" class="m-vertical-5">

                  <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item"
                    color="ultra-light" lines="none">
                    <ion-label class="form-label" style="margin-top:-12px" position="floating">
                      {{'auth.password' | translate}}
                    </ion-label>
                    <ion-input required formControlName="password" (change)="setPwd($event)" id="signIn_Password"
                      [type]="passwordVisibility ? 'text' : 'password'"> </ion-input>
                    <ion-icon class="ion-justify-content-center ion-align-self-center" slot="end" id="signIn_ShowPass"
                      (click)="passwordVisibility = !passwordVisibility"
                      [src]="( passwordVisibility ? 'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
                  </ion-item>
                  <ion-text color="danger" *ngIf="areCrendentialsWrong">
                    <h1 class="font-size-12">{{"auth.wrong_credts_error" | translate}}</h1>
                  </ion-text>
                </ion-col>
                <ion-col size="12">
                  <ion-text color="dark" class="ion-text-right d-block ">
                    <span (click)="forgetPassword()" id="guest-login-forget-pass"
                      class="font-size-13 text-decoration-underline">{{'auth.forget_password' | translate}}</span>
                  </ion-text>
                </ion-col>
                <ion-col size="12" class="m-top-39">
                  <ion-button (click)="onSubmit()" id="guest-login-submit" [disabled]="loginForm.invalid"
                    expand="block" fill="solid" shape="round">
                    {{"auth.login_btn" | translate}}
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ion-col>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="segment == 'register'">
      <ion-col size="12">
        <form [formGroup]="registerForm" (keydown.enter)="$event.preventDefault()">
          <ion-grid fixed>
            <ion-row>
              <ion-col size="12" class="m-vertical-5">
                <ion-item lines="none" shape="round" color="tab-primary"
                  [class]="(registerForm | isFormValid : 'namesurname') ? 'ion-item-invalid item-height-61' : 'item-height-61'">
                  <!-- [position]="loginForm.controls['email'].value ? 'stacked' : 'fixed'" -->
                  <ion-label class="form-label" style="margin-top:-8px" position="floating">{{"auth.namesurname" |
                    translate}}
                    <ion-text color="danger">*</ion-text>
                  </ion-label>
                  <ion-input required formControlName="namesurname" type="text"></ion-input>
                  <ion-icon class="ion-align-self-center" *ngIf="registerForm | isFormValid : 'namesurname'"
                    color="danger" slot="end" name="alert-circle"></ion-icon>
                </ion-item>
                <ion-text *ngIf="registerForm | isFormValid : 'namesurname': ['nameValidation']" color="danger"
                  class="form-error">
                  <span>{{"auth.namesurname_validation_error" | translate}}</span>
                </ion-text>
              </ion-col>
              <ion-col size="12">
                <div class="d-flex row gap-12">
                  <div>
                    <ion-item shape="round" class="border-basic focus-border full-height arpa-input-item"
                      color="ultra-light" lines="none" id="guest-register-phone-code" (click)="openFlagsModal()">
                      <img slot="start" class="height-20 m-right-8-i"
                        [src]=" ('countryFlags' | environment) + (selectedCountry?.iso2 | uppercase)+'.svg'" alt=""
                        srcset="">
                      <ion-label class="font-size-14-i font-weight-600" color="dark">+
                        {{selectedCountry?.dialCode}}</ion-label>
                      <ion-icon class="font-size-14-i" color="dark" slot="end"
                        name="chevron-forward-outline"></ion-icon>
                    </ion-item>
                  </div>
                  <div class="flex-1">
                    <!-- <orbit-arpa-input type="tel" [label]="'auth.phone_number' | translate" [labelPlacement]="'floating'" [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate" ></orbit-arpa-input> -->
                    <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item"
                      color="ultra-light" lines="none">
                      <ion-label class="form-label" style="margin-top:-12px" position="floating">{{'auth.phone_number' |
                        translate}}</ion-label>
                      <ion-input required formControlName="phone" id="register_PhoneNumber" type="tel"
                        [maskito]="phoneMask" [placeholder]="placeholder" [maskitoElement]="maskPredicate"></ion-input>
                    </ion-item>
                  </div>
                </div>
              </ion-col>
              <ion-col size="12" class="m-vertical-5">
                <ion-item lines="none" shape="round" color="tab-primary"
                  [class]="(registerForm | isFormValid : 'email': ['email']) ? 'ion-item-invalid item-height-61' : 'item-height-61'">
                  <!-- [position]="loginForm.controls['email'].value ? 'stacked' : 'fixed'" -->
                  <ion-label class="form-label" style="margin-top:-8px" position="floating">{{"auth.email" |
                    translate}}
                    <ion-text color="danger">*</ion-text>
                  </ion-label>
                  <ion-input required formControlName="email" type="text"></ion-input>
                  <ion-icon class="ion-align-self-center" *ngIf="registerForm | isFormValid : 'email'" color="danger"
                    slot="end" name="alert-circle"></ion-icon>
                </ion-item>
                <ion-text *ngIf="registerForm | isFormValid : 'email': ['email']" color="danger" class="form-error">
                  <span>{{"auth.email_validation_error" | translate}}</span>
                </ion-text>
              </ion-col>
              <ion-col size="12" class="m-vertical-5">
                <ion-item lines="none" shape="round" color="tab-primary"
                  [class]="(registerForm | isFormValid : 'password':['minlength','maxlength']) ? 'ion-item-invalid item-height-61' : 'item-height-61'">
                  <!-- [position]="loginForm.controls['email'].value ? 'stacked' : 'fixed'" -->
                  <ion-label class="form-label" style="margin-top:-8px" position="floating">{{"auth.password" |
                    translate}}
                    <ion-text color="danger">*</ion-text>
                  </ion-label>
                  <ion-input required formControlName="password" [type]="passwordVisibility ? 'text' : 'password'">
                  </ion-input>
                  <ion-icon class="ion-align-self-center" (click)="passwordVisibility = !passwordVisibility"
                    id="toggle-password-icon" [color]="(registerForm | isFormValid : 'password') ? 'danger': ''"
                    slot="end" [name]="passwordVisibility ? 'eye-off-outline' : 'eye-outline'"></ion-icon>
                </ion-item>
                <ion-text *ngIf="registerForm | isFormValid : 'password':['minlength','maxlength']" color="danger"
                  class="form-error">
                  <span>{{"auth.password_validation_error" | translate}}</span>
                </ion-text>
                <ng-container
                  *ngIf="registerForm.controls['passwordCheck'] && registerForm.controls['passwordCheck'].invalid && registerForm.controls['passwordCheck'].dirty ">
                  <ion-text *ngIf="registerForm | isFormValid : 'password':['mismatchPassword']" color="danger"
                    class="form-error">
                    <span>{{"auth.password_mismatch_error" | translate}}</span>
                  </ion-text>
                </ng-container>
              </ion-col>
              <ion-col size="12" class="m-vertical-5">
                <ion-item lines="none" shape="round" color="tab-primary"
                  [class]="(registerForm | isFormValid : 'passwordCheck': ['mismatchPassword','minlength','maxlength']) ? 'ion-item-invalid item-height-61' : 'item-height-61'">
                  <!-- [position]="loginForm.controls['email'].value ? 'stacked' : 'fixed'" -->
                  <ion-label class="form-label" style="margin-top:-8px" position="floating">{{"auth.passwordCheck" |
                    translate}}
                    <ion-text color="danger">*</ion-text>
                  </ion-label>
                  <ion-input required formControlName="passwordCheck" [type]="passwordVisibility ? 'text' : 'password'">
                  </ion-input>
                  <ion-icon class="ion-align-self-center" (click)="passwordVisibility = !passwordVisibility" id="toggle-password-check-icon"
                    [color]="(registerForm | isFormValid : 'passwordCheck') ? 'danger': ''" slot="end"
                    [name]="passwordVisibility ? 'eye-off-outline' : 'eye-outline'"></ion-icon>
                </ion-item>
                <ion-text *ngIf="registerForm | isFormValid : 'passwordCheck':['minlength','maxlength']" color="danger"
                  class="form-error">
                  <span>{{"auth.password_validation_error" | translate}}</span>
                </ion-text>
                <ion-text
                  *ngIf="registerForm.controls['password'] &&  registerForm | isFormValid : 'passwordCheck':['mismatchPassword']"
                  color="danger" class="form-error">
                  <span>{{"auth.password_mismatch_error" | translate}}</span>
                </ion-text>
              </ion-col>

              <ion-col size="12" class="m-vertical-5">
                <ion-item lines="none" shape="round" color="tab-primary" class="item-height-61">
                  <ion-label class="form-label" style="margin-top:-8px" position="floating">
                    {{"auth.invitation_code" | translate}}
                  </ion-label>
                  <ion-input formControlName="refCode">
                  </ion-input>
                  <ion-icon color="dark" class="ion-align-self-center" size="small" slot="end"
                    name="close-circle-outline" (click)="clearInvitationCode()" id="guest-clear-invitation-code"></ion-icon>
                </ion-item>
              </ion-col>
              <ion-col size="12" class="m-vertical-5">
                <ion-item lines="none" shape="round">
                  <ion-label class="ion-text-wrap" [innerHtml]="'auth.register_agreements_item_extended' | translate">
                  </ion-label>
                  <!-- <ion-label class="ion-text-wrap"
          [innerHtml]="('auth.register_agreements_item' | translate: {userAgreement: userAgreementTemplate, kvkk:kvkkTemplate, iys:iysTemplate})">
        </ion-label> -->
                  <ion-checkbox slot="start"></ion-checkbox>
                </ion-item>
              </ion-col>
              <ion-col size="12" class="m-vertical-5">
                <ion-button (click)="submitRegister()" id="guest-submit-register-btn" type="submit" [disabled]="registerForm.invalid" expand="block"
                  fill="solid" shape="round">
                  {{"auth.register" | translate}}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <orbit-external-login></orbit-external-login>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>