export const VraZoneColors = {
    zones3: [
        "rgba(255, 124, 84, 1)",
        "rgba(255, 179, 156, 1)",
        "rgba(255, 235, 229, 1)",
    ],
    zones5: [
        "rgba(255, 124, 84, 1)",
        "rgba(255, 161, 132, 1)",
        "rgba(255, 179, 156, 1)",
        "rgba(255, 216, 205, 1)",
        "rgba(255, 235, 229, 1)"
    ],
    zones7:
        [
            "rgba(255, 124, 84, 1)",
            "rgba(255, 142, 108, 1)",
            "rgba(255, 161, 132, 1)",
            "rgba(255, 179, 156, 1)",
            "rgba(255, 198, 181, 1)",
            "rgba(255, 216, 205, 1)",
            "rgba(255, 235, 229, 1)"
        ]
}