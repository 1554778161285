<orbit-modal-base [hasCloseBtn]="true" (onDismiss)="dismiss(null)">
  <ion-toolbar bg="none" mode="md" class="border-basic-bottom">
    <!-- <ion-buttons slot="start">
      <ion-button (click)="resetValues()" id="unit-prefs-reset-values-btn">
        {{"general.reset" | translate}}
      </ion-button>
    </ion-buttons> -->
    <ion-title class="font-size-20 font-weight-850 ion-no-padding">{{"account.units_title" | translate}}</ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="dismiss(null)" color="dark" id="unit-prefs-dismiss-btn">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
  <ion-card-content style="max-height: calc(100% - var(--ion-safe-area-bottom) - 44px); overflow: auto;"
    class="ion-no-padding p-bottom-20">
    <ion-grid fixed class="ion-no-padding">
      <ion-row class="ion-justify-content-center ion-no-padding ion-align-items-center">
        <ion-col size="12" class="ion-no-padding">
          <div class="full-width">
            <span class="font-size-14-i font-weight-550-i">{{"unit_pref.area_lbl" | translate}}</span>
          </div>
          <div class="m-vertical-12 d-flex row gap-6">
            @for(item of unitPrefService.areaUnits; track item){
            <div>
              <ion-chip (click)="newSelectedAreaUnit = item" class="m-horizontal-0 p-vertical-6" [id]="'area-unit-opts-'+item"
                [color]="item === newSelectedAreaUnit ? 'primary' : 'light'" solid mode="ios" outline="false"
                shape="round">
                <ion-label class="full-width font-size-14-i font-weight-450-i">{{ ("unit_pref." + item) | translate}}</ion-label>
              </ion-chip>
            </div>
            }
          </div>
        </ion-col>
        <div class="divider divider-light full-width m-vertical-12"></div>
        <ion-col size="12" class="ion-no-padding">
          <div class="full-width">
            <span class="font-size-14-i font-weight-550-i">{{"unit_pref.temperature_lbl" | translate}}</span>
          </div>
          <div class="m-vertical-12 d-flex row gap-6">
            @for(item of unitPrefService.temperatureUnits; track item){
            <div>
              <ion-chip shape="round" (click)="newSelectedTemperatureUnit = item" class="m-horizontal-0 p-vertical-6"
                [id]="'temperature-unit-opts-'+item" outline="false" solid
                [color]="item === newSelectedTemperatureUnit ? 'primary' : 'light'" mode="ios">
                <ion-label class="full-width font-size-14-i font-weight-450-i">{{ ("unit_pref." + item + "_deg") | translate}}</ion-label>
              </ion-chip>
            </div>
            }
          </div>
        </ion-col>
        <div class="divider divider-light full-width m-vertical-12"></div>
        <ion-col size="12" class="ion-no-padding">
          <div class="full-width">
            <span class="font-size-14-i font-weight-550-i">{{"unit_pref.speed_lbl" | translate}}</span>
          </div>
          <div class="m-vertical-12 d-flex row gap-6">
            @for(item of unitPrefService.speedUnits; track item){
            <div>
              <ion-chip (click)="newSelectedSpeedUnit = item" class="m-horizontal-0 p-vertical-6" [id]="'speed-unit-opts-'+item"
                [color]="item === newSelectedSpeedUnit ? 'primary' : 'light'" solid mode="ios" outline="false"
                shape="round">
                <ion-label class="full-width font-size-14-i font-weight-450-i">{{ ("unit_pref." + item) | translate}}</ion-label>
              </ion-chip>
            </div>
            }
          </div>
        </ion-col>
        <div class="divider divider-light full-width m-vertical-12"></div>
        <ion-col size="12" class="ion-no-padding">
          <div class="full-width">
            <span class="font-size-14-i font-weight-550-i">{{"unit_pref.rainfall_lbl" | translate}}</span>
          </div>
          <div class="m-vertical-12 d-flex row gap-6">
            @for(item of unitPrefService.rainfallUnits; track item){
            <div>
              <ion-chip (click)="newSelectedRainfallUnit = item" class="m-horizontal-0 p-vertical-6" [id]="'rainfall-unit-opts-'+item"
                [color]="item === newSelectedRainfallUnit ? 'primary' : 'light'" solid mode="ios" outline="false"
                shape="round">
                <ion-label class="full-width font-size-14-i font-weight-450-i">{{ ("unit_pref." + item) | translate}}</ion-label>
              </ion-chip>
            </div>}
          </div>
        </ion-col>
        <div class="divider divider-light full-width m-vertical-12"></div>
        <ion-col size="12" class="ion-no-padding m-top-20">
          <ion-button (click)="saveChanges()" expand="block" fill="solid" shape="round"
            id="unit-prefs-save-changes-btn">
            {{"general.save_changes" | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</orbit-modal-base>