import { Component, Input, OnInit } from "@angular/core";
import { ModalService } from "../../services/modal.service";

@Component({
    selector: "",
    template: ""
})
export class BaseModal implements OnInit {
    @Input() id: number
    @Input() initialBreakpoint: number
    @Input() bottomPadding: number = 0;
    @Input() isBottomSheet: boolean = false
    private _contentClass: any = "bg-default";//
    public get contentClass(): any {
        return this._contentClass;
    }
    public set contentClass(value: any) {
        this._contentClass = value;
    }
    private __modalService: ModalService;
    public get _modalService(): ModalService {
        return this.__modalService;
    }
    public set _modalService(value: ModalService) {
        this.__modalService = value;
    }
    /**
     *
     */
    constructor() {

    }

    ngOnInit(): void {
        if (this.initialBreakpoint) {
            let diff = 1 - this.initialBreakpoint;
            this.bottomPadding = 100 * diff;
        }
        setTimeout(() => {
            this.contentClass = "bg-opaque"
        }, 500);
    }

    async dismiss(data, role = "canceled") {
        // setTimeout(() => {
            this.contentClass = "bg-default"
        // }, 500);
        await this._modalService.dismissByModalId(this.id, data, role)
    }
}