import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'environment'
})

export class EnvironmentPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        if (value !== null || value !== undefined)
            return environment[value]
        else
            return null
    }
}