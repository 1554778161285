<ion-header>
  <ion-toolbar class="p-horizontal-16">
    <ion-buttons slot="start">
      <ion-button class="custom-back-button" (click)="back()" color="ultra-light" id="new-password-close-btn">
        <ion-icon [slot]="'icon-only'" class="custom-back-icon" [src]="'./../../../../../assets/icon/orbit-custom-back-icon.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="openModal()" id="new-password-open-lang-options-btn">
        <span class="font-size-14 font-weight-700">
          {{"lang.default" | translate}} <ion-icon class="m-left-5" style="margin-bottom:-3px"
            name="chevron-down-outline"></ion-icon>
        </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed class="p-horizontal-25" *ngIf="!isSend">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12">
        <h2 class="font-size-28 font-weight-900 letter-space-minus-013 m-top-6">{{"auth.new_password_title" | translate}}</h2>
        <p class="font-size-14">{{"auth.new_password_text" | translate}}</p>
      </ion-col>
      <ion-col size="12">
          <ion-grid class="full-height">
            <ion-row>
              <ion-col size="12" class="m-top-11">
                <ion-item shape="round" class="border-basic input-item focus-border arpa-input-item" color="light" lines="none">
                  <ion-label class="form-label" style="margin-top:-12px" position="floating">{{'auth.password' | translate}}</ion-label>
                  <ion-input required [formControl]="passwordFormControl"
                   [type]="passwordVisibility ? 'text' : 'password'" > </ion-input>
                  <ion-icon class="ion-justify-content-center ion-align-self-center" slot="end" id="new-password-password-toggle" (click)="passwordVisibility = !passwordVisibility" [src]="( passwordVisibility ? 'eye_closed_icon_filled' : 'eye_icon_filled') | orbitIcons"></ion-icon>
                </ion-item>
              </ion-col>
              <ion-text [color]="passwordFormControl.dirty ? passwordFormControl.invalid ? 'danger' : 'success' :  'medium'">
                <p class="m-all-0">
                  {{"auth.password_validation_error" | translate}}
                </p>
              </ion-text>
            </ion-row>

          </ion-grid>
      </ion-col>
      <ion-col size="12">
        <ion-button text-transform="none" color="primary" [disabled]="passwordFormControl.invalid" (click)="save()" id="new-password-confirm-btn" shape="round" fill="solid" expand="block">
          {{"auth.confirm_btn" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid fixed class="p-horizontal-25" [class]="!isSend ? 'hidden':'hidden hidden-open'">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="12" class="ion-no-padding p-all-20">
        <h2 class="font-size-20 font-weight-900 ion-text-center">{{"auth.password_changed_title" | translate}}</h2>
      </ion-col>
      <ion-col size="12" class="text-center">
        <div class="d-flex row full-width justify-center">
          <img src="../../../../../assets/images/password_changed_image.svg">
        </div>
      </ion-col>
      <ion-col size="12">
        <p class="font-size-14 font-weight-600  ion-text-center" color="medium" >{{"auth.password_changed_desc" | translate}}</p>
      </ion-col>
      <ion-col size="12">
        <ion-button text-transform="none" color="primary" (click)="redirect()" expand="block" style="height: 55px;" id="new-password-continue-btn">
          {{"auth.continue_btn" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>