import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppInsightsLoggingService } from '../services/logging/logging.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
})
export class ErrorLogInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        return next.handle(req).pipe(
            catchError((err, observable) => {
                if (environment.production) {
                    let errorDetails = ""
                    if (err.error && typeof err.error == "string") {
                        var rawErrorDetailArray: Array<string> = err.error.split("<!--");
                        var rawErrorData = rawErrorDetailArray.find(t => t.includes("[HttpException]"))
                        if (rawErrorData && rawErrorData != "") {
                            errorDetails = rawErrorData.split("-->").filter(t => t == "").join("-->")
                        }
                    }
                    err.error = errorDetails
                    AppInsightsLoggingService.logException({ message: err.message, name: err.name }, 5, { ...err })
                }
                // return observable
                return of(err)
            })
        );
    }
}