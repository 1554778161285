import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { StorageKeys } from "../models/enums/storage-keys";
import { AccountService } from "../services/account.service";
import { StorageService } from "../services/storage.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard  {

    /**
     *
     */
    constructor(private storageService: StorageService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>(
            observe => {
                if (AccountService.token) {
                    return observe.next(true);
                }
                else {
                    this.storageService.get(StorageKeys.TOKEN).then(response => {
                        if (response.IsSuccess && response.Data !== '') {
                            AccountService.token = response.Data
                            return observe.next(true)
                        } else {
                            let url = state.url;
                            this.router.navigate(["auth"], { queryParams: { redirect: url } })
                            return observe.next(false)
                        }
                    }, error => {
                        let url = state.url;
                        this.router.navigate(["auth"], { queryParams: { redirect: url } })
                        return observe.next(false)
                    })
                }
            }
        )
    }

}