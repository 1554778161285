import { SetScreenName } from 'src/app/core/helper/class/screen-name.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GestureController, Gesture } from '@ionic/angular';
import { ComponentRef } from '@ionic/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AuthModalComponent } from '../../../../pages/auth/components/auth-modal/auth-modal.component';
import { App } from "@capacitor/app";
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ClientBaseApiService } from 'src/app/core/api/client-base-api.service';
import { HttpRequestTypes } from 'src/app/core/models/enums/http-request-types';
import { TranslateService } from '@ngx-translate/core';
import { AutoplayOptions, Swiper } from 'swiper/types';
import { SwiperComponent } from 'swiper/angular';
import { AnalyticService } from 'src/app/core/services/analytic.service';


@Component({
  selector: 'onboarding-tutorial',
  templateUrl: './onboarding-tutorial.component.html',
  styleUrls: ['./onboarding-tutorial.component.scss'],
})
export class OnboardingTutorialComponent extends BaseModal implements OnInit {
  @ViewChild("swipeArea") swipeArea: ElementRef
  @ViewChild("swiperItem") swiperItem: SwiperComponent

  list: Array<any> = []
  current: number = 0
  interval: any = null
  isStoped: boolean = false
  isUserLogged: boolean = false
  swiperEffectStyle = {
    prev: {
      shadow: true,
      translate: ['-100%', 0, 1]
    },
    next: {
      translate: [0, 0, 0]
    }
  }

  autoPlayOpts: AutoplayOptions = {
    delay: 1000,
    stopOnLastSlide: true,
    disableOnInteraction:false,
    pauseOnMouseEnter: false
  }
  constructor(public gestureCtrl: GestureController, public langService: LanguageService, private analyticService:AnalyticService, private translate: TranslateService, private clientApi: ClientBaseApiService, private domSanitizer: DomSanitizer, private storageService: StorageService, private modalService: ModalService, private router: Router) {
    super()
    super._modalService = modalService
    this.list = [] //new Array(4).fill(1).map((t, index) => { return { id: index, value: 0, image: "https://picsum.photos/id/" + Math.ceil(Math.random() * 100) + "/600/800", title: `Title - ${index}`, text: `Text - ${index}` } })
  }

  ngOnInit() {
    this.storageService.get(StorageKeys.USER).then(userResp => {
      if (userResp.IsSuccess && userResp.Data && Object.keys(userResp.Data).length > 0) {
        this.storageService.get(StorageKeys.TOKEN).then(response => {
          if (response.IsSuccess && response.Data && response.Data !== '') {
            this.isUserLogged = true;
          }
        })
      }
    })

    this.clientApi.externalApiCall<any>({ url: `${environment.onboardingDataUrl}?q=${Math.random()}`, payload: null, requestType: HttpRequestTypes.GET }).subscribe(rawData => {
      let lang = this.translate.currentLang // LanguageService.currentLanguage?.code.toLowerCase() || "en"
      let langPrefix = rawData.availableLanguages.find(t => t === lang) || rawData.availableLanguages[0]
      let imageBaseUrl = environment.onboardingImagesBaseUrl
      this.list = rawData.data.map(t => {
        t.imageUrl = imageBaseUrl + langPrefix + "/" + t.imageName
        t.value = 0
        return t
      })
      this.list.sort((a, b) => {
        let valueA = a["id"], valueB = b["id"];
        if (valueA > valueB)
          return 1;
        if (valueA < valueB)
          return -1
        return 0
      })
      this.addDocumentListeners()
      this.initAutomation()
    }, err => { })
  }

  initAutomation() {
    if(this.swiperItem){
      console.log(this.swiperItem)
      setTimeout(() => {
        this.swiperItem
        // elementRef.nativeElement.slideNext()
      }, 1000);
    }else{
      this.initAutomation()
    }
  }

  openModal() {
    this.langService.openOptionsMenu()
  }



  openAddField() {
    this.dismiss(null)
    this.router.navigate(["add-field"], { queryParams: { createguest: true } })
  }

  override dismiss(data: any, role?: string): Promise<void> {
    if (Capacitor.getPlatform() === "web") {
      this.storageService.set(StorageKeys.TUTORIAL_LAST_SHOWED, environment.appVersion).then(storeResp => { })
    } else {
      if (Capacitor.isNativePlatform())
        App.getInfo().then(info => {
          this.storageService.set(StorageKeys.TUTORIAL_LAST_SHOWED, info.build).then(storeResp => { })
        })
    }
    return super.dismiss(data, role)
  }
  continueToApp() {
    this.dismiss(null, "continue_to_app")
  }

  addDocumentListeners() {
    let element = document.getElementsByClassName("onboarding-btn-signin")
    if (element) {
      
      document.getElementsByClassName("onboarding-btn-signin")[0]?.addEventListener("click", (ev) => {
        this.router.navigate(["/auth"], {queryParams: {state: "login"} })
        this.dismiss(null, "to_login")
      })

      document.getElementsByClassName("onboarding-btn-register")[0]?.addEventListener("click", (ev) => {
        this.router.navigate(["/auth"], {queryParams: {state: "register"} })
        this.dismiss(null, "to_register")
      })
      
    } else {
      this.addDocumentListeners()
    }
  }
  slideChanged(ev) {
    console.log(ev)
    this.current = ev[0].realIndex

  }
  swiperOn = (ev) => {
    console.log(ev)
  }
}
