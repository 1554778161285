import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { FarmService } from 'src/app/core/services/farm.service';
import { ModalService } from 'src/app/core/services/modal.service';
import FarmControl from 'src/app/core/services/class/farm/farm-control.class';
import { IFarmModel } from 'src/app/core/models/interfaces/farm-model';

@Injectable(
  {
    providedIn: "root"
  }
)
export class FieldListFilterService {
  /**
   * @deprecated
   */
  filteredFarmList: Array<IFarmModel> = []
  /**
   * @deprecated
   */
  searchResultList: Array<IFarmModel> = []
  filteredResult: FarmControl[] = []
  searchResult: FarmControl[] = []
  searchQuery = null
  filterOptions: Array<IListModalModel> = [
    {
      id: 1,
      key: "active_farms",
      value: this.langService.instant("my_fields.active_farms_lbl"),
      isChecked: false
    },
    {
      id: 2,
      key: "passive_farms",
      value: this.langService.instant("my_fields.passive_farms_lbl"),
      isChecked: false
    },
    // {
    //   id: 3,
    //   key: "farms_with_notes",
    //   value: this.langService.instant("my_fields.farms_with_notes_lbl"),
    //   isChecked: false
    // }
  ]
  constructor(private farmService: FarmService, private router: Router, private modalService: ModalService, private langService: TranslateService) {
  }

  initFarmList() {
    let filteredList = this.farmService.allFarms //this.farmService.farmList.filter(t => true)
    // this.filteredFarmList = []
    this.filteredResult = []
    Object.assign(this.filteredResult, filteredList);
    let searchList = this.filteredResult.filter(t => true)
    this.searchResult = []
    Object.assign(this.searchResult, searchList)
  }

  onFarmsSearchChange(ev) {
    console.log(ev)
    let query = ev.detail.value;
    let queryFn = (ev) => true
    this.searchResult = []
    this.searchQuery = query;
    if (!(query == null || query == undefined)) {
      queryFn = (ev: FarmControl) => ev.farm.FarmName.toLowerCase().includes(query.toLowerCase())
    }
    Object.assign(this.searchResult, this.filteredResult.filter(queryFn))
  }
  openFarmsFilters_old() {
    this.filterOptions = [
      {
        id: 1,
        key: "active_farms",
        value: this.langService.instant("my_fields.active_farms_lbl"),
        isChecked: false
      },
      {
        id: 2,
        key: "passive_farms",
        value: this.langService.instant("my_fields.passive_farms_lbl"),
        isChecked: false
      },
      // {
      //   id: 3,
      //   key: "farms_with_notes",
      //   value: this.langService.instant("my_fields.farms_with_notes_lbl"),
      //   isChecked: false
      // }
    ]
    this.modalService.presentListModal({
      confirmBtnText: "general.submit",
      hasConfirmBtn: true,
      isFullscreen: false,
      isMultiple: true,
      itemList: this.filterOptions,
      title: "my_fields.filter_title",
      showSearchbar: false
    }).then(response => {
      response.modalObj.onDidDismiss().then(dismiss => {
        if (dismiss.role == "itemsSelected") {
          dismiss.data.selectedItems.forEach(el => {
            let option = this.filterOptions.find(t => t.id == el)
            if (option)
              option.isChecked = el.isChecked
          });
          this.filterResults_old();
        }
      })
    })
  }


  openFarmsFilters() {
    this.filterOptions = [
      {
        id: 1,
        key: "pro",
        value: this.langService.instant("my_fields.filter_pro_lbl"),
        isChecked: this.previousFilter.some(t => t.key == 'pro')
      },
      {
        id: 2,
        key: "basic",
        value: this.langService.instant("my_fields.filter_basic_lbl"),
        isChecked: this.previousFilter.some(t => t.key == 'basic')
      },
      {
        id: 3,
        key: "expired",
        value: this.langService.instant("my_fields.filter_expired_lbl"),
        isChecked: this.previousFilter.some(t => t.key == 'expired')
      },
      // {
      //   id: 4,
      //   key: "not_purchased",//"free",
      //   value: this.langService.instant("my_fields.filter_free_lbl"),
      //   isChecked: this.previousFilter.some(t => t.key == 'not_purchased')
      // }
    ]
    this.modalService.presentListModal({
      confirmBtnText: "general.submit",
      hasConfirmBtn: true,
      isFullscreen: false,
      isMultiple: true,
      itemList: this.filterOptions,
      title: "my_fields.filter_title",
      showSearchbar: false
    }).then(response => {
      response.modalObj.onDidDismiss().then(dismiss => {
        if (dismiss.role == "itemsSelected") {
          dismiss.data.selectedItems.forEach(el => {
            let option = this.filterOptions.find(t => t.id == el)
            if (option)
              option.isChecked = el.isChecked
          });
          this.filterResults();
        }
      })
    })
  }


  filterResults_old() {
    let tempFilterOptions = this.filterOptions.filter(t => t.isChecked).map(t => t.key).join("::");
    let filterFn = (ev: FarmControl) => {
      // let isPassiveCheck = ev.PurchaseStatus === FarmPurchaseStates.NO_PURCHASED.toString() // 'NotPurchased'
      // let isActiveCheck = ev.PurchaseStatus === FarmPurchaseStates.PURCHASED.toString() // 'Purchased'
      let planNames = ["basic", "pro", "ultra"]
      let isPassiveCheck = ev.farm.PlanName.toLowerCase() === 'free' // 'NotPurchased'
      let isActiveCheck = planNames.some(t => t === ev.farm.PlanName.toLowerCase()) // 'Purchased'
      let hasNoteCheck = ev.farm.FlagCount > 0
      let response = false;
      if (tempFilterOptions == '')
        response = true
      if (tempFilterOptions.includes("active_farms")) {
        response = response || isActiveCheck
      }
      if (tempFilterOptions.includes("passive_farms")) {
        response = response || isPassiveCheck
      }
      if (tempFilterOptions.includes("farms_with_notes")) {
        response = response || hasNoteCheck
      }
      return response
    }
    this.searchResult = []
    let firstFilter = this.farmService.allFarms.filter(filterFn) //this.farmService.farmList.filter(filterFn)
    this.filteredResult = []
    Object.assign(this.filteredResult, firstFilter)
    let queryFn = (ev) => true
    if (!(this.searchQuery == null || this.searchQuery == undefined)) {
      queryFn = (ev: FarmControl) => ev.farm.FarmName.toLocaleLowerCase("tr").includes(this.searchQuery.toLocaleLowerCase())
    }
    let result = firstFilter.filter(queryFn)
    Object.assign(this.searchResult, result)
    // this.changeRef.markForCheck()
  }

  previousFilter: Array<any> = []
  filterResults() {
    let tempFilterOptions = this.filterOptions.filter(t => t.isChecked);
    let filterFn = (ev: FarmControl) => {
      let response: boolean = false
      if (tempFilterOptions.length === 0)
        response = true

      if (tempFilterOptions.some(t => t.key === "expired")) {
        response = response || new FarmControl(null).buildFarm(ev.farm).isExpired //(ev.farm.PlanName.toLowerCase() == "free" && ev.farm.SatelliteInfo != null)
      }

      if (tempFilterOptions.some(t => t.key === 'not_purchased')) {
        response = response || (ev.farm.PlanName.toLowerCase() == 'free' && ev.farm.SatelliteInfo == null)
      }

      response = response || tempFilterOptions.some(t => t.key == ev.farm.PlanName.toLowerCase())
      return response
    }
    this.previousFilter = tempFilterOptions;
    this.searchResult = []
    let firstFilter = this.farmService.allFarms.filter(filterFn)
    this.filteredResult = firstFilter.filter(t => true)
    let queryFn = (ev) => true
    if (!(this.searchQuery == null || this.searchQuery == undefined)) {
      queryFn = (ev: FarmControl) => ev.farm.FarmName.toLocaleLowerCase("tr").includes(this.searchQuery.toLocaleLowerCase())
    }
    let result = firstFilter.filter(queryFn)
    Object.assign(this.searchResult, result)
  }

  openWeatherForecast() {
    this.router.navigate(["weather"])
  }
}
