import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NETWORK_ERROR_LANG_TEXTS } from 'src/app/core/constants/network-error-texts.constant';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-network-alert-modal',
  templateUrl: './network-alert-modal.component.html',
  styleUrls: ['./network-alert-modal.component.scss'],
})
export class NetworkAlertModalComponent extends BaseModal implements OnInit {

  shakeElements: boolean = false;
  currentLangCode: string = "tr"
  langWords: { [key: string]: { [key: string]: string } } = NETWORK_ERROR_LANG_TEXTS
  constructor(private networkService: NetworkService, private loadingService: LoadingService, private translateService: TranslateService, private storageService: StorageService, private modalService: ModalService, private modalController: ModalController) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    if (Capacitor.isNativePlatform())
      Haptics.vibrate({ duration: 1000 }).then(res => { })
    // NetworkService.networkListener.subscribe(res => {
    //   if (res === "online")
    //     this.dismiss(null)
    // })

    this.storageService.get(StorageKeys.LANG).then(res => {
      let langCode = ""
      if (res.IsSuccess) {
        langCode = res.Data
      } else {
        langCode = this.translateService.getBrowserLang()
      }
      if (Object.keys(this.langWords).some(t => t === langCode)) {
        this.currentLangCode = langCode
      } else {
        this.currentLangCode = "en"
      }
    })

    this.shakeElements = true;
    setTimeout(() => {
      this.shakeElements = false;
    }, 1500);
  }

  retry() {
    this.loadingService.showLoading();
    Network.getStatus().then(e => {
      // setTimeout(() => {
      if (e.connected) {
        NetworkService.setState = "online"
        this.modalController.dismiss().then(a => {
          console.log(a)
        }).catch(err => {
          console.error(err)
        })
        // this.dismiss(null).then()
        this.translateService.reloadLang(this.currentLangCode).subscribe({
          next: res => {
            this.translateService.onLangChange.next({ lang: this.currentLangCode, translations: res })
          }, complete: () => {
            this.loadingService.hideLoading();
            // this.dismiss(null).then()
          }
        })
      } else {
        this.loadingService.hideLoading();
        if (Capacitor.isNativePlatform())
          Haptics.vibrate({ duration: 1000 }).then(res => { })
        this.shakeElements = true;
        setTimeout(() => {
          this.shakeElements = false;
        }, 1500);
      }
      // }, 2000);

    })
  }
  dismissAlt(){
    this.modalController.dismiss().then()
  }
  // override async dismiss(data: any, role?: string): Promise<void> {
  //   super.dismiss(data, role)
  //   // this.modalController.dismiss().then()
  // }



}
