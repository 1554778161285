import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { UserData } from "../../models/classes/user-data";
import { IListModalModel } from "../../models/interfaces/modal-components-models";
import { AccountService } from "../../services/account.service";

@Component({
    selector: "orbit-popover-template",
    template: `<ion-list class="ios-safe-area-margin m-vertical-5" bg="none"><ion-item bg="none" lines="none" *ngFor="let item of itemList;let index=index"><ion-label (click)="itemClicked(item)" [id]="'popover-item-'+index">{{item.value}}</ion-label></ion-item></ion-list>`
})
export class PopoverTemplateComponent {
    @Input() itemList: Array<IListModalModel>
    /**
     *
     */
    constructor(private popoverController: PopoverController) {

    }
    itemClicked(itemProps) {
        this.popoverController.dismiss(itemProps, 'clicked')
    }


}