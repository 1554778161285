<ion-grid fixed [style]="style">
  <ion-row class="ion-justify-content-center ion-align-items-center full-height full-width">
    <ion-col size="12">
      <ion-grid fixed>
        <ion-row class="ion-justify-content-center ion-text-center">
          <ion-img imageLoad class="empty-img" *ngIf="image && image!=''" [src]="image"></ion-img>
          <ion-col size="12" *ngIf="title && title!=''">
            <h3 class="empty-title" [innerHTML]="title"></h3>
          </ion-col>
          <ion-col size="12" *ngIf="description && description!=''">
            <p class="empty-desc" [innerHTML]="description"></p>
          </ion-col>
          <ng-content>

          </ng-content>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>