import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SCREEN_NAME_BY_COMPONENT_NAME } from 'src/app/core/constants/url-screenname.constant';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'sms-validation',
  templateUrl: './sms-validation.component.html',
  styleUrls: ['./sms-validation.component.scss', '../../auth.page.scss'],
})
export class SmsValidationComponent implements OnInit, OnChanges {
  otpLength: number = 4
  number: string = "50 80";
  data: any;
  otpForm: UntypedFormGroup = new UntypedFormGroup({
    otp: new UntypedFormControl("", Validators.required)
  });
  intervalId: any;
  isSendCode: boolean = false;
  counter: { min: number, sec: number }

  @Input() phoneNumber: any;
  @Input() rawPhoneNumber: string;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();
  @Output() onSendAgain: EventEmitter<any> = new EventEmitter();

  OtpConfig = { length: this.otpLength, allowNumbersOnly: true, placeholder: '-', inputClass: "otp-custom-input" }


  constructor(private modalService: ModalService, private analyticService:AnalyticService) {
    // super();
    // super._modalService = modalService;
  }
  ngOnChanges(changes: SimpleChanges): void {
  }


  ngOnInit() {
    this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.ForgetPasswordComponentSmsValidation.screenName
    if (this.phoneNumber) {
      this.phoneNumber = this.phoneNumber.split("").map(t => !Number.isNaN(Number.parseInt(t)) ? t : null).filter(t => t).join("")
      this.number = (this.phoneNumber % 10000).toString()
      // this.number = this.rawPhoneNumber
      if (this.number.trim() === "")
        this.number = "0000"
    }else{
      this.number = "----"
    }
    this.startTimer()
  }


  public startTimer() {
    this.counter = { min: 3, sec: 0 } // choose whatever you want
    this.intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.min -= 1;
        this.counter.sec = 59;
      }
      else
        this.counter.sec -= 1
      if (this.counter.min === 0 && this.counter.sec === 0) {
        clearInterval(this.intervalId)
        this.isSendCode = false;
      }
    }, 1000)
  }

  onOtpChange(event) {
    if (event.length == this.otpLength) {
      this.otpForm.controls["otp"].setValue(event);
    } else {
      this.otpForm.controls["otp"].setValue(null);
    }
  }

  sendAgain() {
    // this.startTimer();
    this.otpForm.controls["otp"].setValue(null);
    this.onSendAgain.emit();
  }

  requestCode() {
    this.onConfirm.emit(this.otpForm.value.otp);
  }

}
